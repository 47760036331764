import { useCallback, useRef, useState } from "react";

import { streamTTSMessage } from "@/data/message";
import { MessageVoiceContext } from "./MessageVoiceContext";

import type { ReactNode } from "react";

export const MessageVoiceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [playingMessageId, setPlayingMessageId] = useState<string | null>(null);
  const [isPaused, setIsPaused] = useState(false);

  const playbackRef = useRef({
    audioContext: null as AudioContext | null,
    sources: [] as AudioBufferSourceNode[],
    chunks: [] as Uint8Array[],
    audioElementFromUrl: null as HTMLAudioElement | null,
    isDecoding: false,
    isStopped: false,
    streamingDone: false,
    abortController: new AbortController(),
    currentPlaybackId: 0,
  });

  const isPlaying = playingMessageId !== null && !isPaused;

  const stopPlayback = useCallback(() => {
    const pb = playbackRef.current;
    pb.currentPlaybackId++;
    pb.isStopped = true;
    pb.abortController.abort();

    pb.sources.forEach(source => {
      try {
        source.onended = null;
        source.disconnect();
        source.stop(0);
      } catch (e) {
        console.error("error stopping source:", e);
      }
    });
    pb.sources = [];

    if (pb.audioContext) {
      void pb.audioContext.suspend().then(() => {
        pb.audioContext?.close().catch(e => console.error("error closing audioContext:", e));
        pb.audioContext = null;
      });
    }

    if (pb.audioElementFromUrl) {
      pb.audioElementFromUrl.pause();
      pb.audioElementFromUrl.src = "";
      pb.audioElementFromUrl = null;
    }

    pb.chunks = [];
    pb.isDecoding = false;
    pb.streamingDone = false;

    setPlayingMessageId(null);
    setIsPaused(false);
  }, []);

  const decodeAndPlayNextChunk = async (playbackId: number, final = false) => {
    const pb = playbackRef.current;

    if (!pb.audioContext) {
      pb.audioContext = new AudioContext();
    }

    if (pb.isStopped || pb.currentPlaybackId !== playbackId) {
      pb.isDecoding = false;
      return;
    }
    pb.isDecoding = true;

    if (pb.chunks.length === 0) {
      pb.isDecoding = false;
      if (final || pb.streamingDone) {
        stopPlayback();
      }
      return;
    }

    const nextChunk = pb.chunks.shift()!;
    try {
      const decoded = await pb.audioContext.decodeAudioData(nextChunk.buffer);

      if (pb.isStopped || !pb.audioContext || pb.currentPlaybackId !== playbackId) {
        pb.isDecoding = false;
        return;
      }
      const source = pb.audioContext.createBufferSource();
      source.buffer = decoded;
      source.connect(pb.audioContext.destination);
      pb.sources.push(source);

      source.onended = () => {
        pb.isDecoding = false;

        const idx = pb.sources.indexOf(source);
        if (idx >= 0) {
          pb.sources.splice(idx, 1);
        }

        const shouldScheduleNextChunk = pb.sources.length === 0 && (final || pb.streamingDone);

        if (shouldScheduleNextChunk) {
          const streamingDone = pb.sources.length === 0 && pb.streamingDone;

          if (streamingDone) {
            stopPlayback();
          } else {
            void decodeAndPlayNextChunk(playbackId, final);
          }
        }
      };
      source.start();
    } catch (err) {
      console.error("decodeAudioData error:", err);

      pb.isDecoding = false;
      if (!pb.isStopped && pb.currentPlaybackId === playbackId) {
        void decodeAndPlayNextChunk(playbackId, final);
      }
    }
  };

  const playMessage = async (messageId: string) => {
    if (playingMessageId) {
      stopPlayback();
    }
    const pb = playbackRef.current;

    pb.currentPlaybackId++;
    const playbackId = pb.currentPlaybackId;

    pb.abortController = new AbortController();
    pb.isStopped = false;
    pb.audioContext = null;
    pb.sources = [];
    pb.chunks = [];
    pb.isDecoding = false;
    pb.audioElementFromUrl = null;
    pb.streamingDone = false;

    setPlayingMessageId(messageId);
    setIsPaused(false);

    try {
      const result = await streamTTSMessage(
        messageId,
        (chunk: Uint8Array) => {
          const shouldAbort = pb.isStopped || pb.currentPlaybackId !== playbackId;

          if (shouldAbort) {
            return;
          }

          pb.chunks.push(chunk);
          if (!pb.isDecoding) {
            void decodeAndPlayNextChunk(playbackId);
          }
        },
        { signal: pb.abortController.signal }
      );

      const isJSONresponse = !!result.url;

      if (isJSONresponse) {
        pb.audioElementFromUrl = new Audio(result.url);

        pb.audioElementFromUrl.play().catch(err => {
          console.error("error playing audio from url:", err);
          stopPlayback();
        });

        pb.audioElementFromUrl.onended = () => {
          stopPlayback();
        };
        return;
      }
    } catch (error) {
      console.error("error streaming voice:", error);

      stopPlayback();
    } finally {
      const shouldContinue = !pb.isDecoding && !pb.audioElementFromUrl;

      if (shouldContinue) {
        void decodeAndPlayNextChunk(playbackId, true);
      } else {
        pb.streamingDone = true;
      }
    }
  };

  const pausePlayback = async () => {
    const pb = playbackRef.current;
    if (!playingMessageId) {
      return;
    }

    if (pb.audioElementFromUrl) {
      pb.audioElementFromUrl.pause();
    } else if (pb.audioContext && pb.audioContext.state === "running") {
      await pb.audioContext.suspend();
    }

    setIsPaused(true);
  };

  const resumePlayback = async () => {
    const pb = playbackRef.current;
    if (!playingMessageId) {
      return;
    }

    if (pb.audioElementFromUrl) {
      pb.audioElementFromUrl.play().catch(err => {
        console.error("error resuming audio from url:", err);
        stopPlayback();
      });
    } else if (pb.audioContext && pb.audioContext.state === "suspended") {
      await pb.audioContext.resume();
    }
    setIsPaused(false);
  };

  return (
    <MessageVoiceContext.Provider
      value={{
        playMessage,
        stopPlayback,
        pausePlayback,
        resumePlayback,
        playingMessageId,
        isPlaying,
        isPaused,
      }}
    >
      {children}
    </MessageVoiceContext.Provider>
  );
};
