import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { User } from "@/types/user";
import { toast } from "react-toastify";
import type { Workspace } from "@/types/workspace";
import { multipleWorkspaceUsersKeys } from "@/data/queries/workspace/useGetMultipleWorkspaceUsers";
import { multipleWorkspaceAllUsersKeys } from "@/data/queries/workspace/useGetAllWorkspaceUsers";

type Props = {
  userId: User["_id"];
  workspaceId: Workspace["_id"];
};

type Response = {
  message: string;
};
const deleteUserFromWorkspace = async ({ userId, workspaceId }: Props) => {
  const { data } = await restClient.delete<Response>(apiPaths.deleteUserFromWorkspace(userId, workspaceId));
  return data;
};

export const useDeleteUserFromWorkspace = (workspaceId?: string) => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<Response>, Props>({
    mutationFn: deleteUserFromWorkspace,
    onSuccess: async data => {
      workspaceId && (await queryClient.invalidateQueries({ queryKey: multipleWorkspaceUsersKeys.id(workspaceId) }));
      await queryClient.invalidateQueries({ queryKey: multipleWorkspaceAllUsersKeys.all });
      toast.success(data.message);
    },
  });
};
