import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import type { Invoice } from "@/types/subscriptions";

export const BillingInvoices = ({ invoices }: { invoices: Invoice[] | undefined }) => {
  const downloadPDF = (downloadLink: string) => {
    const link = document.createElement("a");
    link.href = downloadLink;
    link.download = "Invoice.pdf"; // You can dynamically set the filename based on invoice details if needed
    document.body.appendChild(link); // Append to body
    link.click(); // Simulate click
    document.body.removeChild(link); // Remove from body
  };

  const displayInvoiceStatus = (invoice: Invoice) => {
    switch (invoice.status) {
      case "paid":
        return <span className="capitalize">{invoice.status}</span>;
      default:
        return (
          <a href={invoice.paymentLink} target="_blank" rel="noreferrer" className="flex items-center">
            <HybridTooltipPopover heading="Payment Issue" triggerClassName="mr-2 red">
              <p>There was an issue processing your invoice. Please resolve it promptly to avoid service disruption.</p>
              <Button type="submit" className="my-1" onClick={() => window.open(invoice.paymentLink, "_blank")}>
                Finalize payment
              </Button>
            </HybridTooltipPopover>
            <Button
              size="tiny"
              variant="secondary"
              type="submit"
              className="p-1.5"
              onClick={() => window.open(invoice.paymentLink, "_blank")}
            >
              Pay now
            </Button>
          </a>
        );
    }
  };
  return (
    <div className="pb-10 pt-5 text-xs font-medium">
      <div className="mb-3 text-lg font-bold">Invoices</div>
      {invoices ? (
        <>
          <div className="mb-3 grid grid-cols-[1.5fr_1fr_1fr_1fr_30px] gap-3 px-2 py-1">
            <div>Number</div>
            <div>Amount</div>
            <div>Status</div>
            <div>Date</div>
          </div>
          <div className="grid gap-5">
            {invoices.map(invoice => (
              <div
                key={invoice.invoiceId}
                className="grid grid-cols-[1.5fr_1fr_1fr_1fr_30px] items-center gap-3 rounded-md bg-neutral-50 px-2 py-3 hover:bg-primary-50"
              >
                <span>{invoice.invoiceId}</span>
                <span>
                  {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(invoice.amount / 100)}
                </span>
                <span className="">{displayInvoiceStatus(invoice)}</span>
                <span className="">{new Date(invoice.invoiceDate * 1000).toLocaleDateString()}</span>

                <IconButton
                  variant="tertiary"
                  icon={<Icons.Download className="size-4" />}
                  onClick={() => downloadPDF(invoice.downloadLink)}
                >
                  <span className="sr-only">Delete invoice</span>
                </IconButton>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center">No invoices yet</div>
      )}
    </div>
  );
};
