import { Account } from "@/components/Profiles/Account";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { AgentsList } from "@/components/Profiles/AgentsList";
import { AccountActivities } from "@/components/Profiles/AccountActivities";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import { useBookmarkChatHistoryContext } from "@/contexts/BookmarkChatHistoryContext/useBookmarkChatHistoryContext";
import { useLogOut } from "@/hooks/useLogOut";
import { NavigationProfileSection, NavigationProfileSections } from "./NavigationProfileSection";
import { SubscriptionTierButton } from "../navigation/SubscriptionTierButton";
import { useGetUser } from "@/data/queries/useGetUser";
import { Billing } from "./Billing/Billing";
import { Team } from "./Team/Team";
import { Button } from "../ui/button";
import { IconButton } from "../ui/icon-button";
import { AccountSecurity } from "@/components/Profiles/AccountSecurity";
import { WorkspaceSection } from "@/components/Profiles/WorkspaceSection/WorkspaceSection";
import { DialogTitle } from "@radix-ui/react-dialog";
import type { TypeFromConstObject } from "@/types/types";

const sectionsMap = {
  [NavigationProfileSections.ACCOUNT.ACCOUNT]: Account,
  [NavigationProfileSections.ACCOUNT.ACCOUNT_ACTIVITIES]: AccountActivities,
  [NavigationProfileSections.ACCOUNT.BILLING]: Billing,
  [NavigationProfileSections.ACCOUNT.SECURITY]: AccountSecurity,
  [NavigationProfileSections.WORKSPACES.AGENTS]: AgentsList,
  [NavigationProfileSections.WORKSPACES.TEAM]: Team,
  WORKSPACE: WorkspaceSection,
};

export const ProfileDialog = () => {
  const { navigationProfileOpen, setNavigationProfileOpen } = useNavigationProfileContext();
  const { setIsOpen } = useBookmarkChatHistoryContext();
  const logOutUser = useLogOut();
  const { user } = useGetUser();

  const logOut = () => {
    logOutUser();
    setNavigationProfileOpen({ open: false, section: null, mode: "account" });
    setIsOpen(false);
  };

  const onOpenHandler = (isOpen: boolean) => {
    setNavigationProfileOpen(prev => {
      return {
        open: isOpen,
        section: isOpen ? prev.section : null,
        mode: isOpen ? prev.mode : null,
      };
    });
  };

  const ActiveSectionComponent =
    navigationProfileOpen.section &&
    navigationProfileOpen.mode === "account" &&
    navigationProfileOpen.section !== "WORKSPACE"
      ? sectionsMap[
          navigationProfileOpen.section as
            | TypeFromConstObject<typeof NavigationProfileSections.ACCOUNT>
            | TypeFromConstObject<typeof NavigationProfileSections.WORKSPACES>
        ]
      : navigationProfileOpen.section && navigationProfileOpen.mode === "workspace"
        ? sectionsMap.WORKSPACE
        : () => null;

  return (
    <Dialog onOpenChange={onOpenHandler} open={navigationProfileOpen.open}>
      <DialogContent className="gap-0 p-0">
        <div className="flex h-full flex-col sm:flex-row">
          <div
            className={cn(
              "flex h-full flex-col items-center gap-6 gap-y-5  border-neutral-200 px-6 py-4 sm:border-r-[1.5px]",
              {
                "hidden sm:flex": navigationProfileOpen.section,
              }
            )}
          >
            <DialogTitle className="w-full text-start text-xl font-bold">Settings</DialogTitle>
            <NavigationProfileSection />

            <div className="mt-auto w-full">
              {user?.subscription.tier !== undefined && (
                <SubscriptionTierButton tier={user.subscription.tier} className="w-full" />
              )}
              <Button variant="ghost" className="mx-auto mt-2 w-full text-base font-medium" onClick={logOut}>
                Log out
              </Button>
            </div>
          </div>
          {navigationProfileOpen.section && (
            <div className="w-full">
              <IconButton
                variant="tertiary"
                size="medium"
                className="relative left-1 top-1 sm:hidden"
                onClick={() => {
                  setNavigationProfileOpen(prev => ({ ...prev, section: null }));
                }}
                icon={<Icons.Arrow />}
              >
                <span className="sr-only">Go Back to Profile Menu</span>
              </IconButton>
              <div className="flex h-[calc(100dvh-54px)] flex-col gap-6 overflow-y-auto px-6 py-4 sm:h-full ">
                <ActiveSectionComponent />
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
