import { Icons } from "@/components/ui/icons";
import type { UploadChatFileResponse } from "@/data/mutations/useUploadFileToConversation";

type Props = {
  attachments: UploadChatFileResponse[];
};

export const MessageCloudAttachments = ({ attachments }: Props) => {
  return (
    <div className="mt-3 flex w-full items-center gap-4 overflow-x-auto">
      {attachments.map(attachment => {
        const isImageFileType = attachment.mimetype.split("/")[0] === "image";
        const isPdfFileType = attachment.mimetype === "application/pdf";
        if (isImageFileType) {
          return (
            <div
              title={attachment.originalname}
              key={attachment.filename}
              style={{ backgroundImage: `url('${attachment?.url}')` }}
              className="group relative size-16 min-w-16 rounded-md border border-neutral-300 bg-neutral-300 bg-cover bg-center"
            />
          );
        }
        return (
          <div
            title={attachment.originalname}
            key={attachment.filename}
            className="group relative flex gap-2 rounded-md border border-neutral-300 p-3"
          >
            <div className="flex size-10 min-w-10 items-center justify-center rounded bg-primary-400">
              {isPdfFileType ? (
                <Icons.Pdf className="size-6 text-white [&>*]:!fill-primary-400" />
              ) : (
                <Icons.File className="size-6 text-white" />
              )}
            </div>
            <div className="flex flex-col">
              <span className="max-w-52 truncate text-base font-bold">{attachment.originalname}</span>
              <span className="text-xs font-medium uppercase text-neutral-400">
                {attachment.mimetype.split("/")[1]}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
