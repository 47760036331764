import { Icons } from "@/components/ui/icons";
import { voteMessage } from "@/data/message";
import { conversationMessagesKeys } from "@/data/queries/useGetConversationMessages";
import type { Conversation, ConversationMessagesResponse, Message } from "@/types/conversation";
import type { InfiniteData } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { useCopyNotify } from "@/hooks/useCopyNotify";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { useCreateMessageBookmark } from "@/data/mutations/bookmarks/useCreateUserMessageBookmark";
import { useDeleteUserMessageBookmark } from "@/data/mutations/bookmarks/useDeleteUserMessageBookmark";
import type { Agent } from "@/types/agent";
import useIsChatWithPagination from "../hooks/useIsChatWithPagination";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { PlayMessageVoice } from "./PlayMessageVoice";
import { IconButton } from "@/components/ui/icon-button";
import { useEffect } from "react";
import { useMessageVoice } from "@/contexts/MessageVoiceContext/useMessageVoice";
import { TooltipPortal } from "@radix-ui/react-tooltip";

type VoteMessageProps = {
  messageId: Message["_id"];
  conversationId: Conversation["_id"] | undefined;
  agentId: Agent["_id"] | undefined;
  vote: Message["vote"];
  message?: Message["text"];
  isBookmark: Message["isBookmark"];
};

export const VoteMessage = ({ messageId, conversationId, agentId, vote, message, isBookmark }: VoteMessageProps) => {
  const queryClient = useQueryClient();
  const [, copy] = useCopyNotify();
  const { isPreview } = useChatContext();
  const { mutate: createBookmark, isPending: isPendingCreateBookmark } = useCreateMessageBookmark();
  const { mutate: deleteBookmark, isPending: isPendingDeleteBookmark } = useDeleteUserMessageBookmark();
  const withPagination = useIsChatWithPagination();
  const { stopPlayback } = useMessageVoice();

  useEffect(() => {
    return () => {
      stopPlayback();
    };
  }, [stopPlayback]);

  if (!conversationId || !agentId) {
    return;
  }

  const onThumbClick = async (vote: 1 | 0) => {
    const prevMessages = queryClient.getQueryData<InfiniteData<ConversationMessagesResponse>>(
      conversationMessagesKeys.id({ conversationId }, withPagination)
    );
    const prevMessagesDeepCopy = JSON.parse(JSON.stringify(prevMessages)) as InfiniteData<ConversationMessagesResponse>;

    try {
      queryClient.setQueryData<InfiniteData<ConversationMessagesResponse>>(
        conversationMessagesKeys.id({ conversationId }, withPagination),
        prev => {
          if (!prev) {
            return undefined;
          }
          return {
            pageParams: [...prev.pageParams],
            pages: prev.pages.map(page => {
              return {
                ...page,
                messages: page.messages.map(message => {
                  if (message._id === messageId) {
                    return { ...message, vote };
                  }
                  return message;
                }),
              };
            }),
          };
        }
      );
      await voteMessage({
        messageId,
        vote,
        agentId,
        conversationId,
      });
    } catch (error) {
      queryClient.setQueryData<InfiniteData<ConversationMessagesResponse>>(
        conversationMessagesKeys.id({ conversationId }, withPagination),
        prevMessagesDeepCopy
      );
    }
  };

  const onBookmarkIconClick = () => {
    if (isPendingCreateBookmark || isPendingDeleteBookmark) {
      return;
    }

    if (isBookmark) {
      deleteBookmark({ messageId, agentId, conversationId });
    } else {
      createBookmark({ messageId, agentId, conversationId });
    }
  };

  // TODO: remove when voice is ready for prod
  const isProd = !!import.meta.env.VITE_ENV && import.meta.env.VITE_ENV === "prod";

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        {message && (
          <Tooltip>
            <TooltipTrigger asChild onClick={() => copy(message)}>
              <IconButton
                variant="tertiary"
                className="group/button"
                icon={<Icons.CopyChat className="h-4 w-4 text-neutral-400 group-hover/button:text-neutral-600" />}
              />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent align="end" className="translate-x-3">
                Copy
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )}

        <Tooltip>
          <TooltipTrigger asChild onClick={() => onThumbClick(1)}>
            <IconButton
              variant="tertiary"
              className="group/button"
              icon={
                <Icons.ThumbUp
                  className={cn("h-4 w-4 text-neutral-400 group-hover/button:text-neutral-600", {
                    "text-neutral-600": vote === 1,
                  })}
                />
              }
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Good response</TooltipContent>
          </TooltipPortal>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger asChild onClick={() => onThumbClick(0)}>
            <IconButton
              variant="tertiary"
              className="group/button"
              icon={
                <Icons.ThumbDown
                  className={cn("h-4 w-4 text-neutral-400 group-hover/button:text-neutral-600", {
                    "text-neutral-600": vote === 0,
                  })}
                />
              }
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Bad response</TooltipContent>
          </TooltipPortal>
        </Tooltip>

        {messageId && !isProd && <PlayMessageVoice messageId={messageId} />}
      </div>

      {!isPreview && (
        <Tooltip>
          <TooltipTrigger>
            <div onClick={onBookmarkIconClick}>
              {isBookmark ? (
                <div className="flex items-center gap-2 whitespace-nowrap">
                  <Icons.BookmarkFilled className="size-4 cursor-pointer text-primary-400 hover:text-primary-400" />
                  <span className="text-xs font-medium leading-5 text-neutral-400">Added to bookmark</span>
                </div>
              ) : (
                <Icons.BookmarkOutlined className="size-4 cursor-pointer text-neutral-400 hover:text-neutral-500" />
              )}
            </div>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>{isBookmark ? "Remove from bookmark" : "Add to bookmark"}</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      )}
    </div>
  );
};
