import { cn } from "@/lib/utils";
import type { HTMLAttributes, PropsWithChildren } from "react";

type Props = HTMLAttributes<HTMLDivElement> & PropsWithChildren;

export const MessageCloudOuterWrapper = ({ children, className, ...rest }: Props) => {
  return (
    <div className={cn("flex items-start gap-4 p-4 hover:bg-primary-50", className)} {...rest}>
      {children}
    </div>
  );
};
