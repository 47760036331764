type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
};

export const PredefinedQuestionSingle = ({ children, disabled, onClick }: Props) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="rounded bg-white bg-opacity-50 px-2 py-1 text-left text-xs font-medium leading-5 text-neutral-600 transition duration-200 ease-in-out hover:bg-primary-100"
      style={{
        backgroundImage:
          "linear-gradient(90deg, rgba(189,167,255,0.10) 0.24%, rgba(195,175,255,0.10) 0.25%, rgba(237,211,185,0.10) 106.53%)",
      }}
    >
      {children}
    </button>
  );
};
