import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icons } from "../ui/icons";
import { useState } from "react";
import { cn } from "@/lib/utils";
import type { DateRangeType } from "@/types/datepicker";
import { DateRangeTypes, dateRangeTypes } from "@/types/datepicker";
import Datepicker from "../Datepicker/Datepicker";
import { useGetUser } from "@/data/queries/useGetUser";

type Props = {
  selectedDateRangeType: DateRangeType;
  onSelectedDateRangeTypeChange: (range: DateRangeType) => void;
  startDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  endDate: Date | null;
  onEndDateChange: (date: Date | null) => void;
  align?: "start" | "end";
  className?: string;
  disabled?: boolean;
};

const StatsDateRangePicker = ({
  selectedDateRangeType,
  onSelectedDateRangeTypeChange,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  align = "end",
  className,
  disabled,
}: Props) => {
  const { data } = useGetUser();

  const userCreatedAt = data?.createdAt ? new Date(data.createdAt) : null;

  const [isCustomDatepickerOpen, setIsCustomDatepickerOpen] = useState(false);

  const handleDropdownOpenChange = (open: boolean) => {
    if (!open) {
      setIsCustomDatepickerOpen(false);
    }
  };

  const getDateInfoToDisplay = () => {
    if (selectedDateRangeType === DateRangeTypes.Custom) {
      return `${startDate?.toLocaleDateString()} - ${endDate?.toLocaleDateString() ?? ""}`;
    }

    return selectedDateRangeType;
  };

  const onSelectedDatesChange = (dates: [Date | null, Date | null]) => {
    onStartDateChange(dates[0]);
    onEndDateChange(dates[1]);
    onSelectedDateRangeTypeChange(DateRangeTypes.Custom);
  };

  return (
    <DropdownMenu onOpenChange={handleDropdownOpenChange}>
      <DropdownMenuTrigger
        disabled={disabled}
        className={cn(
          "flex h-10 w-44 items-center whitespace-nowrap rounded-md border border-neutral-300 px-5 py-2 text-base font-medium text-neutral-600 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
      >
        <Icons.Calendar className="mr-2.5 text-neutral-400" />
        {getDateInfoToDisplay()}
      </DropdownMenuTrigger>

      <DropdownMenuContent align={align}>
        {isCustomDatepickerOpen ? (
          <div className="flex items-start">
            <Datepicker
              inline
              selected={startDate}
              selectsRange
              onChange={onSelectedDatesChange}
              monthsShown={2}
              startDate={startDate}
              endDate={endDate}
              minDate={userCreatedAt}
            />
          </div>
        ) : (
          <>
            {dateRangeTypes.map(range => {
              const isSelected = range === selectedDateRangeType;
              const isCustomRange = range === DateRangeTypes.Custom;

              return (
                <DropdownMenuItem
                  key={range}
                  className="flex min-w-44 items-center px-2.5 py-1.5 text-xs font-medium leading-5 text-neutral-600"
                  onClick={e => {
                    if (isCustomRange) {
                      e.preventDefault();
                      setIsCustomDatepickerOpen(true);
                      return;
                    } else {
                      onSelectedDateRangeTypeChange(range);
                    }
                  }}
                >
                  <Icons.Check
                    className={cn("mr-2.5", {
                      "opacity-0": !isSelected,
                    })}
                  />
                  {range}
                </DropdownMenuItem>
              );
            })}
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default StatsDateRangePicker;
