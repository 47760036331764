import type { ChatAgent } from "@/types/conversation";
import { MessageCloud } from "./MessageCloud/MessageCloud";
import type { EmbedKnowledges } from "@/types/agent";
import { Avatar } from "@/components/Avatar";

const getAgentsGreetings = (agent: ChatAgent) => {
  return agent.definedActions?.greeting.map(greeting => greeting.message).reverse();
};

type Props = {
  agents: ChatAgent[];
  botNames: string[];
  setDocumentInPreview: React.Dispatch<React.SetStateAction<EmbedKnowledges | null>>;
  setDocPreviewPage: React.Dispatch<React.SetStateAction<number | null>>;
  isPreview?: boolean;
};

export const ChatGreetingMessages = ({
  agents,
  botNames,
  setDocumentInPreview,
  setDocPreviewPage,
  isPreview,
}: Props) => {
  const areMultipleAgents = agents.length > 1;

  if (areMultipleAgents) {
    return (
      <>
        {agents.map(agent => {
          const hasGreetingMessages = !!agent.definedActions?.greeting.length;

          if (!agent || !hasGreetingMessages) {
            return;
          }

          return getAgentsGreetings(agent)?.map(greeting => (
            <MessageCloud
              message={greeting}
              isBotMessage
              key={greeting}
              vote={null}
              isBookmark={false}
              agent={agent}
              botNames={botNames}
              setDocumentInPreview={setDocumentInPreview}
              setDocPreviewPage={setDocPreviewPage}
            />
          ));
        })}
      </>
    );
  }

  const agent = agents[0];
  const greetingMessages = getAgentsGreetings(agent);
  const displayGenericGreeting = !greetingMessages?.length;

  if (displayGenericGreeting) {
    return (
      <div className="mb-4 px-4">
        <h1 className="select-none bg-[linear-gradient(90deg,_#A38DFF_26%,_#E5B4EA_71.12%,_#EBDDD3_118.65%)] bg-clip-text text-2xl font-bold leading-8 text-transparent lg:text-[2.75rem] lg:leading-[3.625rem]">
          Hi, I am {agents[0].name}
        </h1>
        <h2 className="select-none bg-[linear-gradient(90deg,_#A38DFF_26%,_#E5B4EA_71.12%,_#EBDDD3_118.65%)] bg-clip-text text-2xl font-bold leading-8 text-transparent lg:text-[2.75rem] lg:leading-[3.625rem]">
          What can I help you with?
        </h2>
      </div>
    );
  }

  return (
    <div className="mb-4 px-4">
      {!isPreview && (
        <div className="flex items-center gap-2.5 py-2">
          <Avatar src={agent.avatar} size="custom" className="h-16 w-16 rounded-md" />
          <h1 className="select-none bg-[linear-gradient(90deg,_#A38DFF_26%,_#E5B4EA_71.12%,_#EBDDD3_118.65%)] bg-clip-text text-2xl font-bold leading-8 text-transparent lg:text-[2.75rem] lg:leading-[3.625rem]">
            {agent.name}
          </h1>
        </div>
      )}
      <div className="flex flex-col gap-2.5 py-2">
        {greetingMessages?.map((greeting, index) => {
          return (
            <div
              key={`${greeting}-${index}`}
              className="w-fit rounded-lg bg-white px-4 py-2 shadow-lg backdrop-blur-[50px]"
            >
              <p className="text-neutral-750">{greeting}</p>
            </div>
          );
        })}
      </div>
    </div>
  );

  return <div></div>;
};
