import { cn } from "@/lib/utils";
import type { HTMLAttributes, PropsWithChildren } from "react";

type Props = HTMLAttributes<HTMLDivElement> & PropsWithChildren;

export const MessageCloudContentWrapper = ({ children, className, ...rest }: Props) => {
  return (
    <div className={cn("mt-2.5 flex min-w-0 flex-1 flex-col gap-4", className)} {...rest}>
      {children}
    </div>
  );
};
