import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import { useState } from "react";

type Props = {
  cot: string;
  isLoading: boolean;
  agentName?: string;
};

export const MessageCloudCOT = ({ cot, isLoading, agentName }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const prefix = agentName ? `${agentName} ` : "";

  return (
    <div className="flex flex-col gap-2">
      <div className="flex w-fit items-start gap-1 text-sm font-bold leading-[22px] text-neutral-750">
        <Icons.Universe className={`h-6 min-w-6 ${isLoading && "animate-spin"} text-neutral-750`} />
        {isLoading ? `${prefix}Thinking...` : `${prefix}Thought:`}
      </div>
      <button
        onClick={() => setIsExpanded(prev => !prev)}
        className="flex gap-2 border-l border-primary-400 bg-neutral-100 px-4 py-2 text-xs font-medium text-neutral-600"
      >
        <span
          className={cn("text-left", {
            "line-clamp-2": !isExpanded,
          })}
        >
          {cot}
        </span>

        <Icons.ChevronRight
          className={cn("size-4 min-w-4 rotate-90 self-end", {
            "-rotate-90": isExpanded,
          })}
        />
      </button>
    </div>
  );
};
