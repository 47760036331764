import { Card, CardContent, CardDescription, CardFooter, CardHeader } from "@/components/ui/card";
import { useGetUser } from "@/data/queries/useGetUser";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { AccountActivitiesCard } from "@/components/Profiles/AccountActivitiesCard";
import { Icons } from "@/components/ui/icons";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { AccountActivitiesUsersStats } from "./AccountActivitiesUsersStats/AccountActivitiesUsersStats";
import { SubscriptionTiers } from "@/types/subscriptions";
import { Button } from "../ui/button";

export const AccountActivities = () => {
  const { user, isLoading } = useGetUser();
  const { setNavigationProfileOpen } = useNavigationProfileContext();
  const { openDialog: openSubscriptionTierDialog } = useSubscriptionTierDialogContext();

  if (!user && isLoading) {
    return <LoadingSpinner className="grid size-full place-items-center" />;
  }

  const subscription = user?.subscription;
  const isEnterprise = subscription?.tier === SubscriptionTiers.ENTERPRISE;

  const handleOpenSubscriptionTierDialog = () => {
    openSubscriptionTierDialog();
    setNavigationProfileOpen({ open: false, section: null, mode: "account" });
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="text-xl font-bold">Account Activities</div>
      <div className="flex flex-wrap items-center justify-between gap-6 lg:flex-row">
        <AccountActivitiesCard usage={subscription?.agents} header="Agents">
          <Icons.Bot className="size-6" />
        </AccountActivitiesCard>
        <AccountActivitiesCard usage={subscription?.messages} header="Messages">
          <Icons.Message className="size-6" />
        </AccountActivitiesCard>
        <AccountActivitiesCard usage={subscription?.knowledge.urls} header="Web Resources">
          <Icons.Link2 className="size-6" />
        </AccountActivitiesCard>
        <AccountActivitiesCard usage={subscription?.knowledge.docs} header="Documents">
          <Icons.Book className="size-6" />
        </AccountActivitiesCard>
      </div>
      {isEnterprise ? (
        <AccountActivitiesUsersStats />
      ) : (
        <Card className="flex min-h-72 flex-col items-center justify-center justify-between border-none bg-[conic-gradient(from_30deg,#F262B520,#8155FF20,#799DFF20,#FFFFFF80,#5FC5FF20,#FFAC8920,#F262B520)] py-8 shadow-none">
          <CardHeader className="p-0 text-center text-xl font-bold">Boost your business with AgentX!</CardHeader>
          <CardDescription className="flex max-w-96 flex-col justify-between p-0 text-center text-xs font-medium text-neutral-600">
            enhanced AI capabilities, advanced customisation, priority support, expanded integration, insightful
            analytics and more
          </CardDescription>
          <CardContent className="p-0 text-center text-xs font-bold">supercharge your business today!</CardContent>
          <CardFooter className="flex flex-row items-center p-0">
            <Button onClick={handleOpenSubscriptionTierDialog} variant="primary" className="w-32">
              Upgrade Now
            </Button>
          </CardFooter>
        </Card>
      )}
    </div>
  );
};
