import { Avatar } from "@/components/Avatar";
import { useGetUser } from "@/data/queries/useGetUser";
import { decodeUserRole } from "@/components/Profiles/WorkspaceSection/utils";
import type { WorkspaceUserWrapped } from "@/components/Profiles/WorkspaceSection/types";
import { UserStatus } from "@/types/user";

export const CustomerTeamMembers = ({ members }: { members: WorkspaceUserWrapped[] | undefined }) => {
  const { user } = useGetUser();

  if (!members) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      {members.map(member => {
        const memberInvitationPending = member.status === UserStatus.INVITE_PENDING;

        return (
          <div key={member._id} className="flex w-full gap-4">
            <Avatar src={member?.avatar} name={member?.name} className="size-8 rounded-full lg:size-10" size="custom" />
            <div className="flex w-full items-center justify-between gap-5">
              <div>
                <div className="text-sm font-medium text-neutral-750">
                  {member?.name || "Name is not defined"}{" "}
                  {user?._id === member._id && <span className="text-neutral-400">(You)</span>}
                  {memberInvitationPending && (
                    <span className="rounded-md bg-primary-50 px-1.5 text-xs text-primary-400">Invitation sent</span>
                  )}
                </div>
                <span className="text-xs text-neutral-400">{member.email}</span>
              </div>
              <span className="text-right text-xs text-primary-400">{decodeUserRole(member.role)}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
