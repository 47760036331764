import { Icons } from "@/components/ui/icons";
import { useGetAppConfig } from "@/data/queries/useGetAppConfig";
import { useGetUser } from "@/data/queries/useGetUser";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";
import type { ChatAgent } from "@/types/conversation";
import { useMemo } from "react";
import { useHandleChatAgentKnowledge } from "../hooks/useHandleChatAgentKnowledge";
import { cn } from "@/lib/utils";

type Props = {
  agent: ChatAgent;
  onKnowledgeClick: () => void;
  className?: string;
  knowledgeClassName?: string;
};

export const ChatKnowledgeSummary = ({ agent, onKnowledgeClick, className, knowledgeClassName }: Props) => {
  const { data: config } = useGetAppConfig();
  const { isGuestUser } = useIsGuestUser();
  const { user } = useGetUser({
    enabled: !isGuestUser,
  });

  const { filteredEmbedKnowledges } = useHandleChatAgentKnowledge(agent);

  const { embedKnowledges, creator } = agent;

  const isMyAgent = useMemo(() => {
    return creator === user?._id;
  }, [creator, user?._id]);

  const showEmbedKnowledge =
    isMyAgent && (embedKnowledges.web.length > 0 || embedKnowledges.doc.length > 0 || embedKnowledges.faq.length > 0);

  const llmModel = useMemo(() => {
    const modelConfig = config?.llmList.find(llm => llm.model === agent.llmModel);
    return modelConfig;
  }, [agent.llmModel, config?.llmList]);

  return (
    <div className={cn("flex flex-wrap items-center justify-start gap-4", className)}>
      <div className="flex items-center gap-2 p-0.5">
        {llmModel ? (
          <>
            <img src={llmModel.logo} className="size-6 rounded-md" />
            <p className="text-xs font-bold leading-5 text-neutral-750">{llmModel.display}</p>
          </>
        ) : (
          <p className="text-sm leading-5 text-amber-700">{`⚠️ ${agent.llmModel} is outdated. Select a new one.`}</p>
        )}
      </div>

      {showEmbedKnowledge && (
        <button className={cn("flex flex-wrap items-center gap-4", knowledgeClassName)} onClick={onKnowledgeClick}>
          <div className="flex items-center gap-2 p-0.5">
            <Icons.FileGeneral className="size-6 min-w-6 text-primary-400" />
            <p className="whitespace-nowrap text-xs font-bold leading-5 text-neutral-750">{`${filteredEmbedKnowledges.doc.length} ${filteredEmbedKnowledges.doc.length === 1 ? "Document" : "Documents"}`}</p>
          </div>
          <div className="flex items-center gap-2 p-0.5">
            <Icons.Link2 className="size-6 min-w-6 text-primary-400" />
            <p className="whitespace-nowrap text-xs font-bold leading-5 text-neutral-750">{`${filteredEmbedKnowledges.web.length} ${filteredEmbedKnowledges.web.length === 1 ? "Weblink" : "Weblinks"}`}</p>
          </div>
          <div className="flex items-center gap-2 p-0.5">
            <Icons.FAQ className="size-6 min-w-6 text-primary-400" />
            <p className="whitespace-nowrap text-xs font-bold leading-5 text-neutral-750">{`${filteredEmbedKnowledges.faq.length} FAQ`}</p>
          </div>
        </button>
      )}
    </div>
  );
};
