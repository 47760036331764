import { Avatar } from "@/components/Avatar";
import AgentSizeupAvatarDialog from "@/components/dialogs/AgentAvatarSizeupDialog/AgentAvatarSizeupDialog";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { ROUTES } from "@/constants/routes";
import { useGetUser } from "@/data/queries/useGetUser";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";
import type { ChatAgent } from "@/types/conversation";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { domElementIds } from "@/types/dom-element-ids";
import { KNOWLEDGE_WIDTH } from "../../contants/chat-contants";
import type { CarouselApi } from "@/components/ui/carousel";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { ChatKnowledgeSummary } from "../ChatKnowledgeSummary";

const MIN_CONTENT_WIDTH = 600;

type Props = {
  agents: ChatAgent[];
  onOpenKnowledgePreview: () => void;
  setIsKnowledgeDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeAgentIndex: number;
  setActiveAgentIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const ChatAgentInfoBar = ({
  agents,
  onOpenKnowledgePreview,
  setIsKnowledgeDialogOpen,
  activeAgentIndex,
  setActiveAgentIndex,
}: Props) => {
  const navigate = useNavigate();
  const { isGuestUser } = useIsGuestUser();
  const { user } = useGetUser({
    enabled: !isGuestUser,
  });
  const currentAgent = agents[activeAgentIndex];
  const isGroupChat = agents.length > 1;

  const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);

  const { creator } = currentAgent;

  const isMyAgent = useMemo(() => {
    return creator === user?._id;
  }, [creator, user?._id]);

  const onEditAgentClick = () => {
    navigate(ROUTES.editAgent(currentAgent._id));
  };

  const onShowKnowledgeClick = () => {
    const chatContainer = document.getElementById(domElementIds.CHAT_CONTAINER);
    const chatContainerWidth = chatContainer?.clientWidth || 0;
    const hasSpaceForKnowledgeList = chatContainerWidth - MIN_CONTENT_WIDTH >= KNOWLEDGE_WIDTH;

    if (hasSpaceForKnowledgeList) {
      onOpenKnowledgePreview();
    } else {
      setIsKnowledgeDialogOpen(true);
    }
  };

  useEffect(() => {
    if (activeAgentIndex === undefined || activeAgentIndex === null) {
      return;
    }

    carouselApi?.scrollTo(activeAgentIndex);
  }, [activeAgentIndex, carouselApi]);

  return (
    <Carousel
      className="flex h-fit max-w-none items-center justify-between gap-2 rounded-lg bg-neutral-50 px-6 py-2 @container"
      setApi={setCarouselApi}
      onActiveSlideChange={slideIndex => setActiveAgentIndex(slideIndex)}
      opts={{
        watchDrag: false,
      }}
    >
      <CarouselContent>
        {agents.map(agent => {
          if (!agent) {
            return;
          }

          return (
            <CarouselItem key={agent?._id} className="" containerClassName="w-full">
              <div className="flex flex-wrap items-center justify-start gap-4">
                {isGroupChat && (
                  <div className="flex items-center gap-2 p-0.5">
                    <AgentSizeupAvatarDialog
                      renderButton={() => (
                        <Avatar src={agent?.avatar} name={agent.name} size="custom" className="size-6 cursor-pointer" />
                      )}
                      src={agent?.avatar}
                    />
                    <p className="text-center text-base font-bold leading-5 text-neutral-750">{agent.name}</p>
                    {isMyAgent && (
                      <IconButton
                        onClick={onEditAgentClick}
                        icon={<Icons.Edit />}
                        size="tiny"
                        variant="tertiary"
                        className=""
                      >
                        <span className="sr-only">edit this agent</span>
                      </IconButton>
                    )}
                  </div>
                )}

                <ChatKnowledgeSummary agent={currentAgent} onKnowledgeClick={onShowKnowledgeClick} />
              </div>
            </CarouselItem>
          );
        })}
      </CarouselContent>

      {isGroupChat && (
        <div className="relative flex items-center justify-between gap-2">
          <CarouselPrevious
            variant="secondary"
            size="tiny"
            roundedType="square"
            className="static translate-x-0 translate-y-0 p-1"
            icon={<Icons.Arrow className="" />}
          />
          <CarouselNext
            variant="secondary"
            size="tiny"
            roundedType="square"
            className="static translate-x-0 translate-y-0 p-1"
            icon={<Icons.Arrow className="rotate-180 " />}
          />
        </div>
      )}
    </Carousel>
  );
};
