import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import type { FullAgent } from "@/types/agent";
import type { F1 } from "@/types/types";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { AgentInfo } from "@/components/dialogs/TransferOwnershipAgentDialog/AgentInfo";
import { emailRegex } from "@/constants/regexps";
import { useTransferAgentOwnership } from "@/data/mutations/useTransferAgentOwnership";
import { Input } from "@/components/ui/input";

type TransferOwnershipAgentDialogProps = {
  agent: FullAgent | null;
  setAgent: F1<FullAgent | null>;
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
};

export const TransferOwnershipAgentDialog = ({
  agent,
  setAgent,
  isDialogOpen,
  setShowDialog,
}: TransferOwnershipAgentDialogProps) => {
  const { mutate: transferAgentOwnership, isPending } = useTransferAgentOwnership();
  const [userEmail, setUserEmail] = useState<string>("");

  const submitTransferAgentOwnership = () => {
    if (!agent) {
      return;
    }
    transferAgentOwnership(
      { agentId: agent._id, userEmail },
      {
        onSuccess: () => {
          setShowDialog(false);
          setAgent(null);
        },
      }
    );
  };

  const toggleDialog = (open: boolean) => {
    setShowDialog(open);
    setAgent(null);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={toggleDialog}>
      <DialogContent variant="medium" className="h-[350px]">
        <DialogHeader />
        <div className="flex w-full flex-col items-start gap-6 px-8 py-4">
          <div className="flex flex-col gap-2.5">
            <div className="text-xl font-bold">Transfer Agent Ownership</div>
            <div className="text-sm font-medium text-neutral-400">
              Select a new owner to transfer ownership of the following Agent
            </div>
          </div>
          <div className="grid w-full grid-cols-2 gap-4">
            <AgentInfo agent={agent} />
            <div className="flex flex-col gap-1">
              <div className="text-sm font-medium">New Owner</div>
              <Input
                inputClassName="h-[38px] text-xs"
                placeholder="Enter email address"
                onChange={e => setUserEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button loading={isPending} onClick={submitTransferAgentOwnership} disabled={!emailRegex.test(userEmail)}>
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
