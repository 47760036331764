import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { CommunityUser } from "@/types/community";
import type { RelationResponse } from "@/types/relation";
import type { User } from "@/types/user";
import { communityUserKeys } from "@/data/queries/community/useGetCommunityUser";
import { profileFollowersKeys } from "@/data/queries/useGetProfileFollowers";
import { profileFollowingsKeys } from "@/data/queries/useGetProfileFollowings";
import { notificationsKeys } from "@/data/queries/notifications/useGetNotifications";

type Props = { userId: User["_id"]; status: boolean };

const likeUser = async ({ userId, status }: Props) => {
  const { data } = await restClient.post<RelationResponse>(apiPaths.likeUser(userId), { status });
  return data;
};

export const useLikeUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    RelationResponse,
    AxiosError<{ message: string }>,
    Props,
    { previousUser: CommunityUser | undefined }
  >({
    mutationFn: likeUser,
    onSuccess: (_, { userId }) => {
      void queryClient.invalidateQueries({ queryKey: communityUserKeys.id(userId) });
      void queryClient.invalidateQueries({ queryKey: profileFollowersKeys.all });
      void queryClient.invalidateQueries({ queryKey: profileFollowingsKeys.all });
      void queryClient.invalidateQueries({ queryKey: notificationsKeys.all });
    },
    onMutate: async ({ userId, status }) => {
      await queryClient.cancelQueries({ queryKey: communityUserKeys.id(userId) });

      const previousUser = queryClient.getQueryData<CommunityUser>(communityUserKeys.id(userId));
      queryClient.setQueryData<CommunityUser>(communityUserKeys.id(userId), prev => {
        if (!prev) {
          return prev;
        }
        return { ...prev, followedByUser: status, followers: status ? prev.followers + 1 : prev.followers - 1 };
      });

      return { previousUser };
    },
    onError: (_, variables, context) => {
      if (context?.previousUser) {
        queryClient.setQueryData(communityUserKeys.id(variables.userId), context.previousUser);
      }
    },
  });

  return mutation;
};
