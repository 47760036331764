import { Controller, useWatch } from "react-hook-form";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import {
  VoiceProfileAccent,
  VoiceProfileAccentLabels,
  voiceProfileAccentsPerLanguage,
  VoiceProfileLanguage,
  VoiceProfileLanguageLabels,
} from "@/types/voice";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

export const AgentVoiceLanguageOptions = () => {
  const form = useAgentFormContext();
  const voiceFormValues = useWatch({
    control: form.control,
    name: "voiceProfile",
  });

  const languageValue = voiceFormValues?.language;
  const languageOptions = Object.values(VoiceProfileLanguage);
  const accents = languageValue
    ? voiceProfileAccentsPerLanguage[languageValue as keyof typeof voiceProfileAccentsPerLanguage]
    : [];
  const showAccentSelect = languageValue && accents.length > 1;

  const handleLanguageChange = (val: VoiceProfileLanguage, onChange: (val: VoiceProfileLanguage) => void) => {
    const newAccents = voiceProfileAccentsPerLanguage[val as keyof typeof voiceProfileAccentsPerLanguage];
    form.setValue("voiceProfile.accent", newAccents.length > 0 ? newAccents[0] : VoiceProfileAccent.NONE);
    onChange(val);
  };

  return (
    <div className="flex w-[408px] flex-col gap-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Language</label>
        <Controller
          control={form.control}
          name="voiceProfile.language"
          render={({ field: { value, onChange } }) => (
            <Select onValueChange={(val: VoiceProfileLanguage) => handleLanguageChange(val, onChange)} value={value}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a language" />
              </SelectTrigger>
              <SelectContent className="select-content">
                {languageOptions.map(language => (
                  <SelectItem key={language} value={language}>
                    {VoiceProfileLanguageLabels[language as keyof typeof VoiceProfileLanguageLabels]}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        />
      </div>

      {showAccentSelect && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Voice Accent</label>
          <Controller
            control={form.control}
            name="voiceProfile.accent"
            render={({ field: { value, onChange } }) => (
              <Select onValueChange={(val: VoiceProfileAccent) => onChange(val)} value={value ?? undefined}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select an accent" />
                </SelectTrigger>
                <SelectContent className="select-content">
                  {accents.map(accent => (
                    <SelectItem key={accent} value={accent}>
                      {VoiceProfileAccentLabels[accent as keyof typeof VoiceProfileAccentLabels]}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          />
        </div>
      )}
    </div>
  );
};
