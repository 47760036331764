import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { useGetUser } from "../useGetUser";
import type { Workspace } from "@/types/workspace";
import { SubscriptionTiers } from "@/types/subscriptions";

export const myWorkspacesKeys = {
  all: ["myWorkspaces"] as const,
};
const getMyWorkspaces = async () => {
  const { data } = await restClient.get<Workspace[]>(apiPaths.getMyWorkspaces);
  return data;
};

export const useGetMyWorkspaces = () => {
  const { user } = useGetUser();

  return useQuery({
    queryKey: myWorkspacesKeys.all,
    queryFn: getMyWorkspaces,
    enabled:
      !!user?.workspaces?.length ||
      (user?.subscription.tier !== SubscriptionTiers.FREE && user?.subscription.tier !== SubscriptionTiers.STARTUP),
  });
};
