import type { NotificationGroupedDescriptionProps } from "@/components/Notifications/GroupedNotification/NotificationGroupedDescription";
import { NotificationDot } from "@/components/Notifications/NotificationDot";
import { Avatar } from "@/components/Avatar";
import { AvatarFallback } from "@/components/ui/avatar";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";

export const NotificationGroupedAvatars = ({ groupedNotification }: NotificationGroupedDescriptionProps) => {
  return (
    <>
      <div className="relative ml-3 h-10 w-2.5 items-center p-3 text-center">
        <NotificationDot active={!groupedNotification.read} className="left-1/2 top-1/2 -ml-1 -mt-1.5 size-3" />
      </div>
      <div className="relative h-10 w-10 items-center p-2 text-center">
        {groupedNotification.notifications.map((notification, index) => (
          <Avatar
            key={notification._id}
            className={cn(
              "absolute h-7 w-7 bg-primary-50 text-primary-400",
              index === 1 ? "left-0 top-0" : "bottom-0 right-0 z-10 border-white"
            )}
            src={notification.imgSrc || undefined}
            element={
              notification.imgSrc ? undefined : (
                <AvatarFallback className="flex size-full items-center justify-center bg-primary-50 p-[20%] text-primary-400">
                  <Icons.User />
                </AvatarFallback>
              )
            }
          />
        ))}
      </div>
    </>
  );
};
