import { Icons } from "@/components/ui/icons";
import { Accordion, AccordionContent, AccordionItem } from "@/components/ui/accordion";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { calculateTimeDifference } from "@/utils/calculateTimeDifference";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";
import type { BookmarkMessage } from "@/types/bookmarks";
import { searchParamsData } from "@/types/search-params";
import { useDeleteUserMessageBookmark } from "@/data/mutations/bookmarks/useDeleteUserMessageBookmark";
import { useUpdateUserMessageBookmarkPin } from "@/data/mutations/bookmarks/useUpdateUserMessageBookmarkPin";
import { useState } from "react";
import RenameBookmarkDialog from "./RenameBookmarkDialog";
import { useRenameMessageBookmark } from "@/data/mutations/bookmarks/useRenameUserMessageBookmark";
import { GeneratedImage } from "@/components/ui/GeneratedImage";
import { customEvents } from "@/types/custom-events";

interface SingleBookmarkProps {
  bookmarkMessage: BookmarkMessage;
  agentName: string;
  agentId: string;
  onClosePanel?: () => void;
}

//TODO: update for multiple agents
const SingleBookmark = ({ bookmarkMessage, agentName, agentId, onClosePanel }: SingleBookmarkProps) => {
  const navigate = useNavigate();
  const { mutateAsync: deleteBookmark, isPending: isLoadingDeleteBookmark } = useDeleteUserMessageBookmark();
  const { mutateAsync: updateBookmarkPin, isPending: isLoadingUpdateBookmarkPin } = useUpdateUserMessageBookmarkPin();
  const { mutateAsync: renameBookmark, isPending: isLoadingRenameBookmark } = useRenameMessageBookmark();

  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);

  const handleCloseDropdown = () => setIsDropdownMenuOpen(false);

  const handleDirectThisThread = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    handleCloseDropdown();
    const converstaionId = bookmarkMessage.message.conversationId;

    const currentPath = `${window.location.pathname}${window.location.search}`;

    const newPath = `${ROUTES.conversation(converstaionId)}?${searchParamsData.MESSAGE}=${bookmarkMessage.message._id}`;

    if (currentPath === newPath) {
      window.dispatchEvent(
        new CustomEvent(customEvents.MESSAGE_SCROLL_REQUEST, { detail: { messageId: bookmarkMessage.message._id } })
      );
    } else {
      navigate(newPath);
    }

    onClosePanel?.();
  };

  const handleRenameBookmarkClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleCloseDropdown();
    e.preventDefault();
    setIsRenameDialogOpen(true);
  };

  const handlePin = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    if (isLoadingUpdateBookmarkPin) {
      return;
    }

    handleCloseDropdown();
    await updateBookmarkPin({ messageId: bookmarkMessage.message._id, agentId, isPinned: !bookmarkMessage.isPinned });
  };

  const handleDelete = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    if (isLoadingDeleteBookmark) {
      return;
    }

    handleCloseDropdown();
    await deleteBookmark({
      messageId: bookmarkMessage.message._id,
      agentId,
      conversationId: bookmarkMessage.message.conversationId,
    });
  };

  const handleRenameBookmarkSubmit = async (newName: string) => {
    await renameBookmark({ messageId: bookmarkMessage.message._id, title: newName });
    setIsRenameDialogOpen(false);
  };

  const bookmarkTitle =
    bookmarkMessage.message.title || bookmarkMessage.message.trace?.genImage?.prompt || bookmarkMessage.message.text;

  const bookmarkContent = bookmarkMessage.message.trace?.genImage ? (
    <GeneratedImage genImage={bookmarkMessage.message.trace.genImage} labelClassName=" text-white" />
  ) : (
    <p className="text-sm font-medium text-white">
      <span className="text-primary-200">{agentName}: </span>
      {bookmarkMessage.message.text}
    </p>
  );

  return (
    <>
      <Accordion type="single" collapsible>
        <AccordionItem
          value={`bookmark-${bookmarkMessage._id}`}
          className="border-b border-b-neutral-400 border-opacity-50 hover:bg-white hover:bg-opacity-10 data-[state=open]:bg-white data-[state=open]:bg-opacity-10"
        >
          <AccordionPrimitive.Trigger className="flex w-full items-center gap-2 py-2 [&>button]:hover:visible [&[data-state=open]>svg]:rotate-180">
            <Icons.TriangleDropdown color="#ffffff" className="min-w-6 scale-125 text-white" />
            <div className="w-[75%] truncate text-left text-sm font-medium text-white">{bookmarkTitle}</div>
            <div className="ml-auto w-[25%] text-right text-xs text-neutral-400">
              {calculateTimeDifference(bookmarkMessage.message.createdAt, { strict: true })}
            </div>

            <DropdownMenu open={isDropdownMenuOpen} onOpenChange={setIsDropdownMenuOpen}>
              <DropdownMenuTrigger className="lg:invisible">
                <Icons.Dots2 className="text-white" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className="flex items-center gap-[6px] border-b border-neutral-300 text-xs text-primary-400 focus:text-primary-400"
                  onClick={handleDirectThisThread}
                >
                  <Icons.Locate className=" text-primary-400" />
                  Direct this thread
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="flex items-center gap-[6px] pb-2 pt-3 text-xs text-neutral-600"
                  onClick={handleRenameBookmarkClick}
                >
                  <Icons.Edit />
                  Rename
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="flex items-center gap-[6px] pb-2 pt-3 text-xs text-neutral-600"
                  onClick={handlePin}
                >
                  <Icons.PinOutline />
                  {bookmarkMessage.isPinned ? "Unpin the thread" : "Pin the thread"}
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="flex items-center gap-[6px] border-t border-neutral-300 pb-2 pt-3 text-xs text-neutral-600"
                  onClick={handleDelete}
                >
                  <Icons.Trash className="text-red-500" />
                  Delete this note
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </AccordionPrimitive.Trigger>
          <AccordionContent className="flex flex-col gap-2 pl-8 pr-5">{bookmarkContent}</AccordionContent>
        </AccordionItem>
      </Accordion>

      <RenameBookmarkDialog
        isOpen={isRenameDialogOpen}
        setIsOpen={setIsRenameDialogOpen}
        onRenameSubmit={handleRenameBookmarkSubmit}
        isSubmitLoading={isLoadingRenameBookmark}
      />
    </>
  );
};

export default SingleBookmark;
