import { Icons } from "@/components/ui/icons";
import { getReferenceTitleSource } from "@/utils/getReferenceTitleSource";
import { FileIcon } from "../../AgentForm/components/Knowledge/components/FileIcon";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import type { Reference, AgentFunction } from "@/types/trace";
import type { TitleAndSource } from "@/utils/getReferenceTitleSource";
import type { EmbedKnowledges, ExternalSource } from "@/types/agent";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { LinkButton } from "@/components/ui/link-button";

type ReferencesProps = {
  agentMode: "chat" | "search" | "ecommerce";
  references: Reference[];
  chainOfActions: AgentFunction[];
  setDocumentInPreview?: React.Dispatch<React.SetStateAction<EmbedKnowledges | null>>;
  setDocPreviewPage?: React.Dispatch<React.SetStateAction<number | null>>;
};

const convertReferenceToEmbeddedKnowledge = ({
  db_name,
  externalSource,
  filename,
  source,
  title,
  page_content,
}: TitleAndSource): EmbedKnowledges => ({
  _id: "",
  creator: "",
  embedIdList: [""],
  knowledgeType: db_name === "embedwebs" ? "WEB" : "DOC",
  source: source!,
  externalSource: externalSource as ExternalSource,
  filename: filename!,
  description: title || "",
  content: page_content || "",
  parentId: "",
  status: "success",
  createdAt: "",
  updatedAt: "",
});

const excludeDisplayFunctionName = ["image_generate", "image_generate_flux", "AgentX_Lead_Generation_Tool"];

const convertAgentToolToEmbeddedKnowledge = ({ name, response, args }: AgentFunction): EmbedKnowledges => ({
  _id: "",
  creator: "",
  embedIdList: [""],
  knowledgeType: "DOC",
  source: "agentFunction",
  filename: name,
  description: args || "",
  content: response || "",
  parentId: "",
  status: "success",
  createdAt: "",
  updatedAt: "",
});

const checkForPossibleDocPreview = (filename?: string): boolean => {
  if (!filename) {
    return false;
  }

  const fileFormat = filename.slice(filename.lastIndexOf("."))?.toLowerCase();
  const allowedFileFormats = [
    ".pdf",
    ".xls",
    ".xlsx",
    ".odf",
    ".ods",
    ".odt",
    ".csv",
    ".png",
    ".ppt",
    ".pptx",
    ".tiff",
    ".txt",
    ".jpg",
    ".jpeg",
    ".webp",
    ".gif",
    ".bmp",
    ".html",
    ".htm",
    ".doc",
    ".docx",
  ];
  return allowedFileFormats.includes(fileFormat);
};

export const References = ({
  agentMode,
  references,
  chainOfActions,
  setDocumentInPreview,
  setDocPreviewPage,
}: ReferencesProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!references.length && !chainOfActions.length) {
    return null;
  }

  const titleSourceReferences = getReferenceTitleSource(references);
  const filteredReferences = titleSourceReferences.filter(
    reference => reference.db_name !== "embedfaqs" && reference.title
  );
  const filteredChainOfActions = chainOfActions.filter(
    action => !!action.name && !excludeDisplayFunctionName.includes(action.name)
  );
  const faqReferences = titleSourceReferences.filter(reference => reference.db_name === "embedfaqs").slice(0, 5); // Show only 5 FAQ references

  if (agentMode === "search") {
    return (
      <div>
        {faqReferences?.length > 0 && (
          <div className="my-2 py-2">
            <h2 className="flex text-base uppercase text-primary-black">Related</h2>
            {faqReferences.map((reference, idx) => (
              <Accordion key={`faq_${idx}`} type="single" collapsible className="w-full border-b border-neutral-300">
                <AccordionItem value="outer">
                  <AccordionTrigger>
                    <p className="font-medium">{reference.title}</p>
                  </AccordionTrigger>
                  <AccordionContent className="flex gap-2">
                    {reference.source && <img className="max-w-[120px]" src={reference.source} />}
                    <p className="text-neutral-600">{reference.page_content}</p>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            ))}
          </div>
        )}
        {filteredReferences?.length > 0 && (
          <div className="relative my-2 grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] content-start gap-2 py-2">
            {filteredReferences.map((reference, idx) => (
              <div
                key={`searchElement_${idx}`}
                className="min-h-35 min-w-45 group flex cursor-pointer flex-col justify-between rounded-md border border-neutral-300 bg-white p-2"
                onClick={() => {
                  setDocumentInPreview && setDocumentInPreview(convertReferenceToEmbeddedKnowledge(reference));
                  setDocPreviewPage && setDocPreviewPage(reference.page || null);
                }}
              >
                <div className="flex flex-col justify-between gap-2">
                  <div className="flex grow items-center justify-between gap-2">
                    <div className="flex flex-col gap-1 text-start">
                      <h6 className="text-left text-xs font-medium text-neutral-750 line-clamp-3-wrap">
                        {reference.db_name === "embedwebs" ? reference.page_content : reference.title}
                      </h6>
                    </div>
                  </div>
                  <div className="flex items-center gap-1 text-left">
                    {reference.externalSource === "GOOGLE_DRIVE" && <Icons.GoogleDrive className="size-4 min-w-4" />}
                    {reference.db_name === "embedwebs" ? (
                      <Icons.Link className="size-4 min-w-4" />
                    ) : (
                      <FileIcon className="size-4 min-w-4" fileName={reference.filename || ""} />
                    )}
                    <div className="max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap text-xs text-neutral-500">
                      {reference.filename || reference.title}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  if (!filteredReferences.length && !filteredChainOfActions.length) {
    return null;
  }

  return (
    <div className="flex flex-col rounded-[4px] bg-white px-4 py-3 font-medium shadow-lg">
      <div className="mb-2 flex items-center justify-between">
        <span className="text-xs font-bold uppercase leading-5 text-neutral-750">References:</span>
        <LinkButton onClick={() => setIsExpanded(prev => !prev)} className="text-neutral-400">
          {isExpanded ? "Close" : "Expand"}
        </LinkButton>
      </div>
      <ol
        className={cn("flex list-none flex-col gap-2", {
          "max-h-7 flex-row flex-wrap overflow-y-hidden": !isExpanded,
        })}
      >
        {filteredReferences.map(reference => (
          <li
            key={`${reference?.page_content} ${reference?.filename} ${reference?.source}`}
            className={cn("m-0 cursor-pointer rounded p-1", {
              "bg-neutral-100 px-4": !isExpanded,
            })}
          >
            {reference?.source && setDocumentInPreview && checkForPossibleDocPreview(reference?.filename) ? (
              <button
                onClick={() => {
                  setDocumentInPreview(convertReferenceToEmbeddedKnowledge(reference));
                  setDocPreviewPage && setDocPreviewPage(reference.page || null);
                }}
                className="flex cursor-pointer items-center gap-1 text-start no-underline decoration-1 hover:underline"
              >
                <div>
                  {reference.filename ? (
                    <FileIcon fileName={reference.filename} className="h-4 w-4 text-references-text" />
                  ) : (
                    <Icons.Link2 className="h-4 w-4 text-references-text" />
                  )}
                </div>
                <p className="text-xs font-semibold leading-4 text-references-text">
                  {reference.filename || reference.title}
                </p>
              </button>
            ) : (
              <a
                href={reference.source ? addPrefixToUrl(reference.source || "") : undefined}
                target="_blank"
                rel="noopener noreferrer"
                className="flex cursor-pointer items-center gap-1 text-start no-underline decoration-1 hover:underline"
              >
                <div>
                  {reference.filename ? (
                    <FileIcon fileName={reference.filename} className="h-4 w-4 text-references-text" />
                  ) : (
                    <Icons.Link2 className="h-4 w-4 text-references-text" />
                  )}
                </div>
                <p
                  className={cn("text-xs font-semibold leading-4 text-references-text", {
                    "max-w-24 overflow-hidden text-ellipsis whitespace-nowrap": !isExpanded,
                  })}
                >
                  {reference.filename || reference.title}
                </p>
              </a>
            )}
          </li>
        ))}
        {filteredChainOfActions.map((action, idx) => (
          <li
            key={`coa_${idx}`}
            className={cn("m-0 cursor-pointer rounded p-1", { "bg-neutral-100 px-4": !isExpanded })}
          >
            <button
              onClick={() => {
                setDocumentInPreview && setDocumentInPreview(convertAgentToolToEmbeddedKnowledge(action));
              }}
              className="flex cursor-pointer items-center gap-1 text-start no-underline decoration-1 hover:underline"
            >
              <Icons.Puzzle className="h-4 w-4 text-references-text" />
              <p className="text-xs font-semibold leading-4 text-references-text">{action.name}</p>
            </button>
          </li>
        ))}
      </ol>
    </div>
  );
};

const addPrefixToUrl = (url: string) => {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return "https://" + url; // Add "https://" prefix if not present
  }
  return url; // URL already has a prefix
};
