import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogDescription } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import type { UseFormReturn } from "react-hook-form";
import { CustomisedParameters } from "./CustomisedParameters";
import { CodeEditor } from "./CodeEditor";
import { FormInput } from "@/components/ui/FormInput";
import { FormTextarea } from "@/components/ui/FormTextarea";
import type { Parameter, Tool } from "@/types/tools";
import { useCreateTool } from "@/data/mutations/useCreateTool";
import { toast } from "react-toastify";
import { useUpdateTool } from "@/data/mutations/useUpdateTool";
import { useAssignToolToAgent } from "@/data/mutations/useAssignToolToAgent";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import type { F1 } from "@/types/types";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { UnsavedChangesDialog } from "@/components/AgentForm/components/Actions/components/UnsavedChangesDialog";

type CustomizedToolsDialogProps = {
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
  toolEditorForm: UseFormReturn<Tool>;
};

export const CustomisedToolsDialog = ({ isDialogOpen, setShowDialog, toolEditorForm }: CustomizedToolsDialogProps) => {
  const agentForm = useAgentFormContext();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { mutate: createTool, isPending: isCreatingPending } = useCreateTool();
  const { mutate: updateTool, isPending: isUpdatingPending } = useUpdateTool();
  const { mutate: assignToolToAgent } = useAssignToolToAgent();

  const { handleSubmit, formState } = toolEditorForm;

  const submitToolForm = (formValues: Tool) => {
    const parametersWithoutId = formValues.parameters.map(({ _id, ...param }) => param) as Parameter[];
    if (formValues.isEditing) {
      updateTool(
        { ...formValues, parameters: parametersWithoutId },
        {
          onSuccess: () => {
            setShowConfirmationDialog(false);
            setShowDialog(false);
            toast.success("Tool updated successfully");
          },
        }
      );
      return;
    }

    if (formValues.isAssigning) {
      if (formValues.private == true) {
        assignToolToAgent(
          { toolId: formValues._id, agentId: agentForm.getValues("_id") },
          {
            onSuccess: () => {
              setShowConfirmationDialog(false);
              setShowDialog(false);
              toast.success("Tool assigned successfully");
            },
          }
        );
      } else {
        createTool(formValues, {
          onSuccess: tool => {
            assignToolToAgent({ toolId: tool._id, agentId: agentForm.getValues("_id") });
            setShowConfirmationDialog(false);
            setShowDialog(false);
            toast.success("Tool created successfully");
          },
        });
      }
      return;
    }

    createTool(formValues, {
      onSuccess: tool => {
        assignToolToAgent({ toolId: tool._id, agentId: agentForm.getValues("_id") });
        setShowConfirmationDialog(false);
        setShowDialog(false);
        toast.success("Tool created successfully");
      },
    });
  };

  const closeDialogHandler = () => {
    if (formState.isDirty) {
      return setShowConfirmationDialog(true);
    } else {
      return setShowDialog(false);
    }
  };

  return (
    <>
      <Dialog open={isDialogOpen} onOpenChange={closeDialogHandler}>
        <DialogContent variant="xl" className="!h-[95vh] !w-[80vw] gap-0">
          <DialogHeader className="font-bold">Advanced Tool Editor</DialogHeader>
          <DialogDescription className="flex flex-row gap-3 overflow-y-scroll py-0">
            <div className="w-1/3">
              <div className="flex flex-col gap-1.5">
                <div className="flex gap-1 pt-3">
                  <Label htmlFor="toolName">Tool name*</Label>
                  <HybridTooltipPopover>
                    <p>Give a name to the tool, no space.</p>
                    <p>For example: create_ticket</p>
                  </HybridTooltipPopover>
                </div>
                <FormInput
                  autoFocus
                  id="toolName"
                  name="toolName"
                  formObj={toolEditorForm}
                  placeholder="write_a_tool_name"
                  maxLength={50}
                  required
                />
              </div>

              <div className="flex flex-col gap-1.5">
                <div className="flex gap-1 pt-3">
                  <Label htmlFor="toolDescription">Tool description*</Label>
                  <HybridTooltipPopover>
                    <p>The tool description helps your Agent determine when and how to use it.</p>
                    <p>For example: Use this tool when you need to create a ticket.</p>
                  </HybridTooltipPopover>
                </div>
                <FormTextarea
                  name="toolDescription"
                  id="toolDescription"
                  formObj={toolEditorForm}
                  placeholder="The word limit for this section is 10000 character. Please don't exceed this limit otherwise this function will not work properly."
                  maxLength={10000}
                  className="h-[200px]"
                  required
                />
              </div>

              <CustomisedParameters form={toolEditorForm} />
            </div>
            <div className="w-2/3 border-l-[1.5px] border-l-neutral-200">
              <CodeEditor form={toolEditorForm} />
            </div>
          </DialogDescription>
          <DialogFooter className="border-t-[1.5px] border-t-neutral-200">
            <Button variant="secondary" onClick={() => closeDialogHandler()}>
              Cancel
            </Button>
            <Button onClick={handleSubmit(submitToolForm)} loading={isCreatingPending || isUpdatingPending}>
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <UnsavedChangesDialog
        open={showConfirmationDialog}
        setOpen={setShowConfirmationDialog}
        isLoading={isCreatingPending || isUpdatingPending}
        onSave={handleSubmit(submitToolForm, () => setShowConfirmationDialog(false))}
        onDiscard={() => {
          setShowDialog(false);
          setShowConfirmationDialog(false);
        }}
      />
    </>
  );
};
