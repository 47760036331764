import type { Reference } from "@/types/trace";

export type TitleAndSource = {
  db_name?: "embedfaqs" | "embeddocs" | "embedwebs";
  title?: string;
  source?: string;
  page_content?: string;
  filename?: string;
  page?: number;
  externalSource?: string;
};

export const getReferenceTitleSource = (references: Reference[]) => {
  const allFilenamesAndSources: string[] = [];
  const titleAndSource: TitleAndSource[] = [];

  (references || []).map(ref => {
    const { db_name, source, title, filename, page_content: pageContent, page, externalSource } = ref;
    if (filename) {
      // it's a document reference
      if (!allFilenamesAndSources.includes(filename)) {
        titleAndSource.push({ db_name, filename, title, source, page, page_content: pageContent, externalSource });
        allFilenamesAndSources.push(filename);
      }
    } else {
      if (db_name === "embedwebs") {
        // it's a website reference
        if (!allFilenamesAndSources.includes(source)) {
          const trimmedSource = source.replace("https://", "").replace("http://", "").replace("www.", "");
          titleAndSource.push({
            db_name,
            source: trimmedSource,
            title,
            page,
            page_content: pageContent,
            externalSource,
          });
          allFilenamesAndSources.push(source);
        }
      } else {
        // it's faq
        // in FAQ, source is attached image if there is any
        titleAndSource.push({ db_name, source, page_content: pageContent, title, externalSource });
        allFilenamesAndSources.push(source);
        // TODO, how to display FAQ reference?
      }
    }
  });
  return titleAndSource;
};
