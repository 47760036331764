import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import type { ExternalAgent, InstagramAgent } from "@/types/agent";
import { externalAgentsKeys } from "@/data/queries/useGetExternalAgents";

type Response = {
  message: string;
  externalAgent: InstagramAgent;
};

export type MessengerPayload = {
  agentId: string;
  token: string;
  messengerId: string;
  handle: string;
};

const createMessengerAgent = async (payload: MessengerPayload) => {
  const { data } = await restClient.post<Response, AxiosResponse<Response>, MessengerPayload>(
    apiPaths.createMessengerAgent,
    payload
  );
  return data;
};

export const useCreateMessengerAgent = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<Response, AxiosError<Response>, MessengerPayload>({
    mutationFn: createMessengerAgent,
    onSuccess: ({ externalAgent }, { agentId }) => {
      queryClient.setQueryData<ExternalAgent[]>(externalAgentsKeys.id(agentId), prev => {
        if (!prev) {
          return [externalAgent];
        }
        return [...prev, externalAgent];
      });
      toast.success("Messenger register ID created successfully.");
    },
  });

  return mutation;
};
