import { Avatar } from "@/components/Avatar";
import { useGetUser } from "@/data/queries/useGetUser";
import { useUpdateAvatarUser } from "@/data/mutations/useUpdateAvatarUser";
import { FILE_INPUT_USER_ID, MAX_IMAGE_SIZE } from "@/constants/images";
import { isImageFormatAllowed } from "@/utils/fileUploads";
import { toast } from "react-toastify";
import { Button } from "../ui/button";
import { Icons } from "@/components/ui/icons";

export const ChangeUserAvatar = () => {
  const { user } = useGetUser();
  const { mutate, isPending } = useUpdateAvatarUser();

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];
    if (!image) {
      return;
    }
    if (!isImageFormatAllowed(image)) {
      return toast.error("Image format not allowed");
    }
    if (image.size > MAX_IMAGE_SIZE) {
      return toast.error("Image size too large");
    }
    const formData = new FormData();
    formData.append("avatar", image);
    mutate(formData, {
      onSuccess: () => {
        toast.success("Avatar updated!");
      },
    });
  };

  const openImagePicker = () => {
    const input = document.getElementById(FILE_INPUT_USER_ID);
    if (input) {
      input.click();
    }
  };

  return (
    <div className="flex flex-row items-center gap-4">
      <Avatar src={user?.avatar} name={user?.name} className="size-[120px] rounded-full lg:size-20" size="full" />
      <input
        type="file"
        accept="image/*"
        hidden
        name="avatar"
        onChange={uploadImage}
        id={FILE_INPUT_USER_ID}
        className="hidden"
      />
      <Button
        variant="secondary"
        onClick={openImagePicker}
        loading={isPending}
        className="flex flex-row items-center gap-2"
      >
        <Icons.Upload className="size-4" />
        Upload
      </Button>
    </div>
  );
};
