import { Avatar } from "@/components/Avatar";
import type { ChatAgent } from "@/types/conversation";
import { useEffect, useState } from "react";
import animationData from "@/assets/animations/message-loader.json";
import Lottie from "react-lottie";

export const MessageLoader = ({
  agent,
  withDelay,
}: {
  agent: {
    _id: ChatAgent["_id"];
    name: ChatAgent["name"];
    avatar: ChatAgent["avatar"];
  };
  withDelay?: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(!withDelay);

  useEffect(() => {
    if (!withDelay) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [withDelay]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="flex items-center gap-3 p-4 hover:bg-primary-50">
      <Avatar name={agent.name} src={agent?.avatar} />
      <div className="text-sm font-medium text-primary-black">
        <div className="my-2 flex gap-1">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            width={48}
            height={13}
          />
        </div>
      </div>
    </div>
  );
};
