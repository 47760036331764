import { Icons } from "@/components/ui/icons";
import { useInputForm } from "../hooks/useInputForm";
import { Textarea } from "@/components/ui/textarea";
import { memo } from "react";
import type { ChatAgent } from "@/types/conversation";
import Chip from "@/components/ui/Chip";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { cn } from "@/lib/utils";
import { ChatFileInput } from "./ChatFileInput";
import { ChatFileList } from "./ChatFileList";
import { inputDefaultClassName } from "@/components/ui/input";
import { ChatSelectedKnowledgeBadge } from "./ChatSelectedKnowledgeBadge";
import { VoiceMessageBars } from "./VoiceMessageBars";
import { VoiceMessageButton } from "./VoiceMessageButton";
import useAudioRecorder, { MAX_DURATION_IN_SECONDS } from "../hooks/useAudioRecorder";
import { useStopSSEResponse } from "@/data/mutations/useStopSSEResponse";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";
import { IconButton } from "@/components/ui/icon-button";
import { Button } from "@/components/ui/button";
import { ChatInputFormMultiAgentBtn } from "./ChatInputFormMultiAgentBtn";

type Props = {
  agents: ChatAgent[];
  onOpenMultiAgentChat: () => void;
  canCreateMultiAgentChat: boolean;
  isExtended?: boolean;
};

const _ChatInputForm = ({ agents, onOpenMultiAgentChat, canCreateMultiAgentChat, isExtended }: Props) => {
  const { isGuestUser } = useIsGuestUser();
  const { showLoginDialog } = useLoginDialogContext();

  const {
    messageText,
    textareaRef,
    setMessageText,
    handleComposition,
    submitSendMessage,
    isTagListVisible,
    agentsToTag,
    handleAddMessageRecipient,
    handleRemoveMessageRecipient,
    highlightedTagIndex,
  } = useInputForm({
    agents,
  });
  const { messageRecipients, isSendingMessage, isFileUploading, files, responseRequestId, setResponseRequestId } =
    useChatContext();
  const isChatEmpty = !messageText.trim().length && !files.length;

  const audioRecorder = useAudioRecorder({ autoSubmitSendMessage: submitSendMessage });

  const { mutate: stopSSEResponse } = useStopSSEResponse();

  const stop = () => {
    if (!responseRequestId) {
      return;
    }
    setResponseRequestId(null);
    stopSSEResponse(responseRequestId);
  };

  return (
    <div className="relative mt-4 w-full">
      {!isSendingMessage && messageRecipients.length > 0 && (
        <div className="mb-4 flex items-center gap-2 px-4">
          <p className="text-xs font-medium">Tagged agents: </p>
          {messageRecipients.map(recipient => (
            <Chip
              key={recipient._id}
              text={recipient.name}
              canDelete
              onDeleteClick={() => {
                handleRemoveMessageRecipient(recipient._id);
              }}
            />
          ))}
        </div>
      )}
      <div
        className={cn(
          inputDefaultClassName,
          "relative mx-2 flex w-auto flex-col gap-2 !p-0 shadow-[0px_0px_60px_0px_rgba(255,255,255,0.10)_inset,_10px_10px_50px_0px_rgba(47,45,61,0.10)] backdrop-blur-[50px]"
        )}
      >
        <ChatFileList />
        <ChatSelectedKnowledgeBadge />
        <form className="flex min-h-[56px] w-full flex-col items-end gap-4 p-4">
          {audioRecorder.isRecording ? (
            <div className="flex w-full items-center gap-2">
              <VoiceMessageBars />
              <span className="min-w-7 text-xs font-medium text-neutral-400">
                {audioRecorder.recordingTime < MAX_DURATION_IN_SECONDS
                  ? `0:${String(audioRecorder.recordingTime).padStart(2, "0")}`
                  : "1:00"}
              </span>
            </div>
          ) : (
            <Textarea
              containerClassName={cn("self-center", isExtended && "min-h-20")}
              autoFocus
              value={messageText}
              ref={textareaRef}
              onChange={e => setMessageText(e.target.value)}
              className="max-h-[30dvh] min-h-0 w-full resize-none !border-0 !px-0 py-1.5 text-base font-medium text-primary-black !ring-0 !ring-offset-0"
              placeholder="What can I help you with?"
              onCompositionEnd={handleComposition}
              onCompositionStart={handleComposition}
              maxLength={10000}
              hideMaxLengthLabel
              disabled={isGuestUser}
            />
          )}

          <div className="flex w-full items-center justify-between gap-2">
            <div className="flex items-center justify-start gap-2">
              {audioRecorder.isRecording ? (
                <IconButton
                  variant="tertiary"
                  size="small"
                  type="button"
                  icon={<Icons.Trash className="size-6 text-neutral-400" />}
                  onClick={audioRecorder.cancelRecording}
                >
                  <span className="sr-only">Cancel recording</span>
                </IconButton>
              ) : (
                <ChatFileInput agentsIds={agents.map(agent => agent._id)} disabled={isGuestUser} />
              )}

              {canCreateMultiAgentChat && (
                <ChatInputFormMultiAgentBtn agent={agents[0]} onClick={onOpenMultiAgentChat} />
              )}
            </div>

            {isChatEmpty ? (
              isSendingMessage ? (
                <IconButton
                  disabled={!responseRequestId}
                  onClick={stop}
                  icon={<Icons.StopConversation />}
                  type="button"
                  variant="primary"
                  size="medium"
                >
                  <span className="sr-only">Stop conversation</span>
                </IconButton>
              ) : (
                <VoiceMessageButton
                  disabled={isSendingMessage || isGuestUser}
                  audioRecorder={audioRecorder}
                  submitSendMessage={submitSendMessage}
                />
              )
            ) : (
              <IconButton
                type="button"
                variant="primary"
                size="medium"
                disabled={isSendingMessage || isFileUploading}
                onClick={() => submitSendMessage(messageText)}
                icon={<Icons.ArrowUp />}
              >
                <span className="sr-only">Send message</span>
              </IconButton>
            )}
          </div>
          {isTagListVisible && (
            <ul className="absolute bottom-full left-0 mb-3 ml-0 rounded-md border border-slate-200 bg-white p-1 text-slate-950 shadow-md">
              {agentsToTag.map((agent, index) => (
                <li
                  key={agent._id}
                  role="option"
                  onClick={() => handleAddMessageRecipient(agent)}
                  className={cn(
                    "my-0 flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-primary-100",
                    {
                      "bg-primary-100": highlightedTagIndex === index,
                    }
                  )}
                >
                  {agent.name}
                </li>
              ))}
            </ul>
          )}
        </form>
      </div>

      {isGuestUser && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
          <Button onClick={showLoginDialog}>Sign in to chat</Button>
        </div>
      )}
    </div>
  );
};
export const ChatInputForm = memo(_ChatInputForm);
