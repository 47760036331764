import { Avatar } from "@/components/Avatar";
import { References } from "@/components/Chat/components/References";
import { GeneratedImage } from "@/components/ui/GeneratedImage";
import { ActionLoader } from "@/components/Chat/components/ActionLoader";
import type { Trace } from "@/types/trace";
import { MessageCloudContentWrapper } from "@/components/Chat/components/MessageCloud/MessageCloudContentWrapper";
import { MessageCloudMarkdown } from "@/components/Chat/components/MessageCloud/MessageCloudMarkdown";
import { MessageCloudOuterWrapper } from "@/components/Chat/components/MessageCloud/MessageCloudOuterWrapper";

export type Props = {
  message: string;
  isBotMessage: boolean;
  trace?: Trace | null;
  agent?: {
    _id: string;
    name: string;
    avatar: string;
  };
  type: "chat" | "search" | "ecommerce";
  showAgentName?: boolean;
};

export const SharedMessageCloud = ({ message, trace, isBotMessage, agent, type, showAgentName }: Props) => {
  if (!message.length && !trace?.genImage) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <MessageCloudOuterWrapper className="hover:bg-transparent">
        {isBotMessage ? <Avatar name={agent?.name} src={agent?.avatar} /> : <Avatar />}

        <MessageCloudContentWrapper>
          {showAgentName && isBotMessage && (
            <p className="text-sm font-bold leading-[22px] text-neutral-750">{agent?.name}</p>
          )}
          {!!message && <MessageCloudMarkdown message={message} />}
          {trace?.genImage?.status && <GeneratedImage genImage={trace.genImage} />}
          {isBotMessage && trace?.actionType && <ActionLoader action={trace.actionType} />}
          {trace?.reference && <References references={trace.reference} agentMode={type} chainOfActions={[]} />}
        </MessageCloudContentWrapper>
      </MessageCloudOuterWrapper>
    </div>
  );
};
