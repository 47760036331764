import { DialogHeader } from "@/components/ui/dialog";
import { Icons } from "../../ui/icons";
import { ChatHistoryConversation } from "./ChatHistoryConversation";
import { ShareChatHistoryConversationButtonDialog } from "@/components/ChatHistoryDialog/components/ShareChatHistoryConversationButtonDialog";
import { Button } from "../../ui/button";

import type { ConversationWithSearchMatches } from "../types/chat-history-dialog";

type Props = {
  header?: string;
  onBackButtonPress: () => void;
  showSearchMatches: boolean;
  debouncedSearchValue: string;
  currentConversation: ConversationWithSearchMatches;
  renderActionButtons?: () => React.ReactNode;
};

export const ChatHistoryConversationDialogContent = ({
  currentConversation,
  onBackButtonPress,
  showSearchMatches,
  debouncedSearchValue,
  renderActionButtons,
}: Props) => {
  return (
    <>
      <DialogHeader className="h-14 px-12 py-4 font-bold text-primary-black">Selected conversation</DialogHeader>
      <div className="h-full w-full px-12 py-6 pb-20">
        <div className="flex items-start justify-between pb-4">
          <Button variant="tertiary" onClick={() => onBackButtonPress()}>
            <Icons.Arrow className="mr-3 mt-[-1px] size-4" />
            Back
          </Button>
          <div className="flex flex-col items-end gap-2 sm:flex-row sm:items-center">
            {renderActionButtons && renderActionButtons()}
            <ShareChatHistoryConversationButtonDialog conversationId={currentConversation.conversation._id} />
          </div>
        </div>
        <ChatHistoryConversation
          key={currentConversation.conversation._id}
          conversationItem={currentConversation}
          currentSearchValue={debouncedSearchValue}
          showSearchMatches={showSearchMatches}
          conversationUsers={currentConversation.conversation.users}
        />
      </div>
    </>
  );
};
