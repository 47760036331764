import type { AgentFormFields, DomainLinks, EmbedKnowledges, FullAgent } from "@/types/agent";
import { KnowledgeStatus } from "@/types/agent";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Icons } from "@/components/ui/icons";
import type { FieldArrayWithId, UseFieldArrayRemove, UseFieldArrayUpdate } from "react-hook-form";
import { KnowledgeStatusBadge } from "./KnowledgeStatusBadge";
import { IconButton } from "@/components/ui/icon-button";
import { NumberCircle } from "@/components/ui/NumberCircle";

type LinksAccordionProps = {
  webData: FieldArrayWithId<FullAgent, "embedKnowledges.web", "id">[];
  removeWeb: UseFieldArrayRemove;
  sortedWebData: DomainLinks[];
  updateWeb: UseFieldArrayUpdate<AgentFormFields, "embedKnowledges.web">;
};

export const LinksAccordion = ({ webData, removeWeb, sortedWebData, updateWeb }: LinksAccordionProps) => {
  const removeSingleLink = (link: EmbedKnowledges) => {
    const index = webData.findIndex(web => web.source === link.source);
    removeWeb(index);
  };

  const removeMultipleLinks = (links: EmbedKnowledges[]) => {
    const linkIndices = links.map(link => webData.findIndex(web => web.source === link.source));
    removeWeb(linkIndices);
  };

  const refreshLink = (link: EmbedKnowledges) => {
    updateWeb(
      webData.findIndex(web => web.source === link.source),
      {
        ...link,
        status: KnowledgeStatus.REFRESH,
      }
    );
  };

  const refreshMultipleLinks = (links: EmbedKnowledges[]) => {
    links.forEach(link => {
      updateWeb(
        webData.findIndex(web => web.source === link.source),
        {
          ...link,
          status: KnowledgeStatus.REFRESH,
        }
      );
    });
  };

  return (
    <div>
      {sortedWebData.map(data => {
        const newLinks = data.links.filter(link => link.status === undefined);
        const failedLinks = data.links.filter(link => link.status === KnowledgeStatus.FAILED);

        return (
          <Accordion
            type="single"
            collapsible
            className="my-2 rounded-md border border-slate-200 bg-white"
            key={data.domain}
          >
            <AccordionItem value={`online-info-${data.domain}`} className="relative">
              <AccordionTrigger className="h-10 border-slate-200 px-4 text-xs font-medium text-neutral-800 hover:no-underline data-[state=open]:border-b">
                <div className="flex w-full items-center justify-between gap-2 pr-2">
                  <div className="flex gap-1.5">
                    <NumberCircle className="border border-primary-500 bg-white text-primary-500">
                      {data.links.length}
                    </NumberCircle>
                    {!!newLinks.length && (
                      <NumberCircle className="border border-green-500 bg-white text-green-500">
                        {newLinks.length}
                      </NumberCircle>
                    )}
                    {!!failedLinks.length && (
                      <NumberCircle className="border border-red-500 bg-white text-red-500">
                        {failedLinks.length}
                      </NumberCircle>
                    )}
                    <div className="ml-0.5 break-all text-left">{data.domain}</div>
                  </div>
                  <div className="flex gap-2">
                    <IconButton
                      type="button"
                      variant="tertiary"
                      size="tiny"
                      icon={<Icons.Refresh className="text-neutral-400" />}
                      onClick={() => refreshMultipleLinks(data.links)}
                    />
                    <IconButton
                      type="button"
                      variant="tertiary"
                      size="tiny"
                      icon={<Icons.Trash className="text-neutral-400" />}
                      onClick={() => removeMultipleLinks(data.links)}
                    />
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className="flex flex-col bg-neutral-100 p-2">
                <div key={data.domain}>
                  {data.links.map(link => (
                    <div
                      key={link._id}
                      className="my-2 flex w-full cursor-pointer items-center justify-between gap-2 rounded-md border border-slate-200 bg-white px-2"
                    >
                      <div className="flex items-center">
                        <KnowledgeStatusBadge
                          className="rounded-md py-0.5"
                          status={link.status ?? KnowledgeStatus.NEW}
                        />
                        <a
                          href={link.source}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="break-all px-0.5 text-xs font-medium text-neutral-800 hover:underline"
                        >
                          {link.source}
                        </a>
                      </div>
                      <div className="flex gap-2 px-0.5">
                        <IconButton
                          type="button"
                          variant="tertiary"
                          size="tiny"
                          icon={<Icons.Refresh className="text-neutral-400" />}
                          onClick={() => refreshLink(link)}
                        />
                        <IconButton
                          type="button"
                          variant="tertiary"
                          size="tiny"
                          icon={<Icons.Trash className="text-neutral-400" />}
                          onClick={() => removeSingleLink(link)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        );
      })}
    </div>
  );
};
