import { MyProfile } from "@/components/Profiles/MyProfile";
import { GenerateNewAPIKey } from "@/components/Profiles/GenerateNewAPIKey";
import { AssociatedAccount } from "@/components/Profiles/AssociatedAccount";
import { DeleteAccount } from "./DeleteAccount";
import { useGetUser } from "@/data/queries/useGetUser";
import { MyAvatar } from "@/components/Profiles/MyAvatar";

export const Account = () => {
  const { user } = useGetUser();
  const canDeleteAccount = user?.customer == null;
  return (
    <>
      <div className="text-xl font-bold">Account</div>
      <MyProfile />
      <MyAvatar />
      <GenerateNewAPIKey />
      <AssociatedAccount />
      {canDeleteAccount && <DeleteAccount />}
    </>
  );
};
