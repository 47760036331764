import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { agentsWithConfigKeys } from "@/data/queries/useGetAgentsWithConfig";
import { toast } from "react-toastify";
import type { Workspace } from "@/types/workspace";
import { multipleWorkspaceAgentsKeys } from "@/data/queries/workspace/useGetMultipleWorkspaceAgents";

type UseMoveAgentProps = {
  oldWorkspaceId: Workspace["_id"] | undefined;
  newWorkspaceId: Workspace["_id"] | undefined;
};

type Payload = {
  agentId: string;
  workspaceId: Workspace["_id"];
};

type Response = {
  message: string;
};
const moveAgent = async (payload: Payload) => {
  const { data } = await restClient.post<Response>(apiPaths.moveAgent, payload);
  return data;
};

export const useMoveAgent = ({ newWorkspaceId, oldWorkspaceId }: UseMoveAgentProps) => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<{ message: string }>, Payload>({
    mutationFn: moveAgent,
    onSuccess: async () => {
      newWorkspaceId &&
        (await queryClient.invalidateQueries({ queryKey: multipleWorkspaceAgentsKeys.id(newWorkspaceId) }));
      oldWorkspaceId &&
        (await queryClient.invalidateQueries({ queryKey: multipleWorkspaceAgentsKeys.id(oldWorkspaceId) }));
      await queryClient.invalidateQueries({ queryKey: agentsWithConfigKeys.all });
      toast.success("Agent moved to another workspace successfully.");
    },
  });
};
