import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { baseURL, restClient } from "@/data/initAxios";
import { workspaceAgentsKeys } from "@/data/queries/workspace/useGetWorkspaceAgents";
import { agentsWithConfigKeys } from "@/data/queries/useGetAgentsWithConfig";
import { notificationsKeys } from "../queries/notifications/useGetNotifications";

type Payload = {
  actionUrl: string;
};

type Response = {
  message: string;
};
const acceptDeclineAction = async ({ actionUrl }: Payload) => {
  const urlForApi = actionUrl.replace(baseURL, "");
  const { data } = await restClient.put<Response>(urlForApi);
  return data;
};

export const useAcceptDeclineAction = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<{ message: string }>, Payload>({
    mutationFn: acceptDeclineAction,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: workspaceAgentsKeys.all });
      await queryClient.invalidateQueries({ queryKey: agentsWithConfigKeys.all });
      await queryClient.invalidateQueries({ queryKey: notificationsKeys.all });
    },
  });
};
