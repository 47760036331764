import GroupConversationAgentsName from "@/components/GroupCoversations/GroupConversationAgentsName";
import GroupConversationAvatar from "@/components/GroupCoversations/GroupConversationAvatar";
import { Accordion, AccordionContent, AccordionItem } from "@/components/ui/accordion";
import { Icons } from "@/components/ui/icons";
import { domElementIds } from "@/types/dom-element-ids";
import { scrollToElementWithinParent } from "@/utils/scrollToElementWithinParent";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";

const getItemValue = ({ agentIds, label }: { agentIds: string[]; label: string }) => {
  return `${label}-${agentIds
    .sort()
    .map(id => id)
    .join("-")}`;
};

interface BookmarkHistoryAgentTileWrapperProps {
  children: React.ReactNode;
  agents: {
    _id: string;
    avatar: string;
    name: string;
  }[];
  threadsCount: number;
  label: string;
  currentConversationAgentIds?: string[];
}

const BookmarkHistoryAgentTileWrapper = ({
  children,
  agents,
  threadsCount,
  label,
  currentConversationAgentIds,
}: BookmarkHistoryAgentTileWrapperProps) => {
  const threadUnit = threadsCount === 1 ? "thread" : "threads";

  const itemValue = useMemo(() => getItemValue({ label, agentIds: agents.map(agent => agent._id) }), [agents, label]);

  const [accordionValueOpen, setAccordionValueOpen] = useState("");

  const lastConversationAgentsIdsToScroll = useRef<string[]>([]);
  const debounceTimeoutRef = useRef<NodeJS.Timeout>();
  const scrollTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const agentIds = currentConversationAgentIds ?? [];

    if (!agentIds.length) {
      return;
    }

    const currentAgentsItemValue = getItemValue({ label, agentIds });
    const isMatching = itemValue === currentAgentsItemValue;

    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      const isTheSameAsLastOne = _.isEqual(lastConversationAgentsIdsToScroll.current, agentIds);
      lastConversationAgentsIdsToScroll.current = agentIds;

      if (isTheSameAsLastOne) {
        return;
      }

      setAccordionValueOpen(isMatching ? itemValue : "");

      if (isMatching) {
        clearTimeout(scrollTimeoutRef.current);
        scrollTimeoutRef.current = setTimeout(() => {
          scrollToElementWithinParent({
            parentId: domElementIds.BOOKMARKS_PANEL_SCROLL_CONTAINER,
            elementId: itemValue,
          });
        }, 500);
      }
    }, 500);

    return () => {
      clearTimeout(debounceTimeoutRef.current);
      clearTimeout(scrollTimeoutRef.current);
    };
  }, [currentConversationAgentIds, itemValue, label]);

  return (
    <Accordion
      type="single"
      collapsible
      value={accordionValueOpen}
      onValueChange={setAccordionValueOpen}
      defaultValue={itemValue}
    >
      <AccordionItem value={itemValue} id={itemValue}>
        <AccordionPrimitive.Trigger className="flex w-full items-center gap-2 px-6 py-2 hover:bg-white hover:bg-opacity-10 data-[state=open]:bg-white data-[state=open]:bg-opacity-10 [&[data-state=open]>svg]:rotate-[-90deg]">
          <GroupConversationAvatar avatars={agents.map(agent => agent?.avatar)} />
          <div className="flex w-full flex-col overflow-hidden">
            <GroupConversationAgentsName names={agents.map(agent => agent.name)} className="!text-sm  text-white" />

            <div className="text-left text-xs text-neutral-400">
              {threadsCount} {threadUnit}
            </div>
          </div>
          <Icons.ChevronRight className="scale-125 text-white" />
        </AccordionPrimitive.Trigger>
        <AccordionContent className="mt-4 flex flex-col gap-2 px-6 pb-0">{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default BookmarkHistoryAgentTileWrapper;
