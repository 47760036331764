import { ShareConversationButtonDialog } from "@/components/Chat/components/ShareConversationButtonDialog";
import { NewConversationButton } from "../NewConversationButton";
import type { ChatAgent } from "@/types/conversation";
import { ChatTopBarInfo } from "./ChatTopBarInfo/ChatTopBarInfo";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";

type ChatTopBarProps = {
  agents: ChatAgent[];
  onBackClick?: () => void;
  activeAgentIndex?: number;
  setActiveAgentIndex?: React.Dispatch<React.SetStateAction<number>>;
  setIsKnowledgeDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMultiAgentChatDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ChatTopBar = ({
  agents,
  onBackClick,
  activeAgentIndex,
  setActiveAgentIndex,
  setIsKnowledgeDialogOpen,
  setIsMultiAgentChatDialogOpen,
}: ChatTopBarProps) => {
  const { isPreview } = useChatContext();

  return (
    <div className="flex items-start justify-between gap-2 p-4 @container/top-bar">
      <ChatTopBarInfo
        agents={agents}
        onBackClick={onBackClick}
        activeAgentIndex={activeAgentIndex}
        setActiveAgentIndex={setActiveAgentIndex}
        setIsKnowledgeDialogOpen={setIsKnowledgeDialogOpen}
        setIsMultiAgentChatDialogOpen={setIsMultiAgentChatDialogOpen}
      />
      <div className="flex items-center gap-2">
        {!isPreview && <ShareConversationButtonDialog />}
        <NewConversationButton agents={agents} />
      </div>
    </div>
  );
};
