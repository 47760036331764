import { Button } from "@/components/ui/button";
import { NavigationProfileSections } from "@/components/Profiles/NavigationProfileSection";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Icons } from "@/components/ui/icons";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { useGetUser } from "@/data/queries/useGetUser";
import type { Dispatch, SetStateAction } from "react";
import { useMemo } from "react";
import type { TeamSteps } from "@/components/Profiles/WorkspaceSection/WorkspaceMembers";
import type { Workspace } from "@/types/workspace";
// import { UserStatus } from "@/types/user";

type WorkspaceMembersHeaderProps = {
  setActiveStep: Dispatch<SetStateAction<TeamSteps>>;
  myWorkspaces: Workspace[];
};

export const WorkspaceMembersHeader = ({ setActiveStep, myWorkspaces }: WorkspaceMembersHeaderProps) => {
  const { navigationProfileOpen, setNavigationProfileOpen } = useNavigationProfileContext();
  const { user } = useGetUser();

  const myWorkspace = myWorkspaces?.find(workspace => workspace._id === navigationProfileOpen.section) || null;

  // const usedUsersInAnotherWorkspaces = useMemo(() => {
  //   const otherWorkspaces = myWorkspaces?.filter(workspace => workspace._id !== navigationProfileOpen.section) || [];
  //
  //   const members = otherWorkspaces
  //     .map(workspace => workspace.users.filter(user => user.status === UserStatus.ACTIVE))
  //     .flat();
  //   const admins = otherWorkspaces
  //     .map(workspace => workspace.editors.filter(user => user.status === UserStatus.ACTIVE))
  //     .flat();
  //   const viewers = otherWorkspaces
  //     .map(workspace => workspace.viewers.filter(user => user.status === UserStatus.ACTIVE))
  //     .flat();
  //
  //   return members.length + admins.length + viewers.length;
  // }, [myWorkspaces, navigationProfileOpen.section]);

  const usedUsersInThisWorkspace = useMemo(() => {
    const members = myWorkspace?.users.length || 0;
    const admins = myWorkspace?.editors.length || 0;
    const viewers = myWorkspace?.viewers.length || 0;

    return members + admins + viewers + 1;
  }, [myWorkspace]);

  const userLimit = user?.subscription?.users?.limit || 0;

  const availableUsers = userLimit - (user?.subscription?.users?.currentUsage || 0);

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex gap-1">
        <span className="text-sm font-medium text-primary-black">
          {usedUsersInThisWorkspace} {usedUsersInThisWorkspace > 1 ? "members" : "member"}
        </span>
      </div>
      <div className="flex flex-row gap-1">
        <Button
          variant="ghost"
          size="medium"
          onClick={() =>
            setNavigationProfileOpen({
              open: true,
              section: NavigationProfileSections.ACCOUNT.BILLING,
              mode: "account",
            })
          }
        >
          Add Seats
        </Button>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              className={`gap-2 ${!availableUsers ? "cursor-default hover:bg-red-500 hover:text-white" : ""}`}
              onClick={() => !!availableUsers && setActiveStep("inviteTeamMember")}
            >
              <Icons.Plus className={`size-4 ${!availableUsers ? "hover:text-white" : ""}`} />
              <span className="sr-only sm:not-sr-only">Invite member</span>
            </Button>
          </TooltipTrigger>
          {!availableUsers && (
            <TooltipContent side="left">
              <span>To invite more users, please purchase additional seats</span>
            </TooltipContent>
          )}
        </Tooltip>
      </div>
    </div>
  );
};
