import { useState, useMemo } from "react";
import { Dialog, DialogTrigger, DialogPortal, DialogOverlay, DialogContent } from "@radix-ui/react-dialog";
import { AnimatePresence, motion } from "framer-motion";

import { NotificationsIcon } from "@/components/Notifications/NotificationsIcon";
import { NotificationsActions } from "@/components/Notifications/NotificationsActions";
import { NotificationItem } from "@/components/Notifications/UngroupedNotification/NotificationItem";
import { useGetNotifications } from "@/data/queries/notifications/useGetNotifications";
import { groupAndSortNotifications } from "@/components/Notifications/utils/groupAndSortNotifications";
import { NotificationGroupedItem } from "@/components/Notifications/GroupedNotification/NotificationGroupedItem";
import { cn } from "@/lib/utils";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";

export const Notifications = () => {
  const [open, setOpen] = useState(false);

  const { data: notifications } = useGetNotifications();
  const { navigationSectionOpen } = useLayoutContext();

  const unreadNotifications = useMemo(() => (notifications ? notifications.filter(n => !n.read) : []), [notifications]);
  const groupedNotifications = useMemo(
    () => (notifications ? groupAndSortNotifications(notifications) : []),
    [notifications]
  );

  if (!notifications) {
    return null;
  }

  const isEmpty = notifications.length === 0;

  return (
    <AnimatePresence mode="sync">
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <button
            className={cn(
              "flex min-h-[38px] flex-row items-center gap-3 whitespace-nowrap rounded-lg px-2.5 py-2 text-sm text-neutral-500 hover:bg-primary-100 hover:text-primary-400",
              {
                "size-[38px]": !navigationSectionOpen,
              }
            )}
          >
            <NotificationsIcon active={!!unreadNotifications?.length} />
            {navigationSectionOpen && (
              <>
                <span>Notifications</span>
                {!!unreadNotifications?.length && (
                  <div className="ml-auto min-w-5 rounded-full bg-red-500 px-1 text-xxs font-medium text-white">
                    {unreadNotifications.length}
                  </div>
                )}
              </>
            )}
          </button>
        </DialogTrigger>
        <DialogPortal forceMount>
          {open && (
            <>
              <DialogOverlay asChild forceMount>
                <div key="dialogOverlay" className="fixed inset-0 z-[49]" />
              </DialogOverlay>
              <DialogContent asChild forceMount>
                <motion.div
                  key="dialogContent"
                  initial={{ x: "100%" }}
                  animate={{ x: 0 }}
                  exit={{ x: "100%" }}
                  transition={{ type: "tween", duration: 0.3, ease: "easeIn" }}
                  className="fixed right-0 top-0 z-[50] h-screen
                             w-[100vw] border border-t-0 border-gray-200 bg-white p-0
                             shadow-[10px_10px_50px_0px_#2F2D3D1A] md:w-[60vw] lg:w-[50vw]
                             xl:w-[40vw] 2xl:w-[30vw] 3xl:w-[27vw] 4xl:w-[25vw] 5xl:w-[23vw]"
                >
                  <NotificationsActions unreadNotifications={unreadNotifications} />
                  {isEmpty ? (
                    <div className="flex h-full flex-col justify-center gap-2 overflow-y-scroll text-gray-400">
                      <span className="mx-auto text-lg font-bold">There are no notifications yet.</span>
                      <span className="mx-auto text-base font-semibold">
                        Post more agents to start receiving notifications! 🥳
                      </span>
                    </div>
                  ) : (
                    <div className="flex h-full flex-col overflow-y-scroll">
                      {groupedNotifications.map(grouped =>
                        !grouped.grouped || grouped.notifications.length === 1 ? (
                          <NotificationItem
                            key={grouped.notifications[0]._id}
                            notification={grouped.notifications[0]}
                          />
                        ) : (
                          <NotificationGroupedItem
                            key={grouped.lastUpdateTime.toString()}
                            groupedNotification={grouped}
                          />
                        )
                      )}
                    </div>
                  )}
                </motion.div>
              </DialogContent>
            </>
          )}
        </DialogPortal>
      </Dialog>
    </AnimatePresence>
  );
};
