import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { StatsQueryFilters } from "@/types/stats";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";
import { useQuery } from "@tanstack/react-query";
import type { Workspace } from "@/types/workspace";

type UserActivity = {
  user: {
    _id: string;
    name: string;
    avatar: string;
  };
  messages: number;
  downvoted: number;
};

export const myAgentsUsersActivities = {
  all: ["myAgentsUsersActivities"] as const,
  params: ({
    startDateParam,
    endDateParam,
    workspaceId,
  }: {
    startDateParam: string;
    endDateParam: string;
    workspaceId?: Workspace["_id"];
  }) => [...myAgentsUsersActivities.all, startDateParam, endDateParam, workspaceId] as const,
};

const getMyAgentsUsersActivities = async ({
  startDate,
  endDate,
  workspaceId,
}: {
  startDate: string;
  endDate: string;
  workspaceId?: Workspace["_id"];
}) => {
  const { data } = await restClient.get<UserActivity[]>(
    workspaceId ? apiPaths.workspaceAgentsUsersActivities(workspaceId) : apiPaths.myAgentsUsersActivities,
    {
      params: {
        startDate,
        endDate,
      },
    }
  );
  return data;
};

type Props = Omit<StatsQueryFilters, "channels">;

export const useGetMyAgentsUsersActivities = ({ currentRange, startDate, endDate, workspaceId }: Props) => {
  const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
  const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

  const query = useQuery({
    queryKey: myAgentsUsersActivities.params({ startDateParam, endDateParam, workspaceId }),
    queryFn: () => getMyAgentsUsersActivities({ startDate: startDateParam, endDate: endDateParam, workspaceId }),
  });

  return query;
};
