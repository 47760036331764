import { Avatar } from "@/components/Avatar";
import { Icons } from "@/components/ui/icons";
import { ROUTES } from "@/constants/routes";
import { useGetSharedConversation } from "@/data/queries/useGetSharedConversation";
import type { Conversation, Message } from "@/types/conversation";
import { differenceInMinutes, format } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SharedMessageCloud } from "./components/SharedMessageCloud";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import GroupConversationAvatar from "@/components/GroupCoversations/GroupConversationAvatar";
import GroupConversationAgentsName from "@/components/GroupCoversations/GroupConversationAgentsName";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";

export const SharedConversation = () => {
  const { id } = useParams<{ id?: Conversation["_id"] }>();
  const { data: conversation, isPending, isSuccess } = useGetSharedConversation({ id: id || "" });
  const [reversedConversationMessages, setReversedConversationMessages] = useState<Message[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && conversation?.messages.length) {
      setReversedConversationMessages(conversation.messages.reverse());
    }
  }, [isSuccess, conversation?.messages]);

  if (!id) {
    return <div>Invalid conversation id</div>;
  }

  const isGroupChat = conversation?.bots.length && conversation.bots.length > 1;

  return (
    <>
      {conversation && (
        <Helmet>
          <meta property="og:title" content={`A conversation with ${conversation?.bots[0].name}`} />
          <meta property="og:description" content="Check out what this agent has to say." />
          <meta property="og:image" content={conversation?.bots[0]?.avatar} />
          <meta property="og:type" content="website" />
        </Helmet>
      )}
      <div className="flex h-[100vh] w-full min-w-[100vw] flex-col items-center overflow-hidden bg-gradient-to-b from-primary-50 to-stone-50 p-4 pb-[78px]">
        <div className="fixed top-0 z-10 flex h-[118px] w-full flex-col items-center justify-evenly gap-4 border-b border-neutral-300 bg-primary-50 py-3 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1536px]">
          <Icons.AgentLogo className="h-10 w-24 cursor-pointer" onClick={() => navigate(ROUTES.home)} />
          {isSuccess &&
            (isGroupChat ? (
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger>
                  <div className="relative flex items-center text-lg font-semibold">
                    <GroupConversationAgentsName names={conversation.bots.map(bot => bot.name)} />
                    <GroupConversationAvatar
                      avatars={conversation.bots.map(bot => bot?.avatar)}
                      className="absolute left-[-54px]"
                    />
                    <Icons.ChevronRight className="ml-1 size-6 shrink-0 rotate-90" />
                  </div>
                </DropdownMenuTrigger>

                <DropdownMenuContent>
                  <div>
                    {conversation.bots.map(bot => (
                      <div key={bot._id} className="flex items-center justify-between px-4 py-2">
                        <div className="flex items-center gap-2">
                          <Avatar name={bot.name} src={bot?.avatar} />
                          <span className="text-sm font-semibold">{bot.name}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <div className="relative flex items-center text-lg font-semibold">
                <GroupConversationAgentsName names={conversation.bots.map(bot => bot.name)} />
                <GroupConversationAvatar
                  avatars={conversation.bots.map(bot => bot?.avatar)}
                  className="absolute left-[-54px]"
                />
              </div>
            ))}
        </div>
        {isPending && <LoadingSpinner className="flex w-full flex-1 items-center justify-center" />}
        {isSuccess && (
          <div className="mt-28 flex size-full flex-col-reverse overflow-y-scroll sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1536px]">
            {!!reversedConversationMessages.length &&
              reversedConversationMessages.map((message, index) => {
                const { messages, type } = conversation;
                const currentMessageTime = new Date(message.createdAt);
                const prevMessageTime = index > 0 ? new Date(messages[index + 1]?.createdAt) : null;
                const timeDiff = prevMessageTime ? differenceInMinutes(currentMessageTime, prevMessageTime) : null;
                return (
                  <Fragment key={message._id + message.text}>
                    <SharedMessageCloud
                      message={message.text}
                      isBotMessage={"bot" in message}
                      key={message._id + message.text}
                      trace={message?.trace}
                      agent={message.bot}
                      type={type}
                      showAgentName={!!isGroupChat}
                    />
                    {index === conversation?.messages.length - 1 || (timeDiff && timeDiff > 5) ? (
                      <div className="mb-[-11px] mt-3 flex justify-center text-xs text-gray-400">
                        {format(currentMessageTime, "dd MMM yyyy HH:mm")}
                      </div>
                    ) : null}
                  </Fragment>
                );
              })}
          </div>
        )}
        <div className="fixed bottom-0 flex h-[72px] w-full items-center justify-center border-t border-neutral-300 bg-white">
          <div className="flex size-full items-center justify-between sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1536px]">
            <Link to="https://www.agentx.so/post/how-to-create-an-ai-chat-agent-on-agentx-1-0" target="_blank">
              <Button variant="secondary">Learn more</Button>
            </Link>
            <Button onClick={() => navigate(ROUTES.home)}>Get started with Agentx</Button>
          </div>
        </div>
      </div>
    </>
  );
};
