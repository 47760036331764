import type { ChatAgent } from "@/types/conversation";
import { useNavigate } from "react-router";
import { Icons } from "@/components/ui/icons";
import { ChevronDown } from "lucide-react";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import {
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import GroupConversationAgentsName from "../../../../GroupCoversations/GroupConversationAgentsName";
import { useMemo, useState } from "react";
import ChatTopBarAgentTile from "./components/ChatTopBarAgentTile";
import { useGetUser } from "@/data/queries/useGetUser";
import { IconButton } from "@/components/ui/icon-button";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import type { Agent, AgentMode } from "@/types/agent";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import GroupConversationAvatar from "@/components/GroupCoversations/GroupConversationAvatar";
import { ROUTES } from "@/constants/routes";
import { ChatKnowledgeSummary } from "../../ChatKnowledgeSummary";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { useSearchParams } from "react-router-dom";
import { getCanCreateGroupChatWithAgent } from "@/components/Chat/utils/getCanCreateGroupChatWithAgent";

type ChatTopBarInfoProps = {
  agents: ChatAgent[];
  onBackClick?: () => void;
  activeAgentIndex?: number;
  setActiveAgentIndex?: React.Dispatch<React.SetStateAction<number>>;
  setIsKnowledgeDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMultiAgentChatDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ChatTopBarInfo = ({
  agents,
  onBackClick,
  activeAgentIndex,
  setActiveAgentIndex,
  setIsKnowledgeDialogOpen,
  setIsMultiAgentChatDialogOpen,
}: ChatTopBarInfoProps) => {
  const { isPreview } = useChatContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isMobile } = useMediaQueriesContext();
  const { conversationDetails, agentMode, handleAgentMode } = useChatContext();

  const { user } = useGetUser();

  const [isHoveredSearchButton, setIsHoveredSearchButton] = useState(false);

  const isSingleAgent = agents.length === 1;

  const activeAgent = agents[activeAgentIndex ?? 0];

  const handleGoBack = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      navigate(-1);
    }
  };

  const isEditForbidden =
    isPreview ||
    agents.some(agent => {
      const canCreateGroup = getCanCreateGroupChatWithAgent({ agent, userId: user?._id ?? "" });
      return !canCreateGroup;
    });

  const handleAgentTileClick = ({ index }: { index: number }) => {
    setActiveAgentIndex?.(index);
  };

  const isActiveSearchModeAvailable = useMemo(() => {
    const agentsKnowledgeCount = agents.map(agent => Object.values(agent.embedKnowledges).flat()).flat().length;

    return agentsKnowledgeCount / agents.length >= 5;
  }, [agents]);

  const hoverSearchButton = () => {
    if (isActiveSearchModeAvailable) {
      return;
    }
    setIsHoveredSearchButton(!isHoveredSearchButton);
  };

  const onEditAgentClick = (agentId: Agent["_id"]) => {
    navigate(ROUTES.editAgent(agentId));
  };

  const getMultiAgentChatStructure = () => {
    if (conversationDetails?.manager || !!searchParams.get("manager")) {
      return "Hierarchy";
    }

    return "Flat";
  };

  return (
    <div className="flex items-start gap-2.5 overflow-hidden">
      {!isPreview && (
        <IconButton
          variant="tertiary"
          size="medium"
          roundedType="rounded"
          icon={<Icons.Arrow className="size-6" />}
          onClick={handleGoBack}
        >
          <span className="sr-only">Go back</span>
        </IconButton>
      )}

      <div className="flex flex-wrap items-center gap-2.5">
        {isEditForbidden ? (
          <div className="flex items-center gap-2 text-primary-black">
            <GroupConversationAvatar avatars={agents.map(agent => agent.avatar)} />
            <div className="grid">
              <h3 className="truncate text-left text-base font-semibold text-primary-black md:text-lg">
                {conversationDetails?.title ? conversationDetails.title : agents[0].name}
              </h3>
              {isPreview && <span className="text-xs font-medium text-gray-400">Chat preview</span>}
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-2 text-primary-black">
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger
                onClick={e => e.stopPropagation()}
                className="flex min-h-10 items-center rounded-lg bg-neutral-50 px-2 py-1"
              >
                <GroupConversationAvatar avatars={agents.map(agent => agent.avatar)} className="mr-2" />
                {conversationDetails?.title ? (
                  <h3 className='"truncate text-left text-base font-semibold text-primary-black md:text-lg'>
                    {conversationDetails.title}
                  </h3>
                ) : (
                  <GroupConversationAgentsName names={agents.map(agent => agent.name)} />
                )}
                <ChevronDown className="ml-1 size-4 shrink-0" />
              </DropdownMenuTrigger>
              <DropdownMenuContent onClick={e => e.stopPropagation()} align="start">
                <div className="max-h-80 overflow-y-scroll">
                  <div className="flex items-start justify-between gap-2 border-b border-neutral-200 px-3 py-2">
                    <div className="flex flex-col gap-1">
                      <GroupConversationAgentsName names={agents.map(agent => agent.name)} />
                      <div className="flex gap-1">
                        <div className="w-fit rounded bg-primary-50 px-1.5">
                          <p className="text-xxs font-semibold leading-4 text-primary-400">
                            {isSingleAgent ? "Single agent" : "Mult-agent Team"}
                          </p>
                        </div>
                        {!isSingleAgent && (
                          <div className="w-fit rounded bg-primary-50 px-1.5">
                            <p className="text-xxs font-semibold leading-4 text-primary-400">
                              {getMultiAgentChatStructure()}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    {isSingleAgent ? (
                      <>
                        {agents[0].creator === user?._id && (
                          <IconButton
                            onClick={() => onEditAgentClick(agents[0]._id)}
                            icon={<Icons.Edit />}
                            size="medium"
                            variant="tertiary"
                          >
                            <span className="sr-only">edit this agent</span>
                          </IconButton>
                        )}
                      </>
                    ) : (
                      <IconButton
                        onClick={() => setIsMultiAgentChatDialogOpen(true)}
                        icon={<Icons.Edit />}
                        size="medium"
                        variant="tertiary"
                      >
                        <span className="sr-only">edit multi-agent chat</span>
                      </IconButton>
                    )}
                  </div>

                  {!isSingleAgent &&
                    agents.map((agent, index) => {
                      const canRemove = agents.length > 1;

                      const isMyAgent = agent.creator === user?._id;

                      const creatorName =
                        "creatorUser" in agent ? agent.creatorUser?.name : isMyAgent ? user.name : agent.creator;

                      return (
                        <ChatTopBarAgentTile
                          key={agent._id}
                          agentId={agent._id}
                          name={agent.name}
                          avatar={agent?.avatar}
                          creatorName={creatorName ?? ""}
                          canRemove={canRemove}
                          currentAgentIds={agents.map(agent => agent._id)}
                          onClick={e => {
                            isMobile && e.preventDefault();
                            handleAgentTileClick({ index });
                          }}
                          isActive={agent._id === activeAgent?._id}
                        />
                      );
                    })}

                  {isMobile && (
                    <ChatKnowledgeSummary
                      agent={activeAgent}
                      onKnowledgeClick={() => {
                        setIsKnowledgeDialogOpen(true);
                      }}
                      className="flex-col items-start px-3 py-2"
                      knowledgeClassName="flex-col items-start"
                    />
                  )}
                </div>

                {isSingleAgent && (
                  <DropdownMenuItem
                    className="flex items-center justify-center gap-4 bg-neutral-50 px-6 py-4 text-xs font-semibold leading-5 text-neutral-750"
                    onSelect={() => setIsMultiAgentChatDialogOpen(true)}
                  >
                    <Icons.AddGroup className="size-4" />
                    <span>Create Multi-agent Team</span>
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}

        {!isPreview && (
          <Tooltip open={isHoveredSearchButton}>
            <TooltipTrigger>
              <ToggleGroup
                type="single"
                value={agentMode}
                defaultValue="chat"
                onValueChange={value => {
                  if (!value) {
                    return;
                  }
                  handleAgentMode(value as AgentMode);
                }}
                orientation="horizontal"
              >
                <ToggleGroupItem value="chat">Chat</ToggleGroupItem>
                <ToggleGroupItem
                  value="search"
                  disabled={!isActiveSearchModeAvailable}
                  onMouseOver={hoverSearchButton}
                  onMouseOut={hoverSearchButton}
                >
                  Search
                </ToggleGroupItem>
              </ToggleGroup>
            </TooltipTrigger>
            {!isActiveSearchModeAvailable && (
              <TooltipPortal>
                <TooltipContent side="bottom" sideOffset={5}>
                  5 knowledge needed to use search mode
                </TooltipContent>
              </TooltipPortal>
            )}
          </Tooltip>
        )}
      </div>
    </div>
  );
};
