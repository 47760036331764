import { useState } from "react";
import { toast } from "react-toastify";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { useCreateUserLicences } from "@/data/mutations/workspace/useCreateUserLicences";
import { useGetUser } from "@/data/queries/useGetUser";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import UpgradeBlurBanner from "@/components/UpgradeBlurBanner/UpgradeBlurBanner";
import { SubscriptionTiers } from "@/types/subscriptions";
import type { WorkspaceAdminDetails } from "@/types/workspace";

const UserLicenceSelector = () => {
  const { data: plans, isLoading: plansLoading } = useGetBillingPlans();
  const { data: user } = useGetUser();
  const { data: workspaceDetails } = useGetWorkspaceDetails();
  const { mutate: createUserLicence, isPending } = useCreateUserLicences();
  const { openDialog: openSubscriptionTierDialog } = useSubscriptionTierDialogContext();

  const workspaceAdminDetails = workspaceDetails as WorkspaceAdminDetails;
  const includedLicenses = workspaceAdminDetails?.customer?.subscription?.users?.included ?? 0;
  const purchasedLicences = (workspaceAdminDetails?.customer?.subscription?.users?.limit ?? 0) - includedLicenses;
  const [quantity, setQuantity] = useState(purchasedLicences);
  const currentUsage = workspaceAdminDetails?.customer?.subscription?.users?.currentUsage ?? 0;
  const shouldUpgrade = user?.subscription?.tier === SubscriptionTiers.FREE;
  const needToRemoveExtraUsers = quantity < currentUsage - includedLicenses;
  const usersToChange = currentUsage - includedLicenses - quantity;
  const monthlyPlan =
    user?.subscription?.billingFrequency?.includes("month") ||
    workspaceAdminDetails?.customer?.subscription?.billingFrequency?.includes("month");

  const license = plans?.userLicences?.find(plan => plan.name.includes(monthlyPlan ? "monthly" : "annual"));
  const pricePerUser = license?.price;
  const additionalLicenses = quantity - purchasedLicences;
  const additionalCharge = additionalLicenses * (pricePerUser?.amount ?? 0);

  const handleUpdate = () => {
    if (!license) {
      return;
    }

    createUserLicence(
      {
        planId: license.price.planId,
        quantity: additionalLicenses,
        convertToCustomer: workspaceAdminDetails == null,
      },
      {
        onSuccess: () => toast.success("Quantity updated successfully!"),
      }
    );
  };

  if (plansLoading) {
    return <LoadingSpinner className="flex size-full items-center justify-center" />;
  }

  if (!plans?.userLicences) {
    toast.error("Failed to retrieve billing plans information");
    return null;
  }

  const renderUpgradeBanner = () => (
    <UpgradeBlurBanner
      upgradeDescription="now to invite more team members"
      onUpgradeClick={() => openSubscriptionTierDialog()}
    />
  );

  const renderPlanDetails = () => (
    <>
      <div className="mt-6 grid max-w-max grid-cols-[220px_1fr] gap-4 text-sm text-neutral-500 [&>*:nth-of-type(odd)]:text-neutral-750">
        <span>Included in your plan:</span>
        <span>{includedLicenses}</span>
        <span>Purchased:</span>
        <span>{purchasedLicences}</span>
      </div>
      <div className="mt-4 flex items-center gap-4 rounded-md bg-neutral-50 p-4 md:gap-8">
        <Icons.Users className="size-10 text-primary-400" />
        <div className="flex flex-col gap-2">
          <p className="text-xs font-semibold text-neutral-500">{license?.description}</p>
          <p className="text-sm">
            <span className="font-semibold text-primary-400">${pricePerUser?.amount?.toFixed(2)}</span>/
            {monthlyPlan ? "month" : "year"}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <IconButton
            variant="tertiary"
            icon={<Icons.Minus />}
            size="medium"
            onClick={() => setQuantity(Math.max(0, quantity - 1))}
            disabled={quantity <= 0}
          />
          <div className="min-h-9 min-w-9 rounded-md border border-neutral-300 bg-white p-2 text-center text-sm font-semibold">
            {quantity}
          </div>
          <IconButton
            variant="tertiary"
            icon={<Icons.Plus />}
            size="medium"
            onClick={() => setQuantity(quantity + 1)}
          />
        </div>
      </div>
    </>
  );

  const renderActionButtons = () => (
    <div className="mt-4 flex flex-col items-center">
      <p className="text-lg font-bold">
        {additionalLicenses > 0
          ? `New seats: ${additionalLicenses}`
          : `Seats to cancel: ${Math.abs(additionalLicenses)}`}
      </p>
      {needToRemoveExtraUsers && (
        <p className="text-sm text-red-600">
          You need to remove {Math.abs(usersToChange)} user(s) before you can reduce the number of seats.
        </p>
      )}
      <p className="text-sm text-gray-600">
        {additionalCharge >= 0
          ? `Additional charge per ${monthlyPlan ? "month" : "year"}: $${additionalCharge.toFixed(2)}`
          : `Reduction in charge per ${monthlyPlan ? "month" : "year"}: -$${Math.abs(additionalCharge).toFixed(2)}`}
      </p>
      <div className="mt-4 flex gap-2">
        <Button variant="tertiary" onClick={() => setQuantity(purchasedLicences)}>
          Cancel
        </Button>
        <Button loading={isPending} disabled={isPending || needToRemoveExtraUsers} onClick={handleUpdate}>
          Confirm
        </Button>
      </div>
    </div>
  );

  return (
    <div className="rounded-lg bg-white">
      <div className="mb-4 mt-6 flex justify-between text-lg font-bold">Team seats</div>
      {shouldUpgrade ? renderUpgradeBanner() : renderPlanDetails()}
      {quantity !== purchasedLicences && renderActionButtons()}
    </div>
  );
};

export default UserLicenceSelector;
