import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { cn } from "@/lib/utils";
import type { HierarchyTask } from "@/types/conversation";
import { TaskType } from "@/types/conversation";
import { useEffect, useRef, useState } from "react";
import { ChatMessageReasoningTimelineDot } from "./components/ChatMessageReasoningTimelineDot";
import { ChatMessageReasoningTimeline } from "./components/ChatMessageReasoningTimeline";
import { ChatMessageReasoningTask } from "./components/ChatMessageReasoningTask";

type Props = {
  tasks: HierarchyTask[];
  onClosePanel?: () => void;
};

export const ChatMessageReasoning = ({ tasks, onClosePanel }: Props) => {
  const { setMessageForReasoningPreview, conversationDetails } = useChatContext();

  const findAgentById = (id: string) => {
    return conversationDetails?.bots.find(bot => bot._id === id);
  };

  const firstTaskRef = useRef<HTMLDivElement>(null);
  const lastTaskRef = useRef<HTMLDivElement>(null);

  const [firstTaskHeight, setFirstTaskHeight] = useState(0);
  const [lastTaskHeight, setLastTaskHeight] = useState(0);

  useEffect(() => {
    if (firstTaskRef.current) {
      setFirstTaskHeight(firstTaskRef.current.clientHeight);
    }
  }, [tasks]);

  useEffect(() => {
    if (lastTaskRef.current) {
      setLastTaskHeight(lastTaskRef.current.clientHeight);
    }
  }, [tasks]);

  const manuallyUpdateRefsHeights = () => {
    if (firstTaskRef.current) {
      setFirstTaskHeight(firstTaskRef.current.clientHeight);
    }
    if (lastTaskRef.current) {
      setLastTaskHeight(lastTaskRef.current.clientHeight);
    }
  };

  return (
    <div
      className={cn(
        "z-50 h-full w-full bg-white lg:min-w-[20rem] lg:max-w-[20rem] min-[1150px]:max-w-[24rem] xl:min-w-[32rem] xl:max-w-[32rem]",
        "flex flex-col border-l border-neutral-200"
      )}
    >
      <div className="flex justify-between p-4">
        <h3 className="text-2xl font-bold leading-8 text-neutral-750">Reasoning</h3>
        <IconButton
          variant="tertiary"
          size="tiny"
          icon={<Icons.Close />}
          onClick={() => {
            setMessageForReasoningPreview("");
            onClosePanel?.();
          }}
        />
      </div>

      <div className="overflow-y-auto p-4">
        <div className="relative flex flex-col gap-4 pl-4">
          <ChatMessageReasoningTimeline firstTaskHeight={firstTaskHeight} lastTaskHeight={lastTaskHeight} />
          {tasks.map((task, index) => {
            const isFirstTask = index === 0;
            const isLastTask = index === tasks.length - 1;

            if (task.taskType === TaskType.DELEGATE) {
              return (
                <div
                  ref={isFirstTask ? firstTaskRef : isLastTask ? lastTaskRef : null}
                  key={`task_${index}`}
                  className="relative bg-neutral-100 px-4 py-2"
                >
                  <ChatMessageReasoningTimelineDot isActive />
                  <p className="text-xs font-medium leading-5 text-neutral-600">{task.input}</p>
                </div>
              );
            }

            const agent = findAgentById(task.name);

            return (
              <div
                key={`task_${index}`}
                ref={isFirstTask ? firstTaskRef : isLastTask ? lastTaskRef : null}
                className="relative"
              >
                <ChatMessageReasoningTask task={task} agent={agent} onIsExpandedChange={manuallyUpdateRefsHeights} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
