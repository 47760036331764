import { useGetAllAgentsConversationsWithUser } from "@/data/queries/useGetAllAgentsConversationsWithUser";
import { useGetUserConversationHistoryReport } from "@/data/queries/stats/useGetUserConversationHistoryReport";
import { ChatHistoryDialogBase } from "@/components/ChatHistoryDialog/ChatHistoryDialogBase";

import type { HandleDownloadReportProps } from "@/data/queries/stats/useGetUserConversationHistoryReport";
import type { DateRangeType } from "@/types/datepicker";
import type { User } from "@/types/user";
import type {
  FetchConversationsArgs,
  FetchConversationsReturn,
} from "@/components/ChatHistoryDialog/types/chat-history-dialog";

interface UserChatHistoryDialogProps {
  isOpen: boolean;
  onClose: () => void;

  userId: User["_id"];
  userName: User["name"];

  isOnlyDownvotedMessages: boolean;
  onIsOnlyDownvotedMessagesChange: (val: boolean) => void;

  initialCurrentRange?: DateRangeType;
  initialStartDate?: Date | null;
  initialEndDate?: Date | null;
}

const createUserConversationsHook = (userId: string, onlyWithDownvotes: boolean) => {
  return (args: FetchConversationsArgs): FetchConversationsReturn => {
    const { data, isLoading } = useGetAllAgentsConversationsWithUser({
      ...args,
      userId,
      onlyWithDownvotes,
      enabled: !!userId,
    });

    return {
      data: data
        ? {
            conversations: data.conversations,
            totalSearchMatches: data.totalSearchMatches ?? 0,
          }
        : undefined,
      isLoading,
    };
  };
};

export const UserChatHistoryDialog = ({
  isOpen,
  onClose,
  userId,
  userName,
  isOnlyDownvotedMessages,
  onIsOnlyDownvotedMessagesChange,
  initialCurrentRange,
  initialStartDate,
  initialEndDate,
}: UserChatHistoryDialogProps) => {
  const { downloadReport: userDownloadReport, isLoadingDownloadReport } = useGetUserConversationHistoryReport();

  const userConversationsHook = createUserConversationsHook(userId, isOnlyDownvotedMessages);

  const handleDownloadReport = (props: Omit<HandleDownloadReportProps, "userId" | "userName">) => {
    void userDownloadReport({ userId, userName, ...props });
  };

  return (
    <ChatHistoryDialogBase
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={`${userName}'s conversations`}
      isOnlyDownvotedMessages={isOnlyDownvotedMessages}
      onIsOnlyDownvotedMessagesChange={onIsOnlyDownvotedMessagesChange}
      fetchConversations={userConversationsHook}
      downloadReport={handleDownloadReport}
      isLoadingDownloadReport={isLoadingDownloadReport}
      initialCurrentRange={initialCurrentRange}
      initialStartDate={initialStartDate}
      initialEndDate={initialEndDate}
    />
  );
};
