import type { FC } from "react";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import type { AudioData } from "@/components/Chat/hooks/useAudioRecorder";

interface AgentVoiceRecorderProps {
  isRecording: boolean;
  isPlaying: boolean;
  hasAudio: boolean;
  recordingTime: number;
  currentTime: number;
  duration: number;
  startRecording: () => Promise<void>;
  stopRecording: () => Promise<AudioData>;
  deleteRecording: () => void;
  play: () => void;
  pause: () => void;
}

export const AgentVoiceRecorder: FC<AgentVoiceRecorderProps> = ({
  isRecording,
  isPlaying,
  hasAudio,
  recordingTime,
  currentTime,
  duration,
  startRecording,
  stopRecording,
  deleteRecording,
  play,
  pause,
}) => {
  const durationInSeconds = isRecording ? Math.min(recordingTime, 60) : duration || 0;
  const flooredCurrentTime = Math.floor(currentTime);
  const overlayWidth = isRecording
    ? (recordingTime / 60) * 100
    : durationInSeconds > 0
      ? (flooredCurrentTime / durationInSeconds) * 100
      : 0;

  const handlePlayPause = () => {
    if (!hasAudio) {
      return;
    }
    isPlaying ? pause() : play();
  };

  return (
    <div className="flex w-full items-center gap-2 pr-1 pt-1">
      <IconButton
        variant="tertiary"
        icon={isPlaying ? <Icons.PauseClip /> : <Icons.PlayClip />}
        onClick={handlePlayPause}
        disabled={!hasAudio}
      >
        <span className="sr-only">{isPlaying ? "Pause" : "Play"} voice message</span>
      </IconButton>

      <div className="relative flex-1 px-1">
        <div className="relative h-5 w-full">
          <div className="absolute inset-0 flex items-center justify-center">
            <Icons.VoiceMessageBarsLong className="w-full text-neutral-300" />
            {(isRecording || hasAudio) && (
              <div
                className="absolute inset-0 -mt-0.5 overflow-hidden"
                style={{
                  clipPath: `inset(0 ${100 - overlayWidth}% 0 0)`,
                }}
              >
                <Icons.VoiceMessageBarsLong className="w-full text-blue-600" />
              </div>
            )}
          </div>
        </div>
      </div>

      <span className="text-xs font-medium">
        {isRecording
          ? `0:${String(recordingTime).padStart(2, "0")}`
          : `0:${String(durationInSeconds).padStart(2, "0")}`}
      </span>

      <IconButton
        variant="tertiary"
        onClick={deleteRecording}
        disabled={!hasAudio || isRecording}
        icon={<Icons.Trash />}
      >
        <span className="sr-only">Delete recording</span>
      </IconButton>

      <button
        onClick={isRecording ? stopRecording : startRecording}
        disabled={hasAudio && !isRecording && isPlaying}
        className="relative flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border border-gray-400 p-1"
      >
        {isRecording ? <div className="h-4 w-4 bg-red-600" /> : <div className="h-5 w-5 rounded-full bg-red-600" />}
        <span className="sr-only">{isRecording ? "Stop recording" : "Start recording"}</span>
      </button>
    </div>
  );
};
