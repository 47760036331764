import { createContext } from "react";

type LayoutContextProps = {
  navigationSectionOpen: boolean;
  toggleNavigationSectionOpen: () => void;
  quickLinksSectionOpen: boolean;
  toggleQuickLinksSectionOpen: () => void;
};

export const LayoutContext = createContext<LayoutContextProps | null>(null);
