import { useSendChatMessage } from "../hooks/useSendChatMessage";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useUpdateNavConversationHistory } from "@/data/queryUpdates/useUpdateNavConversationHistory";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";
import { cn } from "@/lib/utils";
import type { ChatAgent } from "@/types/conversation";
import { PredefinedQuestionSingle } from "./PredefinedQuestionSingle";

type PredefinedQuestionsProps = {
  agent: ChatAgent;
  disabled?: boolean;
  className?: string;
  isWelcomeView?: boolean;
};

export const PredefinedQuestions = ({ agent, disabled, className, isWelcomeView }: PredefinedQuestionsProps) => {
  const { isGuestUser } = useIsGuestUser();
  const { showLoginDialog } = useLoginDialogContext();
  const { updateNavConversationHistory } = useUpdateNavConversationHistory();

  const { sendMessage } = useSendChatMessage();
  const { isPreview, agentMode } = useChatContext();

  const questions = agent.definedActions?.predefined;

  if (!questions?.length) {
    return null;
  }

  const handleSendMessage = (message: string) => {
    if (isGuestUser) {
      showLoginDialog();
      return;
    }
    void sendMessage({
      messageText: message,
      onSendMessageCallback: () => {
        if (!isPreview) {
          updateNavConversationHistory({ agents: [agent] });
        }
      },
      agents: [agent],
      agentMode,
    });
  };

  if (isWelcomeView) {
    return (
      <div className="scrollbar-default my-4 flex max-h-[25vh] flex-wrap gap-2 overflow-y-auto px-2">
        {questions.map(({ question }) => {
          return (
            <PredefinedQuestionSingle key={question} disabled={disabled} onClick={() => handleSendMessage(question)}>
              {question}
            </PredefinedQuestionSingle>
          );
        })}
      </div>
    );
  }

  return (
    <div className={cn("mx-1 ml-[50px] mt-0 flex cursor-pointer flex-wrap gap-2 px-4 py-1 text-xs", className)}>
      {questions.map(({ question }) => (
        <PredefinedQuestionSingle key={question} disabled={disabled} onClick={() => handleSendMessage(question)}>
          {question}
        </PredefinedQuestionSingle>
      ))}
    </div>
  );
};
