import { useForm } from "react-hook-form";
import { FormInput } from "@/components/ui/FormInput";
import { ChangePasswordDialog } from "@/components/Profiles/ChangePasswordDialog";
import { useGetUser } from "@/data/queries/useGetUser";

export const AccountSecurity = () => {
  const { user } = useGetUser();
  const form = useForm({ defaultValues: { email: user?.email, password: "*********" } });

  return (
    <div className="flex flex-col gap-6">
      <div className="text-xl font-bold">Security</div>
      <form>
        <div className="flex flex-row items-end">
          <FormInput
            formObj={form}
            name="email"
            autoComplete="email"
            placeholder="Enter email address"
            label="Email"
            inputClassName="my-0 w-40 md:w-52 lg:w-64"
            disabled
          />
        </div>

        {!user?.provider && (
          <div className="flex flex-row items-end pt-4">
            <FormInput
              formObj={form}
              name="password"
              autoComplete="current-password"
              placeholder="Enter password"
              label="Password"
              inputClassName="my-0 w-40 md:w-52 lg:w-64"
              disabled
            />
            <ChangePasswordDialog />
          </div>
        )}
      </form>
    </div>
  );
};
