import { useGetUser } from "@/data/queries/useGetUser";
import { useCreateWorkspace } from "@/data/mutations/workspace/useCreateWorkspace";
import { useGetMyWorkspaces } from "@/data/queries/workspace/useGetMyWorkspaces";
import { cn } from "@/lib/utils";
import { Icons } from "@/components/ui/icons";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { IconButton } from "@/components/ui/icon-button";
import { SubscriptionTiers } from "@/types/subscriptions";

type AddWorkspaceButtonProps = {
  isSideMenu?: boolean;
};

export const AddWorkspaceButton = ({ isSideMenu }: AddWorkspaceButtonProps) => {
  const { user } = useGetUser();
  const { data: myWorkspaces } = useGetMyWorkspaces();

  const { mutate: createWorkspace, isPending: isCreatingWorkspace } = useCreateWorkspace();
  const { setNavigationProfileOpen } = useNavigationProfileContext();

  const isFreeTier = user?.subscription.tier === SubscriptionTiers.FREE;
  const isStartupTier = user?.subscription.tier === SubscriptionTiers.STARTUP;

  const createWorkspaceHandle = () => {
    if (isCreatingWorkspace) {
      return;
    }

    const generateName = (): string => {
      const workspaceName = `My workspace ${Math.floor(Math.random() * 100)}`;
      if (myWorkspaces?.find(workspace => workspace.name === workspaceName)) {
        return generateName();
      }
      return workspaceName;
    };

    createWorkspace(
      { name: generateName() },
      {
        onSuccess: workspace => setNavigationProfileOpen({ open: true, section: workspace._id, mode: "workspace" }),
      }
    );
  };

  if (!user?.subscription.users || isFreeTier || isStartupTier) {
    return null;
  }

  if (isSideMenu) {
    return (
      <div
        className={cn(
          "flex w-full cursor-pointer items-center rounded-md border-gray-200 px-2 py-4 text-sm font-medium text-neutral-750 sm:w-40 sm:border-none  sm:p-2 sm:hover:bg-primary-200 sm:hover:font-semibold lg:w-52 [&:not(:last-child)]:border-b",
          isCreatingWorkspace && "cursor-default"
        )}
        onClick={createWorkspaceHandle}
      >
        <Icons.Plus className="size-6" />
        <span className="ml-3 lg:ml-4">Add workspace</span>
      </div>
    );
  }

  return (
    <IconButton
      icon={<Icons.Plus className="size-4 text-primary-400" />}
      size="medium"
      variant="tertiary"
      className="bg-primary-50 hover:bg-primary-100"
      roundedType="rounded"
      onClick={createWorkspaceHandle}
    />
  );
};
