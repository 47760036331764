import { useQuery } from "@tanstack/react-query";

import type { FullAgent } from "@/types/agent";
import type { Workspace } from "@/types/workspace";
import { restClient } from "@/data/initAxios";
import { apiPaths } from "@/data/apiPaths";

export const multipleWorkspaceAgentsKeys = {
  all: ["multipleWorkspaceAgents"] as const,
  id: (id: Workspace["_id"]) => [...multipleWorkspaceAgentsKeys.all, id] as const,
};

type Props = {
  id: Workspace["_id"];
};

const getMultipleWorkspaceAgents = async ({ id }: Props) => {
  const { data } = await restClient.get<FullAgent[]>(apiPaths.getMultipleWorkspaceAgents(id));
  return data;
};

export const useGetMultipleWorkspaceAgents = ({ id }: Props) => {
  return useQuery({
    queryKey: multipleWorkspaceAgentsKeys.id(id),
    queryFn: () => getMultipleWorkspaceAgents({ id }),
  });
};
