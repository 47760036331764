import { DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import StatsDateRangePicker from "@/components/StatsDateRangePicker/StatsDateRangePicker";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ChatHistoryDialogFoundBadge } from "./ChatHistoryDialogFoundBadge";
import { cn } from "@/lib/utils";
import type { DateRangeType } from "@/types/datepicker";
import type { MessagesNumRange } from "../constants/chatHistory";
import { messagesNumRanges } from "../constants/chatHistory";
import { useRef } from "react";
import { useAdaptiveScrollbar } from "@/hooks/useAdaptiveScrollbar";

interface Props {
  renderHeader?: React.ReactNode;
  children: React.ReactNode;
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  showSearchMatches: boolean;
  totalSearchMatches: number;
  debouncedSearchValue: string;
  currentRange: DateRangeType;
  onChangeCurrentRange: (value: DateRangeType) => void;
  startDate: Date | null;
  onStartDateChange: (value: Date | null) => void;
  endDate: Date | null;
  onEndDateChange: (value: Date | null) => void;
  selectedMessagesNumRange: MessagesNumRange;
  onSelectedMessagesRangeChange: (value: MessagesNumRange) => void;
  isOnlyDownvotedMessages: boolean;
  onIsOnlyDownvotedMessagesChange: (value: boolean) => void;
  isCustomMessagesRange: boolean;
  renderFooter?: () => React.ReactNode;
  renderDownloadSelect?: () => React.ReactNode;
}

export const ChatHistoryDialogContentLarge = ({
  renderHeader = "Conversations",
  children,
  searchValue,
  onSearchValueChange,
  showSearchMatches,
  totalSearchMatches,
  debouncedSearchValue,
  currentRange,
  onChangeCurrentRange,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  selectedMessagesNumRange,
  onSelectedMessagesRangeChange,
  isOnlyDownvotedMessages,
  onIsOnlyDownvotedMessagesChange,
  isCustomMessagesRange,
  renderFooter,
  renderDownloadSelect,
}: Props) => {
  const contentContainerRef = useRef<HTMLDivElement>(null);
  useAdaptiveScrollbar(contentContainerRef, 10);

  return (
    <>
      <DialogHeader className="h-14 px-12 py-4 font-bold text-primary-black">{renderHeader}</DialogHeader>
      <div className={cn("h-full w-full px-12 pt-4", { "pb-8": !!renderFooter })}>
        <div className={cn("flex items-center justify-between pb-4", { "pb-9": showSearchMatches })}>
          <div className="relative w-full">
            <Input
              value={searchValue}
              onChange={e => onSearchValueChange(e.target.value)}
              placeholder="Search keywords or name"
              inputClassName="placeholder:text-slate-400 !pr-9 h-10"
              handleClear={() => onSearchValueChange("")}
            />
            {showSearchMatches && (
              <ChatHistoryDialogFoundBadge
                foundValue={totalSearchMatches}
                searchValue={debouncedSearchValue}
                className="absolute mt-3"
              />
            )}
          </div>

          <span className="mx-4">
            <Checkbox
              id="downvotedMessages"
              label="Downvoted only"
              checked={isOnlyDownvotedMessages}
              onCheckedChange={onIsOnlyDownvotedMessagesChange}
              labelClassName="text-nowrap"
            />
          </span>

          <div className="mx-4">
            <StatsDateRangePicker
              selectedDateRangeType={currentRange}
              onSelectedDateRangeTypeChange={onChangeCurrentRange}
              startDate={startDate}
              onStartDateChange={onStartDateChange}
              endDate={endDate}
              onEndDateChange={onEndDateChange}
              align="start"
            />
          </div>

          <div className="ml-3 mr-1">
            <Select
              onValueChange={val => onSelectedMessagesRangeChange(val as MessagesNumRange)}
              value={selectedMessagesNumRange}
            >
              <SelectTrigger id="numOfMessages" className="w-[209px]">
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent>
                {messagesNumRanges?.map(rangeType => (
                  <SelectItem key={rangeType} value={rangeType}>
                    {rangeType}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {renderDownloadSelect?.()}
        </div>

        <div
          className="flex h-full flex-1 grow flex-col gap-2 overflow-y-scroll"
          style={{
            maxHeight: `calc(100% - 98px - ${isCustomMessagesRange ? "60px" : "0px"})`,
          }}
          ref={contentContainerRef}
        >
          {children}
        </div>
      </div>
      {renderFooter && <DialogFooter className="px-12">{renderFooter()}</DialogFooter>}
    </>
  );
};
