import { SORT_BY_VALUES } from "@/pages/Workspace/components/SortAgentsByNameDropdown";
import type { FullAgent } from "@/types/agent";

type FilterAndSortAgentsProps = {
  debouncedSearchValue: string;
  sortBy: null | SORT_BY_VALUES;
  myAgents: FullAgent[] | undefined;
};

export const filterAndSortAgents = ({ debouncedSearchValue, sortBy, myAgents }: FilterAndSortAgentsProps) => {
  if (!debouncedSearchValue.length && !sortBy) {
    return myAgents ?? [];
  } else {
    const searchValueLowerCased = debouncedSearchValue.toLowerCase();
    const agents = myAgents?.filter(agent => agent.name.toLowerCase().includes(searchValueLowerCased));

    if (!sortBy) {
      return agents ?? [];
    } else {
      const sortedAgents = agents?.sort((a, b) => {
        if (sortBy === SORT_BY_VALUES.ASC) {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });

      return sortedAgents ?? [];
    }
  }
};
