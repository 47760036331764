import { useQuery } from "@tanstack/react-query";

import { restClient } from "@/data/initAxios";
import { apiPaths } from "@/data/apiPaths";
import type { WorkspaceUser } from "@/types/user";

export const multipleWorkspaceAllUsersKeys = {
  all: ["multipleWorkspaceAllUsers"] as const,
};

const getMultipleWorkspaceAllUsers = async () => {
  const { data } = await restClient.get<WorkspaceUser[]>(apiPaths.getMultipleWorkspaceAllUsers);
  return data;
};

export const useGetMultipleWorkspaceAllUsers = () => {
  return useQuery({
    queryKey: multipleWorkspaceAllUsersKeys.all,
    queryFn: getMultipleWorkspaceAllUsers,
  });
};
