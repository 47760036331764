import { useState } from "react";
import type { Tool } from "@/types/tools";
import { Icons } from "@/components/ui/icons";
import Editor from "@monaco-editor/react";
import { Controller, type UseFormReturn } from "react-hook-form";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useGetSingleToolLogs } from "@/data/queries/useGetSingleToolLogs";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { LinkButton } from "@/components/ui/link-button";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";

type CodeEditorProps = {
  form: UseFormReturn<Tool>;
};

const defaultCode = `def run(args_json: dict):
    # To get the value of a parameter, use args_json['parameter_name']
    # e.g. my_parameter = args_json.get('my_parameter')
    # json, requests are automatically imported
    # < Write your code here >
    return json.dumps({'response': 'change me', 'success': True})
`;

type CodeOrLog = "Code" | "Logs";
const parseLogDate = (
  url: string
): {
  displayDate: string; // the local time string
  restName: string; // the rest of the filename
} => {
  try {
    if (!url) {
      return {
        displayDate: "Unknown date",
        restName: "Unknown name",
      };
    }
    const fullFilename = url?.split("/").pop() || "";
    const [datePart, ...restParts] = fullFilename.split("-");

    // If the first chunk (datePart) isn't 14 digits, bail out:
    if (!/^\d{14}$/.test(datePart)) {
      return {
        displayDate: "Unknown date",
        restName: fullFilename,
      };
    }

    // Extract date/time parts (YYYYMMDDHHmmss)
    const year = parseInt(datePart.substring(0, 4), 10); // e.g. 2024
    const month = parseInt(datePart.substring(4, 6), 10); // e.g. 12
    const day = parseInt(datePart.substring(6, 8), 10); // e.g. 09
    const hour = parseInt(datePart.substring(8, 10), 10); // e.g. 05
    const minute = parseInt(datePart.substring(10, 12), 10); // e.g. 13
    const second = parseInt(datePart.substring(12, 14), 10); // e.g. 12

    // Create the Date object in UTC.
    // month - 1 because JavaScript months are 0-based (0 = January)
    const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

    // Format as a local time string (e.g. "12/9/2024, 5:13:12 AM")
    const displayDate = utcDate?.toLocaleString();

    // Remainder of filename after the first dash
    // e.g. ["sentinel_query_tool-", ".log"] or a single chunk, depending on your filenames
    let restName = restParts?.join("-");
    if (restName.endsWith("-.log")) {
      restName = restName.replace(/-\.log$/, ".log");
    }
    return {
      displayDate,
      restName,
    };
  } catch (error) {
    return {
      displayDate: "Unknown date",
      restName: "Unknown name",
    };
  }
};

export const CodeEditor = ({ form }: CodeEditorProps) => {
  const agentForm = useAgentFormContext();
  const [activeCodeOrLog, setActiveCodeOrLog] = useState<CodeOrLog>("Code");
  const [codeEditorTheme, setCodeEditorTheme] = useState<"light" | "vs-dark">("light");

  const { data: logs } = useGetSingleToolLogs({
    agentId: agentForm.getValues("_id") || "",
    toolId: form.getValues("_id") || "",
  });

  return (
    <div className="h-full min-h-[600px] overflow-hidden border border-neutral-300">
      <div className="flex w-full gap-2 border-b border-neutral-300 p-3 px-4 text-sm font-medium">
        Code editor
        <HybridTooltipPopover heading="Python Script">
          <p>
            Only write your code within the givin "run" function. Always return stringfied JSON with "response" and
            "success" keys.
          </p>
        </HybridTooltipPopover>
        <div
          onClick={() => setCodeEditorTheme(codeEditorTheme === "light" ? "vs-dark" : "light")}
          className="ml-auto cursor-pointer"
        >
          <Icons.LightBulb className="size-4 text-primary-400" />
        </div>
      </div>
      <Tabs value={activeCodeOrLog} className="w-full space-y-4">
        <TabsList className="w-full bg-neutral-100 p-1">
          {["Code", "Logs"].map(tab => (
            <TabsTrigger
              key={tab}
              value={tab}
              onClick={() => setActiveCodeOrLog(tab as CodeOrLog)}
              className="flex-1 text-neutral-600 data-[state=active]:text-neutral-750"
            >
              {tab}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      {activeCodeOrLog === "Code" ? (
        <Controller
          control={form.control}
          name="toolFunction.code"
          render={({ field: { value, onChange } }) => (
            <Editor
              theme={codeEditorTheme} // light
              height="calc(100% - 86px)"
              defaultLanguage="python"
              defaultValue={value || defaultCode}
              onChange={onChange}
            />
          )}
        />
      ) : (
        <div className="h-[85%] overflow-auto p-3">
          <div>
            {logs?.urlList.map((url, index) => {
              const { displayDate, restName } = parseLogDate(url);

              return (
                <div key={`log_${index}`} className="mb-2">
                  <LinkButton type="button" className="text-primary-500" onClick={() => window.open(url)}>
                    <Icons.FileDownload />
                    <span className="ml-4 text-neutral-600">
                      {/* Show local time, then the remainder of the filename */}

                      <a className="pl-2" href={url} download>
                        {displayDate} - {restName}
                      </a>
                    </span>
                  </LinkButton>
                </div>
              );
            })}
          </div>

          {logs?.urlList.length === 0 && <div className="text-neutral-400">No logs available</div>}
        </div>
      )}
    </div>
  );
};
