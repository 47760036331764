import StatsDateRangePicker from "@/components/StatsDateRangePicker/StatsDateRangePicker";
import { useGetUser } from "@/data/queries/useGetUser";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";

export const StatsHeader = () => {
  //TODO: uncomment when needed
  // const isMobileMd = !useTailwindMediaQuery("md");
  const { user } = useGetUser();
  const {
    currentRange,
    setCurrentRange,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    workspaceOptions,
    activeWorkspaceId,
    setActiveWorkspaceId,
  } = useStatsContext();

  const nameToDisplay = user?.name + (user?.lastName ? ` ${user.lastName}` : "");

  return (
    <div className="flex flex-col gap-5 px-5 pb-5 lg:px-6">
      <div className="grid grid-cols-2 items-center justify-between gap-6 xl:grid-cols-3 xl:items-center">
        <div className="col-span-2 md:col-span-1">
          <h1 className="text-[1.75rem] font-bold leading-9">{`Welcome, ${nameToDisplay}!`}</h1>
          <p className="text-sm leading-5 text-secondary-400">Here are the statistics about all your agents.</p>
        </div>

        {/* {isMobileMd && <StatsTabsMobile />}

        {!isMobileMd && <StatsTabsDesktop />} */}

        <div className="col-start-2 flex flex-col gap-2 place-self-end md:row-start-1 md:flex-row xl:col-start-3 xl:self-center">
          {workspaceOptions.length > 1 && (
            <select
              id="buttonType"
              className="w-52 rounded-md border border-gray-300 p-1.5 text-neutral-600"
              value={activeWorkspaceId}
              onChange={e => setActiveWorkspaceId(e.target.value)}
            >
              {workspaceOptions.map(({ workspaceId, name }) => (
                <option value={workspaceId} key={workspaceId}>
                  {name}
                </option>
              ))}
            </select>
          )}
          <StatsDateRangePicker
            selectedDateRangeType={currentRange}
            onSelectedDateRangeTypeChange={setCurrentRange}
            startDate={startDate}
            onStartDateChange={setStartDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
          />
        </div>
      </div>
    </div>
  );
};
