import { useState, useMemo } from "react";
import { useDebounceValue } from "usehooks-ts";
import { cn } from "@/lib/utils";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";

import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { IconButton } from "@/components/ui/icon-button";
import { Checkbox } from "@/components/ui/checkbox";

import { ChatHistoryDialogDownloadFooter } from "@/components/ChatHistoryDialog/components/ChatHistoryDialogDownloadFooter";
import { ChatHistoryDialogDownloadSelectBtn } from "@/components/ChatHistoryDialog/components/ChatHistoryDialogDownloadSelectBtn";
import { ChatHistoryConversationDialogContent } from "@/components/ChatHistoryDialog/components/ChatHistoryConversationDialogContent";
import { ChatHistoryDialogListItem } from "@/components/ChatHistoryDialog/components/ChatHistoryDialogListItem";
import { ChatHistoryDialogLoader } from "@/components/ChatHistoryDialog/components/ChatHistoryDialogLoader";
import { ChatHistoryDialogEmptyMessage } from "@/components/ChatHistoryDialog/components/ChatHistoryDialogEmptyMessage";

import { ChatHistoryDialogContentLarge } from "./components/ChatHistoryDialogContentLarge";
import { ChatHistoryDialogContentSmall } from "./components/ChatHistoryDialogContentSmall";
import ChatHistoryContentMessagesRangeSlider from "@/components/ChatHistoryDialog/components/ChatHistoryContentMessagesRangeSlider";

import { Pagination } from "@/components/ui/Pagination";
import { DateRangeTypes } from "@/types/datepicker";
import { MessagesNumRanges, MESSAGES_SLIDER_VALUES } from "@/components/ChatHistoryDialog/constants/chatHistory";

import type { ChatHistoryDialogBaseProps, ConversationWithSearchMatches } from "./types/chat-history-dialog";
import type { DateRangeType } from "@/types/datepicker";
import type { Conversation } from "@/types/conversation";
import type { MessagesNumRange } from "@/components/ChatHistoryDialog/constants/chatHistory";

const ITEMS_PER_PAGE = 20;
const MAX_PAGINATION_ELEMENTS_MOBILE = 7;
const MAX_PAGINATION_ELEMENTS_DESKTOP = 9;
const DEBOUNCE_TIME_MS = 700;

export const ChatHistoryDialogBase = ({
  isOpen,
  onClose,
  headerTitle,
  isOnlyDownvotedMessages,
  onIsOnlyDownvotedMessagesChange,
  fetchConversations,
  downloadReport,
  isLoadingDownloadReport,
  initialCurrentRange,
  initialStartDate,
  initialEndDate,
  renderChannelPicker,
}: ChatHistoryDialogBaseProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [currentRange, setCurrentRange] = useState<DateRangeType>(initialCurrentRange ?? DateRangeTypes.Last7Days);
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate ?? null);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate ?? null);

  const [selectedMessagesNumRange, setSelectedMessagesNumRange] = useState<MessagesNumRange>(MessagesNumRanges.All);
  const [customMinNumOfMessages, setCustomMinNumOfMessages] = useState<number | null>(null);
  const [customMaxNumOfMessages, setCustomMaxNumOfMessages] = useState<number | null>(null);

  const [isSelectToDownloadOpen, setIsSelectToDownloadOpen] = useState(false);
  const [selectedConversationsIds, setSelectedConversationsIds] = useState<string[]>([]);

  const [currentConversation, setCurrentConversation] = useState<ConversationWithSearchMatches | null>(null);

  const { isMobile } = useMediaQueriesContext();
  const isScreenXl = useTailwindMediaQuery("xl");

  const [currentPage, setCurrentPage] = useState(1);

  const [debouncedSearchValue] = useDebounceValue(searchValue, DEBOUNCE_TIME_MS);
  const [debouncedCustomMinNumOfMessages] = useDebounceValue(customMinNumOfMessages, DEBOUNCE_TIME_MS);
  const [debouncedCustomMaxNumOfMessages] = useDebounceValue(customMaxNumOfMessages, DEBOUNCE_TIME_MS);

  const { data, isLoading } = fetchConversations({
    searchValue: debouncedSearchValue,
    currentRange,
    startDate,
    endDate,
    minMessages: debouncedCustomMinNumOfMessages,
    maxMessages: debouncedCustomMaxNumOfMessages,
  });

  const conversations = data?.conversations ?? [];
  const totalSearchMatches = data?.totalSearchMatches ?? 0;
  const showSearchMatches = !!debouncedSearchValue && data?.totalSearchMatches !== undefined;

  const totalPages = Math.ceil(conversations.length / ITEMS_PER_PAGE);
  const pageStart = (currentPage - 1) * ITEMS_PER_PAGE;
  const pageConversations = conversations.slice(pageStart, pageStart + ITEMS_PER_PAGE);

  const handleClickConversationSelect = (convId: string, checked: boolean) => {
    setSelectedConversationsIds(prev => (checked ? [...prev, convId] : prev.filter(x => x !== convId)));
  };
  const getIsConversationSelected = (convId: string) => {
    return selectedConversationsIds.includes(convId);
  };

  const handleLetMeSelectConversationsToDownload = () => {
    setIsSelectToDownloadOpen(prev => !prev);
    const allIds = conversations.map(c => c.conversation._id);
    setSelectedConversationsIds(allIds);
  };

  const handleDownloadReport = (conversationIds: Conversation["_id"][]) => {
    void downloadReport({
      currentRange,
      startDate,
      endDate,
      minMessages: debouncedCustomMinNumOfMessages,
      maxMessages: debouncedCustomMaxNumOfMessages,
      searchValue: debouncedSearchValue,
      onlyWithDownvotes: isOnlyDownvotedMessages,
      conversations: conversationIds,
    });
  };

  const isCustomMessagesRange = selectedMessagesNumRange === MessagesNumRanges.Custom;

  const sliderMinValue = useMemo(() => {
    if (customMinNumOfMessages === null) {
      return 0;
    }
    return MESSAGES_SLIDER_VALUES.indexOf(customMinNumOfMessages);
  }, [customMinNumOfMessages]);

  const sliderMaxValue = useMemo(() => {
    if (customMaxNumOfMessages === null) {
      return MESSAGES_SLIDER_VALUES.length - 1;
    }
    return MESSAGES_SLIDER_VALUES.indexOf(customMaxNumOfMessages);
  }, [customMaxNumOfMessages]);

  function handleSliderChange(values: number[]) {
    const [minIndex, maxIndex] = values;
    const minVal = MESSAGES_SLIDER_VALUES[minIndex];
    const maxVal = MESSAGES_SLIDER_VALUES[maxIndex];
    setCustomMinNumOfMessages(typeof minVal === "string" || minVal === 0 ? null : minVal);
    setCustomMaxNumOfMessages(typeof maxVal === "string" ? null : maxVal);
  }

  const ChatHistoryDialogContent = isScreenXl ? ChatHistoryDialogContentLarge : ChatHistoryDialogContentSmall;

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        onClose();
        setCurrentConversation(null);
      }}
    >
      <DialogContent variant={isScreenXl ? "xl" : "large"} onPointerDownOutside={e => e.preventDefault()}>
        {currentConversation && (
          <ChatHistoryConversationDialogContent
            currentConversation={currentConversation}
            onBackButtonPress={() => setCurrentConversation(null)}
            showSearchMatches={showSearchMatches}
            debouncedSearchValue={debouncedSearchValue}
            renderActionButtons={() => (
              <Button
                variant="tertiary"
                prefixIcon={<Icons.Download />}
                onClick={() => handleDownloadReport([currentConversation.conversation._id])}
              >
                Download
              </Button>
            )}
          />
        )}

        <div className={cn("h-full", currentConversation && "hidden")}>
          <ChatHistoryDialogContent
            renderHeader={
              <>
                {headerTitle}
                {renderChannelPicker?.()}
              </>
            }
            searchValue={searchValue}
            onSearchValueChange={setSearchValue}
            showSearchMatches={showSearchMatches}
            totalSearchMatches={totalSearchMatches}
            debouncedSearchValue={debouncedSearchValue}
            currentRange={currentRange}
            onChangeCurrentRange={setCurrentRange}
            startDate={startDate}
            onStartDateChange={setStartDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            selectedMessagesNumRange={selectedMessagesNumRange}
            onSelectedMessagesRangeChange={val => {
              setSelectedMessagesNumRange(val);
              if (val !== MessagesNumRanges.Custom) {
                setCustomMinNumOfMessages(null);
                setCustomMaxNumOfMessages(null);
              }
            }}
            isOnlyDownvotedMessages={isOnlyDownvotedMessages}
            onIsOnlyDownvotedMessagesChange={onIsOnlyDownvotedMessagesChange}
            isCustomMessagesRange={isCustomMessagesRange}
            renderDownloadSelect={() => (
              <ChatHistoryDialogDownloadSelectBtn
                onClick={handleLetMeSelectConversationsToDownload}
                disabled={isLoading}
              />
            )}
            renderFooter={() => (
              <div className="flex w-full flex-col gap-2">
                {isCustomMessagesRange && (
                  <ChatHistoryContentMessagesRangeSlider
                    className={isScreenXl ? "w-full" : "mt-2 pb-2"}
                    value={[sliderMinValue, sliderMaxValue]}
                    onSliderValueChange={handleSliderChange}
                  />
                )}
                <div className="flex w-full flex-row-reverse items-center justify-between gap-2">
                  <ChatHistoryDialogDownloadFooter
                    disabled={!selectedConversationsIds.length}
                    onDeselectAllClick={() => setSelectedConversationsIds([])}
                    onDownloadClick={() => handleDownloadReport(selectedConversationsIds)}
                    isLoadingDownload={isLoadingDownloadReport}
                  />
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                    maxElements={isMobile ? MAX_PAGINATION_ELEMENTS_MOBILE : MAX_PAGINATION_ELEMENTS_DESKTOP}
                  />
                </div>
              </div>
            )}
          >
            {isLoading ? (
              <ChatHistoryDialogLoader />
            ) : pageConversations.length === 0 ? (
              <ChatHistoryDialogEmptyMessage />
            ) : (
              pageConversations.map(item => (
                <div key={item.conversation._id} className="flex items-center gap-2">
                  {isSelectToDownloadOpen && (
                    <Checkbox
                      variant="circle"
                      checked={getIsConversationSelected(item.conversation._id)}
                      onCheckedChange={checked => handleClickConversationSelect(item.conversation._id, checked)}
                    />
                  )}
                  <ChatHistoryDialogListItem
                    conversationItem={item}
                    conversationUsers={item.conversation.users}
                    onOpen={() => setCurrentConversation(item)}
                    renderHoverButtons={() => (
                      <IconButton
                        variant="tertiary"
                        className="p-0"
                        icon={<Icons.Download className="size-5" />}
                        onClick={e => {
                          e.stopPropagation();
                          handleDownloadReport([item.conversation._id]);
                        }}
                      />
                    )}
                  />
                </div>
              ))
            )}
          </ChatHistoryDialogContent>
        </div>
      </DialogContent>
    </Dialog>
  );
};
