import type { MessageBookmarkGroup } from "@/types/bookmarks";
import { Accordion, AccordionContent, AccordionItem } from "@/components/ui/accordion";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import SingleBookmark from "./SingleBookmark";
import { Icons } from "@/components/ui/icons";

interface BookmarkPinnedListProps {
  bookmarkGroups: MessageBookmarkGroup[];
}

const BookmarkPinnedList = ({ bookmarkGroups }: BookmarkPinnedListProps) => {
  const messagesWithAgents = bookmarkGroups
    .map(group => group.messages.map(message => ({ ...message, agent: group.agent })))
    .flat();

  return (
    <div className="px-6">
      <Accordion key="pinned-bookmarks" type="single" collapsible>
        <AccordionItem
          value="pinned-bookmarks"
          className="mb-4 border-b border-b-neutral-400 data-[state=open]:border-none"
        >
          <AccordionPrimitive.Trigger className="flex w-full items-center gap-2 border-opacity-50 py-2 [&[data-state=open]>svg]:rotate-[-90deg]">
            <div className="flex w-full items-center gap-2">
              <Icons.PinOutline className="text-white" />
              <div className="truncate text-left text-sm font-semibold text-white">Pinned notes</div>
            </div>
            <Icons.ChevronRight className="scale-125 text-white" />
          </AccordionPrimitive.Trigger>
          <AccordionContent className="mt-4 flex flex-col gap-2 pb-0">
            {messagesWithAgents.map(bookmarkMessage => {
              return (
                <SingleBookmark
                  key={bookmarkMessage.message._id}
                  bookmarkMessage={bookmarkMessage}
                  agentName={bookmarkMessage.agent.name}
                  agentId={bookmarkMessage.agent._id}
                />
              );
            })}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default BookmarkPinnedList;
