import { createContext } from "react";

export type MessageVoiceContextType = {
  playMessage: (messageId: string) => Promise<void>;
  stopPlayback: () => void;
  pausePlayback: () => Promise<void>;
  resumePlayback: () => Promise<void>;
  isPlaying: boolean;
  isPaused: boolean;
  playingMessageId: string | null;
};

export const MessageVoiceContext = createContext<MessageVoiceContextType | undefined>(undefined);
