import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { useDeleteUserFromWorkspace } from "@/data/mutations/workspace/useDeleteUserFromWorkspace";
import type { WorkspaceUser } from "@/types/user";
import { useState } from "react";

type DeleteUserFromAllWorkspacesDialogProps = {
  user: WorkspaceUser;
};
export const DeleteUserFromAllWorkspacesDialog = ({ user }: DeleteUserFromAllWorkspacesDialogProps) => {
  const [isDialogOpen, setShowDialog] = useState(false);
  const { mutate: deleteUserFromWorkspace, isPending: isUserDeletionPending } = useDeleteUserFromWorkspace();
  const handleDeleteUserFromAllWorkspaces = () => {
    const workspaceCount = user.workspaces?.length;

    user.workspaces?.map((workspace, index) =>
      deleteUserFromWorkspace(
        { userId: user._id, workspaceId: workspace._id },
        {
          onSuccess: () => {
            if (index + 1 !== workspaceCount) {
              return;
            }

            setShowDialog(false);
          },
        }
      )
    );
  };
  return (
    <Dialog onOpenChange={setShowDialog} open={isDialogOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" className="w-full items-center justify-start gap-2 p-0">
          <Icons.Trash className="text-error" />
          <span className="text-error">Delete</span>
        </Button>
      </DialogTrigger>

      <DialogContent variant="medium">
        <DialogHeader></DialogHeader>
        <div className="mx-auto flex max-w-[446px] flex-col items-center gap-2.5 px-8 pb-5 text-center">
          <Icons.DialogTrash />
          <div className="text-xl font-bold">Are you sure you want to Delete this user from all workspaces?</div>
          <p className="text-sm text-neutral-400">
            Do you really want to delete this user from all workspaces Please note this action cannot be undone.
          </p>
        </div>
        <DialogFooter>
          <Button loading={isUserDeletionPending} onClick={handleDeleteUserFromAllWorkspaces} variant="danger">
            Delete
          </Button>
          <DialogClose asChild>
            <Button variant="tertiary">Cancel</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
