import { agentCreationId } from "@/constants/agentCreationId";
import { ROUTES } from "@/constants/routes";
import { agentsKeys } from "@/data/queries/useGetAgents";
import type { Agent } from "@/types/agent";
import { newDraftAgent } from "@/types/agent";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import type { Workspace } from "@/types/workspace";

export const useCreateNewAgent = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createNewAgentNavigation = (workspaceId?: Workspace["_id"]) => {
    queryClient.setQueryData<Agent[]>(agentsKeys.all, prev => prev?.filter(agent => agent._id !== agentCreationId)); // remove draft agent if exists
    queryClient.setQueryData<Agent[]>(agentsKeys.all, prev => (prev ? [newDraftAgent, ...prev] : [newDraftAgent])); // add draft agent
    navigate(ROUTES.createAgent(workspaceId || "none"));
  };

  return { createNewAgentNavigation };
};
