import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

export const AgentVoiceTypeButton = ({
  label,
  isActive,
  onClick,
  className,
}: {
  label: string;
  isActive: boolean;
  onClick: () => void;
  className?: string;
}) => (
  <Button
    onClick={onClick}
    className={cn(
      "text-md h-full w-1/3 rounded-none text-sm",
      isActive ? "border-b border-b-primary-500 bg-primary-100" : "bg-gray-100 hover:bg-gray-200",
      className
    )}
    variant="ghost"
  >
    {label}
  </Button>
);
