import { SideBarNav } from "../navigation/SideBarNav";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { cn } from "@/lib/utils";
import { TopBarNav } from "@/components/navigation/TopBarNav";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";
import { BillingWarningBanner } from "./components/BillingWarningBanner";

export const OPENED_NAV_WIDTH = 204;
export const CLOSED_NAV_WIDTH = 62;

const MainLayout = ({
  children,
  isGuestUser,
  isLoadingUser,
}: {
  children: React.ReactNode;
  isGuestUser: boolean;
  isLoadingUser?: boolean;
}) => {
  const { isMobile } = useMediaQueriesContext();
  const { navigationSectionOpen } = useLayoutContext();

  return (
    <div className="h-dvh w-dvw overflow-hidden">
      <BillingWarningBanner />
      {isMobile && <TopBarNav isGuestUser={isGuestUser} isLoadingUser={isLoadingUser} />}
      <SideBarNav isGuestUser={isGuestUser} />
      <div
        className={cn(
          `flex size-full overflow-hidden bg-gradient-to-b from-violet-50 to-stone-50 lg:pl-[204px]`,
          !navigationSectionOpen && `lg:pl-[62px]`,
          isMobile && `pt-[72px]`
        )}
      >
        <div
          className="ml-auto flex max-w-screen-md flex-1 border-l border-primary-100 bg-white lg:max-w-none lg:rounded-tr-none"
          style={{
            maxWidth: isMobile ? "100dvw" : `calc(100dvw - ${!navigationSectionOpen ? 62 : 204}px)`,
            maxHeight: isMobile ? `calc(100dvh - 72px)` : "100dvh",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
