import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { useGetMyWorkspaces } from "@/data/queries/workspace/useGetMyWorkspaces";
import type { WorkspaceMembersStepChildrenProps } from "@/components/Profiles/WorkspaceSection/WorkspaceMembers";
import { useGetMultipleWorkspaceUsers } from "@/data/queries/workspace/useGetMultipleWorkspaceUsers";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { WorkspaceUsers } from "@/components/Profiles/WorkspaceSection/WorkspaceUsers";
import { WorkspaceMembersHeader } from "@/components/Profiles/WorkspaceSection/WorkspaceMembersHeader";

export const WorkspaceMembersDashboard = ({ setActiveStep, isOwner }: WorkspaceMembersStepChildrenProps) => {
  const { navigationProfileOpen } = useNavigationProfileContext();

  const { data: myWorkspaces, isLoading: isLoadingWorkspace } = useGetMyWorkspaces();
  const { data: workspaceUsers, isLoading: isLoadingWorkspaceUsers } = useGetMultipleWorkspaceUsers({
    id: navigationProfileOpen.section!,
  });

  const myWorkspace = myWorkspaces?.find(workspace => workspace._id === navigationProfileOpen.section) || null;

  return (
    <div className="flex flex-col gap-6 pt-4">
      {(isLoadingWorkspaceUsers || isLoadingWorkspace) && (
        <div className="flex justify-center">
          <LoadingSpinner />
        </div>
      )}
      {myWorkspaces && isOwner && <WorkspaceMembersHeader setActiveStep={setActiveStep} myWorkspaces={myWorkspaces} />}
      <div className="flex flex-col gap-6">
        {myWorkspace && <WorkspaceUsers workspaceUsers={workspaceUsers} myWorkspace={myWorkspace} isOwner={isOwner} />}
      </div>
    </div>
  );
};
