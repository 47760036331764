import DocumentLimit from "@/assets/images/limits/DocumentLimit.svg";
import MessagesLimit from "@/assets/images/limits/MessagesLimit.svg";
import UrlLimit from "@/assets/images/limits/UrlLimit.svg";
import EnhancedCapabilityLimit from "@/assets/images/limits/EnhancedCapabilityLimit.svg";
import AgentCreateLimit from "@/assets/images/limits/AgentCreateLimit.svg";
import AdvancedToolsLimit from "@/assets/images/limits/AdvancedToolsLimit.svg";

import type { UsageExceededType } from "@/types/subscriptions";

type AccountUsageMessageProps = {
  limitType: UsageExceededType;
  limits?: {
    message: number;
    url: number;
    document: number;
    faqs: number;
  };
};

export const AccountUsageMessage = ({ limitType, limits }: AccountUsageMessageProps) => {
  const getImgSrc = () => {
    switch (limitType) {
      case "url":
        return UrlLimit;
      case "document":
        return DocumentLimit;
      case "faqs":
        return DocumentLimit;
      case "message":
        return MessagesLimit;
      case "enhancedCapability":
        return EnhancedCapabilityLimit;
      case "agentCreate":
        return AgentCreateLimit;
      case "advancedFeatures":
        return AdvancedToolsLimit;
      case "advancedTools":
        return AdvancedToolsLimit;
      case "llmModel":
        return AdvancedToolsLimit;
      default:
        return AdvancedToolsLimit;
    }
  };

  const getTitle = () => {
    switch (limitType) {
      case "agentCreate":
        return "Upgrade to create more agents";
      case "enhancedCapability":
        return "Upgrade to access Enhanced Capability";
      case "advancedFeatures":
        return "Upgrade to access advanced features";
      case "advancedTools":
        return "Upgrade to access Advanced Tools";
      case "llmModel":
        return "Upgrade to access all Models";
      case "stats":
        return "Upgrade to access statistics";
      case "googleDrive":
        return "Upgrade to access Google Drive";
    }

    if (!limits) {
      return "";
    }

    switch (limitType) {
      case "url":
        return `Upgrade to access ${limits.url}+ links usage limit`;
      case "document":
        return `Upgrade to access ${limits.document}+ documents usage limit`;
      case "faqs":
        return `Upgrade to access ${limits.faqs}+ FAQs usage limit`;
      case "message":
        return `Upgrade to access ${limits.message}+ messages usage limit`;
    }
  };

  const getSubtitle = () => {
    switch (limitType) {
      case "url":
        return "You have exceeded the links usage limit";
      case "document":
        return "You have exceeded the documents usage limit";
      case "message":
        return "You have exceeded the messages usage limit";
      case "agentCreate":
        return "You have exceeded the deployment usage limit";
    }
  };

  return (
    <>
      <img src={getImgSrc()} alt="Url limit" className="w-28" />
      <h3 className="text-xl font-bold">{getTitle()}</h3>
      <p className="text-center text-sm text-neutral-400">{getSubtitle()}</p>
    </>
  );
};
