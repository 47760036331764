import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { singlePrivateAgentWithConfigKeys } from "@/data/queries/useGetSinglePrivateAgentWithConfig";
import { useQueries } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { Chat } from "@/components/Chat/Chat";
import { cn } from "@/lib/utils";
import BookmarksHistoryPanel from "@/components/BookmarksHistoryPanel/BookmarksHistoryPanel";
import { getSingleConversationAgentWithConfig } from "@/data/queries/useGetSingleConversationAgentWithConfig";
import { useTrackAgentInteractionStats } from "@/hooks/useTrackAgentInteractionStats";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { getSingleConversationAgentGuest } from "@/data/queries/useGetSingleConversationAgentGuest";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";
import AnimatedBookmarksHistoryWrapper from "@/components/BookmarksHistoryPanel/components/AnimatedBookmarksHistoryWrapper";
import { useGetUser } from "@/data/queries/useGetUser";
import { useGetConversationMessages } from "@/data/queries/useGetConversationMessages";
import { ChatMessageReasoning } from "@/components/Chat/components/ChatMessageReasoning/ChatMessageReasoning";

const NewConversation = () => {
  const { isMobile } = useMediaQueriesContext();
  const { conversationId, conversationAgentIds: agentIds, messageForReasoningPreview } = useChatContext();
  const { isGuestUser } = useIsGuestUser();
  const { isLoading: isLoadingUser } = useGetUser();

  const [isMobileSidePanelOpen, setIsMobileSidePanelOpen] = useState(false);

  const agentResults = useQueries({
    queries:
      agentIds.map(agentId => ({
        queryKey: singlePrivateAgentWithConfigKeys.id(agentId),
        queryFn: isGuestUser
          ? () => getSingleConversationAgentGuest(agentId)
          : () => getSingleConversationAgentWithConfig(agentId),
        enabled: agentIds.length > 0 && !isLoadingUser,
      })) ?? [],
  });

  const agents = useMemo(() => agentResults.map(r => r.data).filter(Boolean), [agentResults]);

  const { data: conversationData } = useGetConversationMessages({
    conversationId,
  });

  const reasoningTasks = conversationData?.messages.find(m => m._id === messageForReasoningPreview)?.tasks;

  const isLoading = agentResults.some(r => r.isPending);
  const isError = agentResults.some(r => !!r.error);

  useTrackAgentInteractionStats({ agentIds, enabled: !isGuestUser });

  if (isMobile) {
    return (
      <>
        <Chat
          agents={agents}
          isLoading={isLoading}
          isError={isError}
          onOpenMobileSidePanel={() => setIsMobileSidePanelOpen(true)}
        />
        <div
          className={cn("fixed right-0 top-[72px] z-40 h-full w-full", {
            "-right-full": !isMobileSidePanelOpen,
          })}
        >
          {reasoningTasks?.length ? (
            <ChatMessageReasoning tasks={reasoningTasks} onClosePanel={() => setIsMobileSidePanelOpen(false)} />
          ) : (
            <BookmarksHistoryPanel onClosePanel={() => setIsMobileSidePanelOpen(false)} />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <Chat agents={agents} isLoading={isLoading} isError={isError} />
      <AnimatedBookmarksHistoryWrapper>
        {reasoningTasks?.length ? <ChatMessageReasoning tasks={reasoningTasks} /> : <BookmarksHistoryPanel />}
      </AnimatedBookmarksHistoryWrapper>
    </>
  );
};

export default NewConversation;
