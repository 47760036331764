import { Icons } from "@/components/ui/icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { useMessageVoice } from "@/contexts/MessageVoiceContext/useMessageVoice";
import type { Message } from "@/types/conversation";
import { IconButton } from "@/components/ui/icon-button";
import { TooltipPortal } from "@radix-ui/react-tooltip";

type PlayMessageVoiceProps = {
  messageId: Message["_id"];
};

export const PlayMessageVoice: React.FC<PlayMessageVoiceProps> = ({ messageId }) => {
  const { playMessage, pausePlayback, resumePlayback, stopPlayback, playingMessageId, isPlaying, isPaused } =
    useMessageVoice();

  const isCurrentMessage = playingMessageId === messageId;

  const handleClick = () => {
    if (!isCurrentMessage) {
      void playMessage(messageId);
      return;
    }

    if (isPlaying) {
      void pausePlayback();
    } else if (isPaused) {
      void resumePlayback();
    } else {
      stopPlayback();
    }
  };

  const getTooltipLabel = () => {
    if (!isCurrentMessage) {
      return "Play message";
    }
    if (isPlaying) {
      return "Pause";
    }
    if (isPaused) {
      return "Resume";
    }
    return "Play message";
  };

  const isPlayingMessage = playingMessageId === messageId;
  const isCurrentPlaying = isPlayingMessage && !isPaused;
  const isCurrentPaused = isPlayingMessage && isPaused;

  return (
    <Tooltip>
      <TooltipTrigger asChild onClick={handleClick}>
        <IconButton
          variant="tertiary"
          className="group/button"
          icon={
            <Icons.PlaySound
              className={cn(
                "h-4 w-4 text-neutral-400 group-hover/button:text-neutral-600",
                isCurrentPlaying
                  ? "text-black group-hover/button:text-neutral-600"
                  : isCurrentPaused
                    ? "text-primary-400"
                    : "text-neutral-400"
              )}
            />
          }
        />
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{getTooltipLabel()}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};
