import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useAgentFormContext } from "../../../../hooks/useAgentFormContext";
import { Controller, useWatch } from "react-hook-form";
import { Icons } from "@/components/ui/icons";
import { VoiceProfileGender } from "@/types/voice";
import { AgentVoiceLanguageOptions } from "./components/AgentVoiceLanguageSelect";
import { AgentVoiceCustomOptions } from "./components/AgentVoiceCustomOptions";
import { useQueryClient } from "@tanstack/react-query";
import { getCustomVoiceListQueryKey } from "@/data/queries/voice/useGetCustomVoiceList";
import { getCustomVoiceListForAgent } from "@/data/customVoice";
import { AgentVoiceTypeButton } from "./components/AgentVoiceTypeButton";

export const AgentVoice = () => {
  const form = useAgentFormContext();
  const {
    control,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = form;
  const queryClient = useQueryClient();
  const [agentId] = getValues(["_id", "publishedToCommunity"]);

  const voiceFormValues = useWatch({
    control,
    name: "voiceProfile",
  });
  const isVoiceProfileDirty = Object.keys(dirtyFields.voiceProfile || {}).length > 0;

  const selectedGender = voiceFormValues?.gender;
  const isCustomSelected = voiceFormValues?.activeCustomVoice !== null;
  const showLanguageOptions =
    !isCustomSelected && (selectedGender === VoiceProfileGender.FEMALE || selectedGender === VoiceProfileGender.MALE);
  const showCustomOptions = isCustomSelected;

  const [isOpen, setIsOpen] = useState(false);
  const [savedCustomVoiceId, setSavedCustomVoiceId] = useState<string | null>(null);

  useEffect(() => {
    if (agentId) {
      void queryClient.prefetchQuery({
        queryKey: getCustomVoiceListQueryKey(agentId),
        queryFn: () => getCustomVoiceListForAgent(agentId),
      });
    }
  }, [agentId, queryClient]);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOverlayClick = () => setIsOpen(false);

  return (
    <div className="relative">
      <Button
        onClick={toggleDropdown}
        className="flex w-40 items-center justify-between border border-gray-300 bg-white px-3 text-black hover:bg-gray-100"
      >
        <Icons.AudioLines className="mr-1 flex-shrink-0" />
        <span className="ml-1">Select a voice</span>
        {isOpen ? (
          <ChevronUp size={16} className="ml-1 mt-0.5 flex-shrink-0" />
        ) : (
          <ChevronDown size={16} className="ml-1 mt-0.5 flex-shrink-0" />
        )}
      </Button>
      {isOpen && (
        <>
          <div className="fixed inset-0 z-10 bg-transparent" onClick={handleOverlayClick} />
          <div
            className="dropdown-container absolute left-0 top-full z-50 flex flex-col items-center justify-center gap-4 rounded-md border border-gray-300 bg-white p-4 shadow-lg"
            style={{ boxShadow: "2px 2px 10px 0px rgba(51, 51, 51, 0.10)", width: "440px" }}
          >
            <div className="w-full text-left text-lg font-bold text-black">Voice Profile</div>
            <div className="flex h-[30px] w-full">
              <Controller
                control={control}
                name="voiceProfile.gender"
                render={({ field: { value, onChange } }) => (
                  <>
                    <AgentVoiceTypeButton
                      label="Male"
                      isActive={!isCustomSelected && value === VoiceProfileGender.MALE}
                      onClick={() => {
                        onChange(VoiceProfileGender.MALE);
                        setSavedCustomVoiceId(voiceFormValues?.activeCustomVoice || savedCustomVoiceId || null);
                        setValue("voiceProfile.activeCustomVoice", null, { shouldDirty: true });
                      }}
                    />
                    <AgentVoiceTypeButton
                      label="Female"
                      isActive={!isCustomSelected && value === VoiceProfileGender.FEMALE}
                      onClick={() => {
                        onChange(VoiceProfileGender.FEMALE);
                        setSavedCustomVoiceId(voiceFormValues?.activeCustomVoice || savedCustomVoiceId || null);
                        setValue("voiceProfile.activeCustomVoice", null, { shouldDirty: true });
                      }}
                      className="border-x-[0.5px] border-x-gray-300"
                    />
                  </>
                )}
              />
              <AgentVoiceTypeButton
                label="Custom"
                isActive={isCustomSelected}
                onClick={() =>
                  setValue("voiceProfile.activeCustomVoice", savedCustomVoiceId || "", {
                    shouldDirty: !!savedCustomVoiceId,
                  })
                }
              />
            </div>
            {showLanguageOptions && <AgentVoiceLanguageOptions />}
            {showCustomOptions && <AgentVoiceCustomOptions />}
            {isVoiceProfileDirty && (
              <div className="my-1 text-center text-sm font-medium text-primary-600">
                Agent voice profile modified; press the "Save changes" button on the page to submit.
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
