import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { Workspace } from "@/types/workspace";
import { multipleWorkspaceUsersKeys } from "@/data/queries/workspace/useGetMultipleWorkspaceUsers";

type Payload = {
  emails: string[];
  id: Workspace["_id"];
};

type Response = {
  message: string;
};
const inviteUsersToMultipleWorkspace = async (payload: Payload) => {
  const { data } = await restClient.post<Response>(apiPaths.inviteUsersToMultipleWorkspaces(payload.id), {
    emails: payload.emails,
  });
  return data;
};

export const useInviteUserToMultipleWorkspace = (id: Payload["id"]) => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<{ message: string }>, Payload>({
    mutationFn: inviteUsersToMultipleWorkspace,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: multipleWorkspaceUsersKeys.id(id) });
    },
  });
};
