import { Icons } from "@/components/ui/icons";
import { useCreateNewAgent } from "@/hooks/useCreateNewAgent";
import type { Workspace } from "@/types/workspace";

type WorkspaceCreateAgentTileProps = {
  workspaceId?: Workspace["_id"];
};

export const WorkspaceCreateAgentTile = ({ workspaceId }: WorkspaceCreateAgentTileProps) => {
  const { createNewAgentNavigation } = useCreateNewAgent();

  return (
    <div
      onClick={() => createNewAgentNavigation(workspaceId)}
      className="group flex min-h-[9.5rem] min-w-[16.8125rem] cursor-pointer flex-col items-center justify-center gap-2 rounded-lg border border-neutral-100 bg-light-grey p-4 shadow-[0px_3px_4px_-2px_rgba(0,0,0,0.15)]"
    >
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="flex h-[38px] w-[38px] items-center justify-center rounded-md border border-neutral-400 text-lg">
          <Icons.PlusThin className="h-6 w-6" />
        </div>
        <p className="font-bold">Create an Agent</p>
      </div>
    </div>
  );
};
