import { NavLink } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { Icons } from "@/components/ui/icons";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";
import { motion } from "framer-motion";

type SideBarNavHeaderProps = {
  isGuestUser: boolean;
};

export const SideBarNavHeader = ({ isGuestUser }: SideBarNavHeaderProps) => {
  const { navigationSectionOpen, toggleNavigationSectionOpen } = useLayoutContext();

  return (
    <div className="flex flex-row items-center justify-between py-1">
      {navigationSectionOpen && (
        <NavLink to={isGuestUser ? ROUTES.community : ROUTES.workspace} className="w-fit">
          <Icons.AgentLogo className="max-h-8 w-[100px] cursor-pointer text-primary-black lg:max-h-[38px]" />
        </NavLink>
      )}

      <motion.div whileTap={{ y: 1, rotate: 180 }}>
        <Button
          onClick={toggleNavigationSectionOpen}
          variant="ghost"
          className="size-[38px] bg-white p-0 text-center text-primary-400 hover:bg-primary-100"
        >
          <Icons.ChevronRight className={cn(navigationSectionOpen && "rotate-180")} />
        </Button>
      </motion.div>
    </div>
  );
};
