import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { AgentOverviewStats, StatsQueryFilters } from "@/types/stats";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";
import type { Workspace } from "@/types/workspace";

export const agentsOverviewStats = {
  all: ["agentsOverviewStats"] as const,
  params: ({
    startDateParam,
    endDateParam,
    workspaceId,
  }: {
    startDateParam: string;
    endDateParam: string;
    workspaceId?: Workspace["_id"];
  }) => [...agentsOverviewStats.all, startDateParam, endDateParam, workspaceId] as const,
};

const getAgentsOverviewStats = async ({
  startDate,
  endDate,
  workspaceId,
}: {
  startDate: string;
  endDate: string;
  workspaceId?: Workspace["_id"];
}) => {
  const { data } = await restClient.get<AgentOverviewStats[]>(
    workspaceId ? apiPaths.getWorkspaceAgentsOverviewStats(workspaceId) : apiPaths.getAgentsOverviewStats,
    {
      params: {
        startDate,
        endDate,
        timezone: StatisticsHelpers.getTimezone(),
      },
    }
  );
  return data;
};

type Props = Omit<StatsQueryFilters, "channels">;

export const useGetAgentsOverviewStats = (props: Props) => {
  const { currentRange, startDate, endDate, enabled = true, workspaceId } = props;

  const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
  const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

  const query = useQuery({
    queryKey: agentsOverviewStats.params({ startDateParam, endDateParam, workspaceId }),
    queryFn: () => getAgentsOverviewStats({ startDate: startDateParam, endDate: endDateParam, workspaceId }),
    enabled: !!startDateParam && !!endDateParam && enabled,
    placeholderData: keepPreviousData,
  });

  return query;
};
