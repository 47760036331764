import { WorkspaceAccessRoles } from "@/components/Profiles/WorkspaceSection/types";

export const decodeUserRole = (role: WorkspaceAccessRoles) => {
  switch (role) {
    case WorkspaceAccessRoles.EDITOR:
      return "Editor";
    case WorkspaceAccessRoles.OWNER:
      return "Admin";
    case WorkspaceAccessRoles.USER:
      return "Member";
    case WorkspaceAccessRoles.VIEWER:
      return "Viewer";
    default:
      return "Member";
  }
};
