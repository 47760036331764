import { Table, TableBody, TableCell, TableHeader, TableRow } from "@/components/ui/table";
import { useGetAgentsOverviewStats } from "@/data/queries/stats/useGetAgentsOverviewStats";
import StatsAgentsOverviewSingleAgentRow from "./StatsAgentsOverviewSingleAgentRow";
import { useCallback, useEffect, useRef, useState } from "react";
import { cn } from "@/lib/utils";
import { Icons } from "@/components/ui/icons";
import { CHANNELS_LEGEND_DATA } from "../../constants/statsConstants";
import AgentOverviewSkeleton from "../loaders/AgentOverviewSkeleton";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import { IconButton } from "@/components/ui/icon-button";
import { statsMockAgentsOverviewData } from "../../constants/statsMockData";
import { StatsUpgradeMessage } from "../common/StatsUpgradeMessage";
import { StatsBlur } from "../common/StatsBlur";
import { StatsSidePanel } from "../StatsSidePanel/StatsSidePanel";
import type { AgentOverviewStats } from "@/types/stats";

const headerCells = [
  {
    label: "Messages",
  },
  {
    label: "Conversations",
  },
  {
    label: "Satisfaction",
  },
  {
    label: "Channels",
  },
  {
    label: "Status",
  },
  {
    label: "Chart",
  },
];

const StatsAgentsOverview = () => {
  const { currentRange, startDate, endDate, isUpgradeNeeded, activeWorkspaceId, isDefaultWorkspace } =
    useStatsContext();

  const { data, isFetching } = useGetAgentsOverviewStats({
    currentRange,
    startDate,
    endDate,
    enabled: !isUpgradeNeeded,
    workspaceId: isDefaultWorkspace ? undefined : activeWorkspaceId,
  });
  const dataToShow = isUpgradeNeeded ? statsMockAgentsOverviewData : data;

  const [selectedSidePanelAgent, setSelectedSidePanelAgent] = useState<AgentOverviewStats | null>(null);
  const isStatsSidePanelOpen = !!selectedSidePanelAgent;

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeaderRef = useRef<HTMLTableSectionElement>(null);

  const [visibleContentColumnsIndexes, setVisibleContentColumnsIndexes] = useState<number[]>([0, 1]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    const updateVisibleColumns = () => {
      if (tableContainerRef.current) {
        const minWidth = 120;

        const containerWidth = tableContainerRef.current.offsetWidth;
        const allowedColumns = Math.floor(containerWidth / minWidth) - 2;

        setVisibleContentColumnsIndexes(
          allowedColumns === 0 ? [0] : Array.from({ length: allowedColumns }, (_, i) => i)
        );
      }
    };

    updateVisibleColumns();

    window.addEventListener("resize", updateVisibleColumns);
    return () => window.removeEventListener("resize", updateVisibleColumns);
  }, [isFetching]);

  const getIsColumnVisible = (index: number) => visibleContentColumnsIndexes.includes(index);

  const handleChangeVisibleColumns = (direction: "prev" | "next") => {
    if (direction === "prev") {
      if (visibleContentColumnsIndexes.includes(0)) {
        return;
      }

      setVisibleContentColumnsIndexes(prev => {
        const newIndexes = prev.map(index => index - 1);

        if (newIndexes[0] < 0) {
          return prev;
        }

        return newIndexes;
      });
    }

    if (direction === "next") {
      const columnsCount = headerCells.length;

      if (visibleContentColumnsIndexes.includes(columnsCount - 1)) {
        return;
      }

      setVisibleContentColumnsIndexes(prev => {
        const newIndexes = prev.map(index => index + 1);

        if (newIndexes[newIndexes.length - 1] >= columnsCount) {
          return prev;
        }

        return newIndexes;
      });
    }
  };

  const handleScrollToRowDetails = useCallback(({ scrollToElementId }: { scrollToElementId: string }) => {
    const headerHeight = tableHeaderRef.current?.offsetHeight;
    const elementTopOffset = document.getElementById(scrollToElementId)?.offsetTop;
    const totalTopDistance = (elementTopOffset ?? 0) - (headerHeight ?? 0);

    tableContainerRef.current?.scrollTo({
      top: totalTopDistance,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="items-center gap-2.5 rounded-md border border-border-light bg-white px-5 py-6 shadow-md @container/main">
        {isFetching ? (
          <AgentOverviewSkeleton />
        ) : (
          <>
            <div className="flex flex-wrap gap-x-7 gap-y-2">
              <h2 className="text-2xl font-bold">Agent Overview</h2>

              <div className="flex flex-wrap items-center gap-x-7 gap-y-2">
                {CHANNELS_LEGEND_DATA.map(({ color, label }) => (
                  <div className="flex items-center gap-2" key={label}>
                    <div className="h-2 w-2 min-w-2 rounded-full" style={{ backgroundColor: color }} />
                    <p className="text-sm">{label}</p>
                  </div>
                ))}
              </div>
            </div>

            <div
              ref={tableContainerRef}
              className="relative mt-4 max-h-[410px] w-full overflow-scroll scrollbar-custom-mt"
            >
              {isUpgradeNeeded && (
                <StatsBlur>
                  <StatsUpgradeMessage suffixText="to see agents overview" />
                </StatsBlur>
              )}
              <Table>
                <TableHeader ref={tableHeaderRef} className="sticky top-0 z-10 bg-white">
                  <TableRow className="border-b border-b-border-light">
                    <TableCell className="whitespace-nowrap text-xs font-medium text-neutral-500">Agent Name</TableCell>
                    {headerCells.map((cell, index) => (
                      <TableCell
                        key={index}
                        className={cn(
                          "relative hidden whitespace-nowrap text-center text-xs font-medium text-neutral-500 @3xl/main:table-cell @3xl/main:text-left",
                          {
                            "table-cell": getIsColumnVisible(index),
                          }
                        )}
                      >
                        {cell.label}
                      </TableCell>
                    ))}

                    <TableCell className="hidden whitespace-nowrap text-xs font-medium text-neutral-500 @3xl/main:table-cell">
                      Action
                    </TableCell>

                    <TableCell className="flex items-center gap-3 @3xl/main:hidden">
                      <IconButton
                        variant="primary"
                        variantColor="grey"
                        size="small"
                        roundedType="rounded"
                        onClick={() => handleChangeVisibleColumns("prev")}
                        icon={<Icons.ChevronRight className="size-4 rotate-180 text-black" />}
                      >
                        <span className="sr-only">Previous column</span>
                      </IconButton>
                      <IconButton
                        variant="primary"
                        variantColor="grey"
                        size="small"
                        roundedType="rounded"
                        onClick={() => handleChangeVisibleColumns("next")}
                        icon={<Icons.ChevronRight className="size-4 text-black" />}
                      >
                        <span className="sr-only">Next column</span>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {dataToShow?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={8} className="py-3 text-center text-base font-medium text-neutral-400">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                  {dataToShow?.map(item => (
                    <StatsAgentsOverviewSingleAgentRow
                      key={item.agent._id}
                      agent={item.agent}
                      messages={item.messages}
                      conversations={item.conversations}
                      satisfaction={item.satisfaction}
                      channels={item.channels}
                      currentColumnIndexes={visibleContentColumnsIndexes}
                      onRowDetailsOpen={handleScrollToRowDetails}
                      onClickChart={() => setSelectedSidePanelAgent(item)}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </div>

      <StatsSidePanel
        isOpen={isStatsSidePanelOpen}
        setIsOpen={() => setSelectedSidePanelAgent(null)}
        agentData={
          selectedSidePanelAgent && {
            selectedAgent: selectedSidePanelAgent.agent,
            onChangeSelectedAgent: newAgent =>
              setSelectedSidePanelAgent(dataToShow?.find(item => item.agent._id === newAgent._id) ?? null),
            messages: selectedSidePanelAgent.messages,
            messagesTrend: selectedSidePanelAgent.messagesTrend,
            conversations: selectedSidePanelAgent.conversations,
            conversationsTrend: selectedSidePanelAgent.conversationsTrend,
            satisfaction: selectedSidePanelAgent.satisfaction,
            satisfactionTrend: selectedSidePanelAgent.satisfactionTrend,
            allAgents: dataToShow?.map(item => item.agent) ?? [],
            channels: selectedSidePanelAgent.channels,
          }
        }
      />
    </>
  );
};

export default StatsAgentsOverview;
