import type { User } from "@/types/user";
import { cn } from "@/lib/utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { DeleteTeamMemberDialog } from "@/components/dialogs/TeamDialogs/DeleteTeamMemberDialog";
import type { Workspace } from "@/types/workspace";
import type { WorkspaceUserWrapped } from "@/components/Profiles/WorkspaceSection/types";
import { WorkspaceAccessRoles } from "@/components/Profiles/WorkspaceSection/types";
import { useMemo } from "react";
import { useChangeMultipleWorkspaceUserRole } from "@/data/mutations/workspace/useChangeMultipleWorspaceUserRole";
import { useGetUser } from "@/data/queries/useGetUser";
import { Avatar } from "@/components/Avatar";
import { decodeUserRole } from "@/components/Profiles/WorkspaceSection/utils";
import { UserStatus } from "@/types/user";

type WorkspaceUsersProps = {
  workspaceUsers: Workspace | undefined;
  myWorkspace: Workspace;
  isOwner: boolean;
};

export const WorkspaceUsers = ({ workspaceUsers, myWorkspace, isOwner }: WorkspaceUsersProps) => {
  const { user } = useGetUser();
  const { mutate: changeUserRole } = useChangeMultipleWorkspaceUserRole(myWorkspace._id);

  const users: WorkspaceUserWrapped[] = useMemo(
    () => [
      { ...workspaceUsers?.owner, role: WorkspaceAccessRoles.OWNER } as WorkspaceUserWrapped,
      ...((workspaceUsers?.users.map(user => ({ ...user, role: WorkspaceAccessRoles.USER })) ||
        []) as WorkspaceUserWrapped[]),
      ...((workspaceUsers?.editors.map(editor => ({ ...editor, role: WorkspaceAccessRoles.EDITOR })) ||
        []) as WorkspaceUserWrapped[]),
      ...((workspaceUsers?.viewers.map(viewer => ({ ...viewer, role: WorkspaceAccessRoles.VIEWER })) ||
        []) as WorkspaceUserWrapped[]),
    ],
    [workspaceUsers]
  );

  const toggleUserRole = (role: WorkspaceAccessRoles, userId: User["_id"]) => {
    if (!myWorkspace) {
      return;
    }

    changeUserRole({ userId, role, id: myWorkspace._id });
  };

  return users.map(workspaceUser => {
    const memberInvitationPending = workspaceUser.status === UserStatus.INVITE_PENDING;

    return (
      <div key={workspaceUser._id} className="flex w-full flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <Avatar
            src={workspaceUser?.avatar}
            name={workspaceUser?.name}
            className="size-8 rounded-full"
            size="custom"
          />
          <div className="flex flex-col items-start justify-start gap-2">
            <div className="flex flex-row gap-1">
              <span className="flex flex-row items-center gap-1 text-sm font-medium text-neutral-750">
                {workspaceUser?.name || "Name is not defined"}
              </span>
              {memberInvitationPending && (
                <span className="rounded-md bg-primary-50 px-1.5 pb-0.5 pt-1 text-xs text-primary-400">
                  Invitation sent
                </span>
              )}

              {workspaceUser._id === user?._id && <span className="text-sm font-medium text-neutral-400">(You)</span>}
            </div>

            <span className="text-xs font-medium text-neutral-400">{workspaceUser.email}</span>
          </div>
        </div>

        <div className="flex flex-row items-center gap-2">
          <div className="flex flex-row items-center gap-1">
            <span className={cn("text-right text-xs text-primary-400")}>{decodeUserRole(workspaceUser.role)}</span>
            {!memberInvitationPending && isOwner && workspaceUser.role !== WorkspaceAccessRoles.OWNER && (
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
                  <IconButton
                    variant="tertiary"
                    roundedType="rounded"
                    size="tiny"
                    className="aspect-square text-neutral-750"
                    icon={<Icons.ChevronRight className="rotate-90 text-neutral-750" />}
                  />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                  {workspaceUser.role !== WorkspaceAccessRoles.EDITOR && (
                    <DropdownMenuItem onClick={() => toggleUserRole(WorkspaceAccessRoles.EDITOR, workspaceUser._id)}>
                      Editor
                    </DropdownMenuItem>
                  )}
                  {workspaceUser.role !== WorkspaceAccessRoles.USER && (
                    <DropdownMenuItem onClick={() => toggleUserRole(WorkspaceAccessRoles.USER, workspaceUser._id)}>
                      Member
                    </DropdownMenuItem>
                  )}
                  {workspaceUser.role !== WorkspaceAccessRoles.VIEWER && (
                    <DropdownMenuItem onClick={() => toggleUserRole(WorkspaceAccessRoles.VIEWER, workspaceUser._id)}>
                      Viewer
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
          {isOwner && workspaceUser.role !== WorkspaceAccessRoles.OWNER && (
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
                <IconButton
                  variant="tertiary"
                  roundedType="rounded"
                  size="tiny"
                  className="aspect-square"
                  icon={<Icons.Dots2 className="rotate-90 text-black" />}
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                <DropdownMenuItem
                  className="flex items-center gap-1.5 text-xs focus:text-error"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {myWorkspace && <DeleteTeamMemberDialog userId={workspaceUser._id} workspaceId={myWorkspace._id} />}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
    );
  });
};
