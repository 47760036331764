import { Checkbox } from "@/components/ui/checkbox";
import {
  DraggableTable,
  DraggableTableBody,
  DraggableTableHeader,
  DraggableTableHeaderCell,
  DraggableTableRow,
} from "@/components/ui/draggable-table";
import { StatsLeadsTableHeaderCell } from "./component/StatsLeadsTableHeaderCell";
import { getGridTemplateColumns, getKeyDisplayName, getLeadFieldValue } from "../../utils/statsLeadsUtils";
import { Avatar } from "@/components/Avatar";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import type { ILeadGen, LeadStatsHeader, SortByType } from "@/types/stats";
import { arrayMove } from "@dnd-kit/sortable";
import { StatsLeadsSortByDropdown } from "./component/StatsLeadsSortByDropdown";
import { cn } from "@/lib/utils";
import { StatsBlur } from "@/pages/Stats/components/common/StatsBlur";
import { StatsUpgradeMessage } from "@/pages/Stats/components/common/StatsUpgradeMessage";
import { StaticTableHeaders } from "../../constants/StatsLeadsConstants";
import { useState } from "react";
import { StatsLeadsDetailsSidePanel } from "@/pages/StatsLeads/components/StatsLeadContent/component/StatsLeadsDetailsSidePanel/StatsLeadsDetailsSidePanel";

type Props = {
  headers: LeadStatsHeader[];
  leadsData: ILeadGen[];
  onChangeLeadsData?: (newLeadsData: ILeadGen[]) => void;
  sortBy: SortByType;
  onChangeSortBy?: (sortBy: SortByType) => void;
  showUpgradeBlur?: boolean;
  isSelectingEnabled?: boolean;
  selectedRows?: Set<string>;
  onClickSelectRow?: (rowId: string) => void;
  onClickSelectAllRows?: () => void;
};

export const StatsLeadsContent = ({
  headers,
  leadsData,
  onChangeLeadsData,
  sortBy,
  onChangeSortBy,
  showUpgradeBlur,
  isSelectingEnabled,
  selectedRows,
  onClickSelectRow,
  onClickSelectAllRows,
}: Props) => {
  const [selectedSidePanelLead, setSelectedSidePanelLead] = useState<ILeadGen | null>(null);
  const isSidePanelOpen = !!selectedSidePanelLead;

  // dynamic columns + static columns + 1 for checkbox
  const columnsCount = headers.length + Object.keys(StaticTableHeaders).length + 1;

  const handleDragEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const updatedLeadsData = arrayMove(leadsData, oldIndex, newIndex);
    onChangeLeadsData?.(updatedLeadsData);
  };

  return (
    <>
      <div className="scrollbar-default mt-2 w-full overflow-x-scroll pl-6 pr-1">
        <DraggableTable className="relative" onDragEndAction={handleDragEnd} itemsIds={leadsData.map(lead => lead._id)}>
          <DraggableTableHeader className="sticky top-0 z-10 bg-white">
            <div className="absolute -left-5 top-0 h-full w-5 bg-white"></div>
            <tr
              className="border-b border-t"
              style={{
                display: "grid",
                gridTemplateColumns: getGridTemplateColumns(columnsCount),
              }}
            >
              <DraggableTableHeaderCell
                className={cn("border-r py-0", {
                  "w-0 p-0": !isSelectingEnabled,
                })}
              >
                <Checkbox
                  size="sm"
                  value="all"
                  checked={selectedRows?.size === leadsData.length && leadsData.length > 0}
                  onClick={onClickSelectAllRows}
                  className={cn("border-primary-100 group-hover/row:border-primary-400", {
                    "invisible w-0": !isSelectingEnabled,
                  })}
                />
              </DraggableTableHeaderCell>

              <StatsLeadsTableHeaderCell iconKey="BotMessage">
                Agent
                <StatsLeadsSortByDropdown
                  ascendindDescription="A-Z"
                  descendingDescription="Z-A"
                  onClickAscending={() => {
                    onChangeSortBy?.({ key: StaticTableHeaders.AGENT, order: "ASC" });
                  }}
                  onClickDescending={() => {
                    onChangeSortBy?.({ key: StaticTableHeaders.AGENT, order: "DESC" });
                  }}
                  isSelectedAscending={sortBy?.key === StaticTableHeaders.AGENT && sortBy?.order === "ASC"}
                  isSelectedDescending={sortBy?.key === StaticTableHeaders.AGENT && sortBy?.order === "DESC"}
                />
              </StatsLeadsTableHeaderCell>
              <StatsLeadsTableHeaderCell iconKey="Calendar">
                Date created
                <StatsLeadsSortByDropdown
                  ascendindDescription="Latest to Oldest"
                  descendingDescription="Oldest to Latest"
                  onClickAscending={() => {
                    onChangeSortBy?.({ key: StaticTableHeaders.DATE, order: "ASC" });
                  }}
                  onClickDescending={() => {
                    onChangeSortBy?.({ key: StaticTableHeaders.DATE, order: "DESC" });
                  }}
                  isSelectedAscending={sortBy?.key === StaticTableHeaders.DATE && sortBy?.order === "ASC"}
                  isSelectedDescending={sortBy?.key === StaticTableHeaders.DATE && sortBy?.order === "DESC"}
                />
              </StatsLeadsTableHeaderCell>

              {headers.map(header => {
                const { key, iconKey } = header;
                return (
                  <StatsLeadsTableHeaderCell key={key} iconKey={iconKey}>
                    {getKeyDisplayName(key)}
                    <StatsLeadsSortByDropdown
                      ascendindDescription="A-Z"
                      descendingDescription="Z-A"
                      onClickAscending={() => {
                        onChangeSortBy?.({ key, order: "ASC" });
                      }}
                      onClickDescending={() => {
                        onChangeSortBy?.({ key, order: "DESC" });
                      }}
                      isSelectedAscending={sortBy?.key === key && sortBy?.order === "ASC"}
                      isSelectedDescending={sortBy?.key === key && sortBy?.order === "DESC"}
                    />
                  </StatsLeadsTableHeaderCell>
                );
              })}
            </tr>
          </DraggableTableHeader>

          <DraggableTableBody className="relative">
            {showUpgradeBlur && (
              <StatsBlur className="rounded-md">
                <div className="flex h-full flex-col items-center justify-center gap-4">
                  <StatsUpgradeMessage suffixText="to see leads statistics" />
                </div>
              </StatsBlur>
            )}
            {leadsData.map(lead => {
              return (
                <DraggableTableRow
                  key={lead._id}
                  id={lead._id}
                  className="group/row relative flex w-full border-b"
                  disableOnClickPopover
                  style={{
                    display: "grid",
                    gridTemplateColumns: getGridTemplateColumns(columnsCount),
                  }}
                >
                  <td
                    className={cn(
                      "flex h-full w-auto grow items-center justify-center whitespace-nowrap border-r px-4 py-2 text-left text-sm",
                      {
                        "w-0 p-0": !isSelectingEnabled,
                      }
                    )}
                  >
                    <Checkbox
                      size="sm"
                      value={lead._id}
                      checked={!!selectedRows?.has(lead._id)}
                      onClick={() => onClickSelectRow?.(lead._id)}
                      className={cn("border-primary-100 group-hover/row:border-primary-400", {
                        "invisible w-0": !isSelectingEnabled,
                      })}
                    />
                  </td>
                  <td
                    onClick={() => setSelectedSidePanelLead(lead)}
                    className="flex h-full w-auto grow cursor-pointer items-center gap-1 overflow-hidden text-ellipsis whitespace-nowrap border-r px-2 py-2"
                  >
                    <div className="flex items-center gap-1">
                      <Avatar
                        size="custom"
                        className="h-[20px] w-[20px]"
                        src={lead.agent.avatar}
                        name={lead.agent.name}
                      />
                      <span className="overflow-hidden text-ellipsis text-xs font-medium text-neutral-750">
                        {lead.agent.name}
                      </span>
                    </div>
                  </td>
                  <td className="flex h-full w-auto grow items-center gap-1 overflow-hidden text-ellipsis whitespace-nowrap border-r px-2 py-2">
                    <HybridTooltipPopover
                      triggerComponent={
                        <span className="overflow-hidden text-ellipsis text-xs font-medium text-neutral-750">
                          {new Date(lead.createdAt).toLocaleDateString()}
                        </span>
                      }
                    >
                      <span className="text-xs font-medium text-neutral-750">
                        {new Date(lead.createdAt).toLocaleDateString()}
                      </span>
                    </HybridTooltipPopover>
                  </td>
                  {headers.map(header => {
                    return (
                      <td
                        key={header.key}
                        className="flex h-full w-auto grow items-center gap-1 overflow-hidden text-ellipsis whitespace-nowrap border-r px-2 py-2"
                      >
                        <HybridTooltipPopover
                          asChild
                          triggerComponent={
                            <span className="overflow-hidden text-ellipsis text-xs font-medium text-neutral-750">
                              {getLeadFieldValue({ lead, key: header.key })}
                            </span>
                          }
                        >
                          <span className="text-xs font-medium text-neutral-750">
                            {getLeadFieldValue({ lead, key: header.key })}
                          </span>
                        </HybridTooltipPopover>
                      </td>
                    );
                  })}
                </DraggableTableRow>
              );
            })}
          </DraggableTableBody>
        </DraggableTable>
      </div>

      <StatsLeadsDetailsSidePanel
        isOpen={isSidePanelOpen}
        setIsOpen={() => setSelectedSidePanelLead(null)}
        lead={selectedSidePanelLead}
      />
    </>
  );
};
