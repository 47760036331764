import { MessageError } from "./MessageError";
import { PredefinedQuestions } from "./PredefinedQuestions";
import { differenceInMinutes, format } from "date-fns";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { Fragment, useState } from "react";
import type { ChatAgent, ConversationMessagesResponse } from "@/types/conversation";
import type { EmbedKnowledges } from "@/types/agent";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import useScrollToMessage from "../hooks/useScrollToMessage";
import { domElementIds } from "@/types/dom-element-ids";
import { FetchNextPageTrigger } from "./FetchNextPageTrigger";
import { DocumentInfoPreview } from "@/components/AgentForm/components/Knowledge/components/DocumentInfoPreview";
import { MessageVoiceProvider } from "@/contexts/MessageVoiceContext/MessageVoiceProvider";
import { MessageCloud } from "./MessageCloud/MessageCloud";
import { ChatGreetingMessages } from "./ChatGreetingMessages";
import { AnimatePresence, motion } from "framer-motion";

type ChatContentProps = {
  agents: ChatAgent[];
  conversationData: ConversationMessagesResponse | undefined;
  isLoadingConversationData: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isChatEmpty: boolean;
  onOpenMobileSidePanel?: () => void;
};

export const ChatContent = ({
  agents,
  conversationData,
  isLoadingConversationData: isLoading,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  isChatEmpty,
  onOpenMobileSidePanel,
}: ChatContentProps) => {
  const { isSendingMessage, isGroupChat, isMessageError, isPreview } = useChatContext();
  const [documentInPreview, setDocumentInPreview] = useState<EmbedKnowledges | null>(null);
  const [docPreviewPage, setDocPreviewPage] = useState<number | null>(null);

  useScrollToMessage({ enabled: !!conversationData });

  const isFirstPage = conversationData?.messages.length === conversationData?.totalMessages;

  const arePredefinedQuestionsDisabled = isLoading || isSendingMessage;

  if (isLoading) {
    return (
      <div className="grid size-full place-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  const canShowGreetingMessages = isFirstPage;

  const botNames = [
    ...new Set(
      conversationData?.messages.filter(message => "bot" in message).map(message => message?.bot?.name ?? "") ?? []
    ),
  ];

  return (
    <MessageVoiceProvider>
      <motion.div
        className="flex flex-col-reverse overflow-y-auto"
        initial={false}
        animate={!isChatEmpty || isPreview ? { height: "100%" } : { height: "auto" }}
        transition={{ duration: 0.5, ease: "easeInOut", delay: 0.5 }}
        id={domElementIds.CHAT_CONTENT_CONTAINER}
      >
        <div id={domElementIds.CHAT_CONTENT_SCROLL_TO} />

        {isMessageError && <MessageError />}

        {conversationData?.messages.map((message, index, messages) => {
          const currentMessageTime = new Date(message.createdAt);
          const prevMessageTime = index > 0 && new Date(messages[index + 1]?.createdAt);
          const timeDiff = prevMessageTime && differenceInMinutes(currentMessageTime, prevMessageTime);
          const isFirstMessage = index === conversationData.totalMessages - 1;
          const showPredefinedQuestions = isFirstMessage && !isGroupChat;

          return (
            <Fragment key={`${message.conversationId}-message-${message._id}`}>
              <MessageCloud
                message={message.text}
                isBotMessage={"bot" in message}
                messageId={message._id}
                conversationId={message.conversationId}
                trace={message.trace}
                vote={message.vote}
                messageIndex={index}
                createdAt={message.createdAt}
                isBookmark={message.isBookmark}
                agent={message.bot}
                botNames={botNames}
                isGeneratingBotResponse={message.isGenerating}
                attachments={message.attachments}
                messageUser={message.user}
                audio={message?.audio}
                cot={message.cot}
                tasks={message.tasks}
                setDocumentInPreview={setDocumentInPreview}
                setDocPreviewPage={setDocPreviewPage}
                disableActions={message.isAnswering}
                onOpenMobileSidePanel={onOpenMobileSidePanel}
              />
              {index === conversationData?.messages.length - 1 || (timeDiff && timeDiff > 5) ? (
                <div className="my-1 flex justify-center text-xs text-gray-400">
                  {format(currentMessageTime, "dd MMM yyyy HH:mm")}
                </div>
              ) : null}
              {showPredefinedQuestions && (
                <PredefinedQuestions agent={agents[0]} disabled={arePredefinedQuestionsDisabled} />
              )}
            </Fragment>
          );
        })}

        <AnimatePresence>
          {canShowGreetingMessages && isChatEmpty && (
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.5 }}
            >
              <ChatGreetingMessages
                agents={agents}
                botNames={botNames}
                setDocumentInPreview={setDocumentInPreview}
                setDocPreviewPage={setDocPreviewPage}
                isPreview={isPreview}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {isFetchingNextPage && <LoadingSpinner className="mx-auto my-5" />}

        {hasNextPage && <FetchNextPageTrigger fetchNextPage={fetchNextPage} />}
        <DocumentInfoPreview
          setDocumentInPreview={setDocumentInPreview}
          documentInPreview={documentInPreview}
          docPreviewPage={docPreviewPage}
          setDocPreviewPage={setDocPreviewPage}
        />
      </motion.div>
    </MessageVoiceProvider>
  );
};
