import { Link } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { Icons } from "@/components/ui/icons";
import { quickLinks, tutorialsLinks } from "@/pages/Workspace/components/constants";

const WorkspaceWhatsNew = () => {
  return (
    <div className="flex h-full flex-col border-l border-primary-100 px-6 pt-6">
      <h2 className="mb-4 text-2xl leading-[38px] text-neutral-750">Quick Links</h2>
      <div className="mb-4 flex flex-wrap gap-4">
        {quickLinks.map(({ emoji, title, link }) => (
          <a
            key={title}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex w-full items-center gap-2 rounded-md bg-primary-50 px-4 py-1 text-xs font-medium hover:bg-primary-100 2xl:w-fit"
          >
            <span>{emoji}</span>
            <span>{title}</span>
          </a>
        ))}
      </div>

      <div className="overflow-y-scroll pb-4">
        <div className="mb-4 overflow-y-hidden rounded-md">
          <iframe
            className="aspect-video w-full"
            src="https://player.vimeo.com/video/1009343176?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          ></iframe>
        </div>

        <h2 className="my-4 text-2xl text-neutral-750">Tutorials</h2>
        <div className="flex flex-col gap-4">
          {tutorialsLinks.map(({ title, link }) => (
            <a
              key={title}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="flex w-full items-center justify-between gap-2 rounded-lg bg-primary-50 px-4 py-3 text-sm font-medium hover:bg-primary-100"
            >
              <span>{title}</span>
              <Icons.ChevronRight className="size-6 text-primary-300" />
            </a>
          ))}
        </div>

        <h2 className="my-4 mt-10 text-2xl text-neutral-750">Need professional help?</h2>

        <div className="flex flex-col gap-4">
          <p>
            Leave us a message{" "}
            <Link className="text-primary-500 hover:underline" to={ROUTES.help}>
              here
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceWhatsNew;
