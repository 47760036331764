import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

import { Icons } from "@/components/ui/icons";
import { IconButton } from "@/components/ui/icon-button";

import { processMarkdown } from "@/lib/processMarkdown";
import { MESSAGE_HIGHLIGHT_CLASS } from "../../contants/chat-contants";
import { useCopyNotify } from "@/hooks/useCopyNotify";
import { cn } from "@/lib/utils";

import type { LegacyRef } from "react";
import "katex/dist/katex.min.css";

type Props = {
  message: string;
};

export const MessageCloudMarkdown = ({ message }: Props) => {
  const [, copy] = useCopyNotify();

  const handleCopyTableContent = (tableId: string) => {
    const table = document.getElementById(tableId);
    if (table) {
      copy(table.innerText);
    }
  };

  const processedMarkdown = processMarkdown(message);

  const plugins = processedMarkdown.isCode ? [remarkGfm, remarkBreaks] : [remarkGfm, remarkBreaks, remarkMath];

  message = processedMarkdown.markdown;

  return (
    <div className="break-words text-sm font-medium text-primary-black">
      <Markdown
        remarkPlugins={plugins}
        rehypePlugins={[rehypeKatex]}
        children={message}
        components={{
          a: ({ node, ...props }) => (
            <a
              {...props}
              className={cn(
                "text-sm text-sky-900 underline decoration-sky-900 hover:text-sky-600",
                MESSAGE_HIGHLIGHT_CLASS
              )}
              target="_blank"
              rel="noopener noreferrer"
            />
          ),
          ol: ({ node, ...props }) => (
            <ol
              {...props}
              className={cn("ml-5 list-inside list-decimal whitespace-normal text-sm", MESSAGE_HIGHLIGHT_CLASS)}
            />
          ),
          ul: ({ node, ...props }) => (
            <ul
              {...props}
              className={cn("ml-5 list-inside list-disc whitespace-normal text-sm", MESSAGE_HIGHLIGHT_CLASS)}
            />
          ),
          li: ({ node, ...props }) => <li {...props} className={cn("my-2 list-item", MESSAGE_HIGHLIGHT_CLASS)} />,
          h2: ({ node, ...props }) => <h2 {...props} className={cn("text-lg", MESSAGE_HIGHLIGHT_CLASS)} />,
          table: ({ node, children, ...props }) => {
            const tableId = `agx-table-${Math.random().toString(36).substring(2, 9)}`;
            return (
              <div className="group relative">
                <IconButton
                  className="absolute right-0 top-0 hidden group-hover:flex"
                  variant="tertiary"
                  icon={<Icons.CopyChat className="w-5" />}
                  onClick={() => handleCopyTableContent(tableId)}
                />
                <div className="overflow-x-auto">
                  <table
                    {...props}
                    className={cn("min-w-full divide-y divide-gray-200", MESSAGE_HIGHLIGHT_CLASS)}
                    id={tableId}
                  >
                    {children}
                  </table>
                </div>
              </div>
            );
          },
          thead: ({ node, ...props }) => <thead {...props} className={cn("bg-gray-50", MESSAGE_HIGHLIGHT_CLASS)} />,
          tbody: ({ node, ...props }) => (
            <tbody {...props} className={cn("divide-y divide-gray-200 bg-white", MESSAGE_HIGHLIGHT_CLASS)} />
          ),
          tr: ({ node, ...props }) => <tr {...props} className={MESSAGE_HIGHLIGHT_CLASS} />,
          th: ({ node, ...props }) => (
            <th
              {...props}
              scope="col"
              className={cn(
                "border border-gray-200 px-4 py-3 text-left text-sm font-medium uppercase tracking-wider text-gray-500",
                MESSAGE_HIGHLIGHT_CLASS
              )}
            />
          ),
          td: ({ node, ...props }) => (
            <td {...props} className={cn("whitespace-nowrap border px-4 py-2 text-sm", MESSAGE_HIGHLIGHT_CLASS)} />
          ),
          pre: ({ node, ...props }) => <pre {...props} className={cn("overflow-x-auto", MESSAGE_HIGHLIGHT_CLASS)} />,
          code(props) {
            const { children, className, node, style, ...rest } = props;
            const match = /language-(\w+)/.exec(className || "");
            const ref = props.ref as LegacyRef<SyntaxHighlighter> | undefined;
            return match ? (
              <div className="rounded-md border border-neutral-300 bg-[#F5F2F0] text-sm tracking-wide">
                <div className="mb-[-7px] flex h-6 w-full items-center justify-between rounded-t-md border-b border-neutral-300 bg-[#e7e0db] px-2 text-sm text-neutral-500">
                  <div>{match[1]}</div>
                  <div
                    className="flex cursor-pointer items-center gap-1"
                    onClick={() => copy(String(children).replace(/\n$/, ""))}
                  >
                    copy
                    <Icons.CopyChat className="w-4" />
                  </div>
                </div>
                <SyntaxHighlighter
                  {...rest}
                  PreTag="div"
                  ref={ref}
                  children={String(children).replace(/\n$/, "")}
                  language={match[1]}
                />
              </div>
            ) : (
              <code {...rest} className={className + " bg-[#F5F2F0] " + MESSAGE_HIGHLIGHT_CLASS}>
                {children}
              </code>
            );
          },
          p: ({ node, ...props }) => (
            <p {...props} className={cn("text-left text-sm font-medium text-primary-black", MESSAGE_HIGHLIGHT_CLASS)} />
          ),
          img: ({ node, ...props }) => (
            <img {...props} className="mb-4 w-full max-w-[450px] rounded-md object-contain" />
          ),
        }}
      />
    </div>
  );
};
