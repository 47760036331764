/* eslint-disable @typescript-eslint/no-explicit-any */
import type { DomainLinks, EmbedKnowledges } from "@/types/agent";

const getDomainFromUrlByRegex = (url: any): string => {
  url = url.replace(/(https?:\/\/)?(www.)?/i, "");
  url = url.split(".");
  url = url.slice(url.length - 2).join(".");
  if (url.indexOf("/") !== -1) {
    return url.split("/")[0] as string;
  }
  return url as string;
};

const getDomainFromUrl = (url: string) => {
  const urlWithHttps = url.includes("https://") || url.includes("http://") ? url : `https://${url}`;
  let domain: string;
  try {
    domain = new URL(urlWithHttps).hostname.replace("www.", "");
  } catch (e) {
    domain = getDomainFromUrlByRegex(urlWithHttps);
  }
  return domain;
};

export const sortWebByDomainsToSeparateArrays = (webKnowledges: EmbedKnowledges[]) => {
  const list: DomainLinks[] = [];
  if (!webKnowledges) {
    return list;
  }
  webKnowledges.forEach(item => {
    const domain = getDomainFromUrl(item.source);
    const domainIndex = list.findIndex(i => i.domain === domain);
    if (domainIndex !== -1) {
      list[domainIndex].links.push(item);
    } else {
      list.push({ domain, links: [item] });
    }
  });

  return list;
};
