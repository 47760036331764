import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogDescription,
  DialogOverlay,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useQueryClient } from "@tanstack/react-query";
import { deleteCustomVoice } from "@/data/customVoice";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { getCustomVoiceListQueryKey } from "@/data/queries/voice/useGetCustomVoiceList";

type AgentVoiceDeleteDialogProps = {
  voiceId: string | null;
  voiceName: string | null;
  agentId: string;
  onClose: () => void;
};

export const AgentVoiceDeleteDialog: React.FC<AgentVoiceDeleteDialogProps> = ({
  voiceId,
  voiceName,
  agentId,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const form = useAgentFormContext();

  const handleDelete = async () => {
    if (voiceId) {
      try {
        await deleteCustomVoice(agentId, voiceId);

        if (form.getValues("voiceProfile.activeCustomVoice") === voiceId) {
          form.setValue("voiceProfile.activeCustomVoice", null);
        }

        void queryClient.invalidateQueries({ queryKey: getCustomVoiceListQueryKey(agentId) });

        onClose();
      } catch (error) {
        console.error("Failed to delete custom voice:", error);
      }
    }
  };

  return (
    <Dialog open={!!voiceId}>
      <DialogOverlay onClick={onClose}>
        <DialogContent variant="medium" className="h-[200px]">
          <DialogHeader>Are you sure you want to delete custom voice "{voiceName}"?</DialogHeader>
          <DialogDescription asChild className="flex flex-col items-center gap-4">
            <p className="text-center text-sm text-neutral-400">
              Deleting this custom voice will permanently remove it. This action cannot be undone.
            </p>
          </DialogDescription>
          <DialogFooter className="flex gap-4">
            <DialogClose asChild>
              <Button size="medium" variant="tertiary" onClick={onClose}>
                Cancel
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button size="medium" variant="danger" onClick={handleDelete}>
                Delete
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </DialogOverlay>
    </Dialog>
  );
};
