import type { AgentBuildInFunction } from "@/types/agent";
import { agentBuildInFunctions } from "@/types/agent";
import { LoadingSpinner } from "../../ui/loading-spinner";
import type { GenImage, ParseAttachment } from "@/types/trace";
import { useMemo } from "react";

type ActionLoaderProps = {
  action: AgentBuildInFunction;
  genImage?: GenImage;
  actionObject?: ParseAttachment; // or other action object like retrieve, search, webbrowse, etc.
};

export const ActionLoader = ({ action, actionObject }: ActionLoaderProps) => {
  const content = useMemo(() => {
    switch (action) {
      case agentBuildInFunctions.IMAGE_GENERATE:
      case agentBuildInFunctions.IMAGE_GENERATE_FLUX:
        return "Creating image...";
      case agentBuildInFunctions.VIDEO_GENERATE:
        return "Creating video...";
      case agentBuildInFunctions.BROWSE_WEB:
        return agentBuildInFunctions.BROWSE_WEB;
      case agentBuildInFunctions.SEARCH_ENGINE:
        return agentBuildInFunctions.SEARCH_ENGINE;
      case agentBuildInFunctions.EVENT_SCHEDULER:
        return agentBuildInFunctions.EVENT_SCHEDULER;
      case agentBuildInFunctions.TEXT_EXTRACTION:
        return agentBuildInFunctions.TEXT_EXTRACTION;
      case agentBuildInFunctions.ATTACHMENT:
        if (actionObject?.status === "processing") {
          return `🔎 ${actionObject.attachment_index}. ${actionObject.attachment_name} - ${actionObject.step} / 
              ${actionObject.total_step} `;
        } else {
          return agentBuildInFunctions.ATTACHMENT;
        }
      default:
        return <></>;
    }
  }, [action, actionObject]);

  return (
    <div className="flex items-center gap-2">
      <LoadingSpinner iconClassName="size-6" />
      <p className="text-base font-medium leading-6 text-primary-400">{content}</p>
    </div>
  );
};
