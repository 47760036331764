import type { AgentNotification } from "@/types/notification";
import { useAcknowledgeNotifications } from "@/data/mutations/notifications/useAcknowledgeNotifications";
import { useDeleteNotification } from "@/data/mutations/notifications/useDeleteNotification";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import type { GroupedNotification } from "./utils/groupAndSortNotifications";

type NotificationMenuProps = {
  notification?: AgentNotification;
  groupedNotification?: GroupedNotification;
};

export const NotificationMenu = ({ notification, groupedNotification }: NotificationMenuProps) => {
  const { mutate: acknowledgeNotifications, isPending: isPendingAcknowledge } = useAcknowledgeNotifications();
  const { mutate: deleteNotification, isPending: isPendingDelete } = useDeleteNotification();

  if (!notification && !groupedNotification) {
    return null;
  }

  const isPending = isPendingAcknowledge || isPendingDelete;

  const onRead = () => {
    if (groupedNotification) {
      groupedNotification.notifications.forEach(notification => {
        acknowledgeNotifications({ ids: [notification._id] });
      });
    }
    if (notification) {
      acknowledgeNotifications({ ids: [notification._id] });
    }
  };

  const onDelete = () => {
    if (groupedNotification) {
      groupedNotification.notifications.forEach(notification => {
        deleteNotification({ id: notification._id });
      });
    }
    if (notification) {
      deleteNotification({ id: notification._id });
    }
  };

  const read = notification?.read || groupedNotification?.read;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger onClick={e => e.stopPropagation()}>
        <Icons.Dots className="size-8 rotate-90 rounded-full p-1 text-black text-primary-400 hover:bg-gray-100" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="min-w-20">
        <DropdownMenuItem className="flex items-center gap-1.5 text-xs" disabled={read || isPending} onClick={onRead}>
          <Icons.Box />
          Read
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex items-center gap-1.5 text-xs text-error focus:text-error"
          disabled={isPending}
          onClick={onDelete}
        >
          <Icons.Trash />
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
