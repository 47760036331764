import { cn } from "@/lib/utils";
import type React from "react";
import { forwardRef, useState } from "react";
import type { DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import { DndContext, closestCenter, useSensors, useSensor, PointerSensor } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { Popover, PopoverAnchor, PopoverContent } from "./popover";
import { Icons } from "./icons";
import { CSS } from "@dnd-kit/utilities";
import { GripVerticalIcon } from "lucide-react";

type DraggableTableProps = {
  onDragEndAction: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
  itemsIds: (
    | UniqueIdentifier
    | {
        id: UniqueIdentifier;
      }
  )[];
};

const DraggableTable = forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement> & DraggableTableProps>(
  ({ className, onDragEndAction, itemsIds, ...props }, ref) => {
    const pointerSensor = useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    });

    const sensors = useSensors(pointerSensor);

    const handleDragEnd = (event: DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = itemsIds.findIndex(id => id === active.id);
        const newIndex = itemsIds.findIndex(id => id === over.id);
        onDragEndAction({ oldIndex, newIndex });
      }
    };

    return (
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
        <SortableContext items={itemsIds} strategy={rectSortingStrategy}>
          <table ref={ref} className={cn("w-full bg-transparent", className)} {...props}>
            {props.children}
          </table>
        </SortableContext>
      </DndContext>
    );
  }
);
DraggableTable.displayName = "Table";

type DraggableTableActionPopupProps = {
  numOfSelectedRows: number;
  onDelete: () => void;
  onDuplicate: () => void;
};

const DraggableTableHeader = forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => {
    return <thead ref={ref} className={className} {...props} />;
  }
);
DraggableTableHeader.displayName = "TableHeader";

const DraggableTableHeaderCell = forwardRef<
  HTMLTableHeaderCellElement,
  React.HTMLAttributes<HTMLTableHeaderCellElement>
>(({ className, ...props }, ref) => {
  return (
    <th
      ref={ref}
      className={cn(
        "flex h-full w-fit items-center justify-center whitespace-nowrap px-4 py-[18px] text-left text-sm font-bold",
        className
      )}
      {...props}
    />
  );
});
DraggableTableHeaderCell.displayName = "TableHeaderCell";

const DraggableTableBody = forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => {
    return <tbody ref={ref} className={cn("", className)} {...props} />;
  }
);
DraggableTableBody.displayName = "TableBody";

type DraggableTableRowProps = {
  id: UniqueIdentifier;
  onDeleteRowAction?: () => void;
  onDuplicateRowAction?: () => void;
  disableOnClickPopover?: boolean;
};

const DraggableTableRow = forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & DraggableTableRowProps
>(({ className, id, onDeleteRowAction, onDuplicateRowAction, disableOnClickPopover, ...props }, _ref) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    ...props.style,
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <tr ref={setNodeRef} className={cn("", className)} style={style}>
      <div className="absolute -left-5 top-1/2 flex -translate-y-1/2 cursor-pointer items-center justify-center">
        <button
          onClick={() => {
            setIsDropdownOpen(true);
          }}
          className="touch-none"
          {...attributes}
          {...listeners}
        >
          <GripVerticalIcon className="size-4 text-primary-100 group-hover/row:text-primary-400" />
        </button>
        {!disableOnClickPopover && (
          <Popover open={isDropdownOpen} onOpenChange={open => setIsDropdownOpen(open)}>
            <PopoverAnchor />
            <PopoverContent
              side="bottom"
              align="start"
              className="w-fit rounded-md border border-slate-200 bg-white p-1 text-slate-950 shadow-md"
            >
              {onDeleteRowAction && (
                <li
                  className="my-0 flex cursor-default select-none items-center gap-1.5 rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-primary-50"
                  onClick={e => {
                    e.preventDefault();
                    onDeleteRowAction();
                    setIsDropdownOpen(false);
                  }}
                >
                  <Icons.Trash />
                  Delete
                </li>
              )}

              {onDuplicateRowAction && (
                <li
                  className="my-0 flex cursor-default select-none items-center gap-1.5 rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-primary-50"
                  onClick={e => {
                    e.preventDefault();
                    onDuplicateRowAction();
                    setIsDropdownOpen(false);
                  }}
                >
                  <Icons.Copy />
                  Duplicate
                </li>
              )}
            </PopoverContent>
          </Popover>
        )}
      </div>
      {props.children}
    </tr>
  );
});
DraggableTableRow.displayName = "TableRow";

type DraggableTableNewRowButtonProps = {
  onNewRowClick: () => void;
  isDisabled?: boolean;
  disabledNode?: React.ReactNode;
};

const DraggableTableNewRowButton = ({ onNewRowClick, isDisabled, disabledNode }: DraggableTableNewRowButtonProps) => {
  return (
    <tr
      onClick={() => {
        if (!isDisabled) {
          onNewRowClick();
        }
      }}
      className="group/new-row w-full cursor-pointer border-b border-t hover:bg-neutral-100"
    >
      <td className="flex items-center gap-3 px-3 py-3 text-xs font-medium leading-5 text-neutral-300 group-hover/new-row:text-neutral-750">
        {isDisabled && disabledNode ? (
          disabledNode
        ) : (
          <>
            <div className="p-1">
              <Icons.Plus className="size-4 text-neutral-300 group-hover/new-row:text-neutral-750" />
            </div>
            New
          </>
        )}
      </td>
      <td></td>
      <td></td>
    </tr>
  );
};

const DraggableTableActionPopup = ({ numOfSelectedRows, onDelete, onDuplicate }: DraggableTableActionPopupProps) => {
  return (
    <Popover open>
      <PopoverAnchor />
      <PopoverContent
        side="top"
        align="start"
        className="flex w-fit items-center gap-4 rounded-md border border-slate-200 bg-white p-2 shadow-md"
      >
        <p className="text-xs font-medium text-primary-400">{numOfSelectedRows} selected</p>
        <div className="h-4 w-[1px] bg-neutral-400" />
        <button onClick={onDuplicate} className="flex items-center gap-1 text-neutral-750">
          <Icons.Copy />
          Duplicate
        </button>
        <div className="h-4 w-[1px] bg-neutral-400" />
        <button onClick={onDelete} className="flex items-center gap-1  text-neutral-750">
          <Icons.Trash />
          Delete
        </button>
      </PopoverContent>
    </Popover>
  );
};

export {
  DraggableTable,
  DraggableTableBody,
  DraggableTableHeader,
  DraggableTableHeaderCell,
  DraggableTableRow,
  DraggableTableNewRowButton,
  DraggableTableActionPopup,
};
