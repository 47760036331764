import { Checkbox } from "@/components/ui/checkbox";
import { Switch } from "@/components/ui/switch";
import { DraggableTableRow } from "@/components/ui/draggable-table";
import {
  ContentEditableSpan,
  ContentEditableSpanPlaceholder,
} from "@/components/ContentEditableElements/ContentEditableSpan";
import { useCallback, useEffect, useState } from "react";
import { FAQTableRowDropOverlay } from "@/components/Chat/components/FAQTableRowDropOverlay";
import { Icons } from "@/components/ui/icons";
import { useUploadFAQFile } from "@/data/mutations/useUploadFAQFile";
import { LoadingSpinner } from "@/components/ui/loading-spinner";

interface Props {
  row: {
    _id: string;
    description: string;
    content: string;
    predefined?: boolean;
    source?: string;
  };
  onUpdateRow: ({
    _id,
    description,
    content,
    source,
  }: {
    _id: string;
    description?: string;
    content?: string;
    predefined?: boolean;
    source?: string;
  }) => void;
  onSelectRow: (_id: string) => void;
  onDeleteRow: (_id: string) => void;
  onDuplicateRow: (id: string) => void;
  isSelected: boolean;
  descriptionError?: string;
  contentError?: string;
}

const FAQTableRow = ({
  row,
  onUpdateRow,
  onSelectRow,
  onDeleteRow,
  onDuplicateRow,
  isSelected,
  descriptionError,
  contentError,
}: Props) => {
  const { mutateAsync: uploadFAQFile, isPending: isUploadPending } = useUploadFAQFile();

  const questionSpanElementId = row._id + "-faq-question";

  const handleFocusQuestion = useCallback(() => {
    const question = document.getElementById(questionSpanElementId);
    question?.focus();
  }, [questionSpanElementId]);

  useEffect(() => {
    if (!row.content && !row.description) {
      handleFocusQuestion();
    }
  }, [handleFocusQuestion, row]);

  const [isUserDragging, setIsUserDragging] = useState(false);

  const handleUploadFile = async (formData: FormData) => {
    try {
      const { path } = await uploadFAQFile(formData);
      onUpdateRow({ _id: row._id, source: path });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDragEnter: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsUserDragging(true);
  };

  const handleRemoveFile = () => {
    onUpdateRow({ _id: row._id, source: "" });
  };

  return (
    <DraggableTableRow
      className="group/row relative grid w-full grid-cols-[auto_minmax(200px,_2fr)_minmax(200px,_3fr)_minmax(200px,_1fr)] border-t"
      id={row._id}
      onDeleteRowAction={() => onDeleteRow(row._id)}
      onDuplicateRowAction={() => onDuplicateRow(row._id)}
    >
      <td className="flex h-full w-fit items-center justify-center whitespace-nowrap px-4 py-[18px]">
        <Checkbox
          size="sm"
          value={row._id}
          checked={isSelected}
          onClick={() => onSelectRow(row._id)}
          className="border-primary-100 group-hover/row:border-primary-400"
        />
      </td>
      <td className="relative flex flex-col items-start justify-center py-2 pr-4">
        <ContentEditableSpan
          className="peer/title"
          value={row.description}
          onValueChange={value => onUpdateRow({ _id: row._id, description: value })}
          id={questionSpanElementId}
        />
        {!row.description && (
          <ContentEditableSpanPlaceholder className="peer-focus/title:hidden">
            e.g. What is Agent Space?
          </ContentEditableSpanPlaceholder>
        )}

        {descriptionError && <span className="mt-0 text-xxs leading-3 text-red-500">{descriptionError}</span>}
      </td>
      <td className="relative flex items-center border-l px-4 py-2" onDragEnter={handleDragEnter}>
        {isUserDragging && (
          <FAQTableRowDropOverlay
            onDragEnd={() => setIsUserDragging(false)}
            onUpload={handleUploadFile}
            isExistingFile={!!row.source}
          />
        )}

        <div className="flex flex-col gap-2">
          {isUploadPending && <LoadingSpinner />}
          {row.source && (
            <a href={row.source} target="_blank" rel="noopener noreferrer" className="group relative">
              <img src={row.source} alt="source" className="h-24 object-cover" />

              <button
                className="absolute -right-1.5 -top-1.5 grid size-5 place-items-center rounded-full border-2 border-white bg-primary-black lg:hidden lg:group-hover:grid"
                onClick={e => {
                  e.preventDefault();
                  handleRemoveFile();
                }}
              >
                <Icons.Close className="size-2 text-white" />
                <span className="sr-only">remove file</span>
              </button>
            </a>
          )}
        </div>

        <ContentEditableSpan
          className="peer/content"
          value={row.content}
          onValueChange={value => onUpdateRow({ _id: row._id, content: value })}
        />
        {!row.content && !row.source && !isUploadPending && (
          <ContentEditableSpanPlaceholder className="peer-focus/content:hidden">
            e.g., Agent Space is a community place hosted by AgentX for users to share their Agents
          </ContentEditableSpanPlaceholder>
        )}
        {contentError && <span className="absolute bottom-1 text-xxs leading-3 text-red-500">{contentError}</span>}
      </td>
      <td className="flex items-center border-l py-2 pl-4">
        <Switch
          className="scale-75"
          checked={row.predefined}
          onCheckedChange={checked => {
            onUpdateRow({ _id: row._id, predefined: checked });
          }}
        />
      </td>
    </DraggableTableRow>
  );
};

export default FAQTableRow;
