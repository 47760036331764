export const processMarkdown = (
  markdown: string
): { markdown: string; isCode: boolean; isTable: boolean; hasLatex: boolean } => {
  const isCode = /```/.test(markdown);
  const isTable = /^\s*\|.*\|/m.test(markdown);
  const hasLatex = /\$\$.*?\$\$/s.test(markdown);

  if (!isCode && !isTable && !hasLatex) {
    markdown = markdown.replaceAll(/\n/g, "&nbsp; \n");
  }

  if (!isCode) {
    markdown = markdown.replaceAll(/(?<!\$)\$(?!\$)/g, "\\$");
  }

  return { markdown, isCode, isTable, hasLatex };
};
