import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import type { TypeFromConstObject } from "@/types/types";
import { useGetUser } from "@/data/queries/useGetUser";
import { SubscriptionTiers } from "@/types/subscriptions";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { useGetMyWorkspaces } from "@/data/queries/workspace/useGetMyWorkspaces";
import { AddWorkspaceButton } from "@/pages/Workspace/components/AddWorkspaceButton";

export const NavigationProfileSections = {
  ACCOUNT: {
    ACCOUNT: "Account",
    ACCOUNT_ACTIVITIES: "Account Activities",
    BILLING: "Billing",
    SECURITY: "Security",
  },
  WORKSPACES: {
    AGENTS: "Agents",
    TEAM: "Team",
  },
} as const;

export type NavigationProfileSection = TypeFromConstObject<typeof NavigationProfileSections>;
export type NavigationProfileSubSection =
  | string
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | TypeFromConstObject<typeof NavigationProfileSections.ACCOUNT>
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | TypeFromConstObject<typeof NavigationProfileSections.WORKSPACES>;

const iconsMap = {
  [NavigationProfileSections.ACCOUNT.ACCOUNT]: Icons.UserBlack,
  [NavigationProfileSections.ACCOUNT.ACCOUNT_ACTIVITIES]: Icons.Database,
  [NavigationProfileSections.ACCOUNT.BILLING]: Icons.Dollar,
  [NavigationProfileSections.ACCOUNT.SECURITY]: Icons.LockOpen,
  [NavigationProfileSections.WORKSPACES.AGENTS]: Icons.Bot,
  [NavigationProfileSections.WORKSPACES.TEAM]: Icons.Team,
} as const;

export const NavigationProfileSection = () => {
  const { navigationProfileOpen, setNavigationProfileOpen } = useNavigationProfileContext();

  const { data: workspaceDetails } = useGetWorkspaceDetails();
  const { user } = useGetUser();
  const isEnterpriseTier = user?.subscription.tier === SubscriptionTiers.ENTERPRISE;
  const isFreeTier = user?.subscription.tier === SubscriptionTiers.FREE;
  const isStartupTier = user?.subscription.tier === SubscriptionTiers.STARTUP;

  const { data: myWorkspaces } = useGetMyWorkspaces();

  const isShowSubsection = (subSection: NavigationProfileSubSection) => {
    switch (subSection) {
      // case NavigationProfileSections.WORKSPACES.TEAM:
      //   return !isFreeTier;
      case NavigationProfileSections.ACCOUNT.BILLING:
        return !(isEnterpriseTier && workspaceDetails?.isAdmin);
      case NavigationProfileSections.ACCOUNT.ACCOUNT_ACTIVITIES:
        return !(isEnterpriseTier && workspaceDetails?.isAdmin);
      default:
        return true;
    }
  };

  return (
    <div className="flex w-full flex-col gap-6 overflow-y-scroll">
      {Object.keys(NavigationProfileSections).map(section => {
        if (section === "WORKSPACES") {
          return;
        }
        return (
          <div className="flex flex-col gap-2" key={section}>
            <div className="text-sm font-medium text-primary-400">{section}</div>
            {Object.values(NavigationProfileSections[section as keyof typeof NavigationProfileSections]).map(
              subSection => {
                if (!isShowSubsection(subSection as NavigationProfileSubSection)) {
                  return null;
                }
                const IconComponent = iconsMap[subSection];

                return (
                  <button
                    key={subSection}
                    className={cn(
                      "flex w-full items-center rounded-md border-gray-200 px-2 py-4 text-sm font-medium text-neutral-750 sm:w-40 sm:border-none sm:p-2  lg:w-52 [&:not(:last-child)]:border-b",
                      navigationProfileOpen.section === subSection
                        ? "bg-primary-100 font-bold outline-none"
                        : "sm:hover:bg-primary-200 sm:hover:font-semibold"
                    )}
                    onClick={() => {
                      setNavigationProfileOpen(prev => ({ ...prev, section: subSection, mode: "account" }));
                    }}
                    autoFocus={navigationProfileOpen.section === subSection}
                  >
                    <IconComponent />
                    <span className="ml-3 lg:ml-4">{subSection}</span>
                    <Icons.ChevronRight className="ml-auto size-6 sm:hidden" />
                  </button>
                );
              }
            )}
          </div>
        );
      })}

      {((!isStartupTier && !isFreeTier) || !!user?.workspaces?.length || !!user?.subscription.users) && (
        <div className="flex flex-col gap-2" key="workspaces-flow">
          {(!!user?.subscription.users || (!user?.subscription.users && !!myWorkspaces?.length)) && (
            <div className="text-sm font-medium uppercase text-primary-400">Workspaces</div>
          )}
          {myWorkspaces?.map(workspace => (
            <button
              key={workspace._id}
              className={cn(
                "flex w-full items-center rounded-md border-gray-200 px-2 py-4 text-sm font-medium text-neutral-750 sm:w-40 sm:border-none sm:p-2  lg:w-52 [&:not(:last-child)]:border-b",
                navigationProfileOpen.section === workspace._id
                  ? "bg-primary-100 font-bold outline-none"
                  : "sm:hover:bg-primary-200 sm:hover:font-semibold"
              )}
              onClick={() => {
                setNavigationProfileOpen(prev => ({ ...prev, section: workspace._id, mode: "workspace" }));
              }}
              autoFocus={navigationProfileOpen.section === workspace._id}
            >
              <Icons.Workspaces className="size-6" />
              <span className="ml-3 lg:ml-4">{workspace.name}</span>
              {(user?._id === workspace.owner._id || workspace?.editors.some(editor => editor._id === user?._id)) && (
                <Icons.Admin className="ml-auto text-neutral-400" />
              )}
            </button>
          ))}
          <AddWorkspaceButton isSideMenu />
        </div>
      )}
    </div>
  );
};
