import type { ReactNode } from "react";
import { useState } from "react";
import { NavigationProfileContext } from "@/contexts/NavigationProfileContext/NavigationProfileContext";
import { ProfileDialog } from "@/components/Profiles/ProfileDialog";
import type { NavigationProfileSubSection } from "@/components/Profiles/NavigationProfileSection";
import type { TeamSteps } from "@/components/Profiles/WorkspaceSection/WorkspaceMembers";
import type { WorkspaceSectionTabs } from "@/components/Profiles/WorkspaceSection/types";

type NavProfileProviderProps = {
  children: ReactNode;
};

export type NavigationProfileStateType = {
  open: boolean;
  section: NavigationProfileSubSection | null;
  mode: "workspace" | "account" | null;
  activeStep?: TeamSteps | null;
  activeTab?: WorkspaceSectionTabs | null;
};
export const NavigationProfileProvider = ({ children }: NavProfileProviderProps) => {
  const [navigationProfileOpen, setNavigationProfileOpen] = useState<NavigationProfileStateType>({
    open: false,
    section: null,
    mode: "account",
  });

  return (
    <NavigationProfileContext.Provider value={{ navigationProfileOpen, setNavigationProfileOpen }}>
      <ProfileDialog />
      {children}
    </NavigationProfileContext.Provider>
  );
};
