import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { toast } from "react-toastify";
import type { Workspace } from "@/types/workspace";
import { myWorkspacesKeys } from "@/data/queries/workspace/useGetMyWorkspaces";
import { userKeys } from "@/data/queries/useGetUser";

type Props = {
  id: Workspace["_id"];
};
type Response = {
  message: string;
};
const deleteWorkspace = async ({ id }: Props) => {
  const { data } = await restClient.delete<Response>(apiPaths.deleteWorkspace(id));
  return data;
};

export const useDeleteWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<Response>, Props>({
    mutationFn: deleteWorkspace,
    onSuccess: async data => {
      await queryClient.invalidateQueries({ queryKey: myWorkspacesKeys.all });
      await queryClient.invalidateQueries({ queryKey: userKeys.all });
      toast.success(data.message);
    },
  });
};
