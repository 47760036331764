import { DialogClose, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { useGetUser } from "@/data/queries/useGetUser";

import type { SubscriptionTier } from "@/types/subscriptions";
import { Button } from "@/components/ui/button";
import { SubscriptionPeriod, SubscriptionTiers } from "@/types/subscriptions";
import { useUpdateSubscriptionPlan } from "@/data/mutations/subscriptions/useUpdateSubscriptionPlan";
import { Icons } from "@/components/ui/icons";
import { useGetCustomerBillingInfo } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { AccountUsageEnterpriseCard } from "@/components/dialogs/AccountUsageDialog/components/AccountUsageEnterpriseCard";
import { AccountUsageStartupCard } from "@/components/dialogs/AccountUsageDialog/components/AccountUsageStartupCard";
import { AccountUsageProCard } from "@/components/dialogs/AccountUsageDialog/components/AccountUsageProCard";
import { AccountUsageFreeCard } from "@/components/dialogs/AccountUsageDialog/components/AccountUsageFreeCard";
import { useCancelSubscriptionPlan } from "@/data/mutations/subscriptions/useCancelSubscriptionPlan";
import { AccountUsageScaleCard } from "@/components/dialogs/AccountUsageDialog/components/AccountUsageScaleCard";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
//import { useGetWorkspaceUsers } from "@/data/queries/workspace/useGetWorkspaceUsers";
import { IconButton } from "@/components/ui/icon-button";

const cardComponentsMap = (period: SubscriptionPeriod) => {
  return {
    [SubscriptionTiers.FREE]: <AccountUsageFreeCard />,
    [SubscriptionTiers.STARTUP]: <AccountUsageStartupCard showButton={false} period={period} />,
    [SubscriptionTiers.PRO]: <AccountUsageProCard showButton={false} period={period} />,
    [SubscriptionTiers.SCALE]: <AccountUsageScaleCard showButton={false} period={period} />,
    [SubscriptionTiers.PREMIUM]: <AccountUsageProCard showButton={false} period={period} />,
    [SubscriptionTiers.ENTERPRISE]: <AccountUsageEnterpriseCard onCloseDialog={() => null} />,
  };
};

export const ChangeSubscriptionTier = () => {
  const { selectedPlan, setActiveStep, closeDialog } = useSubscriptionTierDialogContext();
  const { user } = useGetUser();
  const { data: customer } = useGetWorkspaceDetails();
  const { data: billingInfo } = useGetCustomerBillingInfo();
  const { mutate: updateSubscriptionPlan, isPending: isSubscriptionPlanUpdating } = useUpdateSubscriptionPlan();
  const { mutate: cancelSubscriptionPlan, isPending: isSubscriptionPlanCancelling } = useCancelSubscriptionPlan();

  if (!user) {
    return <h2 className="px-8 py-6">Could not retrieve user data. Please try again later.</h2>;
  }
  if (!selectedPlan) {
    return <h2 className="px-8 py-6">Could not retrieve plan data. Please try again later.</h2>;
  }
  if (!billingInfo) {
    return <h2 className="px-8 py-6">Could not retrieve billing data. Please try again later.</h2>;
  }

  let currentSubscriptionTier: SubscriptionTier = user.subscription.tier;
  const isCustomerUser = !!user.customer;

  if (isCustomerUser) {
    if (customer && customer?.isAdmin) {
      currentSubscriptionTier = customer?.customer.subscription?.tier;
    }
  }

  const CurrentSubscriptionTierCard = cardComponentsMap(
    billingInfo.subscription.interval === "month" ? SubscriptionPeriod.MONTHLY : SubscriptionPeriod.ANNUALLY
  )[currentSubscriptionTier];
  const TargetSubscriptionTierCard = cardComponentsMap(selectedPlan.period)[selectedPlan.plan.tier];

  //const userTeamSeatWarning = false; //members && members.length > TeamSeatsIncludedInPlans[selectedPlan.plan.tier];

  /*  const displayUserModificationNeeded = () => {
    const allUsers = members?.length ?? 0;
    const targetIncludedSeats = TeamSeatsIncludedInPlans[selectedPlan.plan.tier];
    const sourceIncludedSeats = TeamSeatsIncludedInPlans[currentSubscriptionTier];

    let userModificationNeeded: "increase" | "decrease" | null = null;
    let userModificationCount = 0;

    if (targetIncludedSeats < sourceIncludedSeats) {
      userModificationNeeded = "increase";
      userModificationCount = sourceIncludedSeats - targetIncludedSeats;
    } else if (sourceIncludedSeats < targetIncludedSeats) {
      userModificationNeeded = "decrease";
      userModificationCount = targetIncludedSeats - sourceIncludedSeats;
    }

    return (
      <div className="mt-6 grid max-w-max grid-cols-[120px_1fr] gap-4 text-sm text-neutral-500 [&>*:nth-of-type(odd)]:text-neutral-750">
        <div className="flex flex-col gap-6">
          You are transferring from plan {SubscriptionTierNames[currentSubscriptionTier]} that comes with{" "}
          {TeamSeatsIncludedInPlans[currentSubscriptionTier]} user seat to{" "}
          {SubscriptionTierNames[selectedPlan.plan.tier as keyof typeof SubscriptionTierNames]} that comes with{" "}
          {TeamSeatsIncludedInPlans[selectedPlan.plan.tier as SubscriptionTier] === 0
            ? "no"
            : TeamSeatsIncludedInPlans[selectedPlan.plan.tier as SubscriptionTier]}{" "}
          seats.
        </div>
        {userModificationNeeded && (
          <div>
            Modification needed: {userModificationNeeded} by {userModificationCount} seats. You are currently enrolled
            with {allUsers - sourceIncludedSeats} extra seats on ({sourceIncludedSeats} included) plan.
            <div className="text-sm font-medium text-primary-black">
              {userModificationNeeded === "increase" ? (
                <>Please note that you will be charged for the additional seats.</>
              ) : (
                <>Please note that extra seats will be cancelled.</>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };
*/
  const handleChangeSubscription = () => {
    selectedPlan.plan.tier === SubscriptionTiers.FREE
      ? cancelSubscriptionPlan(undefined, {
          onSuccess: () => {
            closeDialog();
          },
        })
      : updateSubscriptionPlan(
          { targetPlanId: selectedPlan.plan.price[selectedPlan.period].planId },
          {
            onSuccess: () => {
              setActiveStep("stripePaymentSuccess");
            },
          }
        );
  };

  return (
    <>
      <DialogHeader className="min-h-12">
        <IconButton
          variant="tertiary"
          size="small"
          icon={<Icons.Arrow />}
          onClick={() => setActiveStep("subscriptionTiers")}
        >
          <span className="sr-only">go back to plans</span>
        </IconButton>
        <span className="ml-5 font-medium">Confirm plan change</span>
      </DialogHeader>
      <div className="flex flex-col gap-6 px-8 py-6">
        <p className="text-sm font-medium text-primary-black">
          Please review the detail of your new plan and click “Confirm” when ready.
        </p>
        <div className="mx-auto grid max-w-[700px] grid-cols-[1fr_100px_1fr] items-center gap-5">
          {CurrentSubscriptionTierCard}
          <div className=" flex flex-col items-center justify-center text-center text-xs font-medium text-neutral-750">
            <Icons.Arrow className="size-8 rotate-180 text-primary-400" />
            <span className="rounded-full border border-neutral-300 bg-neutral-200 px-2 py-1">changing to</span>
          </div>
          {TargetSubscriptionTierCard}
        </div>
      </div>
      <div className="border-t-[1px] border-neutral-300" />
      <div className="border-t-[1px] border-neutral-300" />
      {/*userTeamSeatWarning && <>{displayUserModificationNeeded()}</>*/}
      <DialogFooter>
        <DialogClose asChild>
          <Button variant="secondary">Cancel</Button>
        </DialogClose>
        <Button loading={isSubscriptionPlanCancelling || isSubscriptionPlanUpdating} onClick={handleChangeSubscription}>
          Confirm
        </Button>
      </DialogFooter>
    </>
  );
};
