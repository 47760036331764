import { ChangeUserAvatar } from "@/components/Profiles/ChangeUserAvatar";

export const MyAvatar = () => {
  return (
    <div className="flex flex-col gap-3 border-b-[1.5px] border-neutral-200 pb-6">
      <div className="font-bold">Avatar</div>
      <ChangeUserAvatar />
    </div>
  );
};
