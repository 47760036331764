import { urlRegex } from "@/constants/regexps";
import type { Agent } from "@/types/agent";
import { AgentTypes, StyleIconSize, StyleSetVariant, agentBuildInFunctions, StyleChatColor } from "@/types/agent";
import { ValidationSchema } from "@/types/validation/validationSchema";
import { VoiceProfileAccent, VoiceProfileGender, VoiceProfileLanguage } from "@/types/voice";
import { z } from "zod";

export const MAX_GREETING_MESSAGES = 3;

export const AgentFormPublishSchema = z.object({
  isConfigured: z.boolean(),
  isPublished: z.boolean(),
  allowedOrigins: z.array(
    z.object({
      domain: z.string(),
    })
  ),
  isAllowedOriginsEnabled: z.boolean(),
  chatStyle: z.object({
    variant: z.nativeEnum(StyleSetVariant),
    color: z.nativeEnum(StyleChatColor).or(z.string()),
    iconSize: z.nativeEnum(StyleIconSize),
  }),
  customBranding: z.object({
    isActive: z.boolean(),
    value: z.string().refine(val => !z.string().url().safeParse(val).success, { message: "URLs are not allowed" }),
    link: z.union([z.string().url("URL must start with http:// or https://"), z.literal("")]).optional(),
  }),
});

const voiceProfileSchema = z
  .object({
    gender: z.nativeEnum(VoiceProfileGender).optional(),
    language: z.nativeEnum(VoiceProfileLanguage).optional(),
    accent: z.nativeEnum(VoiceProfileAccent).optional(),
    customVoices: z.array(z.string()).optional(),
    activeCustomVoice: z.string().nullable().optional(),
  })
  .optional();

export const AgentFormValidationSchema = ({ agentType }: { agentType?: Agent["agentType"] }) => {
  const isEcommerceAgent = agentType === AgentTypes.ECOMMERCE;

  return z.object({
    name: ValidationSchema.agentNameRequired.max(50, { message: "This field must be within 50 characters." }),
    company: ValidationSchema.customerNameRequired.max(50, { message: "This field must be within 50 characters." }),
    avatar: ValidationSchema.stringRequired,
    llmModel: ValidationSchema.stringRequired,
    temperature: z.number(),
    inMemoryKnowledge: z.object({
      about: z.string(),
      instruction: z.string(),
      onboardingList: z
        .array(
          z.object({
            description: ValidationSchema.stringRequired.max(80, "This field must be within 80 characters."),
            content: ValidationSchema.stringRequired.max(800, "This field must be within 800 characters."),
            _id: z.string().optional(),
            isNew: z.boolean().optional(),
          })
        )
        .superRefine((onboardinItems, ctx) => {
          const descriptions = onboardinItems.map(item => item.description);
          const duplicates = descriptions.filter((desc, index) => descriptions.indexOf(desc) !== index);

          descriptions.forEach((desc, index) => {
            const isDuplicate = duplicates.includes(desc);

            if (isDuplicate) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Title must be unique.",
                path: [index, "description"],
              });
            }
          });
        }),
    }),
    definedActions: z.object({
      greeting: z
        .array(
          z.object({
            message: z.string(),
          })
        )
        .max(MAX_GREETING_MESSAGES, `You can add up to ${MAX_GREETING_MESSAGES} greeting messages.`),
    }),
    embedKnowledges: z.object({
      web: z.array(
        z.object({
          creator: z.string().optional(),
          embedIdList: z.array(z.string()).optional(),
          knowledgeType: z.string().optional(),
          source: z.string(),
          filename: z.string().optional(),
          description: z.string().optional(),
          content: z.string().optional(),
          parentId: z.string().optional(),
          status: z.string().optional(),
          createdAt: z.string().optional(),
          updatedAt: z.string().optional(),
          predefined: z.boolean().optional(),
          _id: z.string().optional(),
        })
      ),
      doc: z.array(
        z.union([
          z.object({
            creator: z.string().optional(),
            embedIdList: z.array(z.string()).optional(),
            knowledgeType: z.string().optional(),
            source: z.string().optional(),
            filename: z.string(),
            description: z.string().optional(),
            content: z.string().optional(),
            parentId: z.string().optional(),
            status: z.string().optional(),
            createdAt: z.string().optional(),
            updatedAt: z.string().optional(),
            predefined: z.boolean().optional(),
            _id: z.string().optional(),
            docId: z.string().optional(),
            path: z.string().optional(),
            externalSource: z.string().optional(),
            externalId: z.string().optional(),
            mimeType: z.string().optional(),
          }),
          // This object is used for new folders that come from Google Drive
          z.object({
            isNewFolder: z.boolean(),
            id: z.string(),
            name: z.string(),
            mimeType: z.string(),
            folderId: z.string().optional(),
            children: z.array(
              z.object({
                id: z.string(),
                name: z.string(),
                mimeType: z.string(),
              })
            ),
          }),
        ])
      ),
      faq: z
        .array(
          z
            .object({
              creator: z.string().optional(),
              embedIdList: z.array(z.string()).optional(),
              knowledgeType: z.string().optional(),
              source: z.string().optional(),
              filename: z.string().optional(),
              description: ValidationSchema.stringRequired.max(200, "This field must be within 200 characters."),
              content: z
                .string()
                .optional()
                .refine(
                  content => {
                    const ecommerceContentLength = 12000;
                    const normalContentLength = 1200;

                    if (isEcommerceAgent && content) {
                      return content.length <= ecommerceContentLength;
                    }

                    if (!isEcommerceAgent && content) {
                      return content.length <= normalContentLength;
                    }

                    return true;
                  },
                  {
                    message: isEcommerceAgent
                      ? "This field must be within 12000 characters."
                      : "This field must be within 1200 characters.",
                  }
                ),
              parentId: z.string().optional(),
              status: z.string().optional(),
              createdAt: z.string().optional(),
              updatedAt: z.string().optional(),
              predefined: z.boolean(),
              _id: z.string().optional(),
            })
            .refine(data => data.content || data.source, {
              message: "Answer is required.",
              path: ["content"],
            })
        )
        .superRefine((faqItems, ctx) => {
          if (isEcommerceAgent) {
            return;
          }

          const descriptions = faqItems.map(item => item.description);
          const duplicates = descriptions.filter((desc, index) => descriptions.indexOf(desc) !== index);

          descriptions.forEach((desc, index) => {
            const isDuplicate = duplicates.includes(desc);

            if (isDuplicate) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Question must be unique.",
                path: [index, "description"],
              });
            }
          });
        }),
      externalHelpers: z.array(
        z.object({
          externalSource: z.string(),
          externalId: z.string(),
          type: z.string(),
          name: z.string(),
          botId: z.string().optional(),
          createdAt: z.string().optional(),
          updatedAt: z.string().optional(),
          _id: z.string().optional(),
        })
      ),
    }),
    buildinFunctions: z.array(z.nativeEnum(agentBuildInFunctions)),
    leadGeneration: z.object({
      enabled: z.boolean(),
      params: z.array(
        z.object({
          parameterName: z.string(),
          parameterDescription: z.string(),
          required: z.boolean(),
          type: z.enum(["integer", "string"]),
          _id: z.string(),
        })
      ),
      emailVendor: z.string(),
      disableEmail: z.boolean(),
      webhook: z
        .object({
          enabled: z.boolean(),
          url: z.string(),
          header: z.array(z.string()).optional(),
          method: z.string().optional(),
        })
        .refine(
          data => {
            if (data.enabled) {
              return urlRegex.test(data.url);
            }
            return true;
          },
          {
            message: "Entered value does not match URL format",
            path: ["url"],
          }
        ),
    }),
    googleCalendar: z.object({
      enabled: z.boolean(),
      calendar: z.object({
        calendarId: z.string(),
        email: z.string(),
      }),
    }),
    externalKnowledgeIntegrations: z
      .object({
        google_drive: z.string().optional(),
      })
      .optional(),
    publish: AgentFormPublishSchema,
    loadJs: z.boolean().optional(),
    voiceProfile: voiceProfileSchema,
  });
};
