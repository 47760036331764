import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { EngagementOverviewStat, StatsInterval, StatsQueryFilters } from "@/types/stats";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";
import type { Origin } from "@/types/origin";
import type { Workspace } from "@/types/workspace";

export const engagementOverviewStats = {
  all: ["engagementOverviewStats"] as const,
  params: ({
    startDateParam,
    endDateParam,
    channels,
    interval,
    workspaceId,
  }: {
    startDateParam: string;
    endDateParam: string;
    channels: Origin[];
    interval: StatsInterval;
    workspaceId?: Workspace["_id"];
  }) => [...engagementOverviewStats.all, startDateParam, endDateParam, channels, interval, workspaceId] as const,
};

const getEngagementOverviewStats = async ({
  startDate,
  endDate,
  channels,
  interval,
  workspaceId,
}: {
  startDate: string;
  endDate: string;
  channels: Origin[];
  interval: StatsInterval;
  workspaceId?: Workspace["_id"];
}) => {
  const { data } = await restClient.get<EngagementOverviewStat[]>(
    workspaceId ? apiPaths.getWorkspaceEngagementOverviewStats(workspaceId) : apiPaths.getEngagementOverviewStats,
    {
      params: {
        startDate,
        endDate,
        channels: channels.join(","),
        interval,
        timezone: StatisticsHelpers.getTimezone(),
      },
    }
  );
  return data;
};

type Props = StatsQueryFilters & {
  interval: StatsInterval;
  enabled?: boolean;
};

export const useGetEngagementOverviewStats = (props: Props) => {
  const { currentRange, startDate, endDate, channels, interval, enabled, workspaceId } = props;

  const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
  const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

  const query = useQuery({
    queryKey: engagementOverviewStats.params({ startDateParam, endDateParam, channels, interval, workspaceId }),
    queryFn: () =>
      getEngagementOverviewStats({ startDate: startDateParam, endDate: endDateParam, channels, interval, workspaceId }),
    enabled: !!startDateParam && !!endDateParam && enabled,
    placeholderData: keepPreviousData,
  });

  return query;
};
