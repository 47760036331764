import { Button } from "@/components/ui/button";
import { useAcknowledgeNotifications } from "@/data/mutations/notifications/useAcknowledgeNotifications";
import type { AgentNotification } from "@/types/notification";
import { DialogClose, DialogTitle } from "@radix-ui/react-dialog";
import { X } from "lucide-react";

type NotificationsActionsProps = {
  unreadNotifications: AgentNotification[] | undefined;
};

export const NotificationsActions = ({ unreadNotifications }: NotificationsActionsProps) => {
  const { mutate: acknowledgeNotifications } = useAcknowledgeNotifications();

  const markAllAsRead = () => {
    const unreadNotificationsIds = unreadNotifications?.map(notification => notification._id);

    if (!unreadNotificationsIds) {
      return;
    }

    return acknowledgeNotifications({ ids: unreadNotificationsIds });
  };

  return (
    <div className="flex items-center justify-between border-b border-b-gray-200 px-6 py-4">
      <DialogTitle className="text-2xl font-bold text-neutral-750">Notifications</DialogTitle>
      <div className="flex items-center gap-8">
        {!!unreadNotifications?.length && (
          <Button variant="ghost" className="test-lg p-0 font-semibold text-primary-400" onClick={markAllAsRead}>
            Mark all as read
          </Button>
        )}
        <DialogClose asChild>
          <X className="size-5 cursor-pointer text-primary-black/90 lg:text-neutral-750" />
        </DialogClose>
      </div>
    </div>
  );
};
