import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { StrippedCommunityAgent } from "@/types/community";
import { useGetUser } from "../useGetUser";

export const communityFeaturedAgentsKeys = {
  all: ({ isGuestUser }: { isGuestUser?: boolean } = {}) => ["featuredAgents", isGuestUser] as const,
};
const getCommunityFeaturedAgents = async ({ isGuestUser }: { isGuestUser?: boolean }) => {
  const path = isGuestUser ? apiPaths.getCommunityFeaturedAgentsPublic : apiPaths.getCommunityFeaturedAgents;
  const { data } = await restClient.get<StrippedCommunityAgent[]>(path);
  return data;
};

export const useGetCommunityFeaturedAgents = ({ isGuestUser }: { isGuestUser?: boolean }) => {
  const { isLoading: isLoadingUser } = useGetUser();

  return useQuery({
    queryKey: communityFeaturedAgentsKeys.all({ isGuestUser }),
    queryFn: () => getCommunityFeaturedAgents({ isGuestUser }),
    enabled: !isLoadingUser,
  });
};
