import { useHighlightSearchedText } from "@/pages/Stats/hooks/useHighlightSearchedText";
import { useGetConversationMessages } from "@/data/queries/useGetConversationMessages";
import type { ConversationWithUsersDetails } from "@/types/conversation";
import { ChatHistoryPreview } from "@/components/ChatHistoryPreview/ChatHistoryPreview";

type Props = {
  conversationItem: {
    conversation: ConversationWithUsersDetails;
    searchMatches: number;
  };
  currentSearchValue: string;
  showSearchMatches: boolean;
  conversationUsers: {
    _id: string;
    name: string;
    avatar: string;
  }[];
};

export const ChatHistoryConversation = ({ conversationItem, currentSearchValue, conversationUsers }: Props) => {
  const {
    data: conversationData,
    isLoading,
    isPending,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetConversationMessages({
    conversationId: conversationItem.conversation._id,
  });

  useHighlightSearchedText({
    textToHighlight: currentSearchValue,
    enabled: conversationItem.searchMatches !== 0 && !isPending,
  });

  return (
    <ChatHistoryPreview
      messages={conversationData?.messages ?? []}
      isLoadingMessages={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      conversationUsers={conversationUsers}
      conversationSummary={conversationItem.conversation.summary?.text}
      isMultiAgent={!!conversationItem.conversation.bots.length && conversationItem.conversation.bots.length > 1}
    />
  );
};
