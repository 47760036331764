import { Input } from "@/components/ui/input";
import type { BillingPlanCoupon } from "@/types/subscriptions";
import type { FormEventHandler } from "react";
import { useEffect, useState } from "react";
import type { F1 } from "@/types/types";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { toast } from "react-toastify";
import { Button } from "@/components/ui/button";
import { StripePaymentCategoryWrapper } from "@/components/dialogs/SubscriptionTierDialog/components/StripePaymentSummary/components/StripePaymentCategoryWrapper";
import { domElementIds } from "@/types/dom-element-ids";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";
import { SubscriptionPeriod } from "@/types/subscriptions";
import { StripePaymentOrder } from "@/components/dialogs/SubscriptionTierDialog/components/StripePaymentSummary/components/StripePaymentOrder";
import { Icons } from "@/components/ui/icons";
import type { OnSubmitStripePlan } from "@/components/dialogs/SubscriptionTierDialog/components/StripePaymentSummary/StripePaymentSummary";
import { useGetCustomerBillingInfo } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";

type StripePaymentDetailsProps = {
  setCoupon: F1<string | undefined, void>;
  couponQuery: UseQueryResult<BillingPlanCoupon, Error>;
  onSubmit: OnSubmitStripePlan;
  isLoading: boolean;
};
export const StripePaymentDetails = ({ setCoupon, couponQuery, onSubmit, isLoading }: StripePaymentDetailsProps) => {
  const { data: plans } = useGetBillingPlans();
  const { selectedPlan, selectedSeats, defaultSeats } = useSubscriptionTierDialogContext();
  const [selectedSeatsPrice, setSelectedSeatsPrice] = useState<number>(0);

  const [couponValue, setCouponValue] = useState<string>("");
  const { data: couponData, isLoading: isLoadingCoupon } = couponQuery;
  const { data: billingData } = useGetCustomerBillingInfo();

  const addressDefaultValues = billingData?.paymentMethods[0].billing_details;
  const paymentMethod = billingData?.paymentMethods?.[0];

  useEffect(() => {
    if (!selectedPlan) {
      return;
    }

    const seatPrice =
      plans?.userLicences?.find(license =>
        license.name.includes(selectedPlan.period === SubscriptionPeriod.MONTHLY ? selectedPlan.period : "annual")
      )?.price.amount || 0;

    setSelectedSeatsPrice((selectedSeats - defaultSeats) * Number(seatPrice));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan, plans, selectedSeats]);

  if (!selectedPlan) {
    toast.error("There was an error while retreiving your plan information.");
    return null;
  }

  const planPricingInfo = selectedPlan.plan.price[selectedPlan.period];
  const planPrice = planPricingInfo.amount.toFixed(2);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    const inputValue = couponValue;
    if (inputValue.length) {
      setCoupon(inputValue);
      setCouponValue("");
    }
  };

  const handleForSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    if (!paymentMethod) {
      return;
    }

    onSubmit({
      paymentMethodId: paymentMethod.id,
      customerDetails: addressDefaultValues!,
    }) as unknown as void;
  };

  return (
    <StripePaymentCategoryWrapper title="Order Summary">
      <form onSubmit={handleSubmit} className="mt-2 flex items-center justify-between gap-2.5">
        <Input
          type="text"
          value={couponValue}
          onChange={e => setCouponValue(e.target.value)}
          placeholder="Add promotion code"
        />
        <Button type="submit" loading={isLoadingCoupon} disabled={!couponValue.length}>
          Apply
        </Button>
      </form>
      <StripePaymentOrder
        couponData={couponData}
        selectedSeatsPrice={selectedSeatsPrice}
        selectedSeats={selectedSeats}
        selectedPlan={selectedPlan}
        planPrice={planPrice}
      />
      <form id={domElementIds.STRIPE_FORM} className="flex flex-col gap-4" onSubmit={handleForSubmit}>
        <Button
          loading={isLoading}
          className="flex w-full items-center gap-2 font-bold"
          form={domElementIds.STRIPE_FORM}
          type="submit"
        >
          <Icons.Lock className="h-4 w-4" /> Pay $
          {(
            (couponData?.discountPrice === undefined
              ? Number(planPrice)
              : Number(couponData?.discountPrice.toFixed(2))) + selectedSeatsPrice
          ).toFixed(2)}{" "}
          USD now
        </Button>
      </form>
    </StripePaymentCategoryWrapper>
  );
};
