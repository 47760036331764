import type { AgentNotification } from "@/types/notification";
import { NotificationReference } from "@/types/notification";
import { calculateNotificationsTimeDifference } from "@/utils/calculateNotificationsTimeDifference";
import { Link } from "react-router-dom";
import { LinkButton } from "@/components/ui/link-button";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { Button } from "@/components/ui/button";
import { useAcceptDeclineAction } from "@/data/mutations/useAcceptDeclineAction";
import { useState } from "react";
import { useAcknowledgeNotifications } from "@/data/mutations/notifications/useAcknowledgeNotifications";

type NotificationDescriptionProps = {
  notification: AgentNotification;
};

enum ACCEPT_DECLINE_ACTIONS {
  ACCEPT = "accept",
  DECLINE = "decline",
}

export const NotificationDescription = ({
  notification: { title, subtitle, date, entityName, referenceType, url, approveAction, declineAction, read, _id },
}: NotificationDescriptionProps) => {
  const { openDialog: openSubscriptionTierDialog } = useSubscriptionTierDialogContext();
  const { mutate } = useAcceptDeclineAction();
  const { mutate: acknowledgeNotifications } = useAcknowledgeNotifications();
  const [isLoading, setIsLoading] = useState<ACCEPT_DECLINE_ACTIONS | boolean>(false);

  const notificationType =
    Object.keys(NotificationReference)[Object.values(NotificationReference)?.indexOf(referenceType)]?.toLowerCase();
  const showUpgradeLink = referenceType === NotificationReference.SUBSCRIPTION;
  const showTitle = title !== entityName;
  const showLeadLink = referenceType === NotificationReference.LEAD && !!url;

  const showAcceptDeclineButtons = !!approveAction && !!declineAction && !read;

  const acceptDeclineAction = (action: ACCEPT_DECLINE_ACTIONS, actionUrl: string | null) => {
    if (!actionUrl) {
      return;
    }

    setIsLoading(action);

    mutate(
      { actionUrl },
      { onSuccess: () => acknowledgeNotifications({ ids: [_id] }, { onSuccess: () => setIsLoading(false) }) }
    );
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-1 text-sm">
        {showTitle && <span className="font-semibold">{title}</span>}
        <span className={showTitle ? "lowercase " : "font-semibold"}>
          {subtitle}
          {showLeadLink && (
            <>
              {"  "}
              <Link to={url}>
                <LinkButton size="sm" className="text-sm text-primary-400">
                  Open Lead Details
                </LinkButton>
              </Link>
            </>
          )}
        </span>
      </div>
      <div className="flex items-center gap-2">
        <div className="text-xs text-gray-400">{calculateNotificationsTimeDifference(date)}</div>
        <div className="h-1 w-1 rounded-full bg-neutral-400" />
        <div className="text-xs capitalize text-gray-400">{notificationType}</div>
      </div>
      {showUpgradeLink && (
        <div className="pt-1">
          <Button onClick={() => openSubscriptionTierDialog()}>Upgrade</Button>
        </div>
      )}
      {showAcceptDeclineButtons && (
        <div className="flex gap-2 pt-1">
          <Button
            onClick={() => acceptDeclineAction(ACCEPT_DECLINE_ACTIONS.ACCEPT, approveAction.url)}
            loading={isLoading === ACCEPT_DECLINE_ACTIONS.ACCEPT}
            disabled={!!isLoading}
          >
            Accept
          </Button>
          <Button
            variant="secondary"
            onClick={() => acceptDeclineAction(ACCEPT_DECLINE_ACTIONS.DECLINE, declineAction.url)}
            loading={isLoading === ACCEPT_DECLINE_ACTIONS.DECLINE}
            disabled={!!isLoading}
          >
            Decline
          </Button>
        </div>
      )}
    </div>
  );
};
