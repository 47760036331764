import { Avatar } from "@/components/Avatar";
import { getIconForKey, getKeyDisplayName } from "@/pages/StatsLeads/utils/statsLeadsUtils";
import type { ILeadGen } from "@/types/stats";
import { StatsLeadsDetailsSidePanelLabel } from "./StatsLeadsDetailsSidePanelLabel";
import { StatsLeadsDetailsSidePanelValue } from "./StatsLeadsDetailsSidePanelValue";
import { format } from "date-fns";
import { useGetConversationMessages } from "@/data/queries/useGetConversationMessages";
import { ChatHistoryPreview } from "@/components/ChatHistoryPreview/ChatHistoryPreview";
import { useGetSingleConversationDetails } from "@/data/queries/useGetSingleConversationDetails";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { StatsLeadsKeysToOmit } from "@/pages/StatsLeads/constants/StatsLeadsConstants";
import { SidePanel } from "@/components/SidePanel/SidePanel";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  lead: ILeadGen | null;
};

export const StatsLeadsDetailsSidePanel = ({ isOpen, setIsOpen, lead }: Props) => {
  const name = lead?.fields.find(field => field.key === "name")?.value ?? "";

  const shouldFetch = isOpen && !!lead;

  const {
    data: conversationData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetConversationMessages({
    conversationId: lead?.conversationId ?? "",
    enabled: shouldFetch,
  });
  const { data: conversationDetails } = useGetSingleConversationDetails({
    conversationId: lead?.conversationId ?? "",
    enabled: shouldFetch,
  });

  const leadsFieldsToShow = lead?.fields.filter(field => !StatsLeadsKeysToOmit.includes(field.key)) ?? [];

  return (
    <SidePanel isOpen={isOpen} setIsOpen={setIsOpen}>
      {!!lead && (
        <div className="flex h-full flex-col gap-8">
          <div className="flex flex-wrap items-center justify-between gap-4">
            <h1 className="whitespace-nowrap text-xl font-bold text-neutral-750 @xl/main:text-2xl">{name}</h1>
            <div className="flex items-center gap-4">
              <Avatar size="custom" className="h-[20px] w-[20px]" src={lead.agent.avatar} name={lead.agent.name} />
              <p className="overflow-hidden text-ellipsis text-sm font-medium text-neutral-750">{lead.agent.name}</p>
            </div>
          </div>

          <div className="flex flex-col gap-4 overflow-y-auto pb-20">
            <div className="flex flex-col gap-2">
              <StatsLeadsDetailsSidePanelLabel iconKey="Calendar2" label="Date created" />
              <StatsLeadsDetailsSidePanelValue value={format(new Date(lead.createdAt), "dd MMM yyyy HH:mm")} />
            </div>

            {leadsFieldsToShow.map(field => {
              const icon = getIconForKey(field.key);

              return (
                <div key={field.key} className="flex flex-col gap-2">
                  <StatsLeadsDetailsSidePanelLabel iconKey={icon} label={getKeyDisplayName(field.key)} />
                  <StatsLeadsDetailsSidePanelValue value={field.value} />
                </div>
              );
            })}

            <Accordion type="single" collapsible defaultValue="onboarding">
              <AccordionItem value="onboarding">
                <AccordionTrigger>
                  <StatsLeadsDetailsSidePanelLabel iconKey="Message" label="Chat" />
                </AccordionTrigger>

                <AccordionContent className="min-h-[600px]">
                  <ChatHistoryPreview
                    messages={conversationData?.messages ?? []}
                    isLoadingMessages={isLoading}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    conversationUsers={conversationDetails?.users ?? []}
                    conversationSummary={conversationDetails?.summary?.text}
                    className="max-h-[600px]"
                    isMultiAgent={!!conversationDetails?.bots.length && conversationDetails.bots.length > 1}
                  />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      )}
    </SidePanel>
  );
};
