import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import type { FullAgent } from "@/types/agent";
import type { F1 } from "@/types/types";
import { Button } from "@/components/ui/button";
import { useEffect, useMemo, useState } from "react";
import { AgentInfo } from "@/components/dialogs/TransferOwnershipAgentDialog/AgentInfo";
import type { Workspace } from "@/types/workspace";
import { useMoveAgent } from "@/data/queries/workspace/useMoveAgent";
import { useGetMyWorkspaces } from "@/data/queries/workspace/useGetMyWorkspaces";
import { useGetUser } from "@/data/queries/useGetUser";
import { WorkspaceAgentOwnerSelect } from "@/components/dialogs/MoveAgentDialog/WorkspaceAgentOwnerSelect";

type TransferOwnershipAgentDialogProps = {
  agent: FullAgent | null;
  setAgent: F1<FullAgent | null>;
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
  workspaceId?: Workspace["_id"];
};

export const MoveAgentDialog = ({
  agent,
  setAgent,
  isDialogOpen,
  setShowDialog,
  workspaceId,
}: TransferOwnershipAgentDialogProps) => {
  const { data: user } = useGetUser();
  const [newWorkspaceId, setNewWorkspaceId] = useState<Workspace["_id"] | undefined>(workspaceId || agent?.workspace);
  const { mutate: moveAgent, isPending } = useMoveAgent({ oldWorkspaceId: workspaceId, newWorkspaceId });
  const { data: myWorkspaces } = useGetMyWorkspaces();

  useEffect(() => setNewWorkspaceId(workspaceId || agent?.workspace), [agent?.workspace, workspaceId]);

  const ownerOptions = useMemo(() => {
    if (!myWorkspaces) {
      return [];
    }

    const workspacesWhenUserIsOwner = myWorkspaces.filter(workspace => workspace.owner._id === user?._id);

    return workspacesWhenUserIsOwner.map(workspace => ({ id: workspace._id, name: workspace.name }));
  }, [myWorkspaces, user]);

  const submitMoveAgent = () => {
    if (!agent || !newWorkspaceId) {
      return;
    }
    moveAgent(
      { agentId: agent._id, workspaceId: newWorkspaceId },
      {
        onSuccess: () => {
          setShowDialog(false);
          setAgent(null);
        },
      }
    );
  };

  const toggleDialog = (open: boolean) => {
    setShowDialog(open);
    setAgent(null);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={toggleDialog}>
      <DialogContent variant="medium" className="h-[350px]">
        <DialogHeader />
        <div className="flex w-full flex-col items-start gap-6 px-8 py-4">
          <div className="flex flex-col gap-2.5">
            <div className="text-xl font-bold">Move Agent</div>
            <div className="text-sm font-medium text-neutral-400">Select a new workspace to move Agent</div>
          </div>
          <div className="grid w-full grid-cols-2 gap-4">
            <AgentInfo agent={agent} />
            <div className="flex flex-col gap-1">
              <div className="text-sm font-medium">New Workspace</div>
              <WorkspaceAgentOwnerSelect
                options={ownerOptions}
                agentWorkspaceId={workspaceId || agent?.workspace}
                value={newWorkspaceId}
                onValueChange={value => setNewWorkspaceId(value)}
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button
            loading={isPending}
            onClick={submitMoveAgent}
            disabled={(workspaceId || agent?.workspace) === newWorkspaceId}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
