import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import type { User } from "@/types/user";
import type { Workspace } from "@/types/workspace";
import { apiPaths } from "@/data/apiPaths";
import { myWorkspacesKeys } from "@/data/queries/workspace/useGetMyWorkspaces";
import { restClient } from "@/data/initAxios";

type Props = {
  id: Workspace["_id"];

  name?: Workspace["name"];
  newOwnerEmail?: User["email"];
};

const updateWorkspace = async (props: Props) => {
  const { data } = await restClient.put<Workspace>(apiPaths.updateWorkspace(props.id), props);
  return data;
};

export const useUpdateWorkspace = () => {
  const queryClient = useQueryClient();

  return useMutation<Workspace, AxiosError<{ message: string }>, Props>({
    mutationFn: updateWorkspace,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: myWorkspacesKeys.all });
    },
  });
};
