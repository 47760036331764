import { type SubscriptionTier, SubscriptionTiers } from "@/types/subscriptions";
import { Icons } from "../ui/icons";
import { cn } from "@/lib/utils";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";

const tierDataMap = {
  [SubscriptionTiers.FREE]: {
    text: "UPGRADE",
    icon: <Icons.TierUpgrade />,
    buttonClassName: "bg-tier-upgrade border-[0.5px] border-primary-400 hover:border-primary-600",
    textClassName: "text-primary-400",
  },
  [SubscriptionTiers.STARTUP]: {
    text: "STARTUP",
    icon: <Icons.TierStartup />,
    buttonClassName: "bg-tier-pro border border-white border-[0.5px] hover:border-primary-400",
    textClassName: "text-neutral-750",
  },
  [SubscriptionTiers.PRO]: {
    text: "PRO",
    icon: <Icons.TierPro />,
    buttonClassName: "bg-tier-pro border border-white border-[0.5px] hover:border-primary-400",
    textClassName: "text-neutral-750",
  },
  [SubscriptionTiers.PREMIUM]: {
    text: "PREMIUM",
    icon: <Icons.TierPremium />,
    buttonClassName: "bg-tier-pro border border-white border-[0.5px] hover:border-primary-400",
    textClassName: "text-neutral-750",
  },
  [SubscriptionTiers.SCALE]: {
    text: "SCALE",
    icon: <Icons.TierScale />,
    buttonClassName: "bg-tier-pro border border-white border-[0.5px] hover:border-primary-400",
    textClassName: "text-neutral-750",
  },
  [SubscriptionTiers.ENTERPRISE]: {
    text: "ENTERPRISE",
    icon: <Icons.TierEnterprise />,
    buttonClassName: "border border-white bg-tier-enterprise py-1 border-[0.5px] hover:border-primary-400",
    textClassName: "text-white",
  },
};

export const SubscriptionTierButton = ({
  tier,
  className,
  inNav,
}: {
  tier: SubscriptionTier;
  className?: string;
  inNav?: boolean;
}) => {
  const { openDialog: openSubscriptionTierDialog } = useSubscriptionTierDialogContext();
  const { navigationSectionOpen } = useLayoutContext();

  const isEnterpriseTier = tier === SubscriptionTiers.ENTERPRISE;

  const HtmlElement = isEnterpriseTier ? "span" : "button";

  return (
    <HtmlElement
      {...(!isEnterpriseTier && { onClick: () => openSubscriptionTierDialog() })}
      className={cn(
        "relative flex max-h-10 w-full min-w-32 items-center justify-center gap-2 rounded-full px-4 py-2",
        isEnterpriseTier && "cursor-default",
        !navigationSectionOpen && "w-[38px] min-w-[38px] p-1.5",
        tierDataMap[tier].buttonClassName,
        className
      )}
    >
      {tierDataMap[tier].icon}
      {(navigationSectionOpen || !inNav) && (
        <p className={cn("text-sm font-bold leading-6", tierDataMap[tier].textClassName)}>{tierDataMap[tier].text}</p>
      )}
    </HtmlElement>
  );
};
