import { useUpdateUser } from "@/data/mutations/useUpdateUser";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import type { User } from "@/types/user";
import { toast } from "react-toastify";
import { FormInput } from "@/components/ui/FormInput";
import { useGetUser } from "@/data/queries/useGetUser";
import { Button } from "../ui/button";

export const MyProfile = () => {
  const { user } = useGetUser();
  const { mutate } = useUpdateUser();
  const form = useForm<User>({ defaultValues: { name: user?.name } });
  const { handleSubmit, watch } = form;
  const watchedName = watch("name");
  const submitPreferredName: SubmitHandler<{ name: string }> = ({ name }) => {
    mutate({ name }, { onSuccess: () => toast.success("Preferred name updated!") });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="font-bold">My Profile</div>
      <form
        onSubmit={handleSubmit(submitPreferredName)}
        className="flex flex-row items-end justify-between border-b-[1.5px] border-neutral-200 pb-6"
      >
        <FormInput
          formObj={form}
          name="name"
          required
          placeholder="Enter prefered name"
          rules={{
            required: "Name is required",
          }}
          label="Preferred Name"
          inputClassName="my-0 w-40 md:w-52 lg:w-64"
        />

        <Button type="submit" className="my-1" disabled={watchedName == user?.name}>
          Change Name
        </Button>
      </form>
    </div>
  );
};
