import * as React from "react";
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { cn } from "@/lib/utils";

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <ToggleGroupPrimitive.Root
      className={cn("inline-flex gap-0.5 rounded-3xl border border-neutral-200 bg-neutral-50 p-1", className)}
      {...props}
      ref={ref}
    />
  );
});
ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName;

const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={cn(
        "whitespace-nowrap rounded-2xl px-2.5 py-1 text-center text-sm font-medium text-neutral-400 hover:bg-primary-100 hover:text-primary-400 hover:disabled:bg-transparent hover:disabled:text-neutral-400 [&[data-state=on]]:bg-primary-100 [&[data-state=on]]:font-bold [&[data-state=on]]:text-primary-400",
        className
      )}
      {...props}
    />
  );
});
ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName;

export { ToggleGroup, ToggleGroupItem };
