import StatsDateRangePicker from "@/components/StatsDateRangePicker/StatsDateRangePicker";
import { useGetMyAgentsUsersActivities } from "@/data/queries/stats/useGetMyAgentsUsersActivities";
import type { DateRangeType } from "@/types/datepicker";
import { DateRangeTypes } from "@/types/datepicker";
import { useState } from "react";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "../../ui/table";
import { Avatar } from "@/components/Avatar";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import { UserChatHistoryDialog } from "./components/UserChatHistoryDialog";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { cn } from "@/lib/utils";
import { IconButton } from "@/components/ui/icon-button";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";

export const AccountActivitiesUsersStats = () => {
  const { isDefaultWorkspace, activeWorkspaceId } = useStatsContext();
  const [currentRange, setCurrentRange] = useState<DateRangeType>(DateRangeTypes.Last7Days);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const { data: usersActivitiesData, isLoading: isLoadingUsersActivities } = useGetMyAgentsUsersActivities({
    currentRange,
    startDate,
    endDate,
    workspaceId: isDefaultWorkspace ? undefined : activeWorkspaceId,
  });

  const [historyDialog, setHistoryDialog] = useState<{
    userId: string;
    onlyDownvotedMessages: boolean;
  }>({
    userId: "",
    onlyDownvotedMessages: false,
  });

  const [visibleContentColumnsIndex, setVisibleContentColumnsIndex] = useState<number>(0);

  const getIsColumnVisible = (index: number) => visibleContentColumnsIndex === index;

  const handleChangeVisibleColumn = (direction: "prev" | "next") => {
    if (direction === "prev") {
      if (visibleContentColumnsIndex === 0) {
        return;
      }

      setVisibleContentColumnsIndex(prev => prev - 1);
    }

    if (direction === "next") {
      if (visibleContentColumnsIndex === 1) {
        return;
      }

      setVisibleContentColumnsIndex(prev => prev + 1);
    }
  };

  return (
    <>
      <div className="@container/main">
        <div className="flex items-center justify-between">
          <div className="text-lg font-bold">Account Activities</div>
          <div>
            <StatsDateRangePicker
              selectedDateRangeType={currentRange}
              onSelectedDateRangeTypeChange={setCurrentRange}
              startDate={startDate}
              onStartDateChange={setStartDate}
              endDate={endDate}
              onEndDateChange={setEndDate}
            />
          </div>
        </div>

        <div>
          {isLoadingUsersActivities ? (
            <div className="my-10 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <Table>
              <TableHeader>
                <TableCell>
                  <p className="my-1 w-fit border-b border-primary-400 text-base font-bold text-neutral-750">User</p>
                </TableCell>
                <TableCell
                  className={cn("hidden @lg/main:table-cell", {
                    "table-cell": getIsColumnVisible(0),
                  })}
                >
                  <p className="my-1 w-fit border-b border-primary-400 text-base font-bold text-neutral-750">
                    Messages
                  </p>
                </TableCell>
                <TableCell
                  className={cn("hidden @lg/main:table-cell", {
                    "table-cell": getIsColumnVisible(1),
                  })}
                >
                  <p className="my-1 w-fit border-b border-primary-400 text-base font-bold text-neutral-750">
                    Downvotes
                  </p>
                </TableCell>
                <TableCell className="hidden @lg/main:table-cell">
                  <p className="my-1 w-fit border-b border-primary-400 text-base font-bold text-neutral-750">Action</p>
                </TableCell>
                <TableCell className="flex items-center gap-3 @lg/main:hidden">
                  <IconButton
                    variant="primary"
                    variantColor="grey"
                    size="small"
                    roundedType="rounded"
                    icon={<Icons.ChevronRight className="size-4 rotate-180 text-black" />}
                    onClick={() => handleChangeVisibleColumn("prev")}
                  >
                    <span className="sr-only">Previous column</span>
                  </IconButton>

                  <IconButton
                    variant="primary"
                    variantColor="grey"
                    size="small"
                    roundedType="rounded"
                    icon={<Icons.ChevronRight className="size-4 text-black" />}
                    onClick={() => handleChangeVisibleColumn("next")}
                  >
                    <span className="sr-only">Next column</span>
                  </IconButton>
                </TableCell>
              </TableHeader>
              <TableBody>
                {usersActivitiesData?.map(userActivity => {
                  return (
                    <TableRow key={userActivity.user._id}>
                      <TableCell className="flex items-center gap-2">
                        <Avatar src={userActivity.user.avatar} size="custom" className="h-5 w-5" />
                        <p className="text-xs font-medium text-neutral-750">{userActivity.user.name}</p>
                      </TableCell>
                      <TableCell
                        className={cn("hidden text-xs font-medium text-neutral-400 @lg/main:table-cell", {
                          "table-cell": getIsColumnVisible(0),
                        })}
                      >
                        {userActivity.messages}
                      </TableCell>
                      <TableCell
                        className={cn("hidden text-xs font-medium text-neutral-400 @lg/main:table-cell", {
                          "table-cell": getIsColumnVisible(1),
                        })}
                      >
                        {userActivity.downvoted}
                      </TableCell>

                      <TableCell>
                        <DropdownMenu modal={false}>
                          <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
                            <IconButton
                              variant="tertiary"
                              size="tiny"
                              roundedType="rounded"
                              icon={<Icons.Dots2 className="h-4 w-4 rotate-90 text-black" />}
                            />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="start">
                            <DropdownMenuItem
                              className="flex items-center gap-1.5 text-xs"
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setHistoryDialog({
                                  userId: userActivity.user._id,
                                  onlyDownvotedMessages: false,
                                });
                              }}
                            >
                              See conversations
                            </DropdownMenuItem>

                            <DropdownMenuItem
                              className="flex items-center gap-1.5 text-xs"
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setHistoryDialog({
                                  userId: userActivity.user._id,
                                  onlyDownvotedMessages: true,
                                });
                              }}
                            >
                              See downvoted messages
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </div>
      </div>

      <UserChatHistoryDialog
        isOpen={!!historyDialog.userId}
        onClose={() =>
          setHistoryDialog({
            userId: "",
            onlyDownvotedMessages: false,
          })
        }
        userId={historyDialog.userId}
        userName={
          usersActivitiesData?.find(userActivity => userActivity.user._id === historyDialog.userId)?.user.name ?? ""
        }
        initialCurrentRange={currentRange}
        isOnlyDownvotedMessages={historyDialog.onlyDownvotedMessages}
        onIsOnlyDownvotedMessagesChange={isOnlyDownvotedMessages => {
          setHistoryDialog(prev => ({
            ...prev,
            onlyDownvotedMessages: isOnlyDownvotedMessages,
          }));
        }}
      />
    </>
  );
};
