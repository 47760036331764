import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { myWorkspacesKeys } from "@/data/queries/workspace/useGetMyWorkspaces";
import type { Workspace } from "@/types/workspace";

type Payload = {
  name: string;
};

const createWorkspace = async (payload: Payload) => {
  const { data } = await restClient.post<Workspace>(apiPaths.createWorkspace, payload);
  return data;
};

export const useCreateWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation<Workspace, AxiosError<{ message: string }>, Payload>({
    mutationFn: createWorkspace,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: myWorkspacesKeys.all });
    },
  });
};
