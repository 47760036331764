import { WorkspaceMyAgents } from "@/pages/Workspace/components/WorkspaceMyAgents";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { FetchNextPageTrigger } from "@/components/Chat/components/FetchNextPageTrigger";
import { useGetAgentsWithConfig } from "@/data/queries/useGetAgentsWithConfig";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { Button } from "@/components/ui/button";
import { ROUTES } from "@/constants/routes";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import { useNavigate } from "react-router";
import { useEffect, useMemo, useState } from "react";
import MultiAgentsChatDialog from "@/components/dialogs/MultiAgentsChatDialog/MultiAgentsChatDialog";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import type { Agent, FullAgent } from "@/types/agent";
import { Input } from "@/components/ui/input";
import { useDebounceValue } from "usehooks-ts";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import type { SORT_BY_VALUES } from "@/pages/Workspace/components/SortAgentsByNameDropdown";
import { SortAgentsByNameDropdown } from "@/pages/Workspace/components/SortAgentsByNameDropdown";
import { SpaceHeader } from "@/pages/Workspace/components/CustomerSpace/SpaceHeader";
import { filterAndSortAgents } from "@/pages/Workspace/components/utils";
import { DEBOUNCE_TIME_MS } from "@/pages/Workspace/components/constants";
import { useGetMyWorkspaces } from "@/data/queries/workspace/useGetMyWorkspaces";
import { useGetUser } from "@/data/queries/useGetUser";

export const MyAgentsSpace = () => {
  const navigate = useNavigate();
  const {
    data: myAgents,
    isPending: isMyAgentsWithConfigPending,
    isSuccess: isMyAgentsWithConfigSuccess,
    fetchNextPage: myAgentsFetchNextPage,
    hasNextPage: myAgentsHasNextPage,
    isFetchingNextPage: isMyAgentsFetchingNextPage,
  } = useGetAgentsWithConfig();
  const { isMobile } = useMediaQueriesContext();
  const { data: myWorkspaces } = useGetMyWorkspaces();
  const { user } = useGetUser();

  const isScreenSm = !useTailwindMediaQuery("sm");
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue] = useDebounceValue(searchValue, DEBOUNCE_TIME_MS);
  const [filteredAgents, setFilteredAgents] = useState<FullAgent[]>([]);
  const [sortBy, setSortBy] = useState<SORT_BY_VALUES | null>(null);

  const [isMultiAgentsChatDialogOpen, setIsMultiAgentsChatDialogOpen] = useState(false);
  const isOwnerInWorkspace = useMemo(
    () => !!myWorkspaces?.filter(workspace => workspace.owner._id === user?._id).length,
    [myWorkspaces, user]
  );

  const handleCreateMultiAgentsChat = ({ agentIds, manager }: { agentIds: string[]; manager: Agent["_id"] }) => {
    navigate(getNewConversationRoute(agentIds, manager));
  };

  useEffect(() => {
    setFilteredAgents(filterAndSortAgents({ debouncedSearchValue, sortBy, myAgents }));
  }, [debouncedSearchValue, myAgents, sortBy]);

  return (
    <div className="bg-[#FCFAFF]">
      <h4 className="sticky top-0 z-10 flex w-full items-center justify-between bg-fade-out-vertical-primary px-6 py-4 text-left font-bold">
        <SpaceHeader
          agents={myAgents?.length ?? 0}
          embeddedAgents={myAgents?.filter(agent => agent.publish.isPublished).length || 0}
        />

        <div className="flex flex-row gap-4">
          <SortAgentsByNameDropdown sortBy={sortBy} setSortBy={setSortBy} />
          {!isMobile && (
            <Input
              placeholder="Search Agents"
              value={searchValue}
              inputClassName="placeholder:text-slate-400 !pr-9 !sm:pr-9 h-10"
              onChange={e => setSearchValue(e.target.value)}
              handleClear={() => setSearchValue("")}
            />
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              {isScreenSm ? (
                <IconButton icon={<Icons.Plus />} size="medium" />
              ) : (
                <Button prefixIcon={<Icons.Plus />}>New</Button>
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="flex flex-col gap-1 p-1.5">
              <DropdownMenuItem
                className="flex items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
                onClick={() => navigate(ROUTES.createAgent("none"))}
              >
                <Icons.Bot /> Create Agent
              </DropdownMenuItem>

              <DropdownMenuItem
                className="flex items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
                onClick={() => setIsMultiAgentsChatDialogOpen(true)}
              >
                <Icons.AddGroup /> Multi Agents Chat
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </h4>

      <WorkspaceMyAgents
        workspaceId={undefined}
        agents={filteredAgents ?? []}
        isLoading={isMyAgentsWithConfigPending}
        isSuccess={isMyAgentsWithConfigSuccess}
        canEdit
        canTransferOwnership
        canMoveAgent={isOwnerInWorkspace}
      />

      <MultiAgentsChatDialog
        isOpen={isMultiAgentsChatDialogOpen}
        setIsOpen={setIsMultiAgentsChatDialogOpen}
        onSubmit={handleCreateMultiAgentsChat}
        headerText="Select Agents to Create Group Chat"
      />

      {isMyAgentsFetchingNextPage && <LoadingSpinner className="my-5 flex items-center justify-center" />}

      {myAgentsHasNextPage && <FetchNextPageTrigger fetchNextPage={myAgentsFetchNextPage} />}
    </div>
  );
};
