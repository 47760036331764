import type { ChatAgent } from "@/types/conversation";
import { useSetConversationQueryKnowledge } from "./useSetConversationQueryKnowledge";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useMemo } from "react";
import type { EmbedKnowledges } from "@/types/agent";
import { sortWebByDomainsToSeparateArrays } from "@/utils/getDomains";

export const useHandleChatAgentKnowledge = (agent: ChatAgent) => {
  const { setKnowledge } = useSetConversationQueryKnowledge();
  const { conversationDetails } = useChatContext();
  const isNewConversation = !conversationDetails;

  const filteredEmbedKnowledges = useMemo(() => {
    return {
      web: agent.embedKnowledges.web,
      doc: agent.embedKnowledges.doc.filter(
        (item): item is EmbedKnowledges => !("isNewFolder" in item)
      ) as EmbedKnowledges[],
      faq: agent.embedKnowledges.faq,
    };
  }, [agent.embedKnowledges.doc, agent.embedKnowledges.faq, agent.embedKnowledges.web]);

  const selectedChatKnowledge = isNewConversation ? [] : conversationDetails.pins;

  const mergedKnowledges = [
    ...filteredEmbedKnowledges.doc,
    ...filteredEmbedKnowledges.web,
    ...filteredEmbedKnowledges.faq,
  ];
  const knowledgesCount = mergedKnowledges.length;
  const sortedWebData = filteredEmbedKnowledges.web.length
    ? sortWebByDomainsToSeparateArrays(filteredEmbedKnowledges.web)
    : [];

  const handleClearPins = () => {
    setKnowledge([]);
  };

  const handleSimplePinChange = (checked: boolean, idOrIds: string | string[]) => {
    setKnowledge(knowledgeArray => {
      const ids = Array.isArray(idOrIds) ? idOrIds : [idOrIds];
      if (checked) {
        ids.forEach(id => {
          if (!knowledgeArray.includes(id)) {
            knowledgeArray.push(id);
          }
        });
      } else {
        knowledgeArray = knowledgeArray.filter(id => !ids.includes(id));
      }

      return knowledgeArray.length === mergedKnowledges.length ? [] : knowledgeArray;
    });
  };

  return {
    handleClearPins,
    handleSimplePinChange,
    selectedChatKnowledge,
    knowledgesCount,
    sortedWebData,
    filteredEmbedKnowledges,
  };
};
