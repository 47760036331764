import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { Workspace } from "@/types/workspace";
import type { User } from "@/types/user";
import { multipleWorkspaceUsersKeys } from "@/data/queries/workspace/useGetMultipleWorkspaceUsers";

type Payload = {
  role: string;
  userId: User["_id"];
  id: Workspace["_id"];
};

type Response = {
  message: string;
};
const changeMultipleWorkspaceUserRole = async (payload: Payload) => {
  const { data } = await restClient.put<Response>(apiPaths.changeMultipleWorkspaceUserRole(payload.id), {
    userId: payload.userId,
    role: payload.role,
  });
  return data;
};

export const useChangeMultipleWorkspaceUserRole = (id: Workspace["_id"]) => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<{ message: string }>, Payload>({
    mutationFn: changeMultipleWorkspaceUserRole,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: multipleWorkspaceUsersKeys.id(id) });
    },
  });
};
