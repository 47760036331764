import { Avatar } from "@/components/Avatar";
import { cn } from "@/lib/utils";
import AgentStatusDot from "../AgentStatus/AgentStatusDot";
import type { Agent } from "@/types/agent";

const GroupConversationAvatar = ({
  avatars,
  className,
  onClick,
  agentState,
}: {
  avatars: string[] | undefined;
  className?: string;
  onClick?: () => void;
  agentState?: Agent["state"] | null;
}) => {
  if (!avatars || avatars.length === 0) {
    return null;
  }

  if (avatars.length === 1) {
    return (
      <div className={cn("relative", className)} onClick={onClick}>
        <Avatar src={avatars[0]} size="custom" className="size-[38px]" />
        {agentState && <AgentStatusDot agentState={agentState} />}
      </div>
    );
  }

  return (
    <div className={cn("relative size-[38px] min-w-[38px]", className)} onClick={onClick}>
      {agentState && <AgentStatusDot agentState={agentState} />}
      <Avatar
        src={avatars[0]}
        size="custom"
        className="absolute left-0 top-0 h-[30px] w-[30px] border border-white bg-white"
      />
      <Avatar
        src={avatars[1]}
        size="custom"
        className="absolute bottom-0 right-0 h-[30px] w-[30px] border border-white bg-white"
      />
    </div>
  );
};

export default GroupConversationAvatar;
