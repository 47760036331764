import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { Agent } from "@/types/agent";
import { agentsKeys } from "@/data/queries/useGetAgents";
import { chatHistoryWithAgentsKeys } from "../queries/useGetChatHistoryWithAgents";
import { agentsWithConfigKeys } from "../queries/useGetAgentsWithConfig";
import { workspaceAgentsKeys } from "../queries/workspace/useGetWorkspaceAgents";
import { userAgentsKeys } from "../queries/useGetUserAgents";
import type { Workspace } from "@/types/workspace";
import { multipleWorkspaceAgentsKeys } from "@/data/queries/workspace/useGetMultipleWorkspaceAgents";

export const useDeleteAgent = (workspaceId?: Workspace["_id"]) => {
  const queryClient = useQueryClient();

  const deleteAgent = async (agentId: Agent["_id"]) => {
    const { data } = await restClient.delete<string>(apiPaths.deleteAgent(agentId));
    return data;
  };

  const mutation = useMutation<Agent["_id"], AxiosError<{ message: string }>, Agent["_id"]>({
    mutationFn: deleteAgent,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: agentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: agentsWithConfigKeys.all });
      void queryClient.invalidateQueries({ queryKey: chatHistoryWithAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: workspaceAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: userAgentsKeys.all });
      workspaceId && void queryClient.invalidateQueries({ queryKey: multipleWorkspaceAgentsKeys.id(workspaceId) });
    },
  });

  return mutation;
};
