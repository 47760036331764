import type { GenImage } from "@/types/trace";
import { Icons } from "@/components/ui/icons";
import { VideoPlayer } from "./VideoPlayer";
import { Button } from "./button";

type ImageProps = {
  genImage: GenImage;
  labelClassName?: string;
};

export const GeneratedImage = ({ genImage, labelClassName }: ImageProps) => {
  if (!genImage.status || genImage.status == null || genImage.status == "processing") {
    return null;
  }

  if (genImage.status == "failed" || (!genImage.avif && !genImage.mp4 && !genImage.prompt)) {
    return (
      <div className="my-2 flex max-h-[54px] min-h-[54px] max-w-[87%] shrink items-center gap-[6px] whitespace-pre-wrap break-words rounded-cloud border border-solid border-white bg-cloud-light p-4">
        <div>
          <Icons.Error />
        </div>
        <div className="text-xs font-medium uppercase text-primary-black ">
          Sorry! Generation failed, please try again or contact AgentX Support for help!
        </div>
      </div>
    );
  }

  if (genImage.status == "success") {
    if (genImage.mp4) {
      return (
        <>
          <span className={labelClassName}>Here is your video</span>
          <VideoPlayer src={genImage?.mp4} />
          <div className="flex items-start rounded-md border  border-gray-300 bg-primary-50 px-4 pb-3 pt-2">
            <span className="max-w-full flex-1 overflow-hidden text-xs font-medium leading-normal text-gray-600">
              {genImage.prompt}
            </span>
          </div>
        </>
      );
    }

    if (genImage.avif) {
      return (
        <>
          <div>
            <span className={labelClassName}>Here is your image</span>
          </div>
          <div className="group relative inline-block w-fit">
            <img
              src={genImage?.svg || genImage?.avif}
              className="mb-4 w-full max-w-[450px] rounded-md object-contain"
            />
            <a
              href={genImage?.svg || genImage.png || genImage.avif}
              download
              className="absolute right-0 top-0 m-2 h-[2em] w-[2em] rounded opacity-0 transition-opacity duration-200 group-hover:opacity-100"
            >
              <Button className="h-full w-full p-0">
                <Icons.Download />
              </Button>
            </a>
          </div>
          <div className="flex items-start rounded-md border border-gray-300 bg-primary-50 px-4 pb-3 pt-2">
            <span className="max-w-full flex-1 overflow-hidden text-xs font-medium leading-normal text-gray-600">
              {genImage.prompt}
            </span>
          </div>
        </>
      );
    }
  }
};
