import { DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import StatsDateRangePicker from "@/components/StatsDateRangePicker/StatsDateRangePicker";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ChatHistoryDialogFoundBadge } from "./ChatHistoryDialogFoundBadge";
import type { DateRangeType } from "@/types/datepicker";
import type { MessagesNumRange } from "../constants/chatHistory";
import { messagesNumRanges } from "../constants/chatHistory";
import { useRef } from "react";
import { useAdaptiveScrollbar } from "@/hooks/useAdaptiveScrollbar";

interface Props {
  renderHeader?: React.ReactNode;
  children: React.ReactNode;
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  showSearchMatches: boolean;
  totalSearchMatches: number;
  debouncedSearchValue: string;
  currentRange: DateRangeType;
  onChangeCurrentRange: (value: DateRangeType) => void;
  startDate: Date | null;
  onStartDateChange: (value: Date | null) => void;
  endDate: Date | null;
  onEndDateChange: (value: Date | null) => void;
  selectedMessagesNumRange: MessagesNumRange;
  onSelectedMessagesRangeChange: (value: MessagesNumRange) => void;
  isOnlyDownvotedMessages: boolean;
  onIsOnlyDownvotedMessagesChange: (value: boolean) => void;
  isCustomMessagesRange: boolean;
  renderFooter?: () => React.ReactNode;
  renderDownloadSelect?: () => React.ReactNode;
}

export const ChatHistoryDialogContentSmall = ({
  renderHeader = "Conversations",
  children,
  searchValue,
  onSearchValueChange,
  showSearchMatches,
  totalSearchMatches,
  debouncedSearchValue,
  currentRange,
  onChangeCurrentRange,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  selectedMessagesNumRange,
  onSelectedMessagesRangeChange,
  isOnlyDownvotedMessages,
  onIsOnlyDownvotedMessagesChange,
  renderFooter,
  renderDownloadSelect,
}: Props) => {
  const contentContainerRef = useRef<HTMLDivElement>(null);
  useAdaptiveScrollbar(contentContainerRef, 10);

  return (
    <>
      <DialogHeader className="font-bold text-primary-black">{renderHeader}</DialogHeader>
      <div className="flex h-full flex-col gap-6 px-5 py-4 pb-20 lg:flex-row lg:px-8">
        <div className="flex min-w-52 flex-col lg:max-w-52">
          <div className="flex items-start">
            <div className="relative w-full">
              <Input
                value={searchValue}
                onChange={e => onSearchValueChange(e.target.value)}
                placeholder="Search keywords or name"
                inputClassName="placeholder:text-slate-400 !pr-9 !sm:pr-9 h-10"
                handleClear={() => onSearchValueChange("")}
              />
              {showSearchMatches && (
                <ChatHistoryDialogFoundBadge
                  foundValue={totalSearchMatches}
                  searchValue={debouncedSearchValue}
                  className="mt-1"
                />
              )}
            </div>
            {renderDownloadSelect?.()}
          </div>
          <div className="mt-4">
            <p className="mb-2 text-left text-sm font-medium text-neutral-400">Filter by</p>
            <StatsDateRangePicker
              selectedDateRangeType={currentRange}
              onSelectedDateRangeTypeChange={onChangeCurrentRange}
              startDate={startDate}
              onStartDateChange={onStartDateChange}
              endDate={endDate}
              onEndDateChange={onEndDateChange}
              align="start"
            />
          </div>

          <div className="my-4">
            <div className="mb-2 flex items-center gap-1">
              <p className="text-left text-sm font-medium text-neutral-400">Number of messages</p>
            </div>
            <Select
              onValueChange={val => onSelectedMessagesRangeChange(val as MessagesNumRange)}
              value={selectedMessagesNumRange}
            >
              <SelectTrigger id="numOfMessages" className="w-full">
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent>
                {messagesNumRanges?.map(rangeType => (
                  <SelectItem key={rangeType} value={rangeType}>
                    {rangeType}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="my-4">
            <Checkbox
              id="downvotedMessages"
              label="Show only chats with downvoted messages"
              checked={isOnlyDownvotedMessages}
              onCheckedChange={onIsOnlyDownvotedMessagesChange}
            />
          </div>
        </div>

        <div className="flex h-full max-h-full flex-1 grow flex-col gap-2 overflow-y-scroll" ref={contentContainerRef}>
          {children}
        </div>
      </div>
      {renderFooter && <DialogFooter>{renderFooter()}</DialogFooter>}
    </>
  );
};
