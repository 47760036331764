import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { workspaceAgentsKeys } from "@/data/queries/workspace/useGetWorkspaceAgents";
import { agentsWithConfigKeys } from "@/data/queries/useGetAgentsWithConfig";
import { toast } from "react-toastify";

type Payload = {
  agentId: string;
  userEmail: string;
};

type Response = {
  message: string;
};
const transferAgentOwnership = async (payload: Payload) => {
  const { data } = await restClient.post<Response>(apiPaths.transferAgentOwnership, payload);
  return data;
};

export const useTransferAgentOwnership = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<{ message: string }>, Payload>({
    mutationFn: transferAgentOwnership,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: workspaceAgentsKeys.all });
      await queryClient.invalidateQueries({ queryKey: agentsWithConfigKeys.all });
      toast.success("Transfer agent ownership started successfully.");
    },
  });
};
