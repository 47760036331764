import { type OnShareMutationCallback, ShareMultiEmails } from "@/components/ShareMultiEmails";
import type { TeamStepChildrenProps } from "./Team";
import { Icons } from "@/components/ui/icons";
import { IconButton } from "@/components/ui/icon-button";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { useInviteUserToMultipleWorkspace } from "@/data/mutations/workspace/useInviteUsersToMultipleWorkspace";
import { WorkspaceSectionTabs } from "@/components/Profiles/WorkspaceSection/types";

export const InviteTeamMember = ({ setActiveStep }: TeamStepChildrenProps) => {
  const { navigationProfileOpen, setNavigationProfileOpen } = useNavigationProfileContext();
  const { mutate: inviteUsersToMultipleWorkspace, isPending: isInvitationPending } = useInviteUserToMultipleWorkspace(
    navigationProfileOpen.section!
  );

  const handleMemberInvitation: OnShareMutationCallback = ({ emails, onSuccessCallback }) => {
    inviteUsersToMultipleWorkspace(
      { emails, id: navigationProfileOpen.section! },
      {
        onSuccess: () => {
          onSuccessCallback();
          setNavigationProfileOpen({
            open: true,
            section: navigationProfileOpen.section,
            mode: "workspace",
            activeStep: "teamDashboard",
            activeTab: WorkspaceSectionTabs.MEMBERS,
          });
        },
      }
    );
  };

  return (
    <div className="flex flex-col gap-3 pt-4">
      <div className="flex items-center">
        <IconButton
          variant="tertiary"
          icon={<Icons.ChevronRight className="size-5 rotate-180" />}
          onClick={() => setActiveStep("teamDashboard")}
        >
          <span className="sr-only">Back to Team dashboard</span>
        </IconButton>
        <span className="text-lg font-bold">Invite members</span>
      </div>
      <p className="text-sm text-neutral-400">
        This workspace is private, only select members and roles can use this workspace.
      </p>
      <ShareMultiEmails label="Emails" onShare={handleMemberInvitation} isMutationPending={isInvitationPending} />
    </div>
  );
};
