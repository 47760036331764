import { useMemo } from "react";
import { Button } from "@/components/ui/button";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { cn } from "@/lib/utils";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  maxElements?: number;
}

export const Pagination = ({ totalPages, currentPage, onPageChange, maxElements = 7 }: PaginationProps) => {
  const { isMobile } = useMediaQueriesContext();

  const { showPagination, items, start, end } = useMemo(() => {
    if (totalPages <= 1) {
      return {
        showPagination: false,
        items: [] as (number | "LJUMP" | "RJUMP")[],
        start: 0,
        end: 0,
      };
    }

    const safeMax = Math.max(3, Math.min(maxElements, 20));

    if (totalPages <= safeMax) {
      const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      return {
        showPagination: true,
        items: pages,
        start: 0,
        end: 0,
      };
    }

    const maxBlockSize = safeMax - 2;
    let blockSize = maxBlockSize;
    let wantLeftJump = false;
    let wantRightJump = false;

    const half = Math.floor(blockSize / 2);
    let s = currentPage - half;
    let e = s + blockSize - 1;

    if (s < 2) {
      s = 2;
      e = s + blockSize - 1;
    }
    if (e > totalPages - 1) {
      e = totalPages - 1;
      s = e - blockSize + 1;
    }

    if (s > 2) {
      wantLeftJump = true;
    }
    if (e < totalPages - 1) {
      wantRightJump = true;
    }

    if (wantLeftJump) {
      blockSize--;
    }
    if (wantRightJump) {
      blockSize--;
    }

    if (blockSize < 0) {
      blockSize = 0;
    }
    const blockHalf = Math.floor(blockSize / 2);
    s = currentPage - blockHalf;
    e = s + blockSize - 1;

    if (s < 2) {
      s = 2;
      e = s + blockSize - 1;
    }
    if (e > totalPages - 1) {
      e = totalPages - 1;
      s = e - blockSize + 1;
    }

    const items: (number | "LJUMP" | "RJUMP")[] = [];
    items.push(1);

    if (wantLeftJump && s > 2) {
      items.push("LJUMP");
    }

    for (let i = s; i <= e; i++) {
      items.push(i);
    }

    if (wantRightJump && e < totalPages - 1) {
      items.push("RJUMP");
    }

    items.push(totalPages);

    return {
      showPagination: true,
      items,
      start: s,
      end: e,
    };
  }, [totalPages, currentPage, maxElements]);

  if (!showPagination) {
    return null;
  }

  return (
    <div className="flex items-center gap-1">
      {items.map((item, idx) => {
        if (item === "LJUMP") {
          const leftJumpTarget = start - 1;
          return renderPageButton(leftJumpTarget, false, onPageChange, isMobile, `ljump-${idx}`, "...");
        }
        if (item === "RJUMP") {
          const rightJumpTarget = end + 1;
          return renderPageButton(rightJumpTarget, false, onPageChange, isMobile, `rjump-${idx}`, "...");
        }
        const pageNum = item;
        return renderPageButton(pageNum, pageNum === currentPage, onPageChange, isMobile);
      })}
    </div>
  );
};

function renderPageButton(
  page: number,
  isActive: boolean,
  onClick: (page: number) => void,
  isMobile: boolean,
  key?: string,
  label?: string
) {
  return (
    <Button
      key={key ?? `page-${page}`}
      variant={isActive ? "secondary" : "ghost"}
      size={isMobile ? "tiny" : "small"}
      className={cn(isMobile ? "w-3 px-3" : "w-4")}
      onClick={() => onClick(page)}
    >
      {label ?? page}
    </Button>
  );
}
