import { calculateNotificationsTimeDifference } from "@/utils/calculateNotificationsTimeDifference";
import type { GroupedNotification } from "@/components/Notifications/utils/groupAndSortNotifications";
import { NotificationReference } from "@/types/notification";

export type NotificationGroupedDescriptionProps = {
  groupedNotification: GroupedNotification;
};

export const NotificationGroupedDescription = ({ groupedNotification }: NotificationGroupedDescriptionProps) => {
  const generateMainTitle = () => {
    const visibleNames = groupedNotification.notifications
      .map((notification, index) => (index <= 2 ? notification.title : undefined))
      .filter(title => title)
      .join(", ");

    const groupedNotificationsQuantity = groupedNotification.notifications.length;

    if (groupedNotificationsQuantity > 2) {
      return `${visibleNames} and ${groupedNotificationsQuantity - 2} others`;
    }

    return visibleNames;
  };

  const notificationType =
    Object.keys(NotificationReference)[
      Object.values(NotificationReference).indexOf(groupedNotification.notifications[0].referenceType)
    ].toLowerCase();

  return (
    <div className="flex flex-col gap-2">
      <div className="gap-1 break-words text-sm">
        <strong>{generateMainTitle()}</strong>{" "}
        <span className="lowercase">{groupedNotification.notifications[0].subtitle}</span>{" "}
        <strong>{groupedNotification.notifications[0].entityName}</strong>
      </div>
      <div className="flex items-center gap-2">
        <div className="text-xs text-gray-400">
          {calculateNotificationsTimeDifference(groupedNotification.lastUpdateTime)}
        </div>
        <div className="h-1 w-1 rounded-full bg-neutral-400 " />
        <div className="text-xs capitalize text-gray-400">{notificationType}</div>
      </div>
    </div>
  );
};
