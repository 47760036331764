import { Icons } from "@/components/ui/icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import type { Dispatch, SetStateAction } from "react";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";
type TeamDashboardAdminActionsProps = {
  setActiveStep: Dispatch<SetStateAction<"teamDashboard" | "inviteTeamMember">>;
  canAddMembers: boolean;
};

export const TeamDashboardAdminActions = ({ setActiveStep, canAddMembers }: TeamDashboardAdminActionsProps) => {
  const { data: workspaceDetails } = useGetWorkspaceDetails();

  const isWorkspaceAdmin = workspaceDetails?.isAdmin;

  /* if (!isWorkspaceAdmin) {
    return null;
  }*/

  return (
    <div className="flex items-center gap-3">
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            className={`gap-2 ${!canAddMembers ? "hover:bg-red-500 hover:text-white" : ""}`}
            onClick={() => canAddMembers && setActiveStep("inviteTeamMember")}
          >
            <Icons.Plus className={`size-4 ${!canAddMembers ? "hover:text-white" : ""}`} />
            <span className="sr-only sm:not-sr-only">Invite member</span>
          </Button>
        </TooltipTrigger>
        {!canAddMembers && (
          <TooltipContent>
            <span>To invite more users, please purchase additional seats</span>
          </TooltipContent>
        )}
      </Tooltip>
      {isWorkspaceAdmin && workspaceDetails?.customer?.administrators?.length !== 1 && (
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
            <IconButton
              variant="tertiary"
              size="medium"
              roundedType="rounded"
              icon={<Icons.Dots2 className="rotate-90 text-black" />}
              onClick={e => e.stopPropagation()}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuItem
              className="flex items-center gap-2 text-xs"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {/*<LeaveWorkspaceMemberDialog />*/}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
};
