import { FormInput } from "@/components/ui/FormInput";
import { Button } from "@/components/ui/button";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { useGetMyWorkspaces } from "@/data/queries/workspace/useGetMyWorkspaces";
import { useUpdateWorkspace } from "@/data/mutations/workspace/useUpdateWorkspace";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import type { Workspace } from "@/types/workspace";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import type { WorkspaceSectionProps } from "@/components/Profiles/WorkspaceSection/types";
import { LeaveWorkspaceMemberDialog } from "@/components/dialogs/TeamDialogs/LeaveWorkspaceMemberDialog";
import { DeleteWorkspaceDialog } from "@/components/dialogs/TeamDialogs/DeleteWorkspaceDialog";

export const WorkspaceSettings = ({ isOwner, isEditor }: WorkspaceSectionProps) => {
  const { navigationProfileOpen } = useNavigationProfileContext();
  const { data: myWorkspaces } = useGetMyWorkspaces();
  const { mutate } = useUpdateWorkspace();
  const [isValidName, setIsValidName] = useState(false);
  const myWorkspace = myWorkspaces?.find(workspace => workspace._id === navigationProfileOpen.section);
  const form = useForm<Workspace>({ defaultValues: { name: myWorkspace?.name }, reValidateMode: "onChange" });

  const { handleSubmit, watch, reset } = form;
  const watchedName = watch("name");

  useEffect(() => {
    reset({ name: myWorkspace?.name });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myWorkspace]);

  useEffect(() => {
    if (
      myWorkspaces?.find(workspace => workspace?.name?.toLowerCase() === watchedName?.toLowerCase()) ||
      !watchedName.length
    ) {
      setIsValidName(false);
    } else {
      setIsValidName(true);
    }
  }, [watchedName, myWorkspaces]);

  const submitPreferredName: SubmitHandler<{ name: string }> = ({ name }) => {
    if (!myWorkspace?._id) {
      return;
    }
    mutate({ name, id: myWorkspace._id }, { onSuccess: () => toast.success("Preferred name updated!") });
  };

  return (
    <div className="flex flex-col gap-3 pt-4">
      {(isEditor || isOwner) && (
        <>
          <div className="font-bold capitalize">Workspace name</div>
          <form
            onSubmit={handleSubmit(submitPreferredName)}
            className="flex flex-row items-center justify-between gap-2 "
          >
            <FormInput
              formObj={form}
              name="name"
              required
              placeholder="Enter prefered name"
              rules={{
                required: "Name is required",
              }}
              inputClassName="my-0 w-full"
            />

            <Button type="submit" className="my-1" disabled={!isValidName}>
              Change Name
            </Button>
          </form>
          <span className="text-xs font-medium text-neutral-400">
            You can use your organization or company name. Keep it simple.
          </span>
        </>
      )}

      <div className="font-bold capitalize">Danger zone</div>
      {myWorkspace && isOwner && <DeleteWorkspaceDialog workspaceId={myWorkspace._id} />}
      {myWorkspace && (isEditor ? !!myWorkspace?.editors.length : !isOwner) && (
        <LeaveWorkspaceMemberDialog workspaceId={myWorkspace._id} />
      )}
    </div>
  );
};
