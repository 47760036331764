import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { notificationsKeys } from "@/data/queries/notifications/useGetNotifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { AgentNotification } from "@/types/notification";
import type { AxiosError } from "axios";

type DeletePayload = {
  id: AgentNotification["_id"];
};

const deleteNotification = async (payload: DeletePayload) => {
  const { data } = await restClient.delete<Response>(`${apiPaths.deleteNotification(payload.id)}`);
  return data;
};

export const useDeleteNotification = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<{ message: string }>, DeletePayload>({
    mutationFn: deleteNotification,
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries({ queryKey: notificationsKeys.all })]);
    },
  });
};
