import { ROUTES } from "@/constants/routes";
import type { Agent } from "@/types/agent";

export const getNewConversationRoute = (agents: string[], manager?: Agent["_id"]) => {
  let url = `${ROUTES.newConversation}?agent=${agents.join("&agent=")}`;
  if (agents.length > 1 && !!manager) {
    url += `&manager=${manager}`;
  }
  return url;
};
