import { cn } from "@/lib/utils";
import type { ReactNode } from "react";

type NumberCircleProps = { children: ReactNode; className?: string };

export const NumberCircle = ({ children, className }: NumberCircleProps) => {
  return (
    <div
      className={cn(
        "flex h-4 min-w-4 items-center justify-center rounded-full bg-primary-500 px-1 pt-[0.5px] text-[10px] font-medium leading-none text-white",
        className
      )}
    >
      {children}
    </div>
  );
};
