import { createContext } from "react";
import type { DateRangeType } from "@/types/datepicker";
import type { StatsPageChannelTab } from "./StatsProvider";
import type { Agent } from "@/types/agent";
import type { Origin } from "@/types/origin";

export type StatsProps = {
  currentRange: DateRangeType;
  setCurrentRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedAgents: Agent[];
  setSelectedAgents: React.Dispatch<React.SetStateAction<Agent[]>>;
  chatHistoryDialogProps: {
    agent: Agent;
    channel?: Origin;
    downvotedOnly?: boolean;
  } | null;
  setChatHistoryDialogProps: React.Dispatch<
    React.SetStateAction<{
      agent: Agent;
      channel?: Origin;
      downvotedOnly?: boolean;
    } | null>
  >;
  isChatHistoryDialogOpen: boolean;
  activeChannelsOrigins: Origin[];
  activeChannelTab: StatsPageChannelTab;
  switchChannelTab: ({ newTab }: { newTab: StatsPageChannelTab }) => void;
  isUpgradeNeeded: boolean;
  activeWorkspaceId: string | undefined;
  setActiveWorkspaceId: React.Dispatch<React.SetStateAction<string | undefined>>;
  workspaceOptions: { workspaceId: string | undefined; name: string }[];
  isDefaultWorkspace: boolean;
};

export const StatsContext = createContext<StatsProps | null>(null);
