import { useGetSingleAgentEngagementStats } from "@/data/queries/stats/useGetSingleAgentEngagementStats";
import StatsAllDataIcon from "../../common/StatsAllDataIcon";
import { StatsMainChartSkeleton } from "../../loaders/EngagementOverviewSkeleton";
import { StatsIntervalPicker } from "../../StatsIntervalPicker";
import { StatsSingleAgentPerformanceChart } from "../../StatsSingleAgentPerformance/StatsSingleAgentPerformanceChart";
import StatsSingleAgentPerformanceDataTypePicker from "../../StatsSingleAgentPerformance/StatsSingleAgentPerformanceDataTypePicker";
import StatsSingleAgentPerformanceLegend from "../../StatsSingleAgentPerformance/StatsSingleAgentPerformanceLegend";
import type { StatsInterval, StatsSingleAgentPerformanceDataType } from "@/types/stats";
import { StatsIntervals } from "@/types/stats";
import { useState } from "react";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import type { Agent } from "@/types/agent";
import StatsDataTypeIcon from "../../common/StatsDataTypeIcon";
import { ALL_AGENTS_STATS_LEGEND } from "@/pages/Stats/constants/statsConstants";
import { getSingleAgentPerformanceFilteredData } from "@/pages/Stats/utils/getSingleAgentPerformanceFilteredData";

type Props = {
  agent: Agent;
};

export const StatsSidePanelAgentPerformance = ({ agent }: Props) => {
  const [interval, setInterval] = useState<StatsInterval>(StatsIntervals.DAILY);
  const [selectedDataType, setSelectedDataType] = useState<StatsSingleAgentPerformanceDataType | null>(null);

  const { currentRange, startDate, endDate, activeChannelsOrigins, activeWorkspaceId, isDefaultWorkspace } =
    useStatsContext();

  const { data, isFetching: isFetchingSingleAgentEngagement } = useGetSingleAgentEngagementStats({
    currentRange,
    startDate,
    endDate,
    channels: activeChannelsOrigins,
    agentId: agent._id,
    interval,
    workspaceId: isDefaultWorkspace ? undefined : activeWorkspaceId,
  });

  const getDataTypeDropdownIcon = () => {
    if (!selectedDataType) {
      return <StatsAllDataIcon />;
    }

    return <StatsDataTypeIcon selectedDataType={selectedDataType} />;
  };

  const getDataTypeDropdownTitle = () => {
    if (!selectedDataType) {
      return "All data";
    }

    return ALL_AGENTS_STATS_LEGEND[selectedDataType].label;
  };

  const filteredData = getSingleAgentPerformanceFilteredData({
    data,
    selectedDataType,
  });

  return (
    <div className="relative mt-8 flex h-[550px] flex-col items-start">
      <div className="flex w-full flex-wrap items-center justify-between gap-4">
        <h2 className="text-left text-xl font-bold text-neutral-750">Agents Performance</h2>
        <div className="flex items-center gap-2">
          <StatsSingleAgentPerformanceDataTypePicker
            onChangeSelectedDataType={setSelectedDataType}
            triggerIcon={getDataTypeDropdownIcon()}
            triggerTitle={getDataTypeDropdownTitle()}
          />

          <StatsIntervalPicker statsInterval={interval} onStatsIntervalChange={setInterval} />
        </div>
      </div>

      <StatsSingleAgentPerformanceLegend selectedDataType={selectedDataType} />

      {isFetchingSingleAgentEngagement ? (
        <StatsMainChartSkeleton />
      ) : (
        <StatsSingleAgentPerformanceChart data={filteredData} />
      )}
    </div>
  );
};
