import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import type { Workspace } from "@/types/workspace";

type WorkspaceAgentOwnerSelectProps = {
  options: { id: Workspace["_id"]; name: Workspace["name"] }[] | undefined;
  agentWorkspaceId?: string | undefined;
  value: string | undefined;
  onValueChange: (value: string) => void;
};

export const WorkspaceAgentOwnerSelect = ({
  options,
  agentWorkspaceId,
  value,
  onValueChange,
}: WorkspaceAgentOwnerSelectProps) => {
  return (
    <Select onValueChange={onValueChange} value={value}>
      <SelectTrigger id="numOfMessages" className="w-full">
        <SelectValue placeholder="Select new workspace"></SelectValue>
      </SelectTrigger>
      <SelectContent>
        {options?.map(option => (
          <SelectItem key={option.id} value={option.id} disabled={agentWorkspaceId === option.id}>
            {option.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
