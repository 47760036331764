import { cn } from "@/lib/utils";
import { forwardRef, useEffect, useRef } from "react";

type Props = { value: string; onValueChange: (value: string) => void };

const ContentEditableSpan = forwardRef<HTMLSpanElement, React.HTMLAttributes<HTMLSpanElement> & Props>(
  ({ className, value, onValueChange, id, ...props }, _ref) => {
    const valueRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
      if (valueRef.current && valueRef.current.innerText !== value) {
        valueRef.current.innerText = value;
      }
    }, [value]);

    const handlePastePlainText = (e: React.ClipboardEvent<HTMLSpanElement>) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text/plain");
      document.execCommand("insertText", false, text);
    };

    return (
      <span
        ref={valueRef}
        contentEditable
        className={cn(
          "w-full p-2 pl-0 text-xs font-medium text-neutral-750 custom-break-anywhere focus:outline-none",
          className
        )}
        onInput={e => {
          onValueChange(e.currentTarget.innerText);
        }}
        onPaste={handlePastePlainText}
        id={id}
        {...props}
      />
    );
  }
);
ContentEditableSpan.displayName = "ContentEditableSpan";

const ContentEditableSpanPlaceholder = forwardRef<HTMLSpanElement, React.HTMLAttributes<HTMLSpanElement>>(
  ({ className, ...props }, ref) => {
    return (
      <span
        ref={ref}
        className={cn("placeholder pointer-events-none absolute text-xxs leading-3 text-neutral-300", className)}
        {...props}
      />
    );
  }
);
ContentEditableSpanPlaceholder.displayName = "ContentEditableSpanPlaceholder";

export { ContentEditableSpan, ContentEditableSpanPlaceholder };
