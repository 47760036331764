import { cn } from "@/lib/utils";
import type { ReactNode } from "react";

type BadgeProps = {
  icon?: ReactNode;
  text: string;
  className?: string;
};

export const Badge = ({ icon, text, className }: BadgeProps) => {
  return (
    <span
      className={cn(
        "mr-1 inline-flex items-center rounded-full border px-2 py-1 text-xs font-medium text-neutral-800",
        className
      )}
    >
      {icon && <span className="pr-1">{icon}</span>}
      <span>{text}</span>
    </span>
  );
};
