import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Icons } from "@/components/ui/icons";
import { FileIcon } from "./FileIcon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { KnowledgeStatus, type EmbedKnowledges } from "@/types/agent";
import { KnowledgeStatusBadge } from "./KnowledgeStatusBadge";
import { IconButton } from "@/components/ui/icon-button";
import { NumberCircle } from "@/components/ui/NumberCircle";

interface FolderAccordionFolders {
  externalId: string;
  name: string;
  children: EmbedKnowledges[];
}

type FolderAccordionProps = {
  folders: FolderAccordionFolders[];
  removeFolder: (id: string) => void;
  setDocumentInPreview: (document: EmbedKnowledges) => void;
};

export const FolderAccordion = ({ folders, removeFolder, setDocumentInPreview }: FolderAccordionProps) => {
  console.log(folders);
  return (
    <div>
      {folders.map(folder => (
        <Accordion
          type="single"
          collapsible
          className="my-2 rounded-md border border-slate-200 bg-white"
          key={folder.externalId}
        >
          <AccordionItem value={`online-info-${folder.externalId}`} className="relative">
            <AccordionTrigger className="h-10 border-slate-200 px-3 text-xs font-medium text-neutral-800 hover:no-underline data-[state=open]:border-b">
              <div className="flex w-full items-center justify-between gap-2 pr-2">
                <div className="flex items-center justify-center gap-2">
                  <Icons.Folder className="h-6 text-neutral-400" />
                  <NumberCircle className="border border-primary-500 bg-white text-primary-500">
                    {folder.children.length}
                  </NumberCircle>
                  <div className="flex items-center break-all">
                    {!folder?.children.every(file => "_id" in file) && (
                      <KnowledgeStatusBadge status={KnowledgeStatus.NEW} />
                    )}
                    <span className="align-middle">{folder.name}</span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <IconButton
                    type="button"
                    variant="tertiary"
                    size="tiny"
                    icon={<Icons.Trash className="text-neutral-400" />}
                    onClick={() => removeFolder(folder.externalId)}
                  />
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="flex flex-col gap-2 bg-zinc-100 p-2">
              <div key={folder.externalId} className="flex flex-col gap-2 bg-zinc-100 ">
                {folder?.children?.map(child => (
                  <div
                    key={child.name}
                    className="my-1 flex w-full items-center justify-between gap-2 rounded-md border border-slate-200 bg-white pb-1 pr-3 pt-1 hover:bg-primary-50"
                  >
                    <div className="flex flex-col gap-1">
                      <div className="ml-2 flex h-10 min-w-6 items-center">
                        <div className="w-8 min-w-6">
                          <FileIcon fileName={child.filename} />
                        </div>

                        <div className="ml-2 flex w-full flex-col gap-1 py-1">
                          {child?.status == KnowledgeStatus.SUCCESS ? (
                            child?.source ? (
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <p
                                    onClick={() => setDocumentInPreview(child)}
                                    className="break-all pr-2 text-xs font-bold text-neutral-800 hover:text-neutral-500 hover:underline"
                                  >
                                    {child.name}
                                  </p>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="mb-2 flex justify-between gap-5">
                                    <span className="text-base font-bold">AI Summary</span>
                                    <button
                                      onClick={() => setDocumentInPreview(child)}
                                      className="text-sm font-medium text-primary-200"
                                    >
                                      (Click to preview)
                                    </button>
                                  </div>
                                  <p className="line-clamp-2 text-xs font-medium">
                                    {child.description || "Unable to generate summary for this file."}
                                  </p>
                                </TooltipContent>
                              </Tooltip>
                            ) : (
                              <p className="break-all pr-2 text-xs font-bold text-neutral-400">(NEW) {child.name}</p>
                            )
                          ) : (
                            <p className="break-all pr-2 text-xs font-bold text-neutral-400">{child.name}</p>
                          )}
                        </div>
                        {child?.status && <KnowledgeStatusBadge status={child.status} />}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  );
};
