import { cn } from "@/lib/utils";

type Props = {
  isActive?: boolean;
};

export const ChatMessageReasoningTimelineDot = ({ isActive }: Props) => {
  return (
    <div
      className={cn("absolute -left-4 top-1/2 h-2 w-2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-primary-200", {
        "bg-primary-300": isActive,
      })}
    />
  );
};
