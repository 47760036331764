import { useState } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import type { UseFormReturn } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import { Icons } from "@/components/ui/icons";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import type { Parameter, Tool } from "@/types/tools";
import { Input } from "@/components/ui/input";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";
import { NumberCircle } from "@/components/ui/NumberCircle";

type CustomisedParametersProps = {
  form: UseFormReturn<Tool>;
};

export const CustomisedParameters = ({ form }: CustomisedParametersProps) => {
  const [accordionValueOpen, setAccordionValueOpen] = useState("customised");

  const {
    fields: parameters,
    append,
    remove,
    update,
  } = useFieldArray({
    control: form.control,
    name: "parameters",
  });

  const addParameter = () => {
    append({
      parameterName: "",
      parameterDescription: "",
      required: false,
      type: "string",
      _id: "",
    });
  };

  const removeParameter = (index: number) => {
    remove(index);
  };

  const updateField = <Key extends keyof Parameter, Value extends Parameter[Key]>(
    index: number,
    field: Key,
    value: Value
  ) => {
    const newParameter = { ...parameters[index], [field]: value };
    update(index, newParameter);
  };

  return (
    <Accordion
      type="single"
      collapsible
      className="mt-2 rounded-md border border-neutral-300"
      value={accordionValueOpen}
      onValueChange={setAccordionValueOpen}
    >
      <AccordionItem value="customised">
        <AccordionTrigger className="px-4 text-xs font-medium text-neutral-800">
          <NumberCircle>{parameters.length || 0}</NumberCircle>
          <div className="ml-2 mr-auto flex gap-1 text-sm">
            <div>Parameters</div>
            <HybridTooltipPopover>
              <p>Add and describe each parameter in detail.</p>
              <p>More description helps the agent better determine the parameter value.</p>
            </HybridTooltipPopover>
          </div>
        </AccordionTrigger>
        <AccordionContent className="flex flex-col gap-4 rounded-b-md bg-neutral-50 p-2 pt-4">
          {parameters?.map((parameter, index) => (
            <div
              key={parameter._id}
              className="group relative flex w-full flex-col items-start justify-between gap-2 overflow-x-scroll rounded-md border border-neutral-200 bg-white px-4 py-3"
            >
              <Label htmlFor="parameterRequired" className="text-s text-neutral-600">
                Parameter {index + 1}
              </Label>
              <div className="flex w-full items-center justify-between gap-2">
                <div className="flex flex-grow flex-col gap-1.5">
                  <Input
                    name="parameterName"
                    value={parameter.parameterName}
                    onChange={e => updateField(index, "parameterName", e.target.value)}
                    placeholder="Parameter name"
                    inputClassName="h-10"
                    required
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <Select
                    onValueChange={e => updateField(index, "type", e as Parameter["type"])}
                    value={parameter.type}
                  >
                    <SelectTrigger id="parameterType" className="w-full">
                      <SelectValue placeholder="Select"></SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="string">string</SelectItem>
                      <SelectItem value="integer">number</SelectItem>
                      <SelectItem value="integer">boolean</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex flex-col">
                  <Label htmlFor="parameterRequired" className="text-xs text-neutral-400">
                    required?
                  </Label>
                  <Switch
                    id="parameterRequired"
                    checked={parameter.required}
                    onCheckedChange={e => updateField(index, "required", e)}
                  />
                </div>

                <IconButton
                  type="button"
                  className="absolute right-0 top-0 hidden group-hover:block"
                  variant="tertiary"
                  size="tiny"
                  onClick={() => removeParameter(index)}
                  icon={<Icons.Trash className="text-neutral-400" />}
                />
              </div>
              <Input
                name="description"
                value={parameter.parameterDescription}
                onChange={e => updateField(index, "parameterDescription", e.target.value)}
                placeholder="Write a description"
                inputClassName="h-10"
                required
              />
            </div>
          ))}
          <div className="flex items-center justify-center">
            <Button onClick={addParameter}>Add parameter</Button>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
