import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { AgentVoiceCreateProfile } from "./AgentVoiceCreateProfile";
import { AgentVoiceSpeedPicker } from "./AgentVoiceSpeedPicker";
import { AgentVoiceName } from "./AgentVoiceName";
import { AgentVoiceDeleteDialog } from "./AgentVoiceDeleteDialog";

import { cn } from "@/lib/utils";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { useGetCustomVoiceList } from "@/data/queries/voice/useGetCustomVoiceList";
import { useUpdateCustomVoice } from "@/data/mutations/voice/useUpdateCustomVoice";
import { LoadingSpinner } from "@/components/ui/loading-spinner";

export const AgentVoiceCustomOptions = () => {
  const form = useAgentFormContext();
  const [agentId] = form.getValues(["_id", "publishedToCommunity"]);
  const { customVoiceList, isLoading, isError } = useGetCustomVoiceList({ botId: agentId });
  const { mutate: updateCustomVoice } = useUpdateCustomVoice();

  const [isSamplingOverlayOpen, setIsSamplingOverlayOpen] = useState(false);
  const [playingVoiceId, setPlayingVoiceId] = useState<string | null>(null);
  const [audioInstance, setAudioInstance] = useState<HTMLAudioElement | null>(null);
  const [focusedVoiceId, setFocusedVoiceId] = useState<string | null>(null);
  const [deleteDialogVoiceId, setDeleteDialogVoiceId] = useState<string | null>(null);

  const togglePlay = (e: React.MouseEvent<SVGElement, MouseEvent>, voice: string) => {
    e.preventDefault();
    const voiceItem = customVoiceList?.find(item => item.src === voice);

    if (!voiceItem) {
      return;
    }

    if (playingVoiceId === voiceItem.id) {
      audioInstance?.pause();
      setPlayingVoiceId(null);
      return;
    }

    if (playingVoiceId === null) {
      const newAudio = new Audio(voiceItem.src);
      newAudio.onended = () => setPlayingVoiceId(null);

      setPlayingVoiceId(voiceItem.id);
      setAudioInstance(newAudio);

      void newAudio.play().catch(err => {
        console.error("Audio playback failed:", err);
        setPlayingVoiceId(null);
      });
    }
  };

  useEffect(() => {
    return () => {
      audioInstance?.pause();
    };
  }, [audioInstance]);

  const handleSpeedChange = (voiceId: string, newSpeed: number) => {
    if (!customVoiceList?.some(item => item.id === voiceId)) {
      return;
    }
    updateCustomVoice({
      botId: agentId,
      voiceId,
      speed: newSpeed,
    });
  };

  const handleNameChange = (voiceId: string, newName: string) => {
    if (!customVoiceList?.some(item => item.id === voiceId)) {
      return;
    }
    updateCustomVoice({
      botId: agentId,
      voiceId,
      name: newName,
    });
    setFocusedVoiceId(null);
  };

  if (isLoading) {
    return <LoadingSpinner className="my-4 flex h-[84px] items-center justify-center" />;
  }

  if (isError) {
    return (
      <div className="my-12 text-center text-sm text-red-500">Failed to load custom voices. Please try again.</div>
    );
  }

  return (
    <>
      <Button
        className="text-md flex h-12 w-full text-sm"
        variant="secondary"
        onClick={() => setIsSamplingOverlayOpen(true)}
      >
        <Icons.Plus className="-mt-[1px] mr-2 size-5 shrink-0" />
        Create a voice
      </Button>

      {isSamplingOverlayOpen && <AgentVoiceCreateProfile onClose={() => setIsSamplingOverlayOpen(false)} />}
      <AgentVoiceDeleteDialog
        voiceId={deleteDialogVoiceId}
        voiceName={customVoiceList?.find(voice => voice.id === deleteDialogVoiceId)?.name || ""}
        agentId={agentId}
        onClose={() => setDeleteDialogVoiceId(null)}
      />

      <Controller
        control={form.control}
        name="voiceProfile.activeCustomVoice"
        render={({ field: { value, onChange } }) => (
          <RadioGroup
            className="w-full gap-4"
            value={value ?? ""}
            onValueChange={id => {
              const selected = customVoiceList?.find(voice => voice.id === id) || null;
              onChange(selected?.id ?? "");
            }}
          >
            {customVoiceList?.map(voice => {
              const isSelected = value === voice.id;
              return (
                <label
                  key={voice.id}
                  className={`flex h-12 w-full items-center justify-between gap-2 rounded-lg pl-2 ${isSelected ? "bg-transparent" : "bg-primary-50"}`}
                  htmlFor={`voice-${voice.id}`}
                  onFocus={() => setFocusedVoiceId(voice.id)}
                  onClick={e => {
                    e.preventDefault();
                    setFocusedVoiceId(voice.id);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <RadioGroupItem value={voice.id} id={`voice-${voice.id}`} className="bg-white" />
                    <AgentVoiceName
                      name={voice.name}
                      onRename={newName => handleNameChange(voice.id, newName)}
                      disabled={focusedVoiceId !== voice.id}
                      onFocus={() => setFocusedVoiceId(voice.id)}
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="flex h-9 w-9 items-center justify-center rounded border border-primary-400 bg-primary-50 p-2  text-primary-400">
                      {voice.language}
                    </span>
                    <AgentVoiceSpeedPicker
                      quantity={voice.speed ?? 1}
                      onChangeQuantity={newSpeed => handleSpeedChange(voice.id, newSpeed)}
                      disabled={focusedVoiceId !== voice.id}
                    />
                    {playingVoiceId === voice.id ? (
                      <Icons.StopMedia
                        className="cursor-hover h-12 w-12 rounded-lg p-2.5 text-primary-400"
                        onClick={e => togglePlay(e, voice.src)}
                      />
                    ) : (
                      <Icons.PlayMedia
                        className={cn("cursor-hover h-12 w-12 rounded-lg p-2.5", {
                          "text-gray-200": playingVoiceId !== null && playingVoiceId !== voice.id,
                          "text-primary-400": playingVoiceId === null || playingVoiceId === voice.id,
                        })}
                        onClick={e => togglePlay(e, voice.src)}
                      />
                    )}
                    {isSelected ? (
                      <div className="h-12 w-12" />
                    ) : (
                      <Icons.Trash
                        className="cursor-hover h-12 w-12 rounded-lg p-2 text-neutral-300 hover:bg-primary-100"
                        onClick={() => setDeleteDialogVoiceId(voice.id)}
                      />
                    )}
                  </div>
                </label>
              );
            })}
          </RadioGroup>
        )}
      />
    </>
  );
};
