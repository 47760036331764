import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactQueryProvider } from "@/utils/ReactQueryProvider";
import { NavigationProfileProvider } from "@/contexts/NavigationProfileContext/NavigationProfileProvider";
import { MediaQueriesProvider } from "@/contexts/MediaQueriesContext/MediaQueriesProvider";
import { AccountUsageProvider } from "@/contexts/AccountUsageDialogContext/AccountUsageDialogProvider";
import { BookmarkChatHistoryProvider } from "@/contexts/BookmarkChatHistoryContext/BookmarkChatHistoryProvider";
import { TooltipProvider } from "@/components/ui/tooltip";
import { ChatProvider } from "./contexts/ChatContext/ChatProvider";
import { SubscriptionTierDialogProvider } from "./contexts/SubscriptionTierDialogContext/SubscriptionTierDialogProvider";
import { StripeElementsProvider } from "./contexts/StripeElementsContext/StripeElementsProvider";
import { LoginDialogProvider } from "./contexts/LoginDialogContext/LoginDialogProvider";
import { WebsocketContextProvider } from "@/contexts/WebsocketContext/WebsocketContextProvider";
import { LayoutProvider } from "@/contexts/LayoutContext/LayoutProvider";

export const AppProviders = ({ children }: { children: JSX.Element }) => (
  <ReactQueryProvider>
    <MediaQueriesProvider>
      <LayoutProvider>
        <TooltipProvider delayDuration={0}>
          <LoginDialogProvider>
            <ChatProvider>
              <BookmarkChatHistoryProvider>
                <StripeElementsProvider>
                  <SubscriptionTierDialogProvider>
                    <NavigationProfileProvider>
                      <AccountUsageProvider>
                        <WebsocketContextProvider>{children}</WebsocketContextProvider>
                        <ToastContainer />
                      </AccountUsageProvider>
                    </NavigationProfileProvider>
                  </SubscriptionTierDialogProvider>
                </StripeElementsProvider>
              </BookmarkChatHistoryProvider>
            </ChatProvider>
          </LoginDialogProvider>
        </TooltipProvider>
      </LayoutProvider>
    </MediaQueriesProvider>
  </ReactQueryProvider>
);
