import { useState, useRef, useEffect } from "react";

interface AgentVoiceNameProps {
  name: string;
  onRename: (newName: string) => void;
  disabled: boolean;
  onFocus: () => void;
}

export const AgentVoiceName: React.FC<AgentVoiceNameProps> = ({ name, onRename, onFocus, disabled }) => {
  const [editing, setEditing] = useState(false);
  const [currentName, setCurrentName] = useState(name);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (disabled) {
      setEditing(false);
    }
  }, [disabled]);

  const handleBlur = () => {
    setEditing(false);
    if (currentName.trim() === "" || currentName === name) {
      setCurrentName(name);
      return;
    }
    onRename(currentName);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
    }
  };

  const handleNameClick = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    setEditing(true);
    onFocus();
  };

  return (
    <>
      {editing && !disabled ? (
        <input
          ref={inputRef}
          value={currentName}
          onChange={e => setCurrentName(e.target.value)}
          onBlur={handleBlur}
          className="-ml-0.5 w-full rounded-lg border border-neutral-300 bg-white px-0.5 py-2.5 focus:border-neutral-300 focus:outline-none focus:ring-transparent"
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
      ) : (
        <span className="w-[7.5rem] cursor-pointer truncate" onClick={handleNameClick} title={currentName}>
          {currentName}
        </span>
      )}
    </>
  );
};
