import { ChatKnowledgeList } from "./ChatKnowledgeList";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { Button } from "@/components/ui/button";
import type { DomainLinks, EmbedKnowledges } from "@/types/agent";

type Props = {
  onClose: () => void;
  selectedChatKnowledge: string[];
  knowledgesCount: number;
  sortedWebData: DomainLinks[];
  handleClearPins: () => void;
  handleSimplePinChange: (checked: boolean, idOrIds: string | string[]) => void;
  filteredEmbedKnowledges: {
    web: EmbedKnowledges[];
    doc: EmbedKnowledges[];
    faq: EmbedKnowledges[];
  };
  onOpenModal: () => void;
};

export const ChatKnowledgeListBanner = ({
  onClose,
  selectedChatKnowledge,
  knowledgesCount,
  sortedWebData,
  handleClearPins,
  handleSimplePinChange,
  filteredEmbedKnowledges,
  onOpenModal,
}: Props) => {
  const { conversationDetails } = useChatContext();
  const isNewConversation = !conversationDetails;

  return (
    <div className="relative flex min-h-40 flex-col rounded-lg bg-white px-0 py-4 shadow-[2px_2px_10px_0px_rgba(51,51,51,0.10)]">
      {isNewConversation && (
        <div className="absolute left-0 top-0 z-10 grid size-full place-items-center backdrop-blur-[5px]">
          <IconButton
            variant="tertiary"
            size="tiny"
            className="absolute right-4 top-4 p-2"
            icon={<Icons.Close className="h-3 w-3 text-neutral-400" />}
            onClick={onClose}
          />
          <p className="px-5 text-center text-base font-bold">
            Please <span className="text-primary-400">send a message</span> first &<br /> then select ‘Knowledge’
          </p>
        </div>
      )}
      <div className="border-b px-4">
        <div className="flex items-center justify-between">
          <p className="text-sm font-bold leading-[22px] text-neutral-750">Knowledge</p>
          <div>
            <IconButton variant="tertiary" size="tiny" icon={<Icons.Maximize2 />} onClick={onOpenModal} />
            <IconButton
              variant="tertiary"
              size="tiny"
              className="p-2"
              icon={<Icons.Close className="h-3 w-3 text-neutral-400" />}
              onClick={onClose}
            />
          </div>
        </div>
        <div className="my-2 flex items-center justify-between">
          <span className="text-xs font-medium leading-5 text-neutral-400">{`Pinned ${selectedChatKnowledge.length}/${knowledgesCount}`}</span>
          <Button
            disabled={!selectedChatKnowledge.length}
            variant="tertiary"
            size="tiny"
            className="px-1"
            onClick={handleClearPins}
          >
            Clear
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-2 overflow-y-auto px-4 pt-2 text-xxs font-medium">
        <ChatKnowledgeList
          selectedChatKnowledge={selectedChatKnowledge}
          sortedWebData={sortedWebData}
          embedKnowledges={filteredEmbedKnowledges}
          handleSimplePinChange={handleSimplePinChange}
        />
      </div>
    </div>
  );
};
