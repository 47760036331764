import { useQuery } from "@tanstack/react-query";
import { getCustomVoiceListForAgent } from "../../customVoice";

export const getCustomVoiceListQueryKey = (botId: string) => ["customVoiceList", { botId }] as const;

type UseGetCustomVoiceListProps = {
  botId: string;
  enabled?: boolean;
};

export const useGetCustomVoiceList = ({ botId, enabled = true }: UseGetCustomVoiceListProps) => {
  const query = useQuery({
    queryKey: getCustomVoiceListQueryKey(botId),
    queryFn: () => getCustomVoiceListForAgent(botId),
    enabled,
    staleTime: 15 * 60 * 1000,
    retry: 1,
  });

  return {
    ...query,
    customVoiceList: query.data,
  };
};
