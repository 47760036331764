import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Icons } from "@/components/ui/icons";
import type { Dispatch, SetStateAction } from "react";

export enum SORT_BY_VALUES {
  ASC = "asc",
  DESC = "desc",
}

type SortAgentsByNameDropdownProps = {
  sortBy: SORT_BY_VALUES | null;
  setSortBy: Dispatch<SetStateAction<SORT_BY_VALUES | null>>;
};

export const SortAgentsByNameDropdown = ({ sortBy, setSortBy }: SortAgentsByNameDropdownProps) => {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger onClick={e => e.stopPropagation()}>
        <Button
          variant="tertiary"
          size="tiny"
          className={cn("gap-1 px-2 py-0.5 font-bold", {
            "bg-primary-100": !!sortBy,
          })}
        >
          Sort By
          <Icons.ArrowUpDown />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent onClick={e => e.stopPropagation()} align="start" className="w-fit">
        <DropdownMenuItem
          className={cn("flex w-fit items-center gap-1.5 text-xs", sortBy === SORT_BY_VALUES.ASC && "bg-primary-100")}
          onClick={e => {
            e.preventDefault();
            setSortBy(sortBy === SORT_BY_VALUES.ASC ? null : SORT_BY_VALUES.ASC);
          }}
        >
          <Icons.Check
            className={cn("mr-1", {
              "opacity-0": sortBy === SORT_BY_VALUES.DESC || !sortBy,
            })}
          />
          Ascending by Name
        </DropdownMenuItem>
        <DropdownMenuItem
          className={cn("flex w-fit items-center gap-1.5 text-xs", sortBy === SORT_BY_VALUES.DESC && "bg-primary-100")}
          onClick={e => {
            e.preventDefault();
            setSortBy(sortBy === SORT_BY_VALUES.DESC ? null : SORT_BY_VALUES.DESC);
          }}
        >
          <Icons.Check
            className={cn("mr-1", {
              "opacity-0": sortBy === SORT_BY_VALUES.ASC || !sortBy,
            })}
          />
          Descending by Name
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
