import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  DialogDescription,
} from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useShareConversation } from "@/data/mutations/useShareConversation";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { SocialMediaShare } from "@/components/SocialMediaShare";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";
import { TooltipPortal } from "@radix-ui/react-tooltip";

export const ShareConversationButtonDialog = () => {
  const { conversationId } = useChatContext();
  const shareLink = `${import.meta.env.VITE_BASE_FRONTEND_URL || "localhost:3001"}/share/conversation/${conversationId}`;

  const { mutate: shareConversation } = useShareConversation();

  if (!conversationId) {
    return null;
  }
  return (
    <Dialog>
      <Tooltip>
        <TooltipTrigger onClick={() => shareConversation({ conversationId })} asChild>
          <DialogTrigger asChild>
            <IconButton variant="tertiary" size="medium" roundedType="rounded" icon={<Icons.Share />} />
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>Share conversation</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <DialogContent variant="small">
        <DialogHeader>Share Conversation</DialogHeader>
        <DialogDescription className="flex w-full flex-col items-center gap-6">
          <SocialMediaShare shareLink={shareLink} />
        </DialogDescription>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="tertiary">Cancel</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
