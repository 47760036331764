import { Icons } from "@/components/ui/icons";

const ChatBookmarksHistoryBtn = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className=" mr-4 flex w-fit items-center justify-center gap-2 rounded-md bg-primary-500 px-4 py-2 "
      {...props}
    >
      <Icons.BookmarkOutlined className="w-8 border-r border-r-white pr-3 text-white" />
      <Icons.ChatHistory className="text-white" />
    </button>
  );
};

export default ChatBookmarksHistoryBtn;
