import type { EmbedKnowledges } from "@/types/agent";
import { KnowledgeStatus } from "@/types/agent";
import { useFieldArray } from "react-hook-form";
import { Icons } from "@/components/ui/icons";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useState } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { LinksAccordion } from "./LinksAccordion";
import { sortWebByDomainsToSeparateArrays } from "@/utils/getDomains";
import { OnlineInfoWWW } from "./OnlineInfoWWW";
import { OnlineInfoSitemap } from "./OnlineInfoSitemap";
import { OnlineInfoRecurringLink } from "./OnlineInfoRecurringLink";
import { Checkbox } from "@/components/ui/checkbox";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";
import { useGetUser } from "@/data/queries/useGetUser";
import UpgradeBlurBanner from "@/components/UpgradeBlurBanner/UpgradeBlurBanner";
import { checkIfKnowledgeLimitExceeded } from "@/utils/checkIfKnowledgeLimitExceeded";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";
import { KnowledgeLimitInfoBadge } from "./KnowledgeLimitInfoBadge";
import { domElementIds } from "@/types/dom-element-ids";
import { NumberCircle } from "@/components/ui/NumberCircle";

const onlineInfoTabs = ["Single Webpage", "Sitemap", "Find Sublinks"] as const;
type OnlineInfoTabs = (typeof onlineInfoTabs)[number];

const onlineInfoTabMap: {
  [K in OnlineInfoTabs]: React.ComponentType<{
    appendWeb: (newData: EmbedKnowledges | EmbedKnowledges[]) => void;
    webData: EmbedKnowledges[];
    disabled: boolean;
  }>;
} = {
  "Single Webpage": OnlineInfoWWW,
  Sitemap: OnlineInfoSitemap,
  "Find Sublinks": OnlineInfoRecurringLink,
};

export const OnlineInfo = () => {
  const { control, watch, setValue } = useAgentFormContext();

  const { user } = useGetUser();
  const { openDialog } = useAccountUsageDialogContext();
  const [activeOnlineInfoTab, setActiveOnlineInfoTab] = useState<OnlineInfoTabs>("Single Webpage");
  const [accordionValueOpen, setAccordionValueOpen] = useState("");
  const loadJs = watch("loadJs");
  const ActiveInfoTab = onlineInfoTabMap[activeOnlineInfoTab];
  const {
    fields: webData,
    append: appendWeb,
    remove: removeWeb,
    update: updateWeb,
  } = useFieldArray({
    control,
    name: "embedKnowledges.web",
  });

  const sortedWebData = webData.length ? sortWebByDomainsToSeparateArrays(webData) : [];

  const handleOpenAccountUsageDialog = () => openDialog("url");

  const userWebLimit = user?.subscription.knowledge.urls?.limit;

  const isURLLimitExceeded = checkIfKnowledgeLimitExceeded({
    currentUsage: user?.subscription.knowledge.urls?.currentUsage,
    limit: userWebLimit,
  });

  const addOnlineWebInfo = (newData: EmbedKnowledges | EmbedKnowledges[]) => {
    appendWeb(newData);
    setAccordionValueOpen("online-info");
  };

  const refreshAllFailedKnowledge = () => {
    const links = webData?.filter(x => x.status === KnowledgeStatus.FAILED);

    if (links?.length > 0) {
      const linkIndices = links.map(link => webData.findIndex(web => web.source === link.source));

      linkIndices.forEach(index => {
        updateWeb(index, {
          ...webData[index],
          status: KnowledgeStatus.REFRESH,
        });
      });
    }
  };

  const newWebData = webData?.filter(x => x.status === undefined);
  const failedWebData = webData?.filter(x => x.status === KnowledgeStatus.FAILED);

  const failedKnowledgeBanner = () => {
    const failedWebDataCount = failedWebData?.length;
    if (failedWebDataCount) {
      return (
        <>
          <span className="my-2 flex items-center justify-between rounded-md border border-red-500 bg-red-50 px-2 py-3 text-xs font-medium text-neutral-800">
            <div className="flex items-center">
              <Icons.Error className="ml-2" />
              <span className="pl-2">
                {failedWebDataCount === 1
                  ? `${failedWebDataCount} website was not parsed properly, please refresh or delete the source`
                  : `${failedWebDataCount} websites were not parsed properly, please refresh or delete the sources`}
              </span>
            </div>

            <div>
              <span
                onClick={refreshAllFailedKnowledge}
                className="mr-1 inline-flex cursor-pointer items-center rounded-full border border-primary-500 bg-primary-50 px-2 py-1 text-xs font-medium"
              >
                <Icons.Refresh />
                <span className="pl-1">Refresh all</span>
              </span>
            </div>
          </span>
        </>
      );
    }
  };

  return (
    <div id={domElementIds.AGENT_FORM_ONLINE_INFO}>
      <div className="flex items-center gap-2 py-5">
        <h4>3. Add website knowledge</h4>
        <HybridTooltipPopover heading="Expand Knowledge from websites:">
          <p>
            <span className="font-semibold">- Single Webpage: </span>
            Teach your agent with the data from a single webpage URL.
          </p>
          <p>
            <span className="font-semibold">- Sitemap: </span>
            Use a sitemap to provide your agent with a list of links from a website.
          </p>
          <p>
            <span className="font-semibold">- Find Sublinks: </span>
            Find all the possible links appearing on the giving website. Use depth to define how deep the crawler should
            go.
          </p>
        </HybridTooltipPopover>

        <KnowledgeLimitInfoBadge currentUsage={webData.length} limit={userWebLimit} />
      </div>
      <div className="flex flex-col gap-4 md:pl-5">
        <Tabs value={activeOnlineInfoTab} className="w-full space-y-4">
          <TabsList className="w-full bg-neutral-100 p-1">
            {onlineInfoTabs.map(tab => (
              <TabsTrigger
                key={tab}
                value={tab}
                onClick={() => setActiveOnlineInfoTab(tab)}
                className="flex-1 text-neutral-600 data-[state=active]:text-neutral-750"
              >
                {tab}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
        <ActiveInfoTab appendWeb={addOnlineWebInfo} webData={webData} disabled={isURLLimitExceeded} />
        <Accordion
          type="single"
          collapsible
          className="rounded-md border border-slate-200"
          value={accordionValueOpen}
          onValueChange={setAccordionValueOpen}
        >
          <AccordionItem value="online-info">
            <AccordionTrigger className="px-4 text-xs font-medium text-neutral-800">
              <div className="flex items-center gap-2">
                <NumberCircle> {sortedWebData.length}</NumberCircle>
                {!!newWebData.length && <NumberCircle className=" bg-green-500"> {newWebData.length}</NumberCircle>}
                {!!failedWebData.length && <NumberCircle className=" bg-red-500"> {failedWebData.length}</NumberCircle>}
              </div>
              <div className="ml-2 mr-auto">List of added websites</div>
            </AccordionTrigger>

            <AccordionContent className="flex flex-col bg-neutral-50 p-2">
              {failedKnowledgeBanner()}
              <LinksAccordion
                webData={webData}
                removeWeb={removeWeb}
                sortedWebData={sortedWebData}
                updateWeb={updateWeb}
              />
            </AccordionContent>

            {isURLLimitExceeded && (
              <UpgradeBlurBanner
                currentUsageDescription={`You are using ${user?.subscription.knowledge.urls?.currentUsage} out of ${user?.subscription.knowledge.urls?.limit} websites`}
                upgradeDescription="to add more knowledge from links"
                onUpgradeClick={handleOpenAccountUsageDialog}
              />
            )}
          </AccordionItem>
        </Accordion>

        <Checkbox
          id="loadJs"
          size="sm"
          checked={loadJs}
          onCheckedChange={checked => setValue("loadJs", checked)}
          label="Load JavaScript"
          labelClassName="!text-xs"
        />
      </div>
    </div>
  );
};
