import { Label } from "@/components/ui/label";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";
import { Textarea } from "@/components/ui/textarea";
import { get } from "lodash";

export const ResponseInstruction = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useAgentFormContext();

  const placeholderText = `Your responses should maintain a professional and courteous tone, offering clear, structured, and precise information tailored to business contexts.`;

  return (
    <div className="pb-4">
      <div className="flex items-center gap-1 pb-2">
        <Label htmlFor="inMemoryKnowledge.instruction">Response instruction</Label>
        <HybridTooltipPopover heading="Customize response styles:">
          <p>
            Tailor your Agent's responses to fit any tone or style, from professional to playful, using emojis or
            specific language. Set guidelines on topics to focus on or avoid, ensuring your agent aligns perfectly with
            your desired interaction experience.{" "}
          </p>
        </HybridTooltipPopover>
      </div>

      <Textarea
        id="inMemoryKnowledge.instruction"
        register={register("inMemoryKnowledge.instruction")}
        placeholder={placeholderText}
        maxLength={5000}
        error={get(errors, "inMemoryKnowledge.instruction.message")}
        formValue={watch("inMemoryKnowledge.instruction")}
      />
    </div>
  );
};
