import { useState, useEffect } from "react";
import { Icons } from "@/components/ui/icons";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { ExternalAgentStatus, type ExternalAgent, type InstagramAgent } from "@/types/agent";
import { usePauseExternalAgent } from "@/data/mutations/external-agents/usePauseExternalAgent";
import { useStartExternalAgent } from "@/data/mutations/external-agents/useStartExternalAgent";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { DeleteExternalAgentDialog } from "@/components/dialogs/DeleteExternalAgentDialog/DeleteExternalAgentDialog";
import { IntegrationStatus } from "../IntegrationStatus";
import {
  useCreateInstagramAgent,
  type InstagramPayload,
} from "@/data/mutations/external-agents/useCreateInstagramAgent";

import { Button } from "@/components/ui/button";
import { ExternalAgentPlatform } from "@/types/origin";
const META_IG_APP_ID = import.meta.env.VITE_INSTAGRAM_APP_ID as string;
const REDIRECT_URL = import.meta.env.VITE_INSTAGRAM_REDIRECT_URI as string;
declare let FB: {
  login: (callback: (response: LoginResponse) => void, appInfo: FbAppAccountInfo) => void;
};

interface LoginResponse {
  status: "connected" | "not_authorized" | "unknown";
  authResponse: AuthResponse | null;
}
export interface InstagramResponse {
  message: string;
  status: "fail" | "success";
}
interface AuthResponse {
  code: string;
  userId: string;
  expiresIn: number;
  signedRequest: string;
  reauthorize_required_in: number;
}

interface FbAppAccountInfo {
  config_id: string;
  response_type: string;
  override_default_response_type: boolean;
  extras: {
    feature: string;
    sessionInfoVersion: number;
  };
}

export const InstagramTile = ({ externalAgents }: { externalAgents: ExternalAgent[] | undefined }) => {
  const instagramAgent = externalAgents?.find(
    (agent): agent is InstagramAgent => agent.platform === ExternalAgentPlatform.INSTAGRAM
  );

  const { getValues: getAgentFormValues } = useAgentFormContext();
  const agentId = getAgentFormValues("_id");
  // mutations
  const { mutate: pauseExternalAgent, isPending: isPausing } = usePauseExternalAgent();
  const { mutate: startExternalAgent, isPending: isStarting } = useStartExternalAgent();
  const { mutate: createInstagramAgent, isPending: isInstagramAgentCreating } = useCreateInstagramAgent();

  const isActive = !!instagramAgent?.active && instagramAgent?.status === ExternalAgentStatus.ONLINE;
  const isConfigured =
    instagramAgent?.status === ExternalAgentStatus.ONLINE || instagramAgent?.status === ExternalAgentStatus.PAUSED;

  const [instagramToken, setInstagramToken] = useState("");

  const handleCheckedChange = (checked: boolean) => {
    if (!instagramAgent) {
      if (checked) {
        launchInstagramSignup();
      }
      return;
    }
    const externalAgentId = instagramAgent._id;
    if (checked) {
      startExternalAgent({ externalAgentId, agentId }); // resume
    } else {
      pauseExternalAgent({ externalAgentId, agentId }); // pause
    }
  };

  useEffect(() => {
    if (agentId && instagramToken && !instagramAgent) {
      const payload = {
        agentId,
        token: instagramToken,
        handle: "instagramhandle",
      } as InstagramPayload;
      createInstagramAgent(payload, { onError: error => console.log("error: ", error) });
    }
  }, [createInstagramAgent, agentId, instagramToken, instagramAgent]);

  // Facebook Login with JavaScript SDK
  function launchInstagramSignup() {
    const redirectUri = encodeURIComponent(REDIRECT_URL);

    const instagramAuthUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=${META_IG_APP_ID}&redirect_uri=${redirectUri}&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish%2Cinstagram_business_manage_insights&state=${agentId}`;

    const newWindow = window.open(instagramAuthUrl, "_blank", "width=600,height=600,");
    if (newWindow) {
      newWindow.focus();

      // Poll for changes in localStorage
      const interval = setInterval(() => {
        const authData = localStorage.getItem("instagram_auth_code");
        if (authData) {
          clearInterval(interval); // Stop polling
          localStorage.removeItem("instagram_auth_code"); // Clean up
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const { code, state } = JSON.parse(authData) as any;
          setInstagramToken(code as string);
          console.log("Authorization Code:", code);
          console.log("Agent ID from State:", state);

          // Handle the authorization code as needed
          alert(`Authorization Code Received: ${code}`);
        }

        // Check if the popup has been closed
        if (newWindow.closed) {
          clearInterval(interval);
          console.warn("Popup window closed before completing authorization.");
        }
      }, 500); // Poll every 500ms
    } else {
      alert("Failed to open popup window.");
    }
  }
  return (
    <>
      <div
        className={cn(
          "relative flex max-w-[30.25rem] flex-col justify-between gap-4 overflow-hidden rounded-md p-4",
          instagramAgent ? "border border-neutral-300" : "shadow-integration"
        )}
      >
        <div className="flex gap-4">
          {isInstagramAgentCreating ? (
            <LoadingSpinner className="flex size-16 items-center justify-center" />
          ) : (
            <Icons.Instagram className="size-16 text-[#25D366]" />
          )}
          <div className="flex w-full flex-col gap-4">
            <div className="flex items-center justify-between gap-4">
              <div className="flex gap-4">
                <span className="text-base font-bold">Instagram</span>
                <IntegrationStatus status={instagramAgent?.status} />
              </div>
              <Switch disabled={isPausing || isStarting} checked={isActive} onCheckedChange={handleCheckedChange} />
            </div>
            <p className="text-xs font-medium leading-relaxed">Deploy the agent to a Instagram Business profile.</p>
          </div>
        </div>
        <div className="flex justify-between gap-2">
          {isConfigured ? (
            <span
              className="flex cursor-pointer items-center gap-1 px-4 text-xs font-medium text-neutral-400 hover:underline"
              //onClick={() => }
            >
              <Icons.CheckCircle className="text-success" />

              {instagramAgent?.platformAttributes.display_name && (
                <a
                  href={`https://instagram.com/${instagramAgent?.platformAttributes.handle}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  @{instagramAgent?.platformAttributes.handle} ({instagramAgent?.platformAttributes.display_name})
                </a>
              )}
            </span>
          ) : (
            <Button variant="tertiary" roundedType="rounded" size="tiny" onClick={launchInstagramSignup}>
              Configure
            </Button>
          )}
          {instagramAgent && (
            <DeleteExternalAgentDialog
              externalAgentId={instagramAgent._id}
              platform={ExternalAgentPlatform.INSTAGRAM}
            />
          )}
        </div>
      </div>
    </>
  );
};
