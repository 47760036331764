import { Icons } from "@/components/ui/icons";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { CustomerDetails } from "@/pages/Workspace/components/CustomerSpace/CustomerDetails";
import type { WorkspaceUserWrapped } from "@/components/Profiles/WorkspaceSection/types";
import type { Workspace } from "@/types/workspace";

type SpaceHeaderProps = {
  members?: WorkspaceUserWrapped[];
  agents: number | undefined;
  embeddedAgents: number;
  workspaceId?: Workspace["_id"];
  workspaceName?: string;
};

export const SpaceHeader = ({ members, agents, embeddedAgents, workspaceId, workspaceName }: SpaceHeaderProps) => {
  return (
    <div className="flex flex-row flex-wrap gap-1">
      {members !== undefined && workspaceId && workspaceName && (
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
            <div className="flex cursor-pointer flex-row items-center gap-1 rounded-md px-2 py-1.5 text-xs font-medium text-neutral-750 hover:bg-primary-100">
              <Icons.Team className="size-4 text-primary-400" />
              Members
              <div className="flex h-4 min-w-4 items-center justify-center rounded-full bg-primary-500 px-1 text-white">
                {members.length}
              </div>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" className="flex flex-col gap-3 px-4 py-2.5">
            <CustomerDetails members={members} workspaceId={workspaceId} workspaceName={workspaceName} />
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      <div className="flex flex-row items-center gap-1 px-2 py-1.5 text-xs font-medium text-neutral-750">
        <Icons.Agent className="text-primary-400" />
        Agents
        <div className="flex h-4 min-w-4 items-center justify-center rounded-full bg-primary-500 px-1 text-white">
          {agents}
        </div>
      </div>
      <div className="flex flex-row items-center gap-1 px-2 py-1.5 text-xs font-medium text-neutral-750">
        Embedded
        <div className="flex h-4 min-w-4 items-center justify-center rounded-full bg-[#01A04A] px-1 text-white">
          {embeddedAgents}
        </div>
      </div>
    </div>
  );
};
