import { useEffect, useRef, useState } from "react";
import { ChatMessageReasoningTimelineDot } from "./ChatMessageReasoningTimelineDot";
import { Icons } from "@/components/ui/icons";
import { Avatar } from "@/components/Avatar";
import type { HierarchyTask } from "@/types/conversation";
import { cn } from "@/lib/utils";

type Props = {
  task: HierarchyTask;
  agent:
    | {
        name: string;
        avatar: string;
      }
    | undefined;
  onIsExpandedChange?: () => void;
};

export const ChatMessageReasoningTask = ({ task, agent, onIsExpandedChange }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  const contentRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const truncated = contentRef.current.scrollHeight > contentRef.current.clientHeight;
      setIsTruncated(truncated);
    }
  }, [isExpanded]);

  useEffect(() => {
    onIsExpandedChange?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return (
    <button
      onClick={() => {
        setIsExpanded(prev => !prev);
      }}
      ref={contentRef}
      className={cn("w-full py-2 pl-4 pr-10 text-left text-neutral-400 hover:bg-neutral-50", {
        "line-clamp-2": !isExpanded,
      })}
    >
      <span className="inline-flex w-fit items-center rounded bg-neutral-200 px-1 align-middle text-xs text-neutral-500">
        <Icons.Tools className="size-[14px] text-primary-500" />
        <span className="text-xs leading-4 text-neutral-500">{task.taskType.toUpperCase()}</span>
      </span>
      {agent?.avatar && <Avatar src={agent?.avatar} className="ml-1 inline-flex size-4 align-middle" size="custom" />}
      {agent?.name && <span className="ml-1 text-xs font-medium leading-8 text-neutral-500">{agent?.name}:</span>}
      <span className="ml-1 text-xs font-medium leading-8 text-neutral-400">{task.input}</span>

      <ChatMessageReasoningTimelineDot />
      <Icons.ChevronRight
        className={cn("absolute right-4 top-4 size-4 min-w-4 rotate-90 text-neutral-400", {
          "-rotate-90": isExpanded,
          hidden: !isTruncated && !isExpanded,
        })}
      />
    </button>
  );
};
