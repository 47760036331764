import { motion, AnimatePresence } from "framer-motion";
import { ChatKnowledgeListBanner } from "../ChatKnowledgeList/ChatKnowledgeListBanner";
import type { ChatAgent } from "@/types/conversation";
import { useHandleChatAgentKnowledge } from "../../hooks/useHandleChatAgentKnowledge";
import { ChatKnowledgeDialog } from "../ChatKnowledgeDialog/ChatKnowledgeDialog";
import { cn } from "@/lib/utils";
import { KNOWLEDGE_WIDTH } from "../../contants/chat-contants";

type Props = {
  agent: ChatAgent;
  isPrevieOpen: boolean;
  setIsPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  listBannerClassName?: string;
};

export const ChatKnowledge = ({
  isPrevieOpen,
  setIsPreviewOpen,
  isModalOpen,
  setIsModalOpen,
  agent,
  listBannerClassName,
}: Props) => {
  const {
    selectedChatKnowledge,
    knowledgesCount,
    sortedWebData,
    handleClearPins,
    handleSimplePinChange,
    filteredEmbedKnowledges,
  } = useHandleChatAgentKnowledge(agent);

  return (
    <>
      <AnimatePresence initial={false}>
        {isPrevieOpen && (
          <motion.div
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: KNOWLEDGE_WIDTH }}
            exit={{ opacity: 0, width: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className={cn("flex-shrink-0 overflow-hidden whitespace-nowrap", listBannerClassName)}
          >
            <div className="flex max-h-full flex-col pb-8 pl-8 pr-2 pt-4">
              <ChatKnowledgeListBanner
                onClose={() => setIsPreviewOpen(false)}
                selectedChatKnowledge={selectedChatKnowledge}
                knowledgesCount={knowledgesCount}
                sortedWebData={sortedWebData}
                handleClearPins={handleClearPins}
                handleSimplePinChange={handleSimplePinChange}
                filteredEmbedKnowledges={filteredEmbedKnowledges}
                onOpenModal={() => setIsModalOpen(true)}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <ChatKnowledgeDialog
        isOpen={isModalOpen}
        onOpenChange={() => setIsModalOpen(false)}
        selectedChatKnowledge={selectedChatKnowledge}
        embedKnowledges={filteredEmbedKnowledges}
        knowledgesCount={knowledgesCount}
        handleSimplePinChange={handleSimplePinChange}
        handleClearPins={handleClearPins}
        sortedWebData={sortedWebData}
      />
    </>
  );
};
