import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { ROUTES } from "@/constants/routes";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useGetConversationMessages } from "@/data/queries/useGetConversationMessages";
import type { ChatAgent } from "@/types/conversation";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { useLocation, useNavigate } from "react-router-dom";

export const NewConversationButton = ({ agents }: { agents: ChatAgent[] }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { createNewConversation, conversationId, isPreview, conversationDetails } = useChatContext();

  const { data: conversation } = useGetConversationMessages({
    conversationId,
  });

  const isDisabled = !conversationDetails || conversation?.messages.length === 0;

  const handleCreateNewConversation = async () => {
    try {
      let newConversationId: string | undefined;

      if (isPreview) {
        newConversationId = await createNewConversation([agents[0]._id]);
      } else {
        const agentIds = agents.map(agent => agent._id);
        newConversationId = await createNewConversation(agentIds);
      }

      if (newConversationId && pathname.includes("/conversation/")) {
        const newPath = ROUTES.conversation(newConversationId);

        navigate(newPath);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Tooltip>
      <TooltipTrigger asChild className="disabled:cursor-not-allowed disabled:opacity-40" disabled={isDisabled}>
        <IconButton
          variant="tertiary"
          size="medium"
          roundedType="rounded"
          icon={<Icons.NewConversation className="h-6 w-6" />}
          disabled={isDisabled}
          onClick={handleCreateNewConversation}
        />
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent align="end">New conversation</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};
