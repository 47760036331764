import { AgentForm } from "@/components/AgentForm/AgentForm";
import type { AgentFormOnSubmit } from "@/components/AgentForm/types/AgentFormTypes";
import { ROUTES } from "@/constants/routes";
import { useCreateAgent } from "@/data/mutations/useCreateAgent";
import { AgentState } from "@/types/agent";
import { sleep } from "@/utils/sleep";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import type { Workspace } from "@/types/workspace";

export const CreateAgentPage = () => {
  const navigate = useNavigate();
  const params: { workspaceId: Workspace["_id"] } = useParams();

  const { mutate: createAgent, isPending: isAgentCreatingPending } = useCreateAgent(params?.workspaceId);

  const handleCreateAgent: AgentFormOnSubmit = ({ formValues, onSetFormError, onReset }) => {
    createAgent(params.workspaceId === "none" ? formValues : { ...formValues, workspaceId: params.workspaceId }, {
      onSuccess: agentResponse => {
        onReset?.();
        if (agentResponse.state === AgentState.AGENT_READY) {
          toast.success("Agent created");
        }
        void sleep(100).then(() => navigate(ROUTES.editAgent(agentResponse._id)));
      },
      onError: e => {
        if (e.response?.data.message === "This name is already taken. Please try with a different one") {
          onSetFormError("name", { message: "Agent with this name already exists" });
        }
      },
    });
  };

  const isLoading = isAgentCreatingPending;

  return (
    <AgentForm
      agentCreation
      onSubmitForm={handleCreateAgent}
      disableFormNavigation={isLoading}
      submitBtn={{
        text: "Create Agent",
        disabled: isAgentCreatingPending,
        loading: isAgentCreatingPending,
      }}
    />
  );
};
