import { MESSAGE_HIGHLIGHT_CLASS } from "@/components/Chat/contants/chat-contants";
import { useLayoutEffect } from "react";

export const useHighlightSearchedText = ({
  textToHighlight,
  enabled,
}: {
  textToHighlight: string;
  enabled: boolean;
}) => {
  useLayoutEffect(() => {
    if (!textToHighlight) {
      return;
    }

    if (!enabled) {
      return;
    }

    const allMessagesContainers = document.querySelectorAll(`.${MESSAGE_HIGHLIGHT_CLASS}`);

    allMessagesContainers.forEach(messageContainer => {
      const originalHTML = messageContainer.innerHTML;
      const highlightedHTML = originalHTML.replace(
        new RegExp(textToHighlight, "gi"),
        (match: string) => `<mark class="bg-feedback-warning">${match}</mark>`
      );
      if (originalHTML !== highlightedHTML) {
        messageContainer.innerHTML = highlightedHTML;
      }
    });

    return () => {
      allMessagesContainers.forEach(messageContainer => {
        const text = messageContainer.innerHTML;
        const cleanedText = text.replace(/<\/?mark class="bg-feedback-warning">/gi, "");
        messageContainer.innerHTML = cleanedText;
      });
    };
  }, [textToHighlight, enabled]);
};
