import { WorkspaceMyAgents } from "@/pages/Workspace/components/WorkspaceMyAgents";
import { useGetUser } from "@/data/queries/useGetUser";
import type { Workspace } from "@/types/workspace";
import { useGetMultipleWorkspaceAgents } from "@/data/queries/workspace/useGetMultipleWorkspaceAgents";
import { Icons } from "@/components/ui/icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { IconButton } from "@/components/ui/icon-button";
import { Button } from "@/components/ui/button";
import { ROUTES } from "@/constants/routes";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useGetMultipleWorkspaceUsers } from "@/data/queries/workspace/useGetMultipleWorkspaceUsers";
import MultiAgentsChatDialog from "@/components/dialogs/MultiAgentsChatDialog/MultiAgentsChatDialog";
import type { Agent, FullAgent } from "@/types/agent";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { Input } from "@/components/ui/input";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { SpaceHeader } from "@/pages/Workspace/components/CustomerSpace/SpaceHeader";
import type { SORT_BY_VALUES } from "@/pages/Workspace/components/SortAgentsByNameDropdown";
import { SortAgentsByNameDropdown } from "@/pages/Workspace/components/SortAgentsByNameDropdown";
import { useDebounceValue } from "usehooks-ts";
import { DEBOUNCE_TIME_MS } from "@/pages/Workspace/components/constants";
import { filterAndSortAgents } from "@/pages/Workspace/components/utils";
import type { WorkspaceUserWrapped } from "@/components/Profiles/WorkspaceSection/types";
import { WorkspaceAccessRoles } from "@/components/Profiles/WorkspaceSection/types";
import type { WorkspacePermissions } from "@/pages/Workspace/components/CustomerSpace/types";

type CustomerSpaceProps = {
  workspaceId: Workspace["_id"];
};

export const CustomerSpace = ({ workspaceId }: CustomerSpaceProps) => {
  const navigate = useNavigate();
  const { user } = useGetUser();
  const { isMobile } = useMediaQueriesContext();
  const {
    data: agents,
    isPending: isAgentsWithConfigPending,
    isSuccess: isAgentsWithConfigSuccess,
  } = useGetMultipleWorkspaceAgents({ id: workspaceId });
  const { data: workspaceUsers } = useGetMultipleWorkspaceUsers({ id: workspaceId });
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState<SORT_BY_VALUES | null>(null);
  const [debouncedSearchValue] = useDebounceValue(searchValue, DEBOUNCE_TIME_MS);
  const [filteredAgents, setFilteredAgents] = useState<FullAgent[]>([]);
  const isScreenSm = !useTailwindMediaQuery("sm");

  const [isMultiAgentsChatDialogOpen, setIsMultiAgentsChatDialogOpen] = useState(false);

  const handleCreateMultiAgentsChat = ({ agentIds, manager }: { agentIds: string[]; manager: Agent["_id"] }) => {
    navigate(getNewConversationRoute(agentIds, manager));
  };

  useEffect(() => {
    setFilteredAgents(filterAndSortAgents({ debouncedSearchValue, sortBy, myAgents: agents }));
  }, [debouncedSearchValue, agents, sortBy]);

  const users: WorkspaceUserWrapped[] = useMemo(() => {
    const editors = workspaceUsers?.editors.map(user => ({ ...user, role: WorkspaceAccessRoles.EDITOR })) || [];
    const viewers = workspaceUsers?.viewers.map(user => ({ ...user, role: WorkspaceAccessRoles.VIEWER })) || [];
    const members = workspaceUsers?.users.map(user => ({ ...user, role: WorkspaceAccessRoles.USER })) || [];
    const owner = workspaceUsers?.owner ? [{ ...workspaceUsers.owner, role: WorkspaceAccessRoles.OWNER }] : [];

    return [...owner, ...editors, ...viewers, ...members];
  }, [workspaceUsers]);

  const workspacePermissions: WorkspacePermissions | undefined = useMemo(
    () =>
      users.length
        ? {
            isOwner: !!users.find(
              workspaceUser => workspaceUser._id === user?._id && workspaceUser.role === WorkspaceAccessRoles.OWNER
            ),
            isEditor: !!users.find(
              workspaceUser => workspaceUser._id === user?._id && workspaceUser.role === WorkspaceAccessRoles.EDITOR
            ),
            isMember: !!users.find(
              workspaceUser => workspaceUser._id === user?._id && workspaceUser.role === WorkspaceAccessRoles.USER
            ),
            isViewer: !!users.find(
              workspaceUser => workspaceUser._id === user?._id && workspaceUser.role === WorkspaceAccessRoles.VIEWER
            ),
          }
        : undefined,
    [users, user]
  );

  const isWorkspaceUser = users.some(user => user._id === user?._id);

  return (
    <>
      <h4 className="sticky top-0 z-10 flex w-full items-center justify-between bg-fade-out-vertical-primary px-6 py-4 text-left font-bold">
        <SpaceHeader
          members={users}
          agents={agents?.length ?? 0}
          embeddedAgents={agents?.filter(agent => agent.publish?.isPublished).length || 0}
          workspaceId={workspaceId}
          workspaceName={workspaceUsers?.name}
        />
        <div className="flex flex-row gap-4">
          <SortAgentsByNameDropdown sortBy={sortBy} setSortBy={setSortBy} />
          {!isMobile && (
            <Input
              placeholder="Search Agents"
              value={searchValue}
              inputClassName="placeholder:text-slate-400 !pr-9 !sm:pr-9 h-10"
              onChange={e => setSearchValue(e.target.value)}
              handleClear={() => setSearchValue("")}
            />
          )}
          {(workspacePermissions?.isEditor || workspacePermissions?.isOwner) && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                {isScreenSm ? (
                  <IconButton icon={<Icons.Plus />} size="medium" />
                ) : (
                  <Button prefixIcon={<Icons.Plus />}>New</Button>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="flex flex-col gap-1 p-1.5">
                <DropdownMenuItem
                  className="flex items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
                  onClick={() => navigate(ROUTES.createAgent(workspaceId))}
                >
                  <Icons.Bot /> Create Agent
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="flex items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
                  onClick={() => setIsMultiAgentsChatDialogOpen(true)}
                >
                  <Icons.AddGroup /> Multi Agents Chat
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </h4>
      <WorkspaceMyAgents
        workspaceId={workspaceId}
        agents={filteredAgents ?? []}
        isLoading={isAgentsWithConfigPending}
        isSuccess={isAgentsWithConfigSuccess}
        canEdit={isWorkspaceUser ? (workspacePermissions?.isOwner || workspacePermissions?.isEditor) ?? false : true}
        canTransferOwnership={isWorkspaceUser ? workspacePermissions?.isOwner ?? false : true}
        workspacePermissions={workspacePermissions}
        canMoveAgent={workspacePermissions?.isOwner ?? false}
      />

      <MultiAgentsChatDialog
        isOpen={isMultiAgentsChatDialogOpen}
        setIsOpen={setIsMultiAgentsChatDialogOpen}
        onSubmit={handleCreateMultiAgentsChat}
        headerText="Select Agents to Create Group Chat"
      />
    </>
  );
};
