import { useGetBasicStats } from "@/data/queries/stats/useGetBasicStats";
import StatsTopCard from "./StatsTopCard";
import { Icons } from "@/components/ui/icons";
import StatsTopCardSkeleton from "./loaders/StatsTopCardSkeleton";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import { statsMockBasicData } from "../constants/statsMockData";
import { ROUTES } from "@/constants/routes";

export const StatsBasic = () => {
  const {
    currentRange,
    startDate,
    endDate,
    activeChannelsOrigins,
    isUpgradeNeeded,
    activeWorkspaceId,
    isDefaultWorkspace,
  } = useStatsContext();

  const { data: stats, isFetching } = useGetBasicStats({
    currentRange,
    startDate,
    endDate,
    channels: activeChannelsOrigins,
    enabled: !isUpgradeNeeded,
    workspaceId: isDefaultWorkspace ? undefined : activeWorkspaceId,
  });

  const isMobile = !useTailwindMediaQuery("xl");
  const skeletonsNum = isMobile ? 2 : 4;

  const cardsData: {
    title: string;
    value: number;
    trend: number | null | undefined;
    icon?: React.ReactNode;
    link?: {
      to: string;
      label: string;
    };
  }[] = [
    {
      title: "Messages",
      value: stats?.messages.total ?? 0,
      trend: stats?.messages.trend,
      icon: <Icons.Send2 className="size-4 text-primary-400 @xl/card:size-9 @[280px]/card:size-6" />,
    },
    {
      title: "Conversations",
      value: stats?.conversations.total ?? 0,
      trend: stats?.conversations.trend,
      icon: <Icons.Message className="size-4 text-primary-400 @xl/card:size-9 @[280px]/card:size-6" />,
    },
    {
      title: "Views",
      value: stats?.views.total ?? 0,
      trend: stats?.views.trend,
      icon: <Icons.Eye className="size-4 text-primary-400 @xl/card:size-9 @[280px]/card:size-6" />,
    },
    {
      title: "Lead Generation",
      value: stats?.leadGeneration.total ?? 0,
      trend: stats?.leadGeneration.trend,
      icon: <Icons.Users className="size-4 text-primary-400 @xl/card:size-9 @[280px]/card:size-6" />,
      link: {
        to: ROUTES.statsLeads,
        label: "View leads",
      },
    },
  ];

  const mockData = statsMockBasicData.map(card => {
    return {
      ...card,
      icon: <Icons.Send2 className="size-4 text-primary-400 @xl/card:size-9 @[280px]/card:size-6" />,
    };
  });

  const dataToShow = isUpgradeNeeded ? mockData : cardsData;

  return (
    <div>
      <div className="grid grid-cols-1 gap-2.5 xs:grid-cols-2 sm:gap-7 xl:grid-cols-4">
        {isFetching
          ? Array.from({ length: skeletonsNum }).map((_, index) => <StatsTopCardSkeleton key={index} />)
          : dataToShow.map(card => {
              return (
                <StatsTopCard
                  key={card.title}
                  title={card.title}
                  value={card.value}
                  trend={card.trend}
                  icon={card.icon}
                  blur={isUpgradeNeeded}
                  link={card.link}
                />
              );
            })}
      </div>
    </div>
  );
};
