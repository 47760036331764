import { CustomerTeamMembers } from "@/pages/Workspace/components/CustomerSpace/CustomerTeamMembers";
import type { WorkspaceUserWrapped } from "@/components/Profiles/WorkspaceSection/types";
import { WorkspaceSectionTabs } from "@/components/Profiles/WorkspaceSection/types";
import { useGetUser } from "@/data/queries/useGetUser";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import type { Workspace } from "@/types/workspace";

type CustomerDetailsProps = {
  members: WorkspaceUserWrapped[];
  workspaceId: Workspace["_id"];
  workspaceName: Workspace["name"];
};

export const CustomerDetails = ({ members, workspaceId, workspaceName }: CustomerDetailsProps) => {
  const { user } = useGetUser();
  const { setNavigationProfileOpen } = useNavigationProfileContext();

  const isAdmin = members.find(member => member._id === user?._id);

  return (
    <>
      <div className="flex w-full flex-row items-center justify-between gap-2 border-b-[1.5px] pb-2.5">
        <div className="flex flex-col gap-1  font-bold">
          {workspaceName}
          <span className="text-xxs font-medium text-neutral-400">
            {members?.length} {members?.length === 1 ? "member" : "members"}
          </span>
        </div>
        {isAdmin && (
          <IconButton
            variant="tertiary"
            roundedType="rounded"
            className="bg-primary-50 text-primary-400 hover:bg-primary-100"
            icon={<Icons.Plus className="size-4 text-primary-400" />}
            onClick={() =>
              setNavigationProfileOpen({
                open: true,
                section: workspaceId,
                mode: "workspace",
                activeTab: WorkspaceSectionTabs.MEMBERS,
                activeStep: "inviteTeamMember",
              })
            }
          />
        )}
      </div>

      <div className="pb-2">
        <CustomerTeamMembers members={members} />
      </div>
    </>
  );
};
