import { NavigationProfileSections } from "@/components/Profiles/NavigationProfileSection";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { useGetCustomerBillingInfo } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";

export const BillingWarningBanner = () => {
  const { data: billingInfo } = useGetCustomerBillingInfo();
  const { setNavigationProfileOpen } = useNavigationProfileContext();

  if (!billingInfo?.warning) {
    return null;
  }
  return (
    <div
      className="relative z-10 flex h-16 cursor-pointer flex-col items-center justify-center gap-2 overflow-hidden border border-red-500 bg-red-100 p-5 text-base font-bold text-red-700 sm:p-6 sm:text-lg"
      onClick={() =>
        setNavigationProfileOpen({
          open: true,
          section: NavigationProfileSections.ACCOUNT.BILLING,
          mode: "account",
        })
      }
    >
      {billingInfo.warning}
    </div>
  );
};
