import { useRef } from "react";
import { LoadingSpinner } from "../ui/loading-spinner";
import { FetchNextPageTrigger } from "../Chat/components/FetchNextPageTrigger";
import { differenceInMinutes, format } from "date-fns";
import type { Message } from "@/types/conversation";
import { cn } from "@/lib/utils";
import { useAdaptiveScrollbar } from "@/hooks/useAdaptiveScrollbar";
import { ChatHistoryPreviewConversationSummary } from "./components/ChatHistoryPreviewConversationSummary";
import { MessageCloudTranslated } from "../Chat/components/MessageCloud/MessageCloudTranslated";

type Props = {
  conversationSummary?: string;
  messages: Message[];
  isLoadingMessages: boolean;
  conversationUsers: {
    _id: string;
    name: string;
    avatar: string;
  }[];
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  className?: string;
  isMultiAgent?: boolean;
};

export const ChatHistoryPreview = ({
  conversationSummary,
  messages,
  isLoadingMessages,
  conversationUsers,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  className,
  isMultiAgent,
}: Props) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const botNames = [
    ...new Set(messages.filter(message => "bot" in message).map(message => message?.bot?.name ?? "") ?? []),
  ];

  useAdaptiveScrollbar(scrollContainerRef, 8);

  return (
    <div className={cn("flex size-full flex-col rounded border border-solid border-cloud-dark px-2", className)}>
      {conversationSummary && (
        <ChatHistoryPreviewConversationSummary text={conversationSummary} className="mx-4 mt-2.5" />
      )}

      <div ref={scrollContainerRef} className="my-2 flex flex-col-reverse overflow-auto px-2">
        {isLoadingMessages && <LoadingSpinner className="mx-auto my-5" />}
        {messages.map((message, index) => {
          const currentMessageTime = new Date(message.createdAt);
          const prevMessageTime = index > 0 && new Date(messages[index + 1]?.createdAt);
          const timeDiff = prevMessageTime && differenceInMinutes(currentMessageTime, prevMessageTime);

          return (
            <div
              key={`${message.conversationId}-message-${index}`}
              className={cn("bot" in message ? "bg-neutral-100" : "")}
            >
              <MessageCloudTranslated
                message={message.text}
                isBotMessage={"bot" in message}
                key={message._id + message.text}
                messageId={message._id}
                conversationId={message.conversationId}
                trace={message.trace}
                vote={message.vote}
                messageIndex={index}
                createdAt={message.createdAt}
                isBookmark={message.isBookmark}
                agent={message.bot}
                setDocPreviewPage={undefined}
                setDocumentInPreview={undefined}
                botNames={botNames}
                isGeneratingBotResponse={message.isGenerating}
                attachments={message.attachments}
                messageUser={message.user}
                disableActions
                displayUserDetails={{
                  name: conversationUsers.find(user => user._id === message.user)?.name ?? "",
                  avatar: conversationUsers.find(user => user._id === message.user)?.avatar ?? "",
                }}
                showAgentName={isMultiAgent}
              />
              {index === messages.length - 1 || (timeDiff && timeDiff > 5) ? (
                <div className="my-1 flex justify-center text-xs text-gray-400">
                  {format(currentMessageTime, "dd MMM yyyy HH:mm")}
                </div>
              ) : null}
            </div>
          );
        })}
        {isFetchingNextPage && <LoadingSpinner className="mx-auto my-5" />}
        {hasNextPage && <FetchNextPageTrigger fetchNextPage={fetchNextPage} />}
      </div>
    </div>
  );
};
