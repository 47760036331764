import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";

type Props = {
  disabled: boolean;
  onDeselectAllClick: () => void;
  onDownloadClick: () => void;
  isLoadingDownload: boolean;
};

export const ChatHistoryDialogDownloadFooter = ({
  disabled,
  onDeselectAllClick,
  onDownloadClick,
  isLoadingDownload,
}: Props) => {
  return (
    <div className="flex items-center gap-4">
      {!disabled && (
        <Button variant="tertiary" onClick={onDeselectAllClick}>
          Deselect all
        </Button>
      )}
      <Button onClick={onDownloadClick} loading={isLoadingDownload} prefixIcon={<Icons.Download />} disabled={disabled}>
        Download
      </Button>
    </div>
  );
};
