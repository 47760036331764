import type { DateRangeType } from "@/types/datepicker";
import { DateRangeTypes } from "@/types/datepicker";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { StatsProps } from "./StatsContext";
import { StatsContext } from "./StatsContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import type { TypeFromConstObject } from "@/types/types";
import type { Agent } from "@/types/agent";
import { Origins, type Origin } from "@/types/origin";
import { useGetUser } from "@/data/queries/useGetUser";
import { SubscriptionTiers } from "@/types/subscriptions";
import { useGetMyWorkspaces } from "@/data/queries/workspace/useGetMyWorkspaces";

export const StatsPageChannelsTabs = {
  ALL_CHANNELS: { origin: null, description: "All channels" },
  WEB: { origin: Origins.WEB, description: "Web" },
  EMBEDDED: { origin: Origins.EMBEDDED, description: "Embedded" },
  AGENT_SPACE: { origin: Origins.AGENT_SPACE, description: "Agent Space" },
  WORKSPACE: { origin: Origins.WORKSPACE, description: "Workspace" },
  DISCORD: { origin: Origins.DISCORD, description: "Discord" },
  SLACK: { origin: Origins.SLACK, description: "Slack" },
} as const;

export const MY_AGENTS_OPTION = { workspaceId: "My Agents", name: "My Agents" } as const;

export type StatsPageChannelTab = TypeFromConstObject<typeof StatsPageChannelsTabs>;

export const statsChannelsTabs = Object.values(StatsPageChannelsTabs);

type StatsProviderProps = {
  children: React.ReactNode;
};

export const StatsProvider = ({ children }: StatsProviderProps) => {
  const location = useLocation() as {
    state: { tab: StatsPageChannelTab | null } | null;
    pathname: string;
  };
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { user } = useGetUser();
  const { data: myWorkspaces } = useGetMyWorkspaces();
  const [activeWorkspaceId, setActiveWorkspaceId] = useState<string | undefined>(undefined);

  const isUpgradeNeeded = !myWorkspaces?.length
    ? user?.subscription.tier === SubscriptionTiers.FREE || user?.subscription.tier === SubscriptionTiers.STARTUP
    : !myWorkspaces?.length;

  const isDefaultWorkspace = activeWorkspaceId === undefined || activeWorkspaceId === MY_AGENTS_OPTION.workspaceId;

  const workspaceOptions = useMemo(() => {
    const options = [];
    const isTierUpgradeNeeded =
      user?.subscription.tier === SubscriptionTiers.FREE || user?.subscription.tier === SubscriptionTiers.STARTUP;

    if (!isTierUpgradeNeeded || (isTierUpgradeNeeded && !myWorkspaces?.length)) {
      options.push(MY_AGENTS_OPTION);
    }

    if (myWorkspaces?.length) {
      options.push(...myWorkspaces.map(workspace => ({ workspaceId: workspace._id, name: workspace.name })));
    }

    return options;
  }, [myWorkspaces, user]);

  useEffect(() => {
    const workspaceIdFromUrl = params.get("workspaceId");

    setActiveWorkspaceId(workspaceIdFromUrl || workspaceOptions[0].workspaceId);
  }, [workspaceOptions, params]);

  const [currentRange, setCurrentRange] = useState<DateRangeType>(DateRangeTypes.Last7Days);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [selectedAgents, setSelectedAgents] = useState<Agent[]>([]);

  const [chatHistoryDialogProps, setChatHistoryDialogProps] = useState<{
    agent: Agent;
    channel?: Origin;
    downvotedOnly?: boolean;
  } | null>(null);
  const isChatHistoryDialogOpen = !!chatHistoryDialogProps;

  const activeChannelTab: StatsPageChannelTab = location.state?.tab ?? StatsPageChannelsTabs.ALL_CHANNELS;
  const activeChannelsOrigins =
    activeChannelTab.description === StatsPageChannelsTabs.ALL_CHANNELS.description ? [] : [activeChannelTab.origin];

  const switchChannelTab = useCallback(
    ({ newTab }: { newTab: StatsPageChannelTab }) => {
      navigate(location.pathname, { replace: true, state: { tab: newTab } });
    },
    [location.pathname, navigate]
  );

  const value: StatsProps = {
    currentRange,
    setCurrentRange,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedAgents,
    setSelectedAgents,
    chatHistoryDialogProps,
    setChatHistoryDialogProps,
    isChatHistoryDialogOpen,
    activeChannelsOrigins,
    activeChannelTab,
    switchChannelTab,
    isUpgradeNeeded,
    activeWorkspaceId,
    setActiveWorkspaceId,
    workspaceOptions,
    isDefaultWorkspace,
  };

  return <StatsContext.Provider value={value}>{children}</StatsContext.Provider>;
};
