import { useQuery } from "@tanstack/react-query";
import { getCustomVoiceText } from "@/data/customVoice";
import type { VoiceProfileLanguage } from "@/types/voice";

export const getCustomVoiceTextQueryKey = (language: VoiceProfileLanguage) =>
  ["customVoiceText", { language }] as const;

type UseGetCustomVoiceTextProps = {
  language: VoiceProfileLanguage;
  enabled?: boolean;
};

export const useGetCustomVoiceText = ({ language, enabled = true }: UseGetCustomVoiceTextProps) => {
  const query = useQuery({
    queryKey: getCustomVoiceTextQueryKey(language),
    queryFn: () => getCustomVoiceText(language),
    enabled,
    staleTime: 3 * 60 * 1000, // 3 minutes
    retry: 1,
  });

  return {
    ...query,
    customVoiceText: query.data,
  };
};
