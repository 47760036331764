import { Avatar } from "@/components/Avatar";
import { Icons } from "@/components/ui/icons";
import type { ChatAgent } from "@/types/conversation";

type Props = {
  agent: ChatAgent;
  onClick: () => void;
};

export const ChatInputFormMultiAgentBtn = ({ agent, onClick }: Props) => {
  return (
    <button
      className="flex items-center gap-2.5 rounded-lg border border-neutral-50 bg-white px-3 py-2 shadow-[0px_2px_10px_0px_rgba(124,141,181,0.12)]"
      type="button"
      onClick={onClick}
    >
      <Avatar src={agent.avatar} size="custom" className="size-6 min-w-6 rounded-md" />
      <span className="text-sm font-semibold text-neutral-600">{agent.name}</span>
      <Icons.PlusSquare className="size-6 text-neutral-200" />
    </button>
  );
};
