export const scrollToElementWithinParent = ({ parentId, elementId }: { parentId: string; elementId: string }) => {
  const parent = document.getElementById(parentId);
  const element = document.getElementById(elementId);

  if (!parent || !element) {
    return;
  }

  const offsetTop = element.offsetTop - parent.offsetTop;

  parent.scrollTo({
    top: offsetTop,
    behavior: "smooth",
  });
};
