import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { SingleAgentEngagementStats, StatsInterval, StatsQueryFilters } from "@/types/stats";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";
import type { Agent } from "@/types/agent";
import type { Origin } from "@/types/origin";
import type { Workspace } from "@/types/workspace";

export const singleAgentEngagementStats = {
  all: ["singleAgentEngagementStats"] as const,
  params: ({
    agentId,
    startDateParam,
    endDateParam,
    channels,
    interval,
    workspaceId,
  }: {
    agentId: Agent["_id"];
    startDateParam: string;
    endDateParam: string;
    channels: Origin[];
    interval: StatsInterval;
    workspaceId?: Workspace["_id"];
  }) =>
    [
      ...singleAgentEngagementStats.all,
      agentId,
      startDateParam,
      endDateParam,
      channels,
      interval,
      workspaceId,
    ] as const,
};

const getSingleAgentEngagementStats = async ({
  agentId,
  startDate,
  endDate,
  channels,
  interval,
  workspaceId,
}: {
  agentId: Agent["_id"];
  startDate: string;
  endDate: string;
  channels: Origin[];
  interval: StatsInterval;
  workspaceId?: Workspace["_id"];
}) => {
  const { data } = await restClient.get<SingleAgentEngagementStats>(
    workspaceId
      ? apiPaths.getWorkspaceSingleAgentEngagementStats(agentId, workspaceId)
      : apiPaths.getSingleAgentEngagementStats(agentId),
    {
      params: {
        startDate,
        endDate,
        channels: channels.join(","),
        interval,
        timezone: StatisticsHelpers.getTimezone(),
      },
    }
  );
  return data;
};

type Props = StatsQueryFilters & {
  agentId: Agent["_id"];
  interval: StatsInterval;
  enabled?: boolean;
};

export const useGetSingleAgentEngagementStats = (props: Props) => {
  const { currentRange, startDate, endDate, channels, interval, enabled, agentId, workspaceId } = props;

  const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
  const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

  const query = useQuery({
    queryKey: singleAgentEngagementStats.params({
      agentId,
      startDateParam,
      endDateParam,
      channels,
      interval,
      workspaceId,
    }),
    queryFn: () =>
      getSingleAgentEngagementStats({
        agentId,
        startDate: startDateParam,
        endDate: endDateParam,
        channels,
        interval,
        workspaceId,
      }),
    enabled: !!startDateParam && !!endDateParam && enabled,
    placeholderData: keepPreviousData,
  });

  return query;
};
