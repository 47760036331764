import { NotificationAvatar } from "@/components/Notifications/UngroupedNotification/NotificationAvatar";
import { NotificationDescription } from "@/components/Notifications/UngroupedNotification/NotificationDescription";
import { NotificationMenu } from "../NotificationMenu";

import type { AgentNotification } from "@/types/notification";
import { cn } from "@/lib/utils";

type NotificationItemProps = {
  notification: AgentNotification;
};

export const NotificationItem = ({ notification }: NotificationItemProps) => {
  return (
    <div
      className={cn(
        "flex items-center justify-between gap-2 border-b border-gray-200 px-1 py-4",
        !notification.read && "bg-primary-50"
      )}
    >
      <div className="flex items-center gap-2">
        <NotificationAvatar
          isRead={notification.read}
          imageSrc={notification?.imgSrc}
          isPersonNotification={!!notification?.entityId}
        />
        <NotificationDescription notification={notification} />
      </div>
      <div className="h-full px-4 py-2">
        <NotificationMenu notification={notification} />
      </div>
    </div>
  );
};
