import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { notificationsKeys } from "@/data/queries/notifications/useGetNotifications";
import type { AgentNotification } from "@/types/notification";

type Payload = {
  ids: AgentNotification["_id"][];
};

const acknowledgeNotifications = async (payload: Payload) => {
  const { data } = await restClient.post<Response>(apiPaths.acknowledgeNotifications, payload);
  return data;
};

export const useAcknowledgeNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<{ message: string }>, Payload>({
    mutationFn: acknowledgeNotifications,
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries({ queryKey: notificationsKeys.all })]);
    },
  });
};
