import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { useBookmarkChatHistoryContext } from "@/contexts/BookmarkChatHistoryContext/useBookmarkChatHistoryContext";
import ChatHistoryNavBtn from "./ChatHistoryNavBtn";
import { useGetChatHistoryWithAgentsLimited } from "@/data/queries/useGetChatHistoryWithAgents";
import { NAV_HISTORY_LIMIT } from "@/constants/navHistoryLimit";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import type { Agent } from "@/types/agent";
import { LocalStorageHelpers } from "@/utils/LocalStorageHelpers";
import GroupConversationAgentsName from "../GroupCoversations/GroupConversationAgentsName";
import { cn } from "@/lib/utils";
import GroupConversationAvatarSmall from "@/components/GroupCoversations/GroupConversationsAvatarSmall";
import { Icons } from "@/components/ui/icons";
import { useState } from "react";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";
import MultiAgentsChatDialog from "@/components/dialogs/MultiAgentsChatDialog/MultiAgentsChatDialog";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import _ from "lodash";

export const AgentHistoryNav = ({
  onCloseMobileNav,
  isGuestUser,
}: {
  onCloseMobileNav?: () => void;
  isGuestUser: boolean;
}) => {
  const navigate = useNavigate();
  const { setIsOpen: setIsBookmarkCharHistoryOpen } = useBookmarkChatHistoryContext();
  const { navigationSectionOpen } = useLayoutContext();
  const { conversationAgentIds } = useChatContext();

  const [isMultiAgentsChatDialogOpen, setIsMultiAgentsChatDialogOpen] = useState(false);
  const { showLoginDialog } = useLoginDialogContext();

  const { data: history } = useGetChatHistoryWithAgentsLimited({ limit: NAV_HISTORY_LIMIT });

  const handleAgentsClick = ({ agentIds }: { agentIds: Agent["_id"][] }) => {
    const lastConversationId = LocalStorageHelpers.getAgentsLocalStorageConversation(agentIds);

    const newPath = lastConversationId ? ROUTES.conversation(lastConversationId) : getNewConversationRoute(agentIds);

    navigate(newPath);
  };

  const getAgentsState = ({
    agentsStates,
    agentsCount,
  }: {
    agentsStates: (Agent["state"] | undefined)[];
    agentsCount: number;
  }) => {
    if (agentsStates.length !== agentsCount || agentsStates.some(state => !state)) {
      return null;
    }

    const areAllStatesTheSame = new Set(agentsStates).size === 1;

    return areAllStatesTheSame ? agentsStates[0] : null;
  };

  const handleCreateMultiAgentsChat = ({ agentIds, manager }: { agentIds: string[]; manager: Agent["_id"] }) => {
    navigate(getNewConversationRoute(agentIds, manager));
    setIsMultiAgentsChatDialogOpen(false);
  };
  return (
    <>
      <ChatHistoryNavBtn
        setIsOpen={isOpen => {
          setIsBookmarkCharHistoryOpen(isOpen);
          onCloseMobileNav?.();
        }}
      />

      <div className="flex flex-col place-items-center gap-3">
        <div
          className={cn(
            "ml-6 flex w-full flex-col gap-2 border-l-2 border-l-primary-400 pl-2",
            !navigationSectionOpen && "ml-0 pl-1"
          )}
        >
          {history?.map(historyItem => {
            const agentIds = historyItem.agents.map(agent => agent._id);
            const isActive = _.isEqual(agentIds, conversationAgentIds);

            return (
              <div
                key={agentIds.join("-")}
                className={cn(
                  "flex w-[94%] cursor-pointer select-none flex-row gap-2 rounded-lg p-1.5 hover:bg-primary-100 hover:text-primary-400",
                  !navigationSectionOpen && "w-full",
                  {
                    "bg-primary-100 text-primary-400": isActive,
                  }
                )}
                onClick={() => {
                  handleAgentsClick({
                    agentIds,
                  });
                }}
              >
                <GroupConversationAvatarSmall
                  avatars={historyItem.agents.map(agent => agent?.avatar)}
                  className="size-4 cursor-pointer"
                  agentState={getAgentsState({
                    agentsStates: historyItem.agents.map(agent => agent.state),
                    agentsCount: historyItem.agents.length,
                  })}
                />
                {navigationSectionOpen && (
                  <GroupConversationAgentsName
                    as="p"
                    names={historyItem.agents.map(agent => agent.name)}
                    className="text-xs font-medium text-neutral-500 hover:text-primary-400 md:text-xs"
                  />
                )}
              </div>
            );
          })}
          <div
            className={cn(
              "flex w-[94%] cursor-pointer select-none flex-row items-center gap-2 rounded-lg p-1.5 text-xs text-neutral-500 hover:bg-primary-100 hover:text-primary-400",
              !navigationSectionOpen && "w-full"
            )}
            onClick={() => {
              if (isGuestUser) {
                showLoginDialog();
              } else {
                setIsMultiAgentsChatDialogOpen(true);
              }
            }}
          >
            <Icons.PlusThin className="size-4" />
            {navigationSectionOpen && "New Group Chat"}
          </div>
        </div>
      </div>

      <MultiAgentsChatDialog
        isOpen={isMultiAgentsChatDialogOpen}
        setIsOpen={setIsMultiAgentsChatDialogOpen}
        onSubmit={handleCreateMultiAgentsChat}
        headerText="Select Agents to Create Group Chat"
      />
    </>
  );
};
