import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useLeaveMultipleWorkspace } from "@/data/mutations/workspace/useLeaveMultipleWorkspace";
import type { Workspace } from "@/types/workspace";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { NavigationProfileSections } from "@/components/Profiles/NavigationProfileSection";

type LeaveWorkspaceMemberDialogProps = {
  workspaceId: Workspace["_id"];
};

export const LeaveWorkspaceMemberDialog = ({ workspaceId }: LeaveWorkspaceMemberDialogProps) => {
  const { setNavigationProfileOpen } = useNavigationProfileContext();

  const [isDialogOpen, setShowDialog] = useState(false);

  const { mutate: leaveMultipleWorkspaces, isPending: isPendingLeave } = useLeaveMultipleWorkspace();

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <span className="text-sm font-medium text-neutral-750">Permanently leave workspace</span>
      <Dialog onOpenChange={setShowDialog} open={isDialogOpen}>
        <DialogTrigger asChild>
          <Button variant="secondary" loading={isPendingLeave}>
            Leave workspace
          </Button>
        </DialogTrigger>

        <DialogContent variant="medium">
          <DialogHeader></DialogHeader>
          <div className="mx-auto my-auto flex max-w-[446px] flex-col items-center gap-2.5 px-8 pb-5 text-center">
            <Icons.LeaveWorkspace />
            <div className="text-xl font-bold">Are you sure you want to Leave this workspace?</div>
            <p className="text-sm text-neutral-400">
              Do you really want to leave this workspace Please note this action cannot be undone.
            </p>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="tertiary">Cancel</Button>
            </DialogClose>
            <Button
              loading={isPendingLeave}
              onClick={() => {
                leaveMultipleWorkspaces({ id: workspaceId });
                setNavigationProfileOpen({
                  section: NavigationProfileSections.ACCOUNT.ACCOUNT,
                  mode: "account",
                  open: true,
                });
              }}
              variant="primary"
            >
              Leave
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
