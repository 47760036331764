import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { WorkspaceMembersDashboard } from "@/components/Profiles/WorkspaceSection/WorkspaceMembersDashboard";
import { InviteTeamMember } from "@/components/Profiles/Team/InviteTeamMember";
import type { WorkspaceSectionProps } from "@/components/Profiles/WorkspaceSection/types";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";

export type WorkspaceMembersStepChildrenProps = WorkspaceSectionProps & {
  setActiveStep: Dispatch<SetStateAction<TeamSteps>>;
};
export const teamSteps = ["teamDashboard", "inviteTeamMember"] as const;
export type TeamSteps = (typeof teamSteps)[number];
const teamStepsMap: {
  [K in TeamSteps]: React.ComponentType<WorkspaceMembersStepChildrenProps>;
} = {
  teamDashboard: WorkspaceMembersDashboard,
  inviteTeamMember: InviteTeamMember,
};

export const WorkspaceMembers = ({ isOwner }: WorkspaceSectionProps) => {
  const { navigationProfileOpen } = useNavigationProfileContext();
  const [activeStep, setActiveStep] = useState<TeamSteps>("teamDashboard");
  const ActiveStepComponent = teamStepsMap[activeStep];

  useEffect(() => {
    if (
      navigationProfileOpen.activeStep &&
      navigationProfileOpen.activeTab &&
      navigationProfileOpen.mode === "workspace"
    ) {
      setActiveStep(navigationProfileOpen.activeStep);
    }
  }, [navigationProfileOpen]);

  return <ActiveStepComponent setActiveStep={setActiveStep} isOwner={isOwner} />;
};
