import { AccountUsagePricingCard } from "./AccountUsagePricingCard";
import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { toast } from "react-toastify";
import { SubscriptionPeriod, SubscriptionTiers } from "@/types/subscriptions";
import { AccountUsageBenefitList } from "./AccountUsageBenefitList";

type AccountUsageScaleCardProps = {
  period?: SubscriptionPeriod;
  showButton?: boolean;
  highlightText?: string;
  isHighlighted?: boolean;
  onCloseDialog: () => void;
};
export const AccountUsageEnterpriseCard = ({
  period = SubscriptionPeriod.ANNUALLY,

  onCloseDialog,
}: AccountUsageScaleCardProps) => {
  const navigate = useNavigate();

  const { data: plans, isLoading } = useGetBillingPlans();

  if (isLoading) {
    return <LoadingSpinner className="flex size-full items-center justify-center" />;
  }

  const enterprisePlan = plans?.billingPlans.find(plan => plan.tier === SubscriptionTiers.ENTERPRISE);

  if (!enterprisePlan) {
    toast.error("Failed to retrieve billing plans information");
    return null;
  }

  const monthPriceOnAnnual =
    period === SubscriptionPeriod.ANNUALLY
      ? enterprisePlan.price[SubscriptionPeriod.ANNUALLY].amount / 12
      : enterprisePlan.price[SubscriptionPeriod.MONTHLY].amount;
  const features = enterprisePlan?.features_as_text.split(", ");

  return (
    <AccountUsagePricingCard
      title="Enterprise Plan"
      btn={{
        text: "Contact us",
        variant: "primary",
        onClick: () => {
          navigate(ROUTES.help);
          onCloseDialog();
        },
        className: "w-full",
      }}
      price={monthPriceOnAnnual}
      periodUnit={period === SubscriptionPeriod.ANNUALLY ? "year" : "month"}
      highlightText="Custom"
      isHighlighted
    >
      <AccountUsageBenefitList features={features} />
    </AccountUsagePricingCard>
  );
};
