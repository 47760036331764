import type { User, UserStatus } from "@/types/user";

export enum WorkspaceAccessRoles {
  EDITOR = "EDITOR",
  USER = "USER",
  VIEWER = "VIEWER",
  OWNER = "OWNER",
}

export type WorkspaceUserWrapped = {
  name: string;
  email: string;
  role: WorkspaceAccessRoles;
  _id: User["_id"];
  status: UserStatus;
  avatar?: string;
};

export type WorkspaceSectionProps = {
  isOwner: boolean;
  isEditor?: boolean;
  isMember?: boolean;
};

export enum WorkspaceSectionTabs {
  AGENTS = "AGENTS",
  MEMBERS = "MEMBERS",
  SETTINGS = "SETTINGS",
}
