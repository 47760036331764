import { SwitchWithLabel } from "@/components/ui/switch-with-label";
import { cn } from "@/lib/utils";
import { SubscriptionPeriod } from "@/types/subscriptions";

export const SubscriptionTierHeader = ({
  period,
  onTogglePeriod,
}: {
  period: SubscriptionPeriod;
  onTogglePeriod: () => void;
}) => {
  return (
    <>
      <h3 className="text-center text-[1.75rem] font-bold leading-9 text-neutral-750">Pricing plans for all sizes</h3>
      <div className="mx-auto mt-8 flex items-center justify-center">
        <SwitchWithLabel
          className="relative mx-2 flex cursor-pointer items-center justify-between"
          onClick={onTogglePeriod}
        >
          <span
            className={cn("z-10 ml-3 text-xs", {
              "text-violet-200": period !== SubscriptionPeriod.ANNUALLY,
            })}
          >
            Annually
          </span>
          <span
            className={cn("z-10 mr-3 text-xs", {
              "text-violet-200": period !== SubscriptionPeriod.MONTHLY,
            })}
          >
            Monthly
          </span>
        </SwitchWithLabel>
      </div>
    </>
  );
};
