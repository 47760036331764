import { useQuery } from "@tanstack/react-query";
import { restClient } from "../initAxios";
import { apiPaths } from "../apiPaths";
import type { Agent } from "@/types/agent";
import type { Conversation } from "@/types/conversation";

export const agentsGroupConversationsKeys = {
  all: ["agentsGroupConversations"] as const,
  ids: (agentsIds: Agent["_id"][]) => [...agentsGroupConversationsKeys.all, agentsIds.join("-")] as const,
};

type Props = {
  agentsIds: Agent["_id"][];
};

type AgentConversation = {
  _id: Conversation["_id"];
  createdAt: Conversation["createdAt"];
  lastActivity: Conversation["lastActivity"];
  title: string;
  type: "chat" | "search";
};

const getAgentsGroupConversations = async ({ agentsIds }: Props) => {
  const { data } = await restClient.get<AgentConversation[]>(apiPaths.getAgentsGroupConversation({ agentsIds }));
  return data;
};

export const useGetAgentsGroupConversations = ({ agentsIds }: Props) => {
  return useQuery({
    queryKey: agentsGroupConversationsKeys.ids(agentsIds),
    queryFn: () => getAgentsGroupConversations({ agentsIds }),
  });
};
