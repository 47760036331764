import { Avatar } from "@/components/Avatar";
import { Checkbox } from "@/components/ui/checkbox";
import { Dialog, DialogContent, DialogHeader, DialogFooter, DialogClose } from "@/components/ui/dialog";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useGetAppConfig } from "@/data/queries/useGetAppConfig";
import { useGetUser } from "@/data/queries/useGetUser";
import { useGetUserAgents } from "@/data/queries/useGetUserAgents";
import { useGetWorkspaceBasicAgents } from "@/data/queries/workspace/useGetWorkspaceBasicAgents";
import _ from "lodash";
import { Command, CommandGroup, CommandList, CommandItem } from "@/components/ui/Command";
import { Popover, PopoverAnchor, PopoverContent } from "@/components/ui/popover";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Label } from "@/components/ui/label";
import { inputDefaultClassName } from "@/components/ui/input";
import Chip from "@/components/ui/Chip";
import type { Agent, AgentConfig } from "@/types/agent";
import { cn } from "@/lib/utils";
import { ErrorMessage } from "@/components/ui/ErrorMessage";
import { Button } from "@/components/ui/button";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Icons } from "@/components/ui/icons";
import { ChatStructure } from "@/types/conversation";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

const chatAgentsFormId = "chatAgentsForm";
const MAX_AGENTS = 5;
const isProd = !!import.meta.env.VITE_ENV && import.meta.env.VITE_ENV === "prod";
type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: ({ agentIds, manager }: { agentIds: string[]; manager: Agent["_id"] }) => void;
  isPendingAddAgents?: boolean;
  initialSelectedAgentsIds?: string[];
  headerText: string;
};

const MultiAgentsChatDialog = ({
  isOpen,
  setIsOpen,
  onSubmit,
  isPendingAddAgents,
  initialSelectedAgentsIds = [],
  headerText,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { data: user } = useGetUser();
  const { data } = useGetAppConfig();
  const { data: userAgents, isPending: isPendingUserAgents } = useGetUserAgents({ enabled: isOpen });
  const { data: basicAgents, isPending: isPendingBasicAgents } = useGetWorkspaceBasicAgents({ enabled: isOpen });

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAgentsIds, setSelectedAgentsIds] = useState<string[]>([]);
  const [chatStructure, setChatStructure] = useState<ChatStructure>(ChatStructure.Flat);
  const [chatManagerId, setChatManagerId] = useState<string>("");
  const [isSuggestionsListOpen, setIsSuggestionsListOpen] = useState(false);
  const [selectedAgentsError, setSelectedAgentsError] = useState<string>("");
  const [chatManagerError, setChatManagerError] = useState<string>("");

  const handleToggleAgent = (agentId: string) => {
    setSelectedAgentsIds(prev => {
      if (prev.includes(agentId)) {
        setSelectedAgentsError("");
        return prev.filter(id => id !== agentId);
      } else {
        const alreadySelectedNum = prev.length + initialSelectedAgentsIds.length;
        if (alreadySelectedNum >= MAX_AGENTS) {
          setSelectedAgentsError("You can't add more than 5 agents to the chat.");
          return prev;
        }

        return [...prev, agentId];
      }
    });
  };

  useEffect(() => {
    const isManagerInsideSelectedAgents = selectedAgentsIds.includes(chatManagerId);
    if (!isManagerInsideSelectedAgents) {
      setChatManagerId("");
    }
  }, [chatManagerId, selectedAgentsIds]);

  const handleSubmitAgents = () => {
    const isChatManagerSelected = !!chatManagerId;

    if (chatStructure === ChatStructure.Hierarchy && !isChatManagerSelected) {
      setChatManagerError("Please select a team manager.");
      return;
    }

    onSubmit({ agentIds: selectedAgentsIds, manager: chatManagerId });
  };

  const checkIfAgentIsLocked = useCallback(
    (llmModel: AgentConfig["llmModel"]) => {
      const llmMinTier = data?.llmList.find(model => model.model === llmModel)?.minTier;

      const isLocked =
        llmMinTier !== undefined && user?.subscription.tier !== undefined && llmMinTier > user.subscription.tier;

      return isLocked;
    },
    [data?.llmList, user?.subscription.tier]
  );

  const checkIfAgentMatchesQuery = useCallback(
    (agentName: string) => {
      const matchesQuery = agentName?.toLowerCase().includes(searchQuery?.toLowerCase());

      return matchesQuery;
    },
    [searchQuery]
  );

  const allBasicAgentsSuggestions = useMemo(
    () =>
      basicAgents?.filter(agent => {
        const isAlreadySelected = !initialSelectedAgentsIds.includes(agent._id);
        const isLocked = checkIfAgentIsLocked(agent.llmModel);

        return isAlreadySelected && !isLocked;
      }) ?? [],
    [basicAgents, checkIfAgentIsLocked, initialSelectedAgentsIds]
  );

  const allUserAgentsSuggestions = useMemo(
    () => userAgents?.filter(agent => !initialSelectedAgentsIds.includes(agent._id)) ?? [],
    [initialSelectedAgentsIds, userAgents]
  );

  const filteredUserAgents = useMemo(() => {
    return (
      userAgents?.filter(agent => {
        const matchesQuery = checkIfAgentMatchesQuery(agent.name);
        const isAlreadySelected = initialSelectedAgentsIds.includes(agent._id) || selectedAgentsIds.includes(agent._id);

        return matchesQuery && !isAlreadySelected;
      }) ?? []
    );
  }, [checkIfAgentMatchesQuery, initialSelectedAgentsIds, selectedAgentsIds, userAgents]);

  const filteredBasicAgents = useMemo(() => {
    return (
      basicAgents?.filter(agent => {
        const matchesQuery = checkIfAgentMatchesQuery(agent.name);
        const isAlreadySelected =
          initialSelectedAgentsIds.includes(agent.botId) || selectedAgentsIds.includes(agent.botId);
        const isLocked = checkIfAgentIsLocked(agent.llmModel);

        return matchesQuery && !isAlreadySelected && !isLocked;
      }) ?? []
    );
  }, [basicAgents, checkIfAgentIsLocked, checkIfAgentMatchesQuery, initialSelectedAgentsIds, selectedAgentsIds]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedAgentsIds([]);
      setSearchQuery("");
      setSelectedAgentsError("");
      setChatManagerError("");
    }
  }, [isOpen]);

  const isLoading = isPendingBasicAgents || isPendingUserAgents;
  const canAddMoreAgents = selectedAgentsIds.length + initialSelectedAgentsIds.length < MAX_AGENTS;
  const hasMadeChanges = !_.isEqual(initialSelectedAgentsIds.sort(), selectedAgentsIds.sort());

  const onStructureChange = (structure: ChatStructure) => {
    setChatStructure(structure);
  };

  const onChatManagerChange = (managerId: string) => {
    setChatManagerId(managerId);
    setChatManagerError("");
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent variant="large" className="max-w-[700px]">
        <DialogHeader className="h-fit text-base font-bold text-neutral-750">{`${headerText} (max. ${MAX_AGENTS})`}</DialogHeader>

        {isLoading ? (
          <div className="flex h-full items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="flex h-full flex-col px-3 lg:px-7 ">
            {!isProd && (
              <div className="relative flex flex-col items-start gap-2">
                <Label className="text-sm font-bold leading-[22px]" htmlFor="addAgents">
                  Agent Team Structure
                </Label>
                <RadioGroup
                  onValueChange={onStructureChange}
                  defaultValue={ChatStructure.Flat}
                  value={chatStructure}
                  className="flex w-full items-stretch justify-between gap-4"
                >
                  <label className={cn("flex w-full cursor-pointer flex-col items-center justify-end gap-2")}>
                    <div
                      className={cn(
                        "relative flex h-full w-full items-center justify-center overflow-hidden rounded-lg bg-primary-50 p-2"
                      )}
                    >
                      <Icons.ChatStructureFlat />
                    </div>

                    <span className="flex items-center gap-2 text-xs font-medium capitalize leading-5">
                      <RadioGroupItem value={ChatStructure.Flat} />
                      {ChatStructure.Flat.toString()}
                    </span>
                  </label>

                  <label className={cn("flex w-full cursor-pointer flex-col items-center justify-end gap-2")}>
                    <div
                      className={cn(
                        "relative flex h-full w-full items-center justify-center overflow-hidden rounded-lg bg-primary-50 p-2"
                      )}
                    >
                      <Icons.ChatStructureHierarchy />
                    </div>

                    <span className="flex items-center gap-2 text-xs font-medium capitalize leading-5">
                      <RadioGroupItem value={ChatStructure.Hierarchy} />
                      {ChatStructure.Hierarchy.toString()}
                    </span>
                  </label>
                </RadioGroup>
              </div>
            )}

            {chatStructure === ChatStructure.Hierarchy && (
              <div className="relative my-4 flex flex-col items-start gap-2">
                <Label className="text-sm font-bold leading-[22px]" htmlFor="addAgents">
                  Select team manager
                </Label>

                <Select onValueChange={onChatManagerChange} value={chatManagerId}>
                  <SelectTrigger id="chatManager" className="">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {selectedAgentsIds.map(agentId => {
                      const foundUserAgentName = userAgents?.find(agent => agent._id === agentId)?.name;
                      const foundBasicAgentName = basicAgents?.find(basic => basic.botId === agentId)?.name;

                      const agentName = foundUserAgentName ?? foundBasicAgentName;

                      return (
                        <SelectItem key={agentId} value={agentId}>
                          {agentName ?? ""}
                        </SelectItem>
                      );
                    })}

                    {selectedAgentsIds.length === 0 && (
                      <p className="select-none py-1.5 pl-8 pr-2 text-sm text-neutral-600">
                        Add agents to the chat to select a team manager.
                      </p>
                    )}
                  </SelectContent>
                </Select>
                {!!chatManagerError && <ErrorMessage error={chatManagerError} id="input-error-addAgents" />}
              </div>
            )}

            <div className="relative my-4 flex flex-col items-start gap-2">
              <Label className="text-sm font-bold leading-[22px]" htmlFor="addAgents">
                Select agents to create a team
              </Label>

              <form
                id={chatAgentsFormId}
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmitAgents;
                }}
                className="flex w-full flex-col items-start gap-2 lg:flex-row"
              >
                <Command>
                  <div
                    onClickCapture={() => inputRef.current?.focus()}
                    className={cn(inputDefaultClassName, "flex flex-wrap gap-1.5")}
                  >
                    {selectedAgentsIds.length > 0 &&
                      selectedAgentsIds.map(agentId => {
                        const foundUserAgentName = userAgents?.find(agent => agent._id === agentId)?.name;
                        const foundBasicAgentName = basicAgents?.find(basic => basic.botId === agentId)?.name;

                        const agentName = foundUserAgentName ?? foundBasicAgentName;

                        return (
                          <Chip key={agentId} text={agentName ?? ""} onDeleteClick={() => handleToggleAgent(agentId)} />
                        );
                      })}
                    <input
                      id="addAgents"
                      ref={inputRef}
                      value={searchQuery}
                      onChange={e => setSearchQuery(e.target.value)}
                      onKeyDown={e => {
                        if (!!selectedAgentsIds?.length && !searchQuery.length && e.key === "Backspace") {
                          setSelectedAgentsIds(prev => [...prev.slice(0, prev.length - 1)]);
                        }
                      }}
                      onFocus={() => setIsSuggestionsListOpen(true)}
                      onBlur={() => setIsSuggestionsListOpen(false)}
                      className="h-7 w-32 grow border-none focus:border-none focus:ring-0"
                      {...(!!selectedAgentsError && {
                        "aria-invalid": true,
                        "aria-errormessage": "input-error-addAgents",
                      })}
                    />
                  </div>
                  {!!selectedAgentsError && (
                    <ErrorMessage className="mt-1.5" error={selectedAgentsError} id="input-error-addAgents" />
                  )}
                  <Popover
                    open={isSuggestionsListOpen && (!!filteredBasicAgents.length || !!filteredUserAgents.length)}
                    modal
                  >
                    <PopoverAnchor />
                    <PopoverContent
                      side="bottom"
                      sideOffset={4}
                      className="border-none bg-transparent p-0 shadow-none popover-content-width-same-as-its-trigger"
                      autoFocus={false}
                      onOpenAutoFocus={event => {
                        event.preventDefault();
                      }}
                    >
                      <CommandGroup className="w-full">
                        <CommandList className="max-h-[200px] overflow-y-scroll">
                          {filteredUserAgents.map(userAgent => (
                            <CommandItem
                              key={userAgent._id}
                              value={userAgent._id}
                              onSelect={currentValue => {
                                handleToggleAgent(currentValue);
                                setIsSuggestionsListOpen(false);
                                setSearchQuery("");
                              }}
                            >
                              {userAgent.name}
                            </CommandItem>
                          ))}
                          {filteredBasicAgents.map(basicAgent => (
                            <CommandItem
                              key={basicAgent.botId}
                              value={basicAgent.botId}
                              onSelect={currentValue => {
                                handleToggleAgent(currentValue);
                                setIsSuggestionsListOpen(false);
                                setSearchQuery("");
                              }}
                            >
                              {basicAgent.name}
                            </CommandItem>
                          ))}
                        </CommandList>
                      </CommandGroup>
                    </PopoverContent>
                  </Popover>
                </Command>
              </form>
            </div>

            <h6 className="mb-2 shrink-0 text-sm font-bold leading-6 text-neutral-400">Suggested</h6>

            <div className="mb-[72px] flex h-full flex-col gap-6 overflow-y-scroll pr-4">
              {allBasicAgentsSuggestions.map(agent => {
                return (
                  <div key={agent.botId} className="flex items-center justify-between gap-3">
                    <div className="flex items-center gap-3">
                      <Avatar src={agent?.avatar} />
                      <div className="flex flex-col justify-between">
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-semibold leading-[26px] text-neutral-750">{agent.name}</p>
                          <span className="text-sm text-neutral-500">@AgentX</span>
                        </div>
                        <p className="line-clamp-1 text-xs text-neutral-750">{agent.description}</p>
                      </div>
                    </div>

                    <Checkbox
                      variant="circle"
                      checked={selectedAgentsIds.some(id => id === agent.botId)}
                      onCheckedChange={() => handleToggleAgent(agent.botId)}
                      disabled={!selectedAgentsIds.some(id => id === agent.botId) && !canAddMoreAgents}
                    />
                  </div>
                );
              })}

              {allUserAgentsSuggestions.map(agent => {
                return (
                  <div key={agent._id} className="flex items-center justify-between gap-3">
                    <div className="flex items-center gap-3">
                      <Avatar src={agent?.avatar} />
                      <div className="flex flex-col justify-between">
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-semibold leading-[26px] text-neutral-750">{agent.name}</p>
                          <span className="text-sm text-neutral-500">@{user?.name}</span>
                        </div>
                      </div>
                    </div>

                    <Checkbox
                      variant="circle"
                      checked={selectedAgentsIds.some(id => id === agent._id)}
                      onCheckedChange={() => handleToggleAgent(agent._id)}
                      disabled={!selectedAgentsIds.some(id => id === agent._id) && !canAddMoreAgents}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="tertiary">Cancel</Button>
          </DialogClose>
          <Button onClick={handleSubmitAgents} loading={isPendingAddAgents} disabled={!hasMadeChanges}>
            Add to chat
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MultiAgentsChatDialog;
