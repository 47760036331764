import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Avatar } from "@/components/Avatar";
import { Icons } from "@/components/ui/icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { IconButton } from "@/components/ui/icon-button";
import { DeleteUserFromAllWorkspacesDialog } from "@/components/dialogs/TeamDialogs/DeleteUserFromAllWorkspacesDialog";
import { useGetMultipleWorkspaceAllUsers } from "@/data/queries/workspace/useGetAllWorkspaceUsers";
import { useGetUser } from "@/data/queries/useGetUser";

export const BillingTeam = () => {
  const { user } = useGetUser();
  const { data: workspaceUsers } = useGetMultipleWorkspaceAllUsers();

  if (!workspaceUsers?.length) {
    return null;
  }

  return (
    <div className="gap flex w-full flex-col gap-4 pt-6">
      <div className="flex flex-row items-center gap-1 rounded-md text-sm font-medium text-neutral-750">
        Total team members
        <div className="flex h-4 min-w-4 items-center justify-center rounded-full bg-primary-500 px-1 text-xxs font-medium text-white">
          {workspaceUsers?.length || 0}
        </div>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Workspace</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {workspaceUsers?.map(workspaceUser => (
            <TableRow key={workspaceUser._id}>
              <TableCell>
                <div className="flex w-full gap-4">
                  <Avatar
                    src={workspaceUser?.avatar}
                    name={workspaceUser?.name || workspaceUser?.email}
                    className="size-8 rounded-full lg:size-10"
                    size="custom"
                  />
                  <div className="flex w-full items-center justify-between gap-5">
                    <div>
                      <div className="text-sm font-medium text-neutral-750">
                        {workspaceUser?.name || workspaceUser?.email}{" "}
                        {user?._id === workspaceUser._id && <span className="text-neutral-400">(You)</span>}
                      </div>
                      {workspaceUser?.name && <span className="text-xs text-neutral-400">{workspaceUser.email}</span>}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell className="flex h-[72px] flex-wrap items-center gap-1">
                {workspaceUser.workspaces.map(workspace => (
                  <div
                    key={`${workspace._id}-${workspaceUser._id}`}
                    className="flex w-fit cursor-default flex-row items-center gap-1 rounded-full bg-[#A3A3A31A] px-2 py-1 text-xxs font-medium text-neutral-750"
                  >
                    <Icons.Workspaces className="size-4" />
                    {workspace.name}
                  </div>
                ))}
              </TableCell>
              <TableCell>
                <DropdownMenu modal={false}>
                  <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
                    <IconButton
                      variant="tertiary"
                      roundedType="rounded"
                      size="tiny"
                      className="aspect-square"
                      icon={<Icons.Dots2 className="rotate-90 text-black" />}
                    />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start">
                    <DropdownMenuItem
                      className="flex items-center gap-1.5 text-xs focus:text-error"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <DeleteUserFromAllWorkspacesDialog user={workspaceUser} />
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
