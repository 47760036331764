import type { PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import { LayoutContext } from "@/contexts/LayoutContext/LayoutContext";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";

export const LayoutProvider = ({ children }: PropsWithChildren) => {
  const { isMobile } = useMediaQueriesContext();
  const [navigationSectionOpen, setNavigationSectionOpen] = useState(true);
  const [quickLinksSectionOpen, setQuickLinksSectionOpen] = useState(true);

  useEffect(() => {
    if (isMobile) {
      return setNavigationSectionOpen(true);
    }
  }, [isMobile]);

  const toggleNavigationSectionOpen = () => {
    setNavigationSectionOpen(prev => !prev);
  };

  const toggleQuickLinksSectionOpen = () => {
    setQuickLinksSectionOpen(prev => !prev);
  };

  return (
    <LayoutContext.Provider
      value={{ navigationSectionOpen, toggleNavigationSectionOpen, toggleQuickLinksSectionOpen, quickLinksSectionOpen }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
