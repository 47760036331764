import { useState, type PropsWithChildren } from "react";
import { BookmarkChatHistoryContext } from "./BookmarkChatHistoryContext";
import BookmarksHistoryPanel from "@/components/BookmarksHistoryPanel/BookmarksHistoryPanel";
import { Dialog, DialogOverlay, DialogTitle } from "@/components/ui/dialog";
import * as DialogPrimitive from "@radix-ui/react-dialog";

export const BookmarkChatHistoryProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);

  const value = {
    isOpen,
    setIsOpen,
  };

  return (
    <BookmarkChatHistoryContext.Provider value={value}>
      {isOpen && (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogOverlay />
          <DialogPrimitive.Content>
            <DialogTitle className="sr-only">Chat History</DialogTitle>
            <BookmarksHistoryPanel
              className="fixed bottom-0 left-0 top-0 z-[50] lg:left-auto lg:right-0"
              onClosePanel={() => setIsOpen(false)}
            />
          </DialogPrimitive.Content>
        </Dialog>
      )}
      {children}
    </BookmarkChatHistoryContext.Provider>
  );
};
