import type { Agent } from "@/types/agent";
import { Icons } from "@/components/ui/icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import type { Conversation } from "@/types/conversation";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { useBookmarkChatHistoryContext } from "@/contexts/BookmarkChatHistoryContext/useBookmarkChatHistoryContext";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { calculateTimeDifference } from "@/utils/calculateTimeDifference";
import { cn } from "@/lib/utils";
import type { F1 } from "@/types/types";
import { useGetAgentsGroupConversations } from "@/data/queries/useGetAgentsGroupConversations";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";

type AgentConversationsProps = {
  agentIds: Agent["_id"][];
  setIsDeleteChatDialogOpen: F1<boolean>;
  setConversationIdToDelete: F1<string | null>;
  onClosePanel?: () => void;
};

export const AgentConversations = ({
  agentIds,
  setIsDeleteChatDialogOpen,
  setConversationIdToDelete,
  onClosePanel,
}: AgentConversationsProps) => {
  const { data, isLoading } = useGetAgentsGroupConversations({ agentsIds: agentIds });
  const navigate = useNavigate();
  const { setIsOpen } = useBookmarkChatHistoryContext();
  const { createNewConversation, setConversationId, conversationId } = useChatContext();

  const formatPublishedDate = (date: string) => {
    const timeAgo = calculateTimeDifference(date, { strict: true });
    return timeAgo;
  };

  const deleteChat = (conversationId: Conversation["_id"]) => {
    setIsDeleteChatDialogOpen(true);
    setConversationIdToDelete(conversationId);
  };

  const navigateToConversation = (conversationId: Conversation["_id"]) => {
    setConversationId(conversationId);
    setIsOpen(false);

    navigate(ROUTES.conversation(conversationId));
    onClosePanel?.();
  };

  const createNewConversationHandler = async () => {
    try {
      const newConversationId = await createNewConversation(agentIds);
      if (newConversationId) {
        navigate(ROUTES.conversation(newConversationId));
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return <LoadingSpinner className="flex items-center justify-center pt-4" />;
  }

  return (
    <>
      {data?.map(conversation => {
        const isActive = conversation._id === conversationId;

        return (
          <div
            key={conversation._id}
            className={cn(
              "flex cursor-pointer items-center justify-between gap-2 border-b border-b-neutral-400 border-opacity-50 px-1 py-2 hover:bg-white hover:bg-opacity-10 data-[state=open]:bg-white data-[state=open]:bg-opacity-10 [&>*]:hover:visible",
              {
                "bg-white bg-opacity-10 hover:bg-opacity-10": isActive,
              }
            )}
            onClick={() => navigateToConversation(conversation._id)}
          >
            <div className="flex w-[75%] flex-row gap-2 truncate text-sm text-white">
              {conversation.type && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="flex w-8 justify-center">
                      {conversation.type === "chat" && <Icons.ChatMode className="size-6 min-w-6 text-white" />}
                      {conversation.type === "search" && <Icons.SearchMode className="size-6 min-w-6 text-white" />}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent className="capitalize">{conversation.type} mode</TooltipContent>
                </Tooltip>
              )}
              {conversation.title}{" "}
            </div>
            <div className="ml-auto w-[25%] text-nowrap text-right text-xs text-neutral-400">
              {formatPublishedDate(conversation?.lastActivity || conversation?.createdAt)}
            </div>

            <DropdownMenu>
              <div className="flex h-8 w-4 items-center justify-center lg:invisible">
                <DropdownMenuTrigger>
                  <Icons.Dots2 className="text-white" />
                </DropdownMenuTrigger>
              </div>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className="flex items-center gap-[6px] border-b border-neutral-300 text-xs text-neutral-600"
                  onClick={createNewConversationHandler}
                >
                  <Icons.Plus className="scale-[90%]" />
                  New conversation
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="flex items-center gap-[6px] pb-2 pt-3 text-xs text-neutral-600"
                  onClick={e => {
                    e.stopPropagation();
                    deleteChat(conversation._id);
                  }}
                >
                  <Icons.Trash className="ml-[6px] mr-1 scale-[130%] text-red-500" />
                  Delete chat
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      })}
    </>
  );
};
