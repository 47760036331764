import AgentStatusDot from "@/components/AgentStatus/AgentStatusDot";
import { Avatar } from "@/components/Avatar";
import type { Agent } from "@/types/agent";
import { calculateTimeDifference } from "@/utils/calculateTimeDifference";
import StatsTrendIndicator from "../common/StatsTrendIndicator";
import type { DateRangeType } from "@/types/datepicker";
import AgentUpdating from "@/assets/images/AgentUpdating.svg";

type Props = {
  agent:
    | {
        avatar: Agent["avatar"];
        state: Agent["state"];
        name: Agent["name"];
        createdAt: Agent["createdAt"];
      }
    | null
    | undefined;
  messagesTrend: number | null | undefined;
  currentRange: DateRangeType;
};

export const StatsTopAgentSummary = ({ agent, messagesTrend, currentRange }: Props) => {
  return agent ? (
    <div className="rounded-[4px] bg-primary-50 py-3 pl-4 pr-10 @xl/top-agents:row-span-2">
      <p className="font-bold">Congratulations!</p>
      <div className="mt-2 gap-2 @5xl/top-agents:flex">
        <div className="flex items-center gap-4 rounded-[4px] bg-white px-4 py-2">
          <div className="relative">
            <Avatar src={agent?.avatar} />
            {agent?.state && <AgentStatusDot agentState={agent.state} />}
          </div>
          <div>
            <p className="text-sm font-semibold leading-5">{agent?.name}</p>
            <p className="text-xs font-medium leading-5 text-neutral-500">
              {agent?.createdAt && `Born: ${calculateTimeDifference(agent?.createdAt ?? "")}`}
            </p>
          </div>
        </div>
        <div>
          <span className="text-sm">
            is your top agent from <span className="lowercase">{currentRange}</span>!
          </span>

          <div className="mt-1 sm:mt-3">
            <StatsTrendIndicator trend={messagesTrend} subtitle="messages" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col justify-between rounded bg-primary-50 px-4 py-3 @xl/top-agents:row-span-2 @xl/top-agents:max-w-96">
      <p className="text-base font-medium text-neutral-400">
        Your agents do not have activities within selected dates.
      </p>
      <img src={AgentUpdating} alt="agent updating" />
    </div>
  );
};
