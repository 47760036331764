import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { useState } from "react";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "@/components/ui/table";
import { AgentDeploymentsIcons } from "@/components/AgentDeploymentsIcons/AgentDeploymentsIcons";
import { calculateTimeDifference } from "@/utils/calculateTimeDifference";
import { useGetMultipleWorkspaceAgents } from "@/data/queries/workspace/useGetMultipleWorkspaceAgents";
import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";
import { LocalStorageHelpers } from "@/utils/LocalStorageHelpers";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import type { Agent, FullAgent } from "@/types/agent";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { DeleteAgentDialog } from "@/components/dialogs/DeleteAgentDialog/DeleteAgentDialog";
import type { WorkspaceSectionProps } from "@/components/Profiles/WorkspaceSection/types";
import { cn } from "@/lib/utils";

export const WorkspaceAgents = ({ isMember, isEditor, isOwner }: WorkspaceSectionProps) => {
  const navigate = useNavigate();
  const { navigationProfileOpen, setNavigationProfileOpen } = useNavigationProfileContext();
  const { data: agents, isPending } = useGetMultipleWorkspaceAgents({
    id: navigationProfileOpen.section!,
  });
  const [dialogAgent, setDialogAgent] = useState<FullAgent | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  if (!agents && isPending) {
    return <LoadingSpinner className="grid size-full place-items-center" />;
  }

  const onAgentNameClick = (agentId: Agent["_id"]) => {
    const lastConversationId = LocalStorageHelpers.getAgentsLocalStorageConversation([agentId]);
    const newRoute = lastConversationId ? ROUTES.conversation(lastConversationId) : getNewConversationRoute([agentId]);

    navigate(newRoute);
    setNavigationProfileOpen({ open: false, section: null, mode: "account" });
  };

  const onEditAgentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, agentId: Agent["_id"]) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(ROUTES.editAgent(agentId));
    setNavigationProfileOpen({ open: false, section: null, mode: "account" });
  };

  const onDeleteAgentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, agent: FullAgent) => {
    e.stopPropagation();
    setDialogAgent(agent);
    setIsDeleteDialogOpen(true);
  };

  if (!agents?.length && !isPending) {
    return (
      <div className="flex w-full flex-row flex-wrap justify-center gap-3 pt-4 text-lg font-medium text-neutral-750">
        No agents have been created yet
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3 pt-4">
      <Table>
        <TableHeader className="border-b border-b-neutral-200 bg-neutral-50">
          <TableRow className="text-xs font-bold text-neutral-750 md:text-sm">
            <TableCell className="p-4 lg:p-4">Name</TableCell>
            <TableCell className="p-4 lg:p-4">Deployment</TableCell>
            <TableCell className="p-4 lg:p-4">Date</TableCell>
            {(isOwner || isEditor) && <TableCell className="p-4 lg:p-4" />}
          </TableRow>
        </TableHeader>
        <TableBody>
          {agents?.map(agent => (
            <TableRow key={agent._id}>
              <TableCell
                className={cn(
                  "border-b border-r border-neutral-200 p-4 text-sm font-medium text-neutral-400 lg:p-4",
                  (isOwner || isEditor || isMember) && "cursor-pointer text-primary-400 underline"
                )}
                onClick={() => (isOwner || isEditor || isMember) && onAgentNameClick(agent._id)}
              >
                {agent.name}
              </TableCell>
              <TableCell className="border-b border-r border-neutral-200 p-4 text-sm lg:p-4">
                <div className="flex flex-row items-center gap-1.5">
                  {agent?.deployments && <AgentDeploymentsIcons deployments={agent.deployments} />}
                </div>
              </TableCell>
              <TableCell className="break-words border-b border-r border-neutral-200 p-4 text-xs text-neutral-400 lg:p-4 ">
                {calculateTimeDifference(agent.createdAt)}
              </TableCell>
              {(isOwner || isEditor) && (
                <TableCell className="text-end">
                  <DropdownMenu modal={false}>
                    <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
                      <IconButton
                        variant="tertiary"
                        roundedType="square"
                        size="tiny"
                        icon={<Icons.Dots2 className="rotate-90" />}
                      />
                    </DropdownMenuTrigger>

                    <DropdownMenuContent align="start">
                      <>
                        <DropdownMenuItem
                          className="flex items-center gap-1.5 text-xs"
                          onClick={e => onEditAgentClick(e, agent._id)}
                        >
                          <Icons.Edit />
                          Edit
                        </DropdownMenuItem>
                        <div className="my-1 h-[1px] bg-neutral-300" />
                        <DropdownMenuItem
                          className="flex items-center gap-1.5 text-xs text-error"
                          onClick={e => onDeleteAgentClick(e, agent)}
                        >
                          <Icons.Trash />
                          Delete
                        </DropdownMenuItem>
                      </>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>

        <DeleteAgentDialog
          agentId={dialogAgent?._id}
          setAgentId={setDialogAgent}
          isDialogOpen={isDeleteDialogOpen}
          setShowDialog={setIsDeleteDialogOpen}
          workspaceId={navigationProfileOpen.section!}
        />
      </Table>
    </div>
  );
};
