import type { UploadChatFileResponse } from "@/data/mutations/useUploadFileToConversation";
import type { FullAgent } from "./agent";
import type { SingleCommunityAgentWithConfig } from "./community";
import type { Trace } from "./trace";
import type { User } from "./user";

export type ConversationSummary = {
  text: string;
  error: boolean;
  createdAt: Date;
  tokens: number;
};

export type Conversation = {
  _id: string;
  bots: string[];
  createdAt: string;
  mutedBy: string[];
  updatedAt: string;
  users: string[];
  lastActivity: string;
  title?: string;
  pins: string[];
  summary?: ConversationSummary;
  messages?: Message[];
  type: "chat" | "search" | "ecommerce";
  manager?: string;
};

export type ConversationWithUsersDetails = Omit<Conversation, "users" | "bots"> & {
  bots: {
    _id: string;
    name: string;
    avatar: string;
  }[];
  users: {
    _id: string;
    name: string;
    avatar: string;
  }[];
};

export type SentMessageResponse = {
  _id: string;
  bot: {
    _id: string;
    name: string;
  };
  conversationId: Conversation["_id"];
  createdAt: string;
  recipientIds: string[];
  seenBy: string[];
  text: string;
  trace: { _id: string; reference: string[] };
};
export type AudioData = {
  duration: number;
  base64?: string;
  blob?: Blob;
  url?: string;
};

// {
//   duration: number;
//   blob?: Blob;
//   base64?: string;
// };

export enum TaskType {
  ACTION = "action",
  DELEGATE = "delegate",
  THINK = "think", // different from COT, this is for the sub-agent thinking process
  REPORT = "report", // this is sub-agent report to main agent
}

export type HierarchyTask = {
  taskType: TaskType;
  name: string;
  input: string;
};

export type Message = {
  _id: string;
  conversationId: Conversation["_id"];
  createdAt: string;
  seenBy: string[];
  text: string;
  updatedAt: string;
  vote: 0 | 1 | null;
  trace?: Trace | null;
  bot?: {
    _id: string;
    name: string;
    avatar: string;
    creator?: string;
  };
  user?: User["_id"];
  isBookmark?: boolean;
  title?: string;
  isGenerating?: boolean;
  isAnswering?: boolean;
  cot?: string;
  tasks?: HierarchyTask[];
  attachments?: UploadChatFileResponse[];
  audio?: AudioData;
  agentMode?: string;
};

export type ConversationMessagesResponse = {
  messages: Message[];
  totalMessages: number;
};

export type ConversationMessagesQueryResponse = ConversationMessagesResponse;

export type DisplayMessageProps = {
  children: string;
};

export type ChatAgent = FullAgent | SingleCommunityAgentWithConfig;

export type AgentConversation = {
  _id: Conversation["_id"];
  createdAt: Conversation["createdAt"];
  lastActivity: Conversation["lastActivity"];
  title: string;
};

export enum ChatStructure {
  Flat = "flat",
  Hierarchy = "hierarchy",
}
