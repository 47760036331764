type Props = {
  firstTaskHeight: number;
  lastTaskHeight: number;
};

export const ChatMessageReasoningTimeline = ({ firstTaskHeight, lastTaskHeight }: Props) => {
  return (
    <div
      className="absolute left-0 w-[1px] bg-primary-200"
      style={{
        height: `calc(100% - ${firstTaskHeight / 2}px - ${lastTaskHeight / 2}px)`,
        top: `${firstTaskHeight / 2}px`,
      }}
    />
  );
};
