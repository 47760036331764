import { useGetUserLeadsStats, userLeadsStats } from "@/data/queries/stats/useGetUserLeadsStats";
import { StatsLeadsContent } from "./components/StatsLeadContent/StatsLeadsContent";
import { StatsLeadsHeader } from "./components/StatsLeadsHeader/StatsLeadsHeader";
import type { DateRangeType } from "@/types/datepicker";
import { DateRangeTypes } from "@/types/datepicker";
import { useCallback, useEffect, useRef, useState } from "react";
import type { Agent } from "@/types/agent";
import { useGetAgents } from "@/data/queries/useGetAgents";
import { useDebounceValue } from "usehooks-ts";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import LeadGenEmpty from "@/assets/images/LeadGenEmpty.svg";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { downloadLeadsData, getHeaders, getSortedLeads } from "./utils/statsLeadsUtils";
import type { ILeadGen, LeadStatsHeader, SortByType, UserLeadsStats } from "@/types/stats";
import { StatsLeadsTableSkeleton } from "./components/StatsLeadContent/component/StatsLeadsTableSkeleton";
import { useQueryClient } from "@tanstack/react-query";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";
import { statsMockUserLeadsData } from "../Stats/constants/statsMockData";

export const StatsLeads = () => {
  const queryClient = useQueryClient();
  const { isUpgradeNeeded, activeWorkspaceId, isDefaultWorkspace } = useStatsContext();

  const [currentRange, setCurrentRange] = useState<DateRangeType>(DateRangeTypes.Last7Days);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedAgents, setSelectedAgents] = useState<Agent[]>([]);
  const [selectedTableHeaders, setSelectedTableHeaders] = useState<LeadStatsHeader[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue] = useDebounceValue(searchValue, 700);
  const [sortBy, setSortBy] = useState<SortByType>(null);
  const [isSelectingEnabled, setIsSelectingEnabled] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

  const {
    data: leadsData,
    isLoading: isLoadingLeads,
    isSuccess: isSuccessLeads,
  } = useGetUserLeadsStats({
    enabled: !isUpgradeNeeded && selectedAgents.length > 0,
    currentRange,
    startDate,
    endDate,
    agentsIds: selectedAgents.map(agent => agent._id),
    search: debouncedSearchValue,
    workspaceId: isDefaultWorkspace ? undefined : activeWorkspaceId,
  });
  const { data: allAgentsData, isLoading: isLoadingAllAgents, isSuccess: isSuccessAllAgents } = useGetAgents();

  const allTableHeaders = getHeaders(leadsData?.leads ?? []);
  const isLeadGenEnabled = !!leadsData?.isLeadGenEnabled;

  const currentLeadsQueryKey = userLeadsStats.params({
    startDate: StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "",
    endDate: StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "",
    agentsIds: selectedAgents.map(agent => agent._id),
    search: debouncedSearchValue,
  });

  const handleChangeSortBy = (newSortBy: SortByType) => {
    const newLeads = getSortedLeads({
      leads: leadsData?.leads ?? [],
      sortBy: newSortBy,
      selectedTableHeaders,
    });

    queryClient.setQueryData<UserLeadsStats>(currentLeadsQueryKey, prev => {
      if (!prev) {
        return prev;
      }

      return {
        ...prev,
        leads: newLeads,
      };
    });

    setSortBy(newSortBy);
  };

  const handleDownloadSelectedLeads = () => {
    downloadLeadsData({
      leads: leadsData?.leads ?? [],
      selectedHeaders: selectedTableHeaders,
      selectedRows,
    });
  };

  const handleChangeLeadsData = (newLeadsData: ILeadGen[]) => {
    queryClient.setQueryData<UserLeadsStats>(currentLeadsQueryKey, prev => {
      if (!prev) {
        return prev;
      }

      return {
        ...prev,
        leads: newLeadsData,
      };
    });
  };

  const handleClickSelectRow = (rowId: string) => {
    setSelectedRows(prev => {
      if (prev.has(rowId)) {
        prev.delete(rowId);
      } else {
        prev.add(rowId);
      }

      return new Set(prev);
    });
  };

  const handleSelectAllRows = useCallback(() => {
    setSelectedRows(new Set(leadsData?.leads.map(lead => lead._id) ?? []));
  }, [leadsData?.leads]);

  const handleToggleSelectAllRows = useCallback(() => {
    setSelectedRows(prev => {
      if (prev.size === leadsData?.leads.length) {
        return new Set();
      }

      return new Set(leadsData?.leads.map(lead => lead._id) ?? []);
    });
  }, [leadsData?.leads]);

  useEffect(() => {
    if (isSuccessAllAgents) {
      setSelectedAgents(allAgentsData ?? []);
    }
  }, [isSuccessAllAgents, allAgentsData]);

  const hasSetupHeaders = useRef(false);

  useEffect(() => {
    if (hasSetupHeaders.current) {
      return;
    }
    if (isSuccessLeads) {
      const allTableHeaders = getHeaders(leadsData?.leads ?? []);
      setSelectedTableHeaders(allTableHeaders);
      hasSetupHeaders.current = true;
    }
  }, [isSuccessLeads, leadsData]);

  const getContent = () => {
    if (isUpgradeNeeded) {
      return (
        <div className="relative">
          <StatsLeadsContent
            leadsData={statsMockUserLeadsData.leads}
            headers={getHeaders(statsMockUserLeadsData?.leads ?? [])}
            sortBy={sortBy}
            showUpgradeBlur
          />
        </div>
      );
    }
    if (isLoadingLeads || isLoadingAllAgents) {
      return <StatsLeadsTableSkeleton />;
    }

    if (!isLeadGenEnabled) {
      return (
        <div className="my-6 flex flex-col items-center gap-5 px-6">
          <img src={LeadGenEmpty} alt="" />
          <p className="max-w-96 text-center text-sm font-medium text-neutral-400">
            You haven't enabled the lead generation feature for your agents yet. Go to the "Action" section on the
            agent's edit page and enable it to start receiving leads.
          </p>
          <Link to={ROUTES.workspace}>
            <Button variant="secondary">Workspace</Button>
          </Link>
        </div>
      );
    }

    if (!leadsData.leads.length) {
      return (
        <div className="my-6 flex flex-col items-center gap-5 px-6">
          <img src={LeadGenEmpty} alt="" />
          <p className="max-w-96 text-center text-sm font-medium text-neutral-400">There are no leads found.</p>
        </div>
      );
    }

    return (
      <StatsLeadsContent
        headers={selectedTableHeaders}
        leadsData={leadsData.leads}
        onChangeLeadsData={handleChangeLeadsData}
        sortBy={sortBy}
        onChangeSortBy={handleChangeSortBy}
        isSelectingEnabled={isSelectingEnabled}
        selectedRows={selectedRows}
        onClickSelectRow={handleClickSelectRow}
        onClickSelectAllRows={handleToggleSelectAllRows}
      />
    );
  };

  return (
    <div className="flex w-full flex-col gap-2 pl-6 pr-5 ">
      <StatsLeadsHeader
        disabled={isUpgradeNeeded}
        currentRange={currentRange}
        onCurrentRangeChange={setCurrentRange}
        startDate={startDate}
        onStartDateChange={setStartDate}
        endDate={endDate}
        onEndDateChange={setEndDate}
        selectedAgents={selectedAgents}
        setSelectedAgents={setSelectedAgents}
        allAgents={allAgentsData ?? []}
        leadsCount={leadsData?.leads.length ?? 0}
        searchValue={searchValue}
        setSeachValue={setSearchValue}
        selectedTableHeaders={selectedTableHeaders}
        setSelectedTableHeaders={setSelectedTableHeaders}
        allTableHeaders={allTableHeaders}
        isSelectingEnabled={isSelectingEnabled}
        onEnableSelecting={() => {
          handleSelectAllRows();
          setIsSelectingEnabled(true);
        }}
        onDisableSelecting={() => setIsSelectingEnabled(false)}
        onDownloadSelectedClick={handleDownloadSelectedLeads}
        isDownloadDisabled={selectedRows.size === 0}
      />
      {getContent()}
    </div>
  );
};
