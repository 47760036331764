import type { FullAgent } from "@/types/agent";
import WorkspaceAgentTile from "./WorkspaceAgentTile";
import { useState } from "react";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { DeleteAgentDialog } from "@/components/dialogs/DeleteAgentDialog/DeleteAgentDialog";
import { DuplicateAgentDialog } from "@/components/dialogs/DuplicateAgentDialog/DuplicateAgentDialog";
import { TransferOwnershipAgentDialog } from "@/components/dialogs/TransferOwnershipAgentDialog/TransferOwnershipAgentDialog";
import { useGetUser } from "@/data/queries/useGetUser";
import { WorkspaceCreateAgentTile } from "@/pages/Workspace/components/WorkspaceCreateAgentTile";
import { WorkspaceEmptyAgentTile } from "@/pages/Workspace/components/WorkspaceEmptyAgentTile";
import type { Workspace } from "@/types/workspace";
import type { WorkspacePermissions } from "@/pages/Workspace/components/CustomerSpace/types";
import { MoveAgentDialog } from "@/components/dialogs/MoveAgentDialog/MoveAgentDialog";

export const WorkspaceMyAgents = ({
  agents,
  isLoading,
  isSuccess,
  canEdit,
  canTransferOwnership,
  workspaceId,
  workspacePermissions,
  canMoveAgent,
}: {
  agents: FullAgent[];
  isLoading: boolean;
  isSuccess: boolean;
  canEdit: boolean;
  canTransferOwnership: boolean;
  workspaceId?: Workspace["_id"];
  workspacePermissions?: WorkspacePermissions;
  canMoveAgent?: boolean;
}) => {
  const { user } = useGetUser();
  const [dialogAgent, setDialogAgent] = useState<FullAgent | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isTransferOwnershipDialogOpen, setIsTransferOwnershipDialogOpen] = useState(false);
  const [isMoveAgentDialogOpen, setIsMoveAgentDialogOpen] = useState(false);
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);

  if (isLoading) {
    return <LoadingSpinner className="flex h-32 w-full items-center justify-center" />;
  }

  const showAgents = isSuccess && agents?.length;

  const canCreate = workspacePermissions?.isEditor || workspacePermissions?.isOwner || !workspacePermissions;
  const canRead = !workspacePermissions?.isViewer || !workspacePermissions;

  if (!showAgents) {
    return (
      <div className="relative mb-10 grid auto-rows-fr grid-cols-[repeat(auto-fill,minmax(16.8125rem,1fr))] content-start gap-6 px-6">
        {canCreate && <WorkspaceCreateAgentTile workspaceId={workspaceId} />}
        {Array.from(Array(canCreate ? 3 : 4).keys()).map(key => (
          <WorkspaceEmptyAgentTile key={key} />
        ))}
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="relative mb-10 grid auto-rows-fr grid-cols-[repeat(auto-fill,minmax(16.8125rem,1fr))] content-start gap-6 px-6">
          {agents.map(agent => (
            <WorkspaceAgentTile
              canRead={canRead}
              key={agent._id}
              agent={agent}
              canEdit={canEdit}
              canMoveAgent={canMoveAgent}
              canTransferOwnership={canTransferOwnership && user?._id === agent.creator}
              onDeleteAgentClick={() => {
                setDialogAgent(agent);
                setIsDeleteDialogOpen(true);
              }}
              workspaceId={workspaceId}
              onMoveAgentClick={
                !canMoveAgent
                  ? undefined
                  : () => {
                      setDialogAgent(agent);
                      setIsMoveAgentDialogOpen(true);
                    }
              }
              onTransferOwnershipAgentClick={
                !canTransferOwnership
                  ? undefined
                  : () => {
                      setDialogAgent(agent);
                      setIsTransferOwnershipDialogOpen(true);
                    }
              }
              /*onDuplicateAgentClick={() => {
                setDialogAgentId(agent._id);
                setIsDuplicateDialogOpen(true);
              }}*/
            />
          ))}
        </div>
      </div>

      <DeleteAgentDialog
        workspaceId={workspaceId}
        agentId={dialogAgent?._id}
        setAgentId={setDialogAgent}
        isDialogOpen={isDeleteDialogOpen}
        setShowDialog={setIsDeleteDialogOpen}
      />

      {canMoveAgent && (
        <MoveAgentDialog
          workspaceId={workspaceId}
          agent={dialogAgent}
          setAgent={setDialogAgent}
          isDialogOpen={isMoveAgentDialogOpen}
          setShowDialog={setIsMoveAgentDialogOpen}
        />
      )}

      {canTransferOwnership && (
        <TransferOwnershipAgentDialog
          agent={dialogAgent}
          setAgent={setDialogAgent}
          isDialogOpen={isTransferOwnershipDialogOpen}
          setShowDialog={setIsTransferOwnershipDialogOpen}
        />
      )}

      <DuplicateAgentDialog
        agentId={dialogAgent?._id}
        setAgentId={setDialogAgent}
        isDialogOpen={isDuplicateDialogOpen}
        setShowDialog={setIsDuplicateDialogOpen}
      />
    </>
  );
};
