import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { User } from "@/types/user";
import { useGetUser } from "../useGetUser";

export const workspaceUsersKeys = {
  all: ["workspaceUsers"] as const,
};
const getWorkspaceUsers = async () => {
  const { data } = await restClient.get<User[]>(apiPaths.getWorkspaceUsers);
  return data;
};

export const useGetWorkspaceUsers = () => {
  const { user } = useGetUser();

  return useQuery({
    queryKey: workspaceUsersKeys.all,
    queryFn: getWorkspaceUsers,
    enabled: !!user?.customer,
  });
};
