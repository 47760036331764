import { useGetUser } from "@/data/queries/useGetUser";
import { useGetMyWorkspaces } from "@/data/queries/workspace/useGetMyWorkspaces";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { WorkspaceSettings } from "@/components/Profiles/WorkspaceSection/WorkspaceSettings";
import { WorkspaceMembers } from "@/components/Profiles/WorkspaceSection/WorkspaceMembers";
import { WorkspaceAgents } from "@/components/Profiles/WorkspaceSection/WorkspaceAgents";
import { useEffect, useState } from "react";
import { WorkspaceSectionTabs } from "@/components/Profiles/WorkspaceSection/types";

export const WorkspaceSection = () => {
  const { navigationProfileOpen } = useNavigationProfileContext();
  const { data: myWorkspaces } = useGetMyWorkspaces();
  const myWorkspace = myWorkspaces?.find(workspace => workspace._id === navigationProfileOpen.section);
  const [activeTab, setActiveTab] = useState(WorkspaceSectionTabs.SETTINGS);

  const { user } = useGetUser();

  useEffect(() => {
    if (navigationProfileOpen.activeTab && navigationProfileOpen.mode === "workspace") {
      setActiveTab(navigationProfileOpen.activeTab);
    }
  }, [navigationProfileOpen]);

  const isOwner = myWorkspace?.owner._id === user?._id;
  const isEditor = myWorkspace?.editors.some(editor => editor._id === user?._id) || false;
  const isMember = myWorkspace?.users.some(member => member._id === user?._id) || false;

  return (
    <>
      <div className="text-xl font-bold">{myWorkspace?.name}</div>
      <Tabs
        defaultValue="settings"
        value={activeTab}
        onValueChange={value => setActiveTab(value as WorkspaceSectionTabs)}
      >
        <TabsList className="w-full justify-start gap-2 border-b border-b-neutral-200 bg-transparent text-neutral-750">
          <TabsTrigger
            key={WorkspaceSectionTabs.SETTINGS}
            value={WorkspaceSectionTabs.SETTINGS}
            className="text-base font-medium data-[state=active]:border-b-2 data-[state=active]:border-b-primary-400 data-[state=active]:bg-none"
          >
            Settings
          </TabsTrigger>
          <TabsTrigger
            key={WorkspaceSectionTabs.MEMBERS}
            value={WorkspaceSectionTabs.MEMBERS}
            className="text-base font-medium data-[state=active]:border-b-2 data-[state=active]:border-b-primary-400 data-[state=active]:bg-none"
          >
            Members
          </TabsTrigger>
          {(isOwner || isEditor || isMember) && (
            <TabsTrigger
              key={WorkspaceSectionTabs.AGENTS}
              value={WorkspaceSectionTabs.AGENTS}
              className="text-base font-medium data-[state=active]:border-b-2 data-[state=active]:border-b-primary-400 data-[state=active]:bg-none"
            >
              Agents
            </TabsTrigger>
          )}
        </TabsList>
        <TabsContent value={WorkspaceSectionTabs.SETTINGS}>
          <WorkspaceSettings isOwner={isOwner} isEditor={isEditor} />
        </TabsContent>
        <TabsContent value={WorkspaceSectionTabs.MEMBERS}>
          <WorkspaceMembers isOwner={isOwner} />
        </TabsContent>
        {(isOwner || isEditor || isMember) && (
          <TabsContent value={WorkspaceSectionTabs.AGENTS}>
            <WorkspaceAgents isOwner={isOwner} isEditor={isEditor} isMember={isMember} />
          </TabsContent>
        )}
      </Tabs>
    </>
  );
};
