import { NotificationGroupedDescription } from "@/components/Notifications/GroupedNotification/NotificationGroupedDescription";
import { NotificationGroupedAvatars } from "@/components/Notifications/GroupedNotification/NotificationGroupedAvatars";
import { NotificationMenu } from "../NotificationMenu";

import type { GroupedNotification } from "@/components/Notifications/utils/groupAndSortNotifications";
import { cn } from "@/lib/utils";

type NotificationGroupedItemProps = {
  groupedNotification: GroupedNotification;
};

export const NotificationGroupedItem = ({ groupedNotification }: NotificationGroupedItemProps) => {
  return (
    <div
      className={cn(
        "flex items-center justify-between gap-2 border-b border-gray-200 px-1 py-4",
        !groupedNotification.read && "bg-primary-50"
      )}
    >
      <div className="flex items-center gap-2">
        <NotificationGroupedAvatars groupedNotification={groupedNotification} />
        <NotificationGroupedDescription groupedNotification={groupedNotification} />
      </div>
      <div className="h-full px-4 py-2">
        <NotificationMenu groupedNotification={groupedNotification} />
      </div>
    </div>
  );
};
