import { Icons } from "@/components/ui/icons";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useSetConversationQueryKnowledge } from "../hooks/useSetConversationQueryKnowledge";

export const ChatSelectedKnowledgeBadge = () => {
  const { conversationDetails } = useChatContext();
  const { setKnowledge } = useSetConversationQueryKnowledge();
  const selectedChatKnowledge = conversationDetails?.pins;

  if (!selectedChatKnowledge?.length) {
    return null;
  }

  return (
    <div className="group relative -mb-2 ml-4 mt-4 inline-flex w-fit items-center gap-3.5 rounded-md border border-neutral-300 py-2 pl-3 pr-4 text-sm font-bold text-primary-400">
      <Icons.PinFilled className="size-6" /> {selectedChatKnowledge.length} Knowledge source
      {selectedChatKnowledge?.length === 1 ? "" : "s"} selected
      <button
        className="absolute -right-1.5 -top-1.5 grid size-5 place-items-center rounded-full border-2 border-white bg-primary-black lg:hidden lg:group-hover:grid"
        onClick={() => setKnowledge([])}
      >
        <Icons.Close className="size-2 text-white" />
        <span className="sr-only">clear knowledge sources</span>
      </button>
    </div>
  );
};
