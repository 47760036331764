import { Icons } from "../ui/icons";
import { cn } from "@/lib/utils";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";
import { ROUTES } from "@/constants/routes";
import { useLocation } from "react-router-dom";

const ChatHistoryNavBtn = ({ setIsOpen }: { setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { navigationSectionOpen } = useLayoutContext();
  const { pathname } = useLocation();

  const canClickChatHistoryBtn = !pathname.includes(ROUTES.conversation(""));

  return (
    <div
      className={cn(
        "grid min-h-[38px] cursor-pointer select-none grid-cols-[16px_1fr] items-center gap-3 whitespace-nowrap rounded-lg px-3 py-2 text-sm text-neutral-500 hover:bg-primary-100 hover:text-primary-400",
        { "size-[38px]": !navigationSectionOpen },
        { "cursor-default hover:bg-transparent hover:text-neutral-600": !canClickChatHistoryBtn }
      )}
      onClick={() => {
        if (!canClickChatHistoryBtn) {
          return;
        }

        setIsOpen(prev => !prev);
      }}
    >
      <Icons.ChatHistory className="size-4" />
      {navigationSectionOpen && <span>Chat history</span>}
    </div>
  );
};

export default ChatHistoryNavBtn;
