import { useQuery } from "@tanstack/react-query";

import type { Workspace } from "@/types/workspace";
import { restClient } from "@/data/initAxios";
import { apiPaths } from "@/data/apiPaths";

export const multipleWorkspaceUsersKeys = {
  all: ["multipleWorkspaceUsers"] as const,
  id: (id: Workspace["_id"]) => [...multipleWorkspaceUsersKeys.all, id] as const,
};

type Props = {
  id: Workspace["_id"];
};

const getMultipleWorkspaceUsers = async ({ id }: Props): Promise<Workspace> => {
  const { data } = await restClient.get<Workspace>(apiPaths.getMultipleWorkspaceUsers(id));
  return data;
};

export const useGetMultipleWorkspaceUsers = ({ id }: Props) => {
  return useQuery<Workspace>({
    queryKey: multipleWorkspaceUsersKeys.id(id),
    queryFn: () => getMultipleWorkspaceUsers({ id }),
  });
};
