import type { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import { NavLink } from "react-router-dom";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";
import type { ReactNode } from "react";

export type NavigationItemType = {
  basic?: {
    name: string;
    icon: (typeof Icons)[keyof typeof Icons];
    to: string;
    needToLogIn?: boolean;
    hidden?: boolean;
  };
  component?: ReactNode;
};

type NavigationItemProps = {
  item: NavigationItemType;
};

export const NavigationItem = ({ item }: NavigationItemProps) => {
  const { navigationSectionOpen } = useLayoutContext();
  const { showLoginDialog } = useLoginDialogContext();

  if (item?.component && !item?.basic) {
    return item.component;
  }

  if (!item.basic) {
    return null;
  }

  if (item.basic.needToLogIn) {
    return (
      <button
        className={cn(
          "flex min-h-[38px] flex-row items-center gap-3 whitespace-nowrap rounded-lg px-2.5 py-2 text-sm text-neutral-500 hover:bg-primary-100 hover:text-primary-400",
          { "size-[38px]": !navigationSectionOpen }
        )}
        onClick={showLoginDialog}
      >
        <item.basic.icon className="size-4" />
        {navigationSectionOpen && <span>{item.basic.name}</span>}
      </button>
    );
  }

  return (
    <NavLink to={item.basic.to}>
      {({ isActive }) => (
        <div
          className={cn(
            "grid min-h-[38px] grid-cols-[16px_1fr] items-center gap-3 whitespace-nowrap rounded-lg px-2.5 py-2 text-sm text-neutral-500 hover:bg-primary-100 hover:text-primary-400",
            {
              "bg-primary-100 text-primary-400": isActive,
              "cursor-not-allowed ": item.basic?.to === "#",
              "size-[38px]": !navigationSectionOpen,
            }
          )}
        >
          {item.basic?.icon && <item.basic.icon className="size-4" />}
          {navigationSectionOpen && <span>{item.basic?.name}</span>}
        </div>
      )}
    </NavLink>
  );
};
