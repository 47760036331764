import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { getCustomVoiceListQueryKey } from "@/data/queries/voice/useGetCustomVoiceList";
import { type CustomVoiceItem, updateCustomVoice } from "@/data/customVoice";

type UpdateCustomVoiceRequest = {
  botId: string;
  voiceId: string;
  name?: string;
  speed?: number;
};

const updateCustomVoiceMutation = async ({ botId, voiceId, name, speed }: UpdateCustomVoiceRequest): Promise<void> => {
  await updateCustomVoice(botId, { voiceId, name, speed });
};

export const useUpdateCustomVoice = () => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError<{ message: string }>, UpdateCustomVoiceRequest>({
    mutationFn: updateCustomVoiceMutation,
    onMutate: async ({ botId, voiceId, name, speed }) => {
      await queryClient.cancelQueries({ queryKey: getCustomVoiceListQueryKey(botId) });

      const previousVoiceList = queryClient.getQueryData<CustomVoiceItem[]>(getCustomVoiceListQueryKey(botId));

      if (previousVoiceList) {
        const newVoiceList = previousVoiceList.map(voice => {
          if (voice.id !== voiceId) {
            return voice;
          }
          return {
            ...voice,
            ...(name !== undefined ? { name } : {}),
            ...(speed !== undefined ? { speed } : {}),
          };
        });

        queryClient.setQueryData<CustomVoiceItem[]>(getCustomVoiceListQueryKey(botId), newVoiceList);
      }

      return { previousVoiceList };
    },

    onError: (_error, { botId }, context) => {
      const typedContext = context as { previousVoiceList: CustomVoiceItem[] };
      if (typedContext?.previousVoiceList) {
        queryClient.setQueryData<CustomVoiceItem[]>(getCustomVoiceListQueryKey(botId), typedContext.previousVoiceList);
      }
    },

    onSettled: (_, __, { botId }) => {
      void queryClient.invalidateQueries({ queryKey: getCustomVoiceListQueryKey(botId) });
    },
  });
};
