import { blobToBase64 } from "@/utils/blob2Base64";
import type { Agent, AgentMode } from "@/types/agent";
import type { Conversation, ConversationMessagesResponse, Message } from "@/types/conversation";
import { apiPaths } from "./apiPaths";
import { baseURL, restClient } from "./initAxios";
import type { UploadChatFileResponse } from "./mutations/useUploadFileToConversation";

export const sendSSEMessage = async (
  message: string,
  conversationId: Conversation["_id"],
  pins: string[],
  agentMode: AgentMode,
  recipientIds?: Agent["_id"][],
  attachments?: UploadChatFileResponse[],
  audio?: { blob: Blob; duration: number }
) => {
  const token = localStorage.getItem("token");
  const base64Audio = audio?.blob ? await blobToBase64(audio.blob) : undefined;
  const audioData = base64Audio && { base64: base64Audio, duration: audio?.duration };
  const response = await fetch(`${baseURL}${apiPaths.sendSSEMessage(conversationId)}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Token ${token}` : "",
      accept: "*/*",
    },
    body: JSON.stringify({
      message,
      recipients: recipientIds,
      attachments,
      pins,
      agentMode,
      audio: audioData,
    }),
  });

  return response;
};

export const getConversationMessages = async ({
  agentId,
  conversationId,
  page,
}: {
  agentId: Agent["_id"];
  conversationId: Conversation["_id"];
  page?: number;
}) => {
  const { data } = await restClient.get<ConversationMessagesResponse>(
    page
      ? apiPaths.getConversationMessagesPaginated({ agentId, conversationId, page })
      : apiPaths.getAllConversationMessages({ agentId, conversationId })
  );
  return data;
};

export const getConversationMessagesByIndex = async ({
  conversationId,
  startIndex,
  limit,
}: {
  conversationId: Conversation["_id"];
  startIndex: number;
  limit?: number;
}) => {
  const { data } = await restClient.get<ConversationMessagesResponse>(
    apiPaths.getConversationMessagesByIndex({ conversationId, startIndex, limit })
  );
  return data;
};

export const voteMessage = async ({
  conversationId,
  messageId,
  agentId,
  vote,
}: {
  conversationId: Conversation["_id"];
  messageId: Message["_id"];
  agentId: Agent["_id"];
  vote: 1 | 0;
}) => {
  const { data } = await restClient.put<{ updated: boolean }>(apiPaths.voteMessage(messageId), {
    conversationId,
    messageId,
    agentId,
    vote,
  });
  return data;
};

interface TTSResponse {
  url?: string;
}

export const streamTTSMessage = async (
  messageId: Message["_id"],
  onChunk: (audioChunk: Uint8Array) => void,
  options?: { signal?: AbortSignal }
): Promise<{ url?: string }> => {
  const token = localStorage.getItem("token");

  const response = await fetch(`${baseURL}${apiPaths.ttsMessageByID(messageId)}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Token ${token}` : "",
      Accept: "text/event-stream, application/json",
    },
    // pass signal to abort fetch if we stop playing message
    signal: options?.signal,
  });

  if (!response.ok) {
    throw new Error("Failed to fetch TTS");
  }

  const contentType = response.headers.get("content-type") || "";

  // handle JSON response
  if (contentType.includes("application/json")) {
    const data: TTSResponse = (await response.json()) as TTSResponse;
    if (data.url) {
      return { url: data.url };
    } else {
      throw new Error("JSON response did not contain a URL");
    }
  }

  // handle streaming response
  if (!response.body) {
    throw new Error("No response body for SSE stream");
  }

  const reader = response.body.getReader();
  const textDecoder = new TextDecoder();
  let buffer = "";

  try {
    while (true) {
      const { value, done } = await reader.read();

      if (done) {
        break;
      }

      buffer += textDecoder.decode(value, { stream: true });
      const chunks = buffer.split("\n");
      buffer = chunks.pop() || "";

      for (const base64Chunk of chunks) {
        if (base64Chunk) {
          try {
            const binaryString = atob(base64Chunk);
            const chunkData = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              chunkData[i] = binaryString.charCodeAt(i);
            }
            onChunk(chunkData);
          } catch (e) {
            console.error("Error decoding chunk:", e);
          }
        }
      }
    }
  } catch (err) {
    console.error("Error reading stream:", err);
  } finally {
    reader.releaseLock();
  }

  return {};
};
