import { Icons } from "@/components/ui/icons";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import WorkspaceWhatsNew from "./components/WorkspaceWhatsNew";
import { ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { WorkspaceBaseModels } from "./components/WorkspaceBaseModels";
import { useGetWorkspaceBasicAgents } from "@/data/queries/workspace/useGetWorkspaceBasicAgents";
import { CustomerSpace } from "@/pages/Workspace/components/CustomerSpace/CustomerSpace";
import { MyAgentsSpace } from "@/pages/Workspace/components/MyAgentsSpace";
import { useGetUser } from "@/data/queries/useGetUser";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "@/lib/utils";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useGetMyWorkspaces } from "@/data/queries/workspace/useGetMyWorkspaces";
import { useEffect, useState } from "react";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { AddWorkspaceButton } from "@/pages/Workspace/components/AddWorkspaceButton";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";

export const Workspace = () => {
  const { data: basicAgents, isPending: isAgentBasicPending } = useGetWorkspaceBasicAgents();
  const { setNavigationProfileOpen } = useNavigationProfileContext();
  const { data: user } = useGetUser();
  const { quickLinksSectionOpen, toggleQuickLinksSectionOpen } = useLayoutContext();
  const { data: myWorkspaces } = useGetMyWorkspaces();
  const [activeTab, setActiveTab] = useState("myAgents");
  const { isMobile } = useMediaQueriesContext();

  useEffect(() => {
    const defaultWorkspace = myWorkspaces?.find(workspace => workspace.default);

    setActiveTab(defaultWorkspace ? defaultWorkspace._id : "myAgents");
  }, [myWorkspaces]);

  const isDesktop = useTailwindMediaQuery("lg");
  const nameToDisplay = user?.name || user?.email;

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel defaultSize={70} minSize={30}>
        <div className="flex h-full flex-1 flex-col text-center">
          <div className="relative mb-6 flex items-center justify-between px-6 pt-4">
            <div className="col-span-2 md:col-span-1">
              <h1 className="text-start text-[1.75rem] font-bold leading-9">Hey, {nameToDisplay}👋</h1>
              <p className="text-start text-sm leading-[1.375rem] text-secondary-400">
                Here is your workspace to build new agents
              </p>
            </div>

            {isDesktop && (
              <motion.button onClick={toggleQuickLinksSectionOpen} whileTap={{ y: 1, rotate: 180 }}>
                <Icons.Fold className={cn("size-6", !quickLinksSectionOpen && "rotate-180")} />
              </motion.button>
            )}
          </div>

          <WorkspaceBaseModels agents={basicAgents ?? []} isLoading={isAgentBasicPending} />
          <Tabs defaultValue={activeTab} onValueChange={value => setActiveTab(value)} style={{ height: "100%" }}>
            <TabsList className="w-full justify-start gap-2 overflow-x-scroll rounded-b-none border-b border-b-primary-200 bg-white px-6 data-[state=active]:shadow-none">
              <TabsTrigger
                value="myAgents"
                className="flex flex-row gap-2 rounded-md p-2 font-medium text-neutral-750 hover:bg-primary-100 hover:text-primary-400 data-[state=active]:rounded-b-none data-[state=active]:border-b-2 data-[state=active]:border-b-primary-400 data-[state=active]:bg-primary-100 data-[state=active]:text-primary-400"
              >
                <Icons.Workspaces /> My Agents
              </TabsTrigger>
              <div className="h-6 w-1 border-r border-r-primary-200" />
              {!!myWorkspaces?.length &&
                myWorkspaces.map(workspace => (
                  <TabsTrigger
                    key={workspace._id}
                    value={workspace._id}
                    className="flex flex-row gap-2 rounded-md p-2 font-medium text-neutral-750 hover:bg-primary-100 hover:text-primary-400 data-[state=active]:rounded-b-none data-[state=active]:border-b-2 data-[state=active]:border-b-primary-400 data-[state=active]:bg-primary-100 data-[state=active]:text-primary-400"
                  >
                    {workspace.name}
                    {workspace._id === activeTab && (
                      <Icons.Settings
                        className="size-4 cursor-pointer hover:text-primary-700"
                        onClick={e => {
                          e.stopPropagation();
                          setNavigationProfileOpen({ open: true, section: workspace._id, mode: "workspace" });
                        }}
                      />
                    )}
                  </TabsTrigger>
                ))}
              <AddWorkspaceButton />
            </TabsList>
            <TabsContent
              value="myAgents"
              className={cn(
                "mt-0 h-[calc(100vh-262px)] overflow-y-scroll bg-[#FCFAFF]",
                isMobile && "h-[calc(100vh-334px)]"
              )}
            >
              <MyAgentsSpace />
            </TabsContent>
            {!!myWorkspaces?.length &&
              myWorkspaces.map(workspace => (
                <TabsContent
                  key={workspace._id}
                  value={workspace._id}
                  className={cn(
                    "mt-0 h-[calc(100vh-262px)] overflow-y-scroll bg-[#FCFAFF]",
                    isMobile && "h-[calc(100vh-334px)]"
                  )}
                >
                  <CustomerSpace workspaceId={workspace._id} />
                </TabsContent>
              ))}
          </Tabs>

          {/*<MyAgentsSpace />*/}
          {/*{isCustomerUser && <CustomerSpace />}*/}
        </div>
      </ResizablePanel>

      {isDesktop && (
        <AnimatePresence initial={false}>
          {quickLinksSectionOpen ? (
            <motion.div
              initial={{ width: 0 }}
              transition={{ duration: 0.1 }}
              animate={{ width: "30%" }}
              exit={{ width: 0 }}
              key="box"
            >
              <div className="min-w-30 h-full">
                <WorkspaceWhatsNew />
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      )}
    </ResizablePanelGroup>
  );
};
