import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createCustomVoice } from "@/data/customVoice";
import type { AxiosError } from "axios";
import { getCustomVoiceTextQueryKey } from "@/data/queries/voice/useGetCustomVoiceText";
import type { ErrorData } from "@/data/initAxios";
import { getCustomVoiceListQueryKey } from "@/data/queries/voice/useGetCustomVoiceList";

export const useCreateCustomVoice = (onInvalidAudio: (message: string) => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCustomVoice,
    onSuccess: (_, { botId }) => {
      void queryClient.invalidateQueries({ queryKey: getCustomVoiceListQueryKey(botId) });
    },
    onError: (error: AxiosError, req) => {
      const errorData = error.response?.data as ErrorData;
      const { language } = req;

      if (error.response?.status === 404 || 410) {
        void queryClient.invalidateQueries({ queryKey: getCustomVoiceTextQueryKey(language) });
        onInvalidAudio(errorData.message);
      } else if (error.response?.status === 422) {
        onInvalidAudio(errorData.message);
      } else {
        onInvalidAudio("Something went wrong; please try gain");
      }
    },
  });
};
