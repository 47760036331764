import { useState } from "react";
import { useGetAllUsersConversationsWithAgent } from "@/data/queries/useGetAllUsersConversationsWithAgent";
import { useGetAgentConversationHistoryReport } from "@/data/queries/stats/useGetAgentConversationHistoryReport";
import { ChatHistoryDialogBase } from "@/components/ChatHistoryDialog/ChatHistoryDialogBase";
import { AgentChatHistoryDialogChannelPicker } from "./components/AgentChatHistoryDialogChannelPicker";

import type { HandleDownloadReportProps } from "@/data/queries/stats/useGetUserConversationHistoryReport";
import type { DateRangeType } from "@/types/datepicker";
import type { Agent } from "@/types/agent";
import type { Origin } from "@/types/origin";
import type {
  FetchConversationsArgs,
  FetchConversationsReturn,
} from "@/components/ChatHistoryDialog/types/chat-history-dialog";

type AgentChatHistoryDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  agentId: Agent["_id"];
  agentName: Agent["name"];
  initialDownvotedOnly?: boolean;
  initialCurrentRange?: DateRangeType;
  initialStartDate?: Date | null;
  initialEndDate?: Date | null;
  initialChannel?: Origin | null;
};

const createAgentConversationsHook = (agentId: string, onlyWithDownvotes: boolean, activeChannel: Origin | null) => {
  return (args: FetchConversationsArgs): FetchConversationsReturn => {
    const { data, isLoading } = useGetAllUsersConversationsWithAgent({
      ...args,
      agentId,
      enabled: !!agentId,
      startDate: args.startDate,
      endDate: args.endDate,
      currentRange: args.currentRange,
      searchValue: args.searchValue,
      onlyWithDownvotes,
      channels: activeChannel ? [activeChannel] : undefined,
    });

    return {
      data: data
        ? {
            conversations: data.conversations,
            totalSearchMatches: data.totalSearchMatches ?? 0,
          }
        : undefined,
      isLoading,
    };
  };
};

export const AgentChatHistoryDialog = ({
  isOpen,
  onClose,
  agentId,
  agentName,
  initialDownvotedOnly,
  initialCurrentRange,
  initialStartDate,
  initialEndDate,
  initialChannel,
}: AgentChatHistoryDialogProps) => {
  const [isOnlyDownvotedMessages, setIsOnlyDownvotedMessages] = useState<boolean>(initialDownvotedOnly ?? false);
  const [activeChannel, setActiveChannel] = useState<Origin | null>(initialChannel ?? null);

  const { downloadReport: agentDownloadReport, isLoadingDownloadReport } = useGetAgentConversationHistoryReport();

  const agentConversationsHook = createAgentConversationsHook(agentId, isOnlyDownvotedMessages, activeChannel);

  const handleDownloadReport = (props: Omit<HandleDownloadReportProps, "userId" | "userName">) => {
    void agentDownloadReport({
      agentId,
      agentName,
      ...props,
    });
  };

  return (
    <ChatHistoryDialogBase
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={`${agentName}'s conversations`}
      isOnlyDownvotedMessages={isOnlyDownvotedMessages}
      onIsOnlyDownvotedMessagesChange={setIsOnlyDownvotedMessages}
      fetchConversations={agentConversationsHook}
      downloadReport={handleDownloadReport}
      isLoadingDownloadReport={isLoadingDownloadReport}
      initialCurrentRange={initialCurrentRange}
      initialStartDate={initialStartDate}
      initialEndDate={initialEndDate}
      renderChannelPicker={() => (
        <AgentChatHistoryDialogChannelPicker
          activeChannel={activeChannel}
          onActiveChannelChange={channel => setActiveChannel(channel)}
        />
      )}
    />
  );
};
