import { apiPaths } from "./apiPaths";
import { restClient } from "./initAxios";
import type { VoiceProfileLanguage } from "@/types/voice";

type GetCustomVoiceTextResponse = {
  id: string;
  text: string;
  author: string;
  category: string;
  language: VoiceProfileLanguage;
};

export const getCustomVoiceText = async (language: VoiceProfileLanguage): Promise<GetCustomVoiceTextResponse> => {
  const token: string | null = localStorage.getItem("token");

  const response = await restClient.get<GetCustomVoiceTextResponse>(apiPaths.getCustomVoiceText(language), {
    headers: {
      Authorization: token ? `Token ${token}` : "",
    },
  });

  return response.data;
};

type CreateCustomVoiceRequest = {
  textSampleId: string;
  base64audio: string;
  botId: string;
  name: string;
  language: VoiceProfileLanguage;
};

type CreateCustomVoiceResponse = {
  success: boolean;
  similarity: number;
  url?: string;
};

export const createCustomVoice = async (data: CreateCustomVoiceRequest): Promise<CreateCustomVoiceResponse> => {
  const token: string | null = localStorage.getItem("token");

  const response = await restClient.post<CreateCustomVoiceResponse>(apiPaths.createCustomVoice, data, {
    headers: {
      Authorization: token ? `Token ${token}` : "",
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

export type CustomVoiceItem = {
  id: string;
  name: string;
  src: string;
  isActive: boolean;
  speed: number | null;
  language: VoiceProfileLanguage;
};

type GetCustomVoiceListResponse = CustomVoiceItem[];

export const getCustomVoiceListForAgent = async (botId: string): Promise<GetCustomVoiceListResponse> => {
  const token: string | null = localStorage.getItem("token");

  const response = await restClient.get<GetCustomVoiceListResponse>(apiPaths.getCustomVoiceList(botId), {
    headers: {
      Authorization: token ? `Token ${token}` : "",
    },
  });

  return response.data;
};

export const deleteCustomVoice = async (botId: string, customVoiceId: string): Promise<void> => {
  const token: string | null = localStorage.getItem("token");

  await restClient.delete(apiPaths.deleteCustomVoice(botId, customVoiceId), {
    headers: {
      Authorization: token ? `Token ${token}` : "",
    },
  });
};

type UpdateCustomVoiceRequest = {
  voiceId: string;
  speed?: number;
  name?: string;
};

type UpdateCustomVoiceResponse = void;

export const updateCustomVoice = async (
  botId: string,
  data: UpdateCustomVoiceRequest
): Promise<UpdateCustomVoiceResponse> => {
  const token: string | null = localStorage.getItem("token");

  const response = await restClient.put<UpdateCustomVoiceResponse>(
    apiPaths.updateCustomVoice(botId, data.voiceId),
    data,
    {
      headers: {
        Authorization: token ? `Token ${token}` : "",
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};
