import { KnowledgeStatus } from "@/types/agent";
import { Icons } from "@/components/ui/icons";
import { Badge } from "@/components/ui/Badge";
import { cn } from "@/lib/utils";

export const KnowledgeStatusBadge = ({ status, className }: { status: string; className?: string }) => {
  const statusConfig = {
    [KnowledgeStatus.FAILED]: {
      icon: <Icons.Error />,
      text: "Failed",
      className: "border-red-500 bg-red-50",
    },
    [KnowledgeStatus.REFRESH]: {
      icon: <Icons.Refresh />,
      text: "Refresh",
      className: "border-primary-500 bg-primary-50",
    },
    [KnowledgeStatus.NEW]: {
      icon: <Icons.File className="size-4 p-[1px] text-green-500" />,
      text: "New",
      className: "border-green-500 bg-green-50",
    },
  };

  const config = statusConfig[status as keyof typeof statusConfig];

  if (!config) {
    return null;
  }

  return <Badge icon={config.icon} text={config.text} className={cn(config.className, className)} />;
};
