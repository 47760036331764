import { ALL_AGENTS_STATS_LEGEND } from "../../constants/statsConstants";
import { StatsAllAgentsPerformanceChart } from "./StatsAllAgentsPerformanceChart";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import { useGetAllAgentsStats } from "@/data/queries/stats/useGetAllAgentsStats";
import { statsMockAllAgentsData } from "../../constants/statsMockData";
import type { Agent } from "@/types/agent";
import { StatsMainChartSkeleton } from "../loaders/EngagementOverviewSkeleton";
import { isAgentInsideArray } from "@/lib/isAgentInsideArray";

export const StatsAllAgentsPerformance = () => {
  const {
    currentRange,
    startDate,
    endDate,
    activeChannelsOrigins,
    isUpgradeNeeded,
    selectedAgents,
    setSelectedAgents,
    isDefaultWorkspace,
    activeWorkspaceId,
  } = useStatsContext();

  const { data: allAgentsData, isFetching: isFetchingAllAgents } = useGetAllAgentsStats({
    currentRange,
    startDate,
    endDate,
    channels: activeChannelsOrigins,
    enabled: !isUpgradeNeeded,
    workspaceId: isDefaultWorkspace ? undefined : activeWorkspaceId,
  });
  const allAgentsDataToShow = isUpgradeNeeded
    ? statsMockAllAgentsData
    : allAgentsData?.filter(agent => isAgentInsideArray(agent.agent._id, selectedAgents)) ?? [];

  const handleOpenSingleAgentChart = (agent: Agent) => {
    setSelectedAgents([agent]);
  };

  return (
    <>
      <div className="my-6 flex flex-wrap items-center gap-x-8 gap-y-2">
        {Object.keys(ALL_AGENTS_STATS_LEGEND).map(key => (
          <div key={key} className="flex items-center gap-2">
            <div
              className="h-4 w-4 rounded-full"
              style={{
                backgroundColor: ALL_AGENTS_STATS_LEGEND[key as keyof typeof ALL_AGENTS_STATS_LEGEND].color,
              }}
            />

            {ALL_AGENTS_STATS_LEGEND[key as keyof typeof ALL_AGENTS_STATS_LEGEND].label}
          </div>
        ))}
      </div>

      {isFetchingAllAgents ? (
        <StatsMainChartSkeleton />
      ) : (
        <StatsAllAgentsPerformanceChart data={allAgentsDataToShow} onOpenAgentChart={handleOpenSingleAgentChart} />
      )}
    </>
  );
};
